import type {
  API,
  AccountAccessToken,
  GenericApiResponse,
  ResetPasswordSession,
  Session,
  Subscriber,
  SubscriberRegistrationStatus,
} from '@setplex/tria-api'
import { SubscriberGender } from '@setplex/tria-api'
import { type SubscriberInfoDto } from '@setplex/wbs-api-types'
import { type HttpClient } from '../http'
import type { AdapterDefaults } from '../index.h'
import type { ApiAnswerSubscriberInfo } from '../interfaces/subscriber'
import { toDate } from '../tools'

function transformer(dto: SubscriberInfoDto): Subscriber {
  return {
    ...dto,
    name: dto.firstName || '',
    gender: {
      MALE: SubscriberGender.Male,
      FEMALE: SubscriberGender.Female,
      OTHER: SubscriberGender.Other,
      NOT_KNOWN: SubscriberGender.Unknown,
      NOP_APPLICABLE: SubscriberGender.NopApplicable,
    }[dto.gender || 'NOT_KNOWN'],
    phone: dto.phoneNumber,
    birthday: toDate(dto.dateOfBirth),
  }
}

export function use(
  http: HttpClient,
  subscriber: API['subscriber'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  // get subscriber info
  subscriber.getFx.use(async () => {
    const json = await http.get<ApiAnswerSubscriberInfo>(`/v3/account`)
    return transformer(json.payload || {})
  })

  // Returns info about features
  subscriber.getFeaturesFx.use(async () => {
    const json = await http.get<GenericApiResponse<any>>(`/v3/features`)
    return json.payload
  })

  // In-app subscriber registration by email
  subscriber.signUpFx.use(async ({ email, password, username }) => {
    const json = await http.post<
      GenericApiResponse<{
        id: string
        time_value: number
        time_period: string
      }>
    >( // TODO openapi missing this request
      `/v3/subscribers/register`,
      {
        json: {
          email,
          password,
          username,
        },
      }
    )
    return json.payload
  })

  // In-app subscriber registration by phone
  subscriber.signUpByPhoneFx.use(async ({ phoneNumber }) => {
    const json = await http.post<
      GenericApiResponse<{
        id: string
        time_value: number
        time_period: string
      }>
    >( // TODO openapi missing this request
      `/../auth/phone/init`,
      {
        json: {
          phoneNumber,
        },
      }
    )
    return json.payload
  })

  // Get status of registration process by id
  subscriber.getRegistrationStatusFx.use(async ({ id }) => {
    const json = await http.get<
      GenericApiResponse<{ status: SubscriberRegistrationStatus }>
    >( // TODO openapi missing this request
      `/v3/subscribers/register/${id}`
    )
    return json.payload
  })

  // Resend confirmation email
  subscriber.resendConfirmationEmailFx.use(async ({ id }) => {
    await http.post<GenericApiResponse<void>>(
      `/v3/subscribers/register/resend`,
      {
        json: {
          id,
        },
      }
    )
  })

  // Resend confirmation phone
  subscriber.resendConfirmationPhoneFx.use(async ({ id }) => {
    await http.post<GenericApiResponse<void>>(`/../auth/phone/resend`, {
      json: {
        id,
      },
    })
  })

  // Confirm registration code from email
  subscriber.confirmCodeRegistrationFx.use(async ({ id, code }) => {
    const json = await http.post<
      GenericApiResponse<{ firstName: string; lastName: string; email: string }>
    >(`/v3/subscribers/register/confirm`, {
      json: {
        id,
        code,
      },
    })
    return json.payload
  })

  // Confirm registration code from email
  subscriber.confirmCodeRegistrationByPhoneFx.use(
    async ({ id, code, rememberMe }) => {
      const { response, payload } = await http.post.raw<
        GenericApiResponse<Session>
      >(`/../auth/phone`, {
        json: {
          id,
          code,
          rememberMe,
          manualProfileManagement: true, // prevents automatic profile creation on the backend
        },
        headers: {
          'MW-Paid-Content-Allowed': 'true',
          'MW-InApp-Subscription-Payment-Supported': 'true',
        },
      })
      return { payload: payload?.payload, code: response.status }
    }
  )

  // Start reset password process, send email with reset code, returns session identifier for reset password operations
  subscriber.initResetPasswordFx.use(async ({ email }) => {
    const json = await http.post<GenericApiResponse<ResetPasswordSession>>(
      `/v3/account/password/initiate`,
      {
        json: {
          email,
        },
      }
    )
    return json.payload
  })

  // Resend resetCode
  subscriber.resendResetPasswordCodeFx.use(async ({ id }) => {
    await http.post<GenericApiResponse<ResetPasswordSession>>(
      `/v3/account/password/resend`,
      {
        json: {
          id,
        },
      }
    )
  })

  // Verify reset password code
  subscriber.verifyResetPasswordCodeFx.use(async ({ id, code }) => {
    await http.post<GenericApiResponse<ResetPasswordSession>>(
      `/v3/account/password/verify`,
      {
        json: {
          id,
          resetCode: code,
        },
      }
    )
  })

  // Reset password, set a new one
  subscriber.setNewPasswordFx.use(async ({ id, code, password }) => {
    await http.post<GenericApiResponse<ResetPasswordSession>>(
      `/v3/account/password/reset`,
      {
        json: {
          id,
          resetCode: code,
          password,
        },
      }
    )
  })

  subscriber.getAccountAccessTokenFx.use(async () => {
    return await http.get<AccountAccessToken>(`/v3/account/access_token`)
  })
}
