import { type SkinView } from '../../index.h'

/**
 * Get/generate key for components, using alias, id and index
 *
 * TODO: maybe add `key` getter function?
 *
 * @param skin - skin part
 * @param index - optional index for list renders
 * @returns - string key
 */
export function key(skin: SkinView, index?: number): string {
  let key: string = skin.id
  if (skin.alias) {
    key += '/' + skin.alias
  }
  if (index !== undefined) {
    key += '/' + index
  }
  return key
}

/**
 * Get part of object/array by path
 */
export const getPath = (obj: any, p: string[]): unknown => {
  let ret = obj
  for (const x of p) {
    if (ret == null) return
    ret = ret[x]
  }
  return ret
}
