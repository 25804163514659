export { MIN_PROGRAM_LENGTH_MS } from './constants/tv'
export {
  RECOMMENDED_AMOUNT_SHOWS,
  RECOMMENDED_AMOUNT_SHOWS_AND_CURRENT,
} from './constants/tvshows'
export {
  RECOMMENDED_AMOUNT_VODS,
  RECOMMENDED_AMOUNT_VODS_AND_CURRENT,
} from './constants/vods'
export type {
  AppearanceColorStyle,
  AppearanceImageStyle,
  AppearanceLinearGradientStyle,
  AppearanceRow,
  AppearanceRowStyle,
  AppearanceStyle,
  AppearanceStyleSet,
  AppearanceUnsetStyle,
} from './entities/appearance'
export { CarouselTypes } from './entities/banners'
export type {
  Banner,
  BannerCarousel,
  BannerContentItem,
  BannerContentItemPrice,
  BannerUpdateObj,
  BannerWithPrice,
  CarouselItem,
  CarouselItemTyped,
  FeaturedCarousel,
  FeaturedCarouselTyped,
} from './entities/banners'
export type {
  AccountDeviceInfo,
  Session,
  SignInParams,
  TOA,
} from './entities/session'
export type { ShowBundleWithContent } from './entities/showsBundles'
export {
  SubscriberGender,
  SubscriberRegistrationStatus,
  type AccountAccessToken,
  type Features,
  type ResetPasswordSession,
  type Subscriber,
} from './entities/subscriber'
export type { LogicError } from './events/logic'
export {
  RequestCancelledError,
  RequestError,
  RequestFailedError,
  RequestHttpError,
  RequestTimeoutError,
  type RequestInput,
  type RequestOutput,
} from './fx'
export type {
  API,
  Adapter,
  ApiError,
  Page,
  PageInfo,
  PageInfoCollection,
  PageInfoNew,
  PageInfoWithCursor,
  PageNew,
  PageWithCursor,
  PageableInfo,
  PageableInfoNew,
} from './index.h'
export type {
  AnalyticsEventType,
  AnalyticsGenericInfo,
  AnalyticsInfo,
  ApplicationInfo,
  BEAnalyticsInfo,
  ClientPartAnalytics,
  ClientPartStartStopAnalytics,
  IContentInfo,
  VideoSourceInfo,
} from './interfaces/analytics'
export type {
  BundleInfo,
  TvBundleInfo,
  TvBundleWithContent,
  TvShowBundleInfo,
  VodBundleInfo,
  VodBundleWithContent,
} from './interfaces/bundles'
export { CartPaymentMethodEnum } from './interfaces/cart'
export type {
  CartPaymentMethod,
  IANetPayRequest,
  ICartConfirmPurchaseInfo,
  ICartCoupon,
  ICartCreditCardData,
  ICartFullInfo,
  ICartInvoice,
  ICartItem,
  ICartItemDiscount,
  ICartPayment,
  IFinalProcessPayment,
} from './interfaces/cart'
export {
  ScheduleType,
  type Catchup,
  type CatchupProgram,
  type CatchupProgramsFormatedToObjectWithDatelist,
  type CatchupProgramsWithDates,
  type CatchupsDateList,
} from './interfaces/catchups'
export { PaymentChannel } from './interfaces/creditCards'
export type {
  ICreditCard,
  ICreditCardSettings,
  ICreditCardsConfig,
} from './interfaces/creditCards'
export type { TimeRange } from './interfaces/epg'
export {
  GlobalSearchMediaTypes,
  LiveEventsTypes,
  MediaOrderType,
  MediaResolution,
  MediaTypes,
  ResourceTypes,
  VideoType,
  type ApiCommonResponse,
  type ContentStore,
  type DRM,
  type GenericApiResponse,
  type LaData,
  type MediaUrl,
  type WrappedData,
} from './interfaces/generic'
export type { ILocale, INoraTranslationsFormat } from './interfaces/i18n'
export { EventStatus, type LiveEvent } from './interfaces/live-events'
export type {
  ICartChannel,
  ICartChannelSet,
  ICartItemInfo,
  ICartNewCreditCardData,
  ICartPaymentPropertySettings,
  ICartProperties,
  ICartSavedCreditCardData,
  ICartTvShow,
  ICartTvShowEpisode,
  ICartTvShowSeason,
  ICartTvShowSet,
  ICartVod,
  ICartVodSet,
} from './interfaces/paymentInfo'
export type { IPlaylist, PlayerUrlError } from './interfaces/player'
export {
  PriceSettingsAvailabilityPeriodTimeUnit,
  PriceSettingsFormattedType,
  PriceSettingsPaymentMethod,
  PriceSettingsType,
  type FormattedPriceSettings,
} from './interfaces/priceSettings'
export type { IProfile } from './interfaces/profiles'
export {
  PurchaseInfoContentPurchaseType,
  type PurchaseInfo,
} from './interfaces/purchaseInfo'
export {
  PaymentEnvEnum,
  PaymentStatusEnum,
  PurchasePaymentChannelEnum,
} from './interfaces/purchases'
export type {
  IPaymentListChannels,
  IPurchaseInvoice,
  IPurchasePayment,
  IPurchasePaymentItem,
  IPurchasePaymentItemContentId,
  IPurchasesPaymentDiscount,
  IPurchasesPaymentInfo,
  PaymentStatus,
  PurchasePaymentChannel,
  PurchasePaymentMethod,
} from './interfaces/purchases'
export type {
  CategoriesWithPageChannelsEpg,
  CategoryWithChannels,
  CategoryWithChannelsApi,
  EpgInfo,
  EpgInfoDto,
  LiveEpgInfo,
  PlaylistChannel,
  TvCategory,
  TvChannel,
  TvChannelUpdateObj,
  TvChannelUpdated,
  TvChannelWithEpg,
  TvEpg,
  TvEpgApiDto,
  TvPlaylist,
} from './interfaces/tv'
export {
  type IGenerateSeriesPlaylistParams,
  type SeriesPlaylistRecalculate,
  type TVShowsPlaylist,
  type TvShowSeasonWithEpisodes,
} from './interfaces/tvshows'
export type {
  CategoryWithShows,
  CategoryWithShowsApi,
  CurrentTvShow,
  PlaylistSeason,
  SeriesPlaylist,
  TvShow,
  TvShowCategory,
  TvShowEpisode,
  TvShowRecommended,
  TvShowSeason,
  TvShowUpdateObj,
  TvShowUpdated,
} from './interfaces/tvshows'
export type {
  CategoryWithVods,
  Vod,
  VodCategory,
  VodPlaylist,
  VodRecommended,
  VodSpecialCategoryWithVodsRecommended,
  VodUpdateObj,
  VodUpdated,
} from './interfaces/vods'

export type {
  IContinueWatchingCard,
  IContinueWatchingCardEpisode,
} from './interfaces/continue-watching'

export type {
  GetPartnerProductParams,
  GetPartnerProductsByPageParams,
  PartnerProduct,
  PartnerProductDetails,
} from './interfaces/partnerProgram'

import { fx } from './fx'
import { type Adapter } from './index.h'

import * as analytics from './entities/analytics'
import * as announcements from './entities/announcements'
import * as appearance from './entities/appearance'
import * as banners from './entities/banners'
import * as cart from './entities/cart'
import * as continueWatching from './entities/continue-watching'
import * as creditCards from './entities/creditCards'
import * as globalSearch from './entities/global-search'
import * as i18n from './entities/i18n'
import * as liveEvents from './entities/live-events'
import * as notifications from './entities/notifications'
import * as partnerProgram from './entities/partnerProgram'
import * as player from './entities/player'
import * as profiles from './entities/profiles'
import * as purchases from './entities/purchases'
import * as session from './entities/session'
import * as showBundles from './entities/showsBundles'
import * as subscriber from './entities/subscriber'
import * as tv from './entities/tv'
import * as tvBundles from './entities/tvBundles'
import * as tvshows from './entities/tvshows'
import * as vods from './entities/vods'
import * as vodBundles from './entities/vodsBundles'
import * as events from './events'

import * as catchups from './entities/catchups'
export const version: string = '__VERSION__'

/**
 * Singleton with API layer,
 * provides events/effects/stores to communicate with backend
 */
export const api = {
  fx,

  use(adapter: Adapter) {
    adapter(this)
  },

  events,

  analytics,
  appearance,
  banners,
  liveEvents,
  session,
  subscriber,
  tv,
  tvBundles,
  vodBundles,
  showBundles,
  tvshows,
  vods,
  player,
  cart,
  creditCards,
  purchases,
  globalSearch,
  catchups,
  partnerProgram,
  announcements,
  continueWatching,
  notifications,
  profiles,
  i18n,
} as const
