export type PaymentStatus =
  | 'PENDING_PAYMENT'
  | 'PAID'
  | 'CANCELLED'
  | 'PAYMENT_ERROR'

export enum PurchasePaymentChannelEnum {
  AUTHORIZE_NET = 'AUTHORIZE_NET',
  CASH = 'CASH',
  CCPP = 'CCPP',
  CHECK = 'CHECK',
  EXTERNAL_PAYMENTS = 'EXTERNAL_PAYMENTS',
  FREE = 'FREE',
  GR4VY_GATEWAY = 'GR4VY_GATEWAY',
  NEWEBPAY = 'NEWEBPAY',
  PAYPAL = 'PAYPAL',
  TELR = 'TELR',
  TOL = 'TOL',
  TRUE_MONEY = 'TRUE_MONEY',
  UNKNOWN = 'UNKNOWN',
}

export enum PaymentStatusEnum {
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
}

export type PurchasePaymentMethod = 'CREDIT_CARD' | 'CONVENIENT_STORE'

export interface IPurchasePayment {
  transactionNumber?: string
  channel: PurchasePaymentChannelEnum
  method: PurchasePaymentMethod
  creditCardNumber: string
  time: string
}

export interface IPurchaseInvoice {
  type:
    | 'EZPAY_DONATION'
    | 'EZPAY_MEMBER_CARRIER'
    | 'EZPAY_MOBILE_BARCODE_CARRIER'
    | 'EZPAY_CITIZEN_DIGITAL_CERT_CARRIER'
    | 'EZPAY_B2B'
    | 'DEFAULT'
  number: string
}

export interface IPurchasesPaymentDiscount {
  code: string
  name: string
  amountOff: number
}

export interface IPurchasePaymentItemContentId {
  channelId?: number
  channelSetId?: number
  vodId?: number
  vodSetId?: number
  tvShowId?: number
  tvShowSetId?: number
  seasonId?: number
  episodeId?: number
  liveEventId?: number
}

export interface IPurchasePaymentItem {
  contentId: IPurchasePaymentItemContentId
  name: string
  description: string
  category:
    | 'TV_SHOW'
    | 'TV_SHOW_SET'
    | 'SEASON'
    | 'EPISODE'
    | 'VOD'
    | 'VOD_SET'
    | 'CHANNEL'
    | 'CHANNEL_SET'
    | 'LIVE_EVENT'
  availableFrom: string
  availableTo: string
  originalPrice: number
  price: number
  currency: string
  discounts: Array<IPurchasesPaymentDiscount>
}

export interface IPurchasesPaymentInfo {
  orderNumber: string
  placementTime: string
  status: PaymentStatus
  payment: IPurchasePayment
  invoice: IPurchaseInvoice
  items: Array<IPurchasePaymentItem>
  totalPrice: number
  currency: string
}

export enum PaymentEnvEnum {
  sandbox = 'sandbox',
  production = 'production',
}

export interface PurchasePaymentChannel {
  paymentChannel: PurchasePaymentChannelEnum
  environment: PaymentEnvEnum
  autoPayAllowed: boolean
}

export interface IPaymentListChannels extends Array<PurchasePaymentChannel> {}
