import type { TFunction } from '!/i18n'
import type {
  TVShowsPlaylist,
  TvShowEpisode,
  TvShowSeasonWithEpisodes,
} from '@setplex/tria-api'
import {
  extendMediaItemByLabelPriceTvShowEpisode,
  extendMediaItemByLabelPriceTvShowSeason,
} from '~/shared/tools'

const formatSeasonEpisode = ({
  episode,
  t,
}: {
  episode: TvShowEpisode
  t: TFunction
}) => {
  const { labelPrice, rented } =
    extendMediaItemByLabelPriceTvShowEpisode(episode)

  return {
    ...episode,
    title: t('episode_number', { number: episode.displayNumber }),
    labelPrice,
    rented,
  }
}

const formatSeason = ({
  season,
  t,
}: {
  season: TvShowSeasonWithEpisodes
  t: TFunction
}) => {
  const { labelPrice, rented } = extendMediaItemByLabelPriceTvShowSeason(season)

  return {
    ...season,
    title: t('episode_count', {
      count: season.episodesAmount,
    }),
    labelPrice,
    rented,
    episodes: season.episodes.map((episode) =>
      formatSeasonEpisode({ episode, t })
    ),
  }
}

export const playlistFormatter = (playlist: TVShowsPlaylist, t: TFunction) => {
  if (playlist.seasonsWithEpisodes) {
    return {
      ...playlist,
      seasonsWithEpisodes: playlist?.seasonsWithEpisodes?.map((season) =>
        formatSeason({ season, t })
      ),
    }
  }

  if (playlist.episodes) {
    return {
      ...playlist,
      episodes: playlist?.episodes?.map((episode) =>
        formatSeasonEpisode({ episode, t })
      ),
    }
  }

  return playlist
}
