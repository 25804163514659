import { exists } from '@/helpers'
import { D } from '@mobily/ts-belt'
import { memo, useMemo, type MemoExoticComponent } from 'react'
import { type SkinView } from '../../index.h'
import { key, useRaw, type Scope, type ScopeList } from '../core'
import { ManyItem } from './ManyItem'
import { type Recursive } from './Recursive'

export type MapSkinView = SkinView & Required<Pick<SkinView, 'map'>>
export type NoMapSkinView = Omit<SkinView, 'map'>

interface Props {
  scopeList: ScopeList
  skin: MapSkinView
  parent: Scope
  Recursive: Recursive
  [key: string]: unknown // any additional props, will be propogated to all elements in list
}

// exports memoized component
export const Map: MemoExoticComponent<typeof _Map> = memo(_Map)

// _Map.whyDidYouRender = true
_Map.displayName = 'Map'
function _Map({
  scopeList,
  skin,
  parent,
  Recursive,
  ...props
}: Props): JSX.Element | null {
  const { list: rawList, name, index } = scopeList
  const list = useRaw(rawList) as unknown[] | null

  // it is crucial to omit `map` field from skin, to prevent infinite loop
  const skinNoMap: NoMapSkinView = useMemo(
    () => D.deleteKey(skin, 'map'),
    [skin]
  )

  // memoized render each element in list
  const kiddos: JSX.Element[] | null = useMemo(() => {
    if (!list) return null
    return list.map((item, i) => {
      return (
        <ManyItem
          {...props}
          key={key(skin, i)}
          i={i}
          item={item}
          name={name}
          index={index}
          skin={skinNoMap}
          parent={parent}
          Recursive={Recursive}
        />
      )
    })
  }, [Recursive, index, list, name, parent, props, skin, skinNoMap])

  return kiddos ? <>{kiddos}</> : null
}

export function hasMap(skin: SkinView): skin is MapSkinView {
  return exists(skin.map)
}
