import { type FormattedPriceSettings } from './priceSettings'
import { type PurchaseInfo } from './purchaseInfo'

export enum EventStatus {
  LIVE = 'LIVE',
  SOON = 'SOON',
  LIVE_SOON = 'LIVE_SOON',
  ENDED = 'ENDED',
}

export enum LiveEventsSortBy {
  START_TIME = 'startTime',
  UPDATED_TIME = 'updatedTime',
}

export enum LiveEventsSortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export interface LiveEvent {
  id: number
  externalId?: string
  name?: string
  description?: string
  ageRatings?: string
  free?: boolean
  status: EventStatus
  startTime?: string
  endTime?: string
  updated?: string
  locked?: boolean
  verticalPosterUrl?: string
  videoPosterUrl?: string
  videoVerticalPosterUrl?: string
  bannerPosterUrl?: string
  priceSettings?: FormattedPriceSettings[]
  purchaseInfo?: PurchaseInfo
  isLive?: boolean
  minCurrency?: string
  minPrice?: number
  duration?: string
  titleButton?: string
  formattedStartTime?: string
  rented: boolean
  recordAvailableFromTime?: string
  recordAvailableFromTimeMS?: number
  recorded?: boolean
  liveRewind?: boolean
  recordAvailableToTime?: string
}
export interface GetLiveEventsParams {
  status: EventStatus
  page: number
  count?: number
  sortOrder?: LiveEventsSortOrder
  sortBy?: LiveEventsSortBy
  ids?: string
  title?: string
  free?: string
}

type TPurchasedLiveEventsMyList = 'myList'
export interface GetPurchasedLiveEventsParams {
  page?: number
  count?: number
  sortBy?: LiveEventsSortBy
  sortOrder?: LiveEventsSortOrder
  // It can be any string, for wbs it doesn't matter
  myList?: TPurchasedLiveEventsMyList
}
