import {
  ButtonGroup,
  CancelSecondaryOpacityButton,
  CreateProfile,
  ErrorMessage,
  FormSC,
  ProfileInputBlock,
  SavePrimaryButton,
} from '@setplex/arcana'
import { useForm } from 'effector-forms'
import { useUnit } from 'effector-react'
import { useCallback, type ChangeEvent, type FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { USERNAME_CHARS_MAX_COUNT } from './index.h'
import { nameMaxLengthFormatter } from './lib'
import {
  $errorKey,
  $formTitle,
  $pending,
  $profilesCount,
  cancel,
  createProfileForm,
} from './model'
import { $createProfileTranslations } from './translations'

export const CreateProfileForm = ({
  isPopup = true,
}: {
  isPopup?: boolean
}): JSX.Element => {
  const formTranslations = useUnit($createProfileTranslations)

  const formTitle = useUnit($formTitle)

  const profilesCount = useUnit($profilesCount)

  const { submit, fields, errorText, hasError, eachValid } =
    useForm(createProfileForm)

  const onCancel = useUnit(cancel)

  const errorKey = useUnit($errorKey)
  const { t } = useTranslation()

  const pending = useUnit($pending)

  const handleChangeUsername = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const profileNameFormat = nameMaxLengthFormatter(
        target.value.trimStart(),
        USERNAME_CHARS_MAX_COUNT
      )
      fields.username.onChange(profileNameFormat)
    },
    [fields.username]
  )

  const getErrorMessage = (type: Parameters<typeof errorText>[0]): string => {
    const text = errorText(type, formTranslations[type].errors)

    return hasError(type) ? text : ''
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    fields.username.onChange(fields.username.value.trim())
    submit()
  }

  return (
    <>
      <CreateProfile avatarTitle={fields.username.value} title={formTitle}>
        <FormSC onSubmit={onSubmit} autoComplete='off' noValidate>
          <ProfileInputBlock
            label={formTranslations.username.title}
            value={fields.username.value}
            onChange={handleChangeUsername}
            placeholder={formTranslations.username.placeholder}
            errorMessage={getErrorMessage('username')}
          />
          <ButtonGroup>
            <SavePrimaryButton
              title={formTranslations.save}
              type='submit'
              disabled={pending || !eachValid}
            />
            {
              // if this form is in popup -> show cancel button, because there are definitely more than 1 profile
              (isPopup ||
                // if this form is in page -> show cancel button, if there are more than 1 profile
                // this prevents "Cancel" button blinks on first profile creation
                profilesCount > 1) && (
                <CancelSecondaryOpacityButton
                  onClick={onCancel}
                  title={formTranslations.cancel}
                />
              )
            }
          </ButtonGroup>
        </FormSC>
      </CreateProfile>
      {errorKey && <ErrorMessage width='100%' title={t(errorKey)} />}
    </>
  )
}
