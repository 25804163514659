import { createEvent, sample } from 'effector'
import { features, model as flags } from '~/core/flags'
import { model as i18n } from '~/core/i18n'
import { model as session } from '~/entities/session'
import { signInByTrueIdFeatureModel as trueid } from '~/features/subscriber/sign-in'
import { model as config } from '~/shared/config'
import { firebaseSharedModel as firebase } from '~/shared/firebase'
import { F, T } from '~/shared/helpers'
import { keys } from '~/shared/jail'
import { type Cage, type Transit } from '../index.h'

/*
 * ### Lobby cage ###
 * App goes here only once, on a startup.
 * It's a place to load all the necessary flags, features and restore session, if any.
 */

const init = createEvent<Transit>('init')
const seal = createEvent<boolean>('seal')

// out events
const preparationsReady = createEvent('preparationsReady')

export const lobby = {
  name: 'lobby',
  key: keys.lost,
  init,
  seal,
  in: [],
  out: {
    preparationsReady,
  },
} satisfies Cage

/*
 * cage logic
 */

sample({
  clock: init,
  target: seal.prepend(T),
})

sample({
  clock: init,
  target: [
    flags.init, // load features
    firebase.init, // set firebase remote config defaults and fetch config
    session.init, // restore session
    trueid.init, // init trueId
  ],
})

sample({
  source: [
    features.$ready, // features are ready
    config.$ready, // firebase remote config is ready
    session.$ready, // session is ready
    trueid.$ready, // trueId is ready
    i18n.$ready,
  ],
  filter: (flags) => flags.every(Boolean),
  target: preparationsReady,
})

sample({
  clock: preparationsReady,
  target: seal.prepend(F),
})
