import { is, type StoreWritable } from 'effector'

// TODO: redo using regular effector's domains
// accepts [api.vods, api.tv]
export const getOnlyStoresFromApiDomains = (apiDomains: unknown[]) =>
  apiDomains.flatMap((domain) =>
    domain != null && typeof domain === 'object'
      ? (Object.values(domain).filter(
          (unit) => is.store(unit) && is.targetable(unit)
        ) as StoreWritable<unknown>[])
      : []
  )
