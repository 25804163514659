export enum PurchaseInfoContentPurchaseType {
  Bought = 'BOUGHT',
  Rented = 'RENTED',
  RentedUnlimited = 'RENTED_UNLIMITED',
}

export interface PurchaseInfo {
  contentPurchaseType?: PurchaseInfoContentPurchaseType
  /**
   * DateTime in UTC until content is rented
   */
  rentedUntil?: string
}
