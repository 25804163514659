import { api, type Session } from '@setplex/tria-api'
import { createEffect, createStore, sample } from 'effector'
import { logger } from '~/shared/logger'
import { $featuredFlags, set } from './features'

// full wbs version from session
// only set once, because application works with single wbs instance,
// and version cannot be changed even if user logs out and logs in again
export const $wbsRawVersion = createStore<string | null>(null)

sample({
  clock: api.session.$session,
  filter: (session: Session | null): session is Session =>
    session !== null && session.version !== null,
  fn: (session) => session.version ?? null,
  target: $wbsRawVersion,
})

// parse version
type WbsVersion = [number, number, number?, string?]

const parseFx = createEffect((version: string): WbsVersion => {
  const match = version.match(/^(\d+)\.(\d+)(?:\.(\d+))?(.*)$/)
  if (!match) {
    throw new Error(`invalid version format: ${version}`)
  }
  return [Number(match[1]), Number(match[2]), Number(match[3]) || 0, match[4]]
})

// parsed version
export const $wbsVersion = createStore<WbsVersion | null>(null)

sample({
  clock: $wbsRawVersion,
  filter: (version) => version !== null,
  target: parseFx,
})

sample({
  clock: parseFx.doneData,
  target: $wbsVersion,
})

sample({
  clock: parseFx.failData,
  fn: (fail) => ['failed to parse version:', fail],
  target: logger.errorFx,
})

/*
 * Adjust flags, features and options depending on version
 * Version parsing and adjusting behaviour from the version is ad hoc solution for specific cases,
 * and we need more general and robust approach, which should be implemented in future
 */

// remove feature `appearanceCustomizationEnabled` in case version < 3.3
// see more about this issue - https://bldsoft.slack.com/archives/C02LLFVSJQ0/p1727791152684229
// after version 3.3 will be deployed everywhere, feature flag `appearanceCustomizationEnabled` will be permanently `true`,
// so we will be able to remove this flag from features at all later
sample({
  source: { version: $wbsVersion, features: $featuredFlags },
  filter: ({ version, features }) =>
    version !== null &&
    lt(version, [3, 3, 0]) && // version < 3.3
    features.appearanceCustomizationEnabled != null, // feature exists and set to true or false
  fn: () => ({ appearanceCustomizationEnabled: undefined }), // erase it
  target: set,
})

/*
 * Helpers
 */

function lt(a: WbsVersion, b: WbsVersion): boolean {
  if (a[0] !== b[0]) return a[0] < b[0]
  if (a[1] !== b[1]) return a[1] < b[1]
  if (a[2] !== b[2]) return (a[2] || 0) < (b[2] || 0)
  return false
}
