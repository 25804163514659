import { attach, createEffect, createStore } from 'effector'
import type { ApiEffect, PageNew, PageableInfoNew } from '../index.h'
import type { TvBundleInfo, TvBundleWithContent } from '../interfaces/bundles'
import type { TvChannel } from '../interfaces/tv'

// *
// * EFFECTS
// *

// GET /v3/bundles/channels
export const getTvBundlesFx: ApiEffect<
  {
    page: number
    channelId?: number
    count?: number
    sortOrder?: string
    sortBy?: string
  },
  PageNew<TvBundleInfo>
> = createEffect()

// GET /v3/bundles/channels/{bundleId}
export const getTvBundleInfoFx: ApiEffect<{ bundleId: number }, TvBundleInfo> =
  createEffect()

// GET /v3/bundles/channels/{bundleId}/content
export const getTvBundleContentFx: ApiEffect<
  { bundleId: number; page: number; count?: number },
  PageNew<TvChannel>
> = createEffect()

export const loadTvBundlePayInfo = attach({ effect: getTvBundleInfoFx })

/**************** $TvBundleWithContent *******************/
export const loadTvBundleWithContentFx: ApiEffect<
  { bundleId: number; page: number; count?: number },
  TvBundleWithContent
> = createEffect()
export const loadTvBundleInfoForContent = attach({ effect: getTvBundleInfoFx })
export const paginateTvBundleWithContent = attach({
  effect: getTvBundleContentFx,
}) // to paginate loadTvBundleWithContentFx

export const $tvBundleWithContent = createStore<TvBundleWithContent | null>(
  null
)
  .on(loadTvBundleWithContentFx.doneData, (_, data) => data)
  .on(paginateTvBundleWithContent.doneData, (content, payload) => {
    if (!content) return null

    const currentChannels = content.channels || []

    return {
      ...content,
      channels: [...currentChannels, ...payload.content],
    }
  })

export const $tvBundleWithContentPageInfo = createStore<PageableInfoNew | null>(
  null
).on(
  paginateTvBundleWithContent.done,
  (_, { result: { content: _ignored, ...pageInfo }, params }) => ({
    ...pageInfo,
    params,
  })
)
/**************** $TvBundleWithContent *******************/

/**************** $TvBundlesRow *******************/
export const initBundlesRowByPageFx = attach({
  effect: getTvBundlesFx,
  mapParams: (_) => ({ ..._, page: 0 }),
})

export const loadBundlesRowByPageFx = attach({ effect: getTvBundlesFx })

export const $tvBundlesRow = createStore<TvBundleInfo[]>([])
  .on(initBundlesRowByPageFx.doneData, (_, page) => page.content)
  .on(loadBundlesRowByPageFx.doneData, (content, page) => [
    ...content,
    ...page.content,
  ])

/**************** $TvBundlesRow *******************/
