export const MAX_REMOTE_CONFIG_AWAIT_TIME = 3000

export const TRIA_CLIENT_PACKAGE_LOGO_HEIGHT = {
  medium: '40px',
  large: '60px',
}

export const TRIA_CLIENT_LANGUAGES = {
  en: 'English',
  es: 'Español',
  hi: 'हिन्दी',
  th: 'th',
  vi: 'vi',
}
