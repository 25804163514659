import { createEvent, sample } from 'effector'
import { not } from 'patronum'
import { model as guest } from '~/features/subscriber/guest'
import { F, T, noop } from '~/shared/helpers'
import { keys } from '~/shared/jail'
import { type Cage, type Transit } from '../index.h'

/*
 * ### Hallway->Guest intermediate cage ###
 */

const init = createEvent<Transit>('init')
const seal = createEvent<boolean>('seal')

// out events
const signedInAsGuest = createEvent('signedInAsGuest')
const failedToSignInAsGuest = createEvent('failedToSignInAsGuest')

export const hallway2guest = {
  name: 'hallway2guest',
  key: keys.lost,
  init,
  seal,
  in: [],
  out: {
    signedInAsGuest,
    failedToSignInAsGuest,
  },
} satisfies Cage

/*
 * cage logic
 */

sample({
  clock: init,
  target: seal.prepend(T),
})

sample({
  clock: init,
  filter: not(guest.guestInFx.pending),
  fn: noop,
  target: guest.guestInFx,
})

sample({
  clock: guest.guestInFx.done,
  target: signedInAsGuest,
})

sample({
  clock: guest.guestInFx.fail,
  target: failedToSignInAsGuest,
})

sample({
  clock: guest.guestInFx.finally,
  target: seal.prepend(F),
})
