import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector'
import { produce } from 'immer'
import type { ApiEffect, Page, PageInfo, PageableInfo } from '../index.h'
import type { MediaUrl } from '../interfaces/generic'
import {
  type CategoryWithVods,
  type CategoryWithVodsApi,
  type GetPurchasedVodsParams,
  type GetVodsPageParams,
  type Vod,
  type VodCategory,
  type VodPlaylist,
  type VodSpecialCategoryWithVodsRecommended,
  type VodUpdateObj,
  type VodUpdated,
} from '../interfaces/vods'

const DEFAULT_PAGE_SIZE = 36

/*
 * Effects
 */

// GET /v3/vods
export const getPageFx: ApiEffect<GetVodsPageParams, Page<Vod>> = createEffect()

// GET /v3/vods?only-favorites=true
export const getOnlyFavoritesVodsRowFx = attach({
  effect: getPageFx,
  mapParams: () => ({ 'page': 0, 'only-favorites': true }),
})

export const getOnlyFavoritesVodsGridFx = attach({
  effect: getPageFx,
  mapParams: (params?: GetVodsPageParams) => ({
    ...params,
    'page': params?.page || 0,
    'only-favorites': true,
  }),
})

// GET /v3/library/vods
export const getPurchasedVodsFx: ApiEffect<
  GetPurchasedVodsParams,
  Page<Vod>
> = createEffect()

export const getPurchasedVodsRowFx = attach({
  effect: getPurchasedVodsFx,
  mapParams: (params?: GetPurchasedVodsParams) => ({
    ...params,
    page: params?.page || 0,
  }),
})

export const getPurchasedVodsGridFx = attach({
  effect: getPurchasedVodsFx,
  mapParams: (params?: GetPurchasedVodsParams) => ({
    ...params,
    page: params?.page || 0,
  }),
})

// GET /v3/vods?updatedTimeOnly=true
export const getUpdatedOnlyFx: ApiEffect<void, VodUpdated[]> = createEffect()

// GET /v3/vods/{vodId}
export const getVodFx: ApiEffect<{ vodId: number }, Vod> = createEffect()

// GET /v3/vods?categoryId=categoryId
export const getVodsByCategoryIdFx: ApiEffect<
  { page: number; count?: number; categoryId: number },
  Page<Vod>
> = createEffect()

// GET /v3/vods/{vodId}/url
export const getUrlFx: ApiEffect<{ vodId: number }, MediaUrl> = createEffect()

// GET /v3/vods/{vodId}/trailer/url
export const getTrailerUrlFx: ApiEffect<{ vodId: number }, MediaUrl> =
  createEffect()

// GET /v3/vods/categories
export const getCategoriesFx: ApiEffect<
  { page: number; count?: number },
  Page<VodCategory>
> = createEffect()

// GET /v3/vods/categories/{categoryId}
export const getVodCategoryFx: ApiEffect<{ categoryId: number }, VodCategory> =
  createEffect()

// GET /v3/vods/categories/{categoryId}/subcategories
export const getSubcategoriesFx: ApiEffect<
  { categoryId: number; page: number; count?: number },
  Page<VodCategory>
> = createEffect()

// PATCH /v3/vods/{vodId}
export const updateVodFx: ApiEffect<VodUpdateObj, void> = createEffect()

// PUT /v3/vods/{vodId}/continue-watching
export const updateContinueWatchingFx: ApiEffect<
  { vodId: number; stoppedTime: number },
  void
> = createEffect()

// DELETE /v3/vods/{vodId}/continue-watching
export const removeContinueWatchingFx: ApiEffect<{ vodId: number }, void> =
  createEffect()

// GET /v3/vods/?sort-by=updatedTime&sort-order=desc
export const getLastAddedMoviesFx: ApiEffect<
  {
    page: number
    count?: number
    categoryId?: number
  },
  Page<Vod>
> = createEffect()

// GET /v3/vods?only-recommended=true
export const getRealRecommendedVodsFx: ApiEffect<
  {
    page: number
    count?: number
  },
  Page<Vod>
> = createEffect()

/*
 *  COMPOSED
 */

export const getCategoriesWithVodsFx: ApiEffect<
  {
    page: number
    count?: number
  },
  Page<CategoryWithVodsApi>
> = createEffect()

export const getRecommendedVodsFx: ApiEffect<
  { categoryId: number; vodId: number; vodIndex?: number },
  VodSpecialCategoryWithVodsRecommended | null
> = createEffect()

/*
 *  HANDLERS
 */

// TODO SHOULD be moved !!! to common/generic helpers/handlers to avoid cycling imports
export const updateSingleHandler = (
  item: Vod | null, // | TvChannel | TvShow
  params: VodUpdateObj
) => {
  if (!item) return
  if ('watched' in params) {
    ;(item as any).__watched = item.watched // hack to preserve previous value
    item.watched = Boolean(params.watched)
  }
  if ('favorite' in params) {
    ;(item as any).__favorite = item.favorite // hack to preserve previous value
    item.favorite = Boolean(params.favorite)
  }
  return item
}

export const updateSingleSuccessHandler = (
  item: Vod | null, // | TvChannel | TvShow
  params: VodUpdateObj
) => {
  if (!item) return item
  if ('watched' in params && '__watched' in item) {
    delete (item as any).__watched
  }
  if ('favorite' in params && '__favorite' in item) {
    delete (item as any).__favorite
  }
  return item
}

export const updateSingleFailHandler = (
  item: Vod | null, // | TvChannel | TvShow
  params: VodUpdateObj
) => {
  if (!item) return
  if ('watched' in params) {
    item.watched =
      '__watched' in item ? (item as any).__watched : !params.watched
  }
  if ('favorite' in params) {
    item.favorite =
      '__favorite' in item ? (item as any).__favorite : !params.favorite
  }
  return item
}

/*
 *  STORES
 */

export const $pageInfoLastAddedMovies = createStore<PageInfo | null>(null).on(
  getLastAddedMoviesFx.doneData,
  (_, { content: _ignored, ...pageInfo }) => pageInfo
)

export const $lastAddedMovies = createStore<Vod[]>([]).on(
  getLastAddedMoviesFx.doneData,
  (_, page) => page.content || []
)

export const $vod = createStore<Vod | null>(null)
  .on(getVodFx.doneData, (_, vod) => vod)
  .on(updateVodFx, (vod, params) => {
    if (params.id === vod?.id) return updateSingleHandler(vod, params) as Vod
  })
  .on(updateVodFx.done, (vod, { params }) => {
    if (params.id === vod?.id)
      return updateSingleSuccessHandler(vod, params) as Vod
  })
  .on(updateVodFx.fail, (vod, { params }) => {
    if (params.id === vod?.id)
      return updateSingleFailHandler(vod, params) as Vod
  })

export const $vodsPageInfo = createStore<PageInfo | null>(null).on(
  getPageFx.doneData,
  (_, { content: _ignored, ...pageInfo }) => pageInfo
)

/*
 *  CUSTOM STORES
 */

/**************** $movieRecommended *******************/

export const $movieCategoryWithRecommended =
  createStore<VodSpecialCategoryWithVodsRecommended | null>(null).on(
    getRecommendedVodsFx.doneData,
    (_, doneData) => doneData
  )
/**************** $movieRecommended *******************/

/**************** $categoriesWithVods *******************/

export const initCategoriesWithVodsFx = attach({
  effect: getCategoriesWithVodsFx,
  mapParams: (params) => ({ ...params, page: 0 }),
})

export const loadCategoriesWithVodsFx = attach({
  effect: getCategoriesWithVodsFx,
})

// to paginate over categories use - loadCategoriesWithVodsFx // here
export const $categoriesWithVods = createStore<CategoryWithVods[]>([])
  .on(initCategoriesWithVodsFx.doneData, (_, payload) => {
    return payload.content.map((page) => ({
      ...page,
      content: page.content.content,
    }))
  })
  .on(loadCategoriesWithVodsFx.doneData, (state, payload) => {
    const newContent = payload.content.map((page) => ({
      ...page,
      content: page.content.content,
    }))
    return [...state, ...newContent]
  })

// pagination of main array of categories $categoriesWithVods
export const $pageInfoCategoriesWithVods = createStore<PageableInfo | null>(
  null
).on(
  [initCategoriesWithVodsFx.done, loadCategoriesWithVodsFx.done],
  (_, { result: { content: _ignored, ...pageInfo }, params }) => ({
    ...pageInfo,
    params,
  })
)

/**************** $categoriesWithVods *******************/

/***************** $vodPlaylist *******************/

const playlistDefault = {
  isLooped: false,
  isAutoplay: true,
  categoryId: null,
  vodId: null,
  vodIndex: null,
  nextVod: null,
  nextVods: null,
  currentVod: null,
}

interface VodPlaylistNoSettings
  extends Omit<VodPlaylist, 'isLooped' | 'isAutoplay'> {}

export const generateVodPlaylistFx: ApiEffect<
  {
    vodId: number
    categoryId: number
    vodIndex: number
    isLooped?: boolean
    isAutoplay?: boolean
  },
  VodPlaylistNoSettings
> = createEffect()
// set options of playlist
export const updateVodPlaylist = createEvent<{
  isLooped?: boolean
  isAutoplay?: boolean
}>()

export const recalculateCurrentValuesPlaylist = createEvent<VodPlaylist>() // call it after autoplay happened

export const $vodPlaylist = createStore<VodPlaylist | null>(playlistDefault)
  .on(generateVodPlaylistFx.doneData, (state, payload) =>
    produce(state, (draft) => ({
      ...payload,
      isLooped: Boolean(draft?.isLooped),
      isAutoplay: Boolean(draft?.isAutoplay),
    }))
  )
  .on(updateVodPlaylist, (state, payload) => {
    const sets = {
      isLooped: Boolean(
        'isLooped' in payload ? payload.isLooped : state?.isLooped
      ),
      isAutoplay: Boolean(
        'isAutoplay' in payload ? payload.isAutoplay : state?.isAutoplay
      ),
    }
    return { ...state, ...sets } as VodPlaylist
  })

sample({
  clock: recalculateCurrentValuesPlaylist,
  fn: (data) => ({
    categoryId: Number(data.categoryId),
    vodIndex: Number(data.vodIndex),
    isLooped: data.isLooped,
    isAutoplay: data.isAutoplay,
    vodId: Number(data.vodId),
  }),
  target: generateVodPlaylistFx,
})

/***************** $vodPlaylist *******************/

/**************** $vodsCategory *******************/

export const loadVodsFx = attach({ effect: getVodsByCategoryIdFx }) // use it for pagination

export const initVodsCategoryFx: ApiEffect<
  {
    categoryId: number
    count?: number
  },
  CategoryWithVods | null
> = createEffect()

export const $vodsCategory = createStore<CategoryWithVods | null>(null)
  .on(initVodsCategoryFx.doneData, (_ignored, payload) => payload)
  .on(loadVodsFx.doneData, (state, { content }) => {
    return produce(state, (draft) => {
      if (draft) {
        draft.content = [...draft.content, ...content]
      }
    })
  })

export const $vodsCategoryPageInfo = createStore<PageableInfo | null>(null).on(
  loadVodsFx.done,
  (_, { result: { content: _ignored, ...pageInfo }, params }) => ({
    ...pageInfo,
    params,
  })
)
/**************** $vodsCategory *******************/

/**************** $subCategoriesWithVods *******************/

export const loadSubCategoriesWithVodsFx: ApiEffect<
  { categoryId: number; page: number; count?: number },
  void
> = createEffect() // use to paginate over subCategories

export const initVodsSubCategoryFx = attach({
  effect: loadSubCategoriesWithVodsFx,
  mapParams: (params) => ({ ...params, page: 0 }),
})
export const loadParentCategoryVodFx = attach({
  effect: getVodCategoryFx,
})
export const loadSubCategoriesFx = attach({ effect: getSubcategoriesFx })
export const loadVodsOfSubcategoryFx = attach({ effect: getVodsByCategoryIdFx }) // to paginate over sub arrays use - loadSubVodsFx

export const $parentCategoryVods = createStore<VodCategory | null>(null).on(
  loadParentCategoryVodFx.doneData,
  (_ignored, payload) => payload
)

export const $subCategoriesWithVods = createStore<CategoryWithVods[]>([])
  .on(loadSubCategoriesFx.doneData, (state, payload) => {
    const newCategories = payload.content.map((category) => ({
      ...category,
      content: [],
      isMoreThanOnePageSubCategories:
        Number(category.subCategories?.length) > DEFAULT_PAGE_SIZE,
    }))
    return [...state, ...newCategories]
  })
  .on(loadVodsOfSubcategoryFx.done, (_, payload) => {
    const { content: newVods } = payload.result
    const { categoryId } = payload.params

    return produce(_, (draft) => {
      const arrayIndex = draft.findIndex(({ id }) => id === categoryId)

      if (!draft[arrayIndex]) return
      draft[arrayIndex].content.push(...newVods)
      draft[arrayIndex].isMoreThanOnePage =
        !payload.result.last || draft[arrayIndex].content.length > 36
    })
  })

// pagination of main array of categories $subCategoriesWithVods
export const $pageInfoSubCategoriesWithVods = createStore<PageableInfo | null>(
  null
).on(
  loadSubCategoriesFx.done,
  (_, { result: { content: _ignored, ...pageInfo }, params }) => ({
    ...pageInfo,
    params,
  })
)

/**************** $subCategoriesWithVods *******************/

/**************** $realRecommendedTvChannels *******************/
export const loadRealRecommendedVodsFx = attach({
  effect: getRealRecommendedVodsFx,
})
export const $realRecommendedVods = createStore<Vod[]>([]).on(
  loadRealRecommendedVodsFx.doneData,
  (_, { content }) => content
)
/**************** $realRecommendedTvChannels *******************/

/**************** $infiniteVodsCategory *******************/

export const initInfiniteVodsCategoryFx = attach({
  effect: getPageFx,
  mapParams: (params) => ({ ...params, page: 0 }),
})

const fillFakeCategory = (
  vods: Vod[],
  isMoreThanOnePage: boolean
): CategoryWithVods => ({
  id: 0,
  name: '',
  sortOrder: 0,
  vodQuantity: vods.length, // not trusted field, but not used anywhere
  parentCategoryId: 0,
  subCategories: [],
  isMoreThanOnePage,
  isMoreThanOnePageSubCategories: false,
  content: vods,
})

// to paginate over categories use - loadCategoriesWithVodsFx
export const $infiniteVodsCategory = createStore<CategoryWithVods | null>(
  null
).on(initInfiniteVodsCategoryFx.doneData, (_, payload) =>
  fillFakeCategory(payload.content, !payload.last)
)

/**************** $infiniteVodsCategory *******************/
