import type { Adapter, API } from '@setplex/tria-api'
import { use as events } from './events'
import { http } from './http'
import { DeviceType, type AdapterDefaults } from './index.h'
import { use as sign } from './sign'

import { type Store } from 'effector'
import { use as analytics } from './entities/analytics'
import { use as announcements } from './entities/announcements'
import { use as appearance } from './entities/appearance'
import { use as banners } from './entities/banners'
import { use as cart } from './entities/cart'
import { use as catchups } from './entities/catchups'
import { use as continueWatching } from './entities/continue-watching'
import { use as creditCards } from './entities/creditCards'
import { use as search } from './entities/global-search'
import { use as i18n } from './entities/i18n'
import { use as liveEvents } from './entities/live-events'
import { use as notifications } from './entities/notifications'
import { use as partnerProgram } from './entities/partnerProgram'
import { use as profiles } from './entities/profiles'
import { use as purchases } from './entities/purchases'
import { use as session } from './entities/session'
import { use as showBundles } from './entities/showBundles'
import { use as subscriber } from './entities/subscriber'
import { use as tv } from './entities/tv'
import { use as tvBundles } from './entities/tvBundles'
import { use as tvshows } from './entities/tvshows'
import { use as vodBundles } from './entities/vodBundles'
import { use as vods } from './entities/vods'
import { setDeviceType } from './utils/images'
// import { initErrorHandling } from './events/errors'

export const version: string = '__VERSION__'

export { setLogger } from './utils/logger'

export const adapter =
  ({ $deviceType }: { $deviceType: Store<DeviceType | null> }): Adapter =>
  (
    api: API,
    defaults: AdapterDefaults = {
      count: 36,
      sortOrder: 'asc',
    }
  ) => {
    $deviceType.watch(setDeviceType)

    events(api)
    // initErrorHandling(api)

    const client = http(api.fx)

    sign(client, api.session, api, defaults)
    session(client, api.session, api, defaults)

    appearance(client, api.appearance, api, defaults)
    banners(client, api.banners, api, defaults)
    liveEvents(client, api.liveEvents, api, defaults)
    subscriber(client, api.subscriber, api, defaults)
    tv(client, api.tv, api, defaults)
    tvBundles(client, api.tvBundles, api, defaults)
    vodBundles(client, api.vodBundles, api, defaults)
    showBundles(client, api.showBundles, api, defaults)
    tvshows(client, api.tvshows, api, defaults)
    vods(client, api.vods, api, defaults)
    cart(client, api.cart, api, defaults)
    creditCards(client, api.creditCards, api, defaults)
    purchases(client, api.purchases, api, defaults)
    analytics(client, api.analytics, api, defaults)
    search(client, api.globalSearch, api)
    catchups(client, api.catchups, api, defaults)
    continueWatching(client, api.continueWatching, api, defaults)
    notifications(client, api.notifications, api, defaults)
    profiles(client, api.profiles, api, defaults)
    i18n(client, api.i18n, api, defaults)
    partnerProgram(client, api.partnerProgram, api, defaults)
    announcements(client, api.announcements, api, defaults)
  }
