import { type Location } from '!/router'
import { type TrueIDAuthConfigType } from '@tdg/trueid-web-sdk'
import { env } from '~/shared/config/env'

const TRUE_ID_DEV_REDIRECT_URI = 'https://tvs.tria-mkdev.norago.tv'

/**
 * Construct TrueID logout redirect URL
 */
export function getTrueIdLogoutRedirectUrl(
  host: string,
  location: Location | null
): string {
  const tail = location ? location.pathname + location.search : ''
  return `${host}/logout/~${tail}`
}

/**
 * Get TrueID config from ERA_CONFIG and extend it with redirect_uri from current location
 */
export function getTrueIdConfig(): TrueIDAuthConfigType | null {
  const config = window.ERA_CONFIG?.trueIdConfig as
    | TrueIDAuthConfigType
    | undefined

  if (!config) return null

  // use hardcoded redirect_uri for `local-dev` environment
  // TODO: remove hardcoded environments names later, think about better solution
  config.redirect_uri =
    env.TRIA_ENVIRONMENT === 'local-dev'
      ? TRUE_ID_DEV_REDIRECT_URI
      : window.location.origin

  return config
}
