import { model as i18nModel } from '!/i18n'
import { register } from '!/repository'
import { combine, createEvent, createStore } from 'effector'
import type { IRemoveProfile } from './index.h'

export const init = createEvent()
export const openProfilesRemovePopup = createEvent<IRemoveProfile>()

export const closeProfilesRemovePopup = createEvent<void>()

export const $profilesRemovePopup = createStore<IRemoveProfile>({
  condition: false,
})
  .on(openProfilesRemovePopup, (_, payload) => ({
    ...payload,
    condition: true,
  }))
  .reset(closeProfilesRemovePopup)

export const $profilesRemovePopupTranslations = combine(
  i18nModel.$t,
  $profilesRemovePopup,
  ({ t }, popupInfo) => ({
    title: t('profile_delete_text', {
      profile_name: popupInfo.username,
    }),
    subtitle: t('profile_delete_confirmation'),
    remove: t('profile_delete_title'),
    cancel: t('cancel'),
  })
)

register(openProfilesRemovePopup, 'openProfilesRemovePopup')
register(closeProfilesRemovePopup, 'closeProfilesRemovePopup')
register($profilesRemovePopup, '$profilesRemovePopup')
register($profilesRemovePopupTranslations, '$profilesRemovePopupTranslations')
