import { register } from '!/repository'
import { createEvent, createStore } from 'effector'
import type { ICreateProfilePopup } from './index.h'

export const init = createEvent()
export const openProfilesCreatePopup = createEvent<ICreateProfilePopup>()

export const closeProfilesCreatePopup = createEvent<void>()

export const $profilesCreatePopup = createStore<ICreateProfilePopup>({
  condition: false,
})
  .on(openProfilesCreatePopup, (_, payload) => ({
    ...payload,
    condition: true,
  }))
  .reset(closeProfilesCreatePopup)

register(openProfilesCreatePopup, 'openProfilesCreatePopup')
register(closeProfilesCreatePopup, 'closeProfilesCreatePopup')
register($profilesCreatePopup, '$profilesCreatePopup')
