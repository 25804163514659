import type { MediaTypes } from '@setplex/tria-api'
import { produce, type Draft } from 'immer'
import type { Favorites } from './index.h'

/**
 * Small helper function to get favorite jar type from `contentType` field
 */
export function getType(from: MediaTypes): keyof Favorites | undefined {
  switch (from) {
    case 'TV_CHANNEL':
      return 'channel'
    case 'TV_SHOW':
      return 'show'
    case 'VOD':
      return 'vod'
  }
}

/**
 * Update parts of favorite jar, used in store reducers
 *
 * @param type - jar type to update
 * @returns curried `produce` function, which is used as reduser to change Favorites
 */
export function update(type?: keyof Favorites | undefined) {
  return produce((draft: Draft<Favorites>, payload: unknown) => {
    traverse(draft, payload, type)
  })
}

/**
 * Recursive helper function to update parts of favorite jar
 *
 * @param draft - draft of Favorites, got from `produce` to change
 * @param obj - any object with any structure
 * @param type - jar type to search
 * @returns nothing, recursively updates `draft` instead
 */
function traverse(
  draft: Draft<Favorites>,
  obj: unknown,
  type?: keyof Favorites | undefined
) {
  // end of recursive branch, stop recursion here
  if (obj == null) {
    return
  }

  // if traversed object is an array - recursively traverse each element
  if (Array.isArray(obj)) {
    obj.forEach((o) => traverse(draft, o, type))
  }

  // if traversed object is an object
  if (typeof obj === 'object') {
    // and has 'contentType' field - it will override given type for all branches below
    if ('contentType' in obj) {
      const newType = getType(obj.contentType as MediaTypes)
      if (newType) {
        type = newType
      }
    }

    // and has 'favorite' and 'id' fields - consider this is a required entity we seek
    //  -> get type and update given entity in favorite jar
    if ('favorite' in obj && 'id' in obj) {
      if (type) {
        draft[type][obj.id as string | number] = Boolean(obj.favorite)
      }
    }

    // otherwise recursively traverse each value from object
    else {
      Object.values(obj).forEach((o) => traverse(draft, o, type))
    }
  }
}
