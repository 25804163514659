import {
  type EpgInfoDto,
  type TimeRange,
  type TvEpgApiDto,
} from '@setplex/tria-api'
import { endOfHour, formatISO, subHours } from 'date-fns'
import { MAX_REWIND_BACK_HOURS } from './index.h'

export const generateEmptyProgramForNow = (): EpgInfoDto => ({
  start: formatISO(subHours(new Date(), MAX_REWIND_BACK_HOURS)).toString(),
  stop: formatISO(endOfHour(new Date())).toString(),
  title: '',
  description: '',
  subtitle: '',
  categories: [],
  icon: '',
  date: '',
  rating: '',
  directors: [],
  actors: [],
  episode: '',
})

export const getCurrentProgram = ({
  epg,
}: {
  epg: TvEpgApiDto
  period: TimeRange
}): EpgInfoDto => {
  const epgId = Object.keys(epg)[0] // should be only one channel / epgId
  const programs = epg[epgId]

  if (programs.length === 0) return generateEmptyProgramForNow()
  if (programs.length === 1) return programs[0]

  // take the latest program // due to fact period is MIN_PROGRAM_LENGTH_MS minutes
  const sorted = programs.sort(
    (a, b) => new Date(b.start).getTime() - new Date(a.start).getTime()
  )
  return sorted[0]
}
