import { supportedDrmsResolved } from '@setplex/player'
import parser from 'ua-parser-js'

const era = window.ERA_CONFIG?.app

export const providerAssets = window.ERA_CONFIG?.assets

export const app = {
  ...era,
  name: era?.name ?? 'tria',
  appName: era?.appName ?? '',
  domain: location.host,
} as const

export const assets = {
  authBackgroundUrl: providerAssets?.authBackgroundUrl,
}

//
// get information from user-agent string
//

const ua: parser.IResult = parser(navigator.userAgent)

export const os = {
  name: ua.os.name,
  version: ua.os.version,
} as const

// TODO: should use feature detection, in case user agent string is spuffed
export const browser = {
  name: ua.browser.name,
  version: ua.browser.version,
  engine: ua.engine.name,
} as const

export const device = {
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
  // > If the device is large enough that it's not marked with Mobi,
  // > you should serve your desktop site (which, as a best practice,
  // > should support touch input anyway, as more desktop machines are appearing with touchscreens).
  type: /Mobi/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
  libtype: ua.device.type,
  model: ua.device.model,
  vendor: ua.device.vendor,
} as const

// TODO: could check https://github.com/fingerprintjs/fingerprintjs repo for more references

//
// EME support
//

export const drms: string[] = []

// wait until player detects supported DRM systems
supportedDrmsResolved.then((resolved: Map<string, string[]>) => {
  const types = [...resolved.keys()].map((type) => type.toUpperCase())
  Array.prototype.push.apply(drms, types)
})
