import { EVENT, get } from '!/repository'
import { logger } from '@/logger'
import { type EventCallable } from 'effector'
import { useUnit } from 'effector-react'
import { useCallback, useMemo, type SyntheticEvent } from 'react'
import { type SkinView, type SkinViewOnHandler } from '../../../index.h'
import { getPath } from '../helpers'
import { express, type Scope } from '../scope'
import { useRaw } from './useRaw'

// debug flag
const debug =
  process.env.NODE_ENV !== 'production' &&
  typeof document !== 'undefined' &&
  globalThis.debugUseEvents

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OnsRecord = { [name: string]: (arg?: any) => void }
export type ArgsRecord = { [name: string]: unknown }

export function useEvents(
  alias: string,
  ons: SkinView['on'],
  scope: Scope
): OnsRecord | undefined {
  if (!ons || typeof ons !== 'object') return

  // this is ok, because skin is static and never changes on the fly
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const events = useMemo(() => {
    const events: {
      [name: string]: {
        event: EventCallable<unknown>
        args?: { [prop: string]: string | string[] | number }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        options: { [key: string]: any }
      }
    } = {}
    for (const on of Object.keys(ons)) {
      const desc: SkinViewOnHandler = ons[on]
      const { handler, args, ...options } =
        typeof desc === 'string' ? { handler: desc, args: undefined } : desc

      if (handler) {
        const event: EventCallable<unknown> | null = get(handler, EVENT)
        if (event) {
          events[on] = { event, args, options }
        } else {
          logger.warn(`Event "${handler}" is unregistered!`)
        }
      } else {
        logger.warn(`Cannot recognize "on.${on}" event handler!`)
      }
    }
    return events
  }, [ons])

  const result: OnsRecord = {}
  for (const [on, { event, args, options }] of Object.entries(events)) {
    const listener: string = 'on' + on.charAt(0).toUpperCase() + on.slice(1)

    // prettier-ignore
    if (debug) {
      logger.groupCollapsed(`🪝 %cevent%c ${alias} %c<-%c ${listener}`,'background:lightgreen;border-radius:3px;padding:0 2px;color:black;font-weight:normal','font-weight:normal','color:pink','color:red;font-weight:normal')
    }

    // this is ok, because skin is static and never changes on the fly
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handler = useEventCallback({ event, args, options, scope })

    if (debug) {
      logger.groupEnd()
    }

    result[listener] = handler
  }
  return result
}

function useEventCallback({
  event,
  args,
  options,
  scope,
}: {
  event: EventCallable<unknown>
  args: { [name: string]: string | string[] | number } | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: { [prop: string]: any }
  scope: Scope
}): {
  (ev: SyntheticEvent): void
  (...as: any[]): void // eslint-disable-line @typescript-eslint/no-explicit-any
} {
  if (debug) {
    logger.log('🗣', event)
    if (options && Object.keys(options).length > 0) logger.log('🫥', options)
    if (args && Object.keys(args).length > 0) logger.log('💅', args)
    logger.log('🧅', scope)
  }

  const callback = useUnit(event)
  const common: ArgsRecord = useArgs(args, scope)

  if (debug) {
    logger.log('📦', common)
  }

  return useCallback(
    (...as) => {
      const payload = { ...common }
      let ev: SyntheticEvent | undefined = undefined
      if (
        as.length === 1 &&
        as[0]?.nativeEvent &&
        as[0]?.nativeEvent instanceof Event
      ) {
        payload['<event>'] = ev = as[0]
      } else {
        payload['<args>'] = [...as]
      }

      if (options && ev) {
        if (options.stopPropagation) {
          ev.stopPropagation()
        }
        if (options.preventDefault) {
          ev.preventDefault()
        }
      }

      for (const [key, value] of Object.entries(payload)) {
        if (typeof value === 'string') {
          if (value.startsWith('<event>')) {
            const [, ...pathToResolve] = value.split('.')
            payload[key] = getPath(ev, pathToResolve)
          } else if (value.startsWith('<args>')) {
            const [, ...pathToResolve] = value.split('.')
            payload[key] = getPath(as, pathToResolve)
          }
        }
      }

      callback(payload)
    },

    // rule `react-hooks/exhaustive-deps` complains
    // > useCallback has a missing dependency: 'common'
    // but this aint true, because `common` IS a dependency, but in decomposed form
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback, options, ...Object.entries(common).flat()]
  )
}

function useArgs(
  args: { [name: string]: string | string[] | number } | undefined,
  scope: Scope
): ArgsRecord {
  if (!args || typeof args !== 'object') return {}

  const result: ArgsRecord = {}
  for (const [name, value] of Object.entries(args)) {
    // this is ok, because skin is static and never changes on the fly
    // eslint-disable-next-line react-hooks/rules-of-hooks
    result[name] = useRaw(express(value, scope))
  }
  return result
}
