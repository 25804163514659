import { createEffect, createStore } from 'effector'
import type { ApiEffect } from '../index.h'
import type {
  Catchup,
  CatchupProgramsFormatedToObjectWithDatelist,
  CatchupProgramsWithDates,
  CatchupsDateList,
} from '../interfaces/catchups'
import type { MediaUrl } from '../interfaces/generic'

//*
//* EFFECTS
//*

//GET /v3/channels/tv/catchups
export const getChannelsWithCatchupsFx: ApiEffect<void, Catchup[]> =
  createEffect()

// GET /v3/channels/tv/{channelId}/catchups/{catchupId}/programmes
export const getChannelCatchupProgramsFx: ApiEffect<
  {
    channelId: number
    catchupId: number
  },
  CatchupProgramsFormatedToObjectWithDatelist
> = createEffect()

// GET /v3/channels/tv/{channelId}/catchups/{catchupId}/programmes/{programmeId}/url
export const getCatchupUrlFx: ApiEffect<
  {
    channelId: number
    catchupId: number
    programmeId: number
  },
  MediaUrl
> = createEffect()

//*
//* STORES
//*

export const $channelsWithCatchups = createStore<Catchup[]>([])
  .on(getChannelsWithCatchupsFx.doneData, (_, payload) => payload)
  .reset(getChannelsWithCatchupsFx)

export const $selectedChannelCatchupPrograms =
  createStore<CatchupProgramsWithDates>([])
    .on(getChannelCatchupProgramsFx.doneData, (_, { programs }) => programs)
    .reset(getChannelCatchupProgramsFx)

export const $selectedChannelDateList = createStore<CatchupsDateList>([])
  .on(getChannelCatchupProgramsFx.doneData, (_, { dateList }) => dateList)
  .reset(getChannelCatchupProgramsFx)
