export type Defer<T> = {
  promise: Promise<T>
  resolve: (value: T | PromiseLike<T>) => void
  reject: (reason?: unknown) => void
}

/**
 * Creates deferred promise
 */
export function defer<T>(): Defer<T> {
  const deferred = {} as Defer<T>
  deferred.promise = new Promise<T>((resolve, reject) => {
    deferred.resolve = resolve
    deferred.reject = reject
  })
  deferred.promise.catch(() => {})
  return deferred
}

export type CancellablePromise<T> = Promise<T> & {
  cancel: () => void
}

export class CancelledError extends Error {}
export class TimeoutError extends Error {}

/**
 * Wrap promise to CancellablePromise, with `cancel` method
 */
export const cancellable = <T>(
  promise: PromiseLike<T>,
  abort?: () => void,
  timeout?: number
): CancellablePromise<T> => {
  let cancel: () => void = () => undefined

  const cancelable = new Promise<never>((_, reject) => {
    let timeoutId: ReturnType<typeof setTimeout>

    // rejecter factory
    const rejectWith =
      (Cls: typeof CancelledError | typeof TimeoutError) => () => {
        clearTimeout(timeoutId)
        reject(new Cls())
        abort && abort()
      }

    // set `.cancel()` callback
    cancel = rejectWith(CancelledError)

    // set timeout boundary
    if (typeof timeout === 'number') {
      timeoutId = setTimeout(rejectWith(TimeoutError), timeout)
    }
  })

  // return race of two Promises, with exposed `.cancel()` method
  // to cancel our `cancelable` promise, created above, to finish race
  return Object.assign(Promise.race([promise, cancelable]), { cancel })
}
