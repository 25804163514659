import type { API } from '@setplex/tria-api'
import { type HttpClient } from '../../http'
import { type AdapterDefaults } from '../../index.h'
import { type ApiPushToken } from '../../interfaces/notifications'

export function use(
  http: HttpClient,
  notifications: API['notifications'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  notifications.pushTokenFx.use(async (token) => {
    await http.post<ApiPushToken>('/v3/device/push_token', {
      json: {
        token,
        pushProviderType: 'FCM',
      },
    })
  })
}
