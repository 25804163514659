import type { Vod } from '@setplex/tria-api'
import { api, type VodUpdateObj } from '@setplex/tria-api'
import { createEvent, sample, type Event } from 'effector'
import { not } from 'patronum'

export const updateVod = createEvent<VodUpdateObj>()

export const updateTime = createEvent<{ vod: Vod; stoppedTime: number }>()

const updateTimeFormat: Event<{ vodId: number; stoppedTime: number }> =
  updateTime.map(({ vod: { id }, stoppedTime }) => ({
    vodId: Number(id),
    stoppedTime,
  }))

export const removeTime = createEvent<{ vod: Vod }>()

const removeTimeFormat: Event<{ vodId: number }> = removeTime.map(
  ({ vod: { id } }) => ({
    vodId: Number(id),
  })
)

sample({
  clock: updateTimeFormat,
  filter: not(api.vods.updateVodFx.pending),
  target: api.vods.updateContinueWatchingFx,
})

sample({
  clock: removeTimeFormat,
  target: api.vods.removeContinueWatchingFx,
})

sample({
  clock: updateVod,
  target: api.vods.updateVodFx,
})
