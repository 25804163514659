import {
  createEffect,
  createEvent,
  createStore,
  sample,
  type Effect,
} from 'effector'
import { $session, type Session } from '../entities/session'
import { updateTvChannelFx } from '../entities/tv'
import { updateTvShowFx } from '../entities/tvshows'
import { updateVodFx } from '../entities/vods'
import {
  AnalyticsEventType as AnalyticsEventTypeEnum,
  type AnalyticsEventType,
  type AnalyticsGenericInfo,
  type AnalyticsWatchingContentInfo,
  type ApplicationInfo,
  type BEAnalyticsInfo,
  type IContentInfo,
  type VideoSourceInfo,
} from '../interfaces/analytics'

enum ContentType {
  vod = 'vod',
  show = 'show',
  tv = 'tv',
}

export const $applicationInfo = createStore<ApplicationInfo | null>(null)

export const $analyticsDomain = createStore<string>('')

const getAnalyticsAppData = (app: ApplicationInfo | null): ApplicationInfo => ({
  appId: app?.appId || '',
  appName: app?.appName || '',
  appVersion: app?.appVersion || '',
  browserName: app?.browserName || '',
  browserVersion: app?.browserVersion || '',
  osName: app?.osName || '',
  osVersion: app?.osVersion || '',
})

const getAnalyticsFormattedAccData = (
  session: Session | null
): BEAnalyticsInfo => ({
  deviceId: session?.deviceIdentifier || '',
  networkIp: session?.deviceExternalIP || '',
  networkIsp: session?.deviceISP || '',
  location: session?.location || '',
  contentProviderId: session?.providerId || '',
  contentUserSubscriberId: session?.subscriberId || '',
  contentUserAccountNumber: session?.accountNumber || '',
  contentUserProfileId: session?.profileId ? String(session?.profileId) : '',
  contentUserProfileName: session?.subscriberName || '',
})

// ************* watching_content *****************
export const sendAnalyticsWatchingContent = createEvent<IContentInfo>()
export const sendAnalyticsWatchingContentFx: Effect<
  { domain: string; data: AnalyticsWatchingContentInfo },
  void
> = createEffect()

sample({
  clock: sendAnalyticsWatchingContent,
  source: {
    domain: $analyticsDomain,
    device: $applicationInfo,
    account: $session,
  },
  fn: ({ domain, device, account }, contentInfo) => ({
    domain,
    data: {
      ...contentInfo,
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
    } as AnalyticsInfoStopWatching,
  }),
  target: sendAnalyticsWatchingContentFx,
})

// ************* play_start *****************
export const sendAnalyticsStartWatching = createEvent<VideoSourceInfo>()

export interface AnalyticsInfoStartWatching extends AnalyticsGenericInfo {
  eventType: AnalyticsEventType.playStart
}

export const sendAnalyticsStartWatchingFx: Effect<
  { domain: string; data: AnalyticsInfoStartWatching },
  void
> = createEffect()

sample({
  clock: sendAnalyticsStartWatching,
  source: {
    domain: $analyticsDomain,
    device: $applicationInfo,
    session: $session,
  },
  fn: ({ domain, device, session }, videoSourceInfo) => ({
    domain,
    data: {
      ...videoSourceInfo,
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(session),
      eventType: AnalyticsEventTypeEnum.playStart,
    } as AnalyticsInfoStartWatching,
  }),
  target: sendAnalyticsStartWatchingFx,
})

// ************* play_stop *****************
export const sendAnalyticsStopPauseWatching = createEvent<VideoSourceInfo>()

export interface AnalyticsInfoStopWatching extends AnalyticsGenericInfo {
  eventType: AnalyticsEventTypeEnum.playStop
}

export const sendAnalyticsStopPauseWatchingFx: Effect<
  { domain: string; data: AnalyticsInfoStopWatching },
  void
> = createEffect()

sample({
  clock: sendAnalyticsStopPauseWatching,
  source: {
    domain: $analyticsDomain,
    device: $applicationInfo,
    account: $session,
  },
  fn: ({ domain, device, account }, videoSourceInfo) => ({
    domain,
    data: {
      ...videoSourceInfo,
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
      eventType: AnalyticsEventTypeEnum.playStop,
    } as AnalyticsInfoStopWatching,
  }),
  target: sendAnalyticsStopPauseWatchingFx,
})

// ************* favorite_add *****************
export const sendAnalyticsFavoriteAdd = createEvent<{
  contentId: number
  contentType: ContentType
}>()

export interface AnalyticsInfoFavoriteAdd extends AnalyticsGenericInfo {
  eventType: AnalyticsEventTypeEnum.favoriteAdd
}

export const sendAnalyticsFavoriteAddFx: Effect<
  { domain: string; data: AnalyticsInfoFavoriteAdd },
  void
> = createEffect()

sample({
  clock: sendAnalyticsFavoriteAdd,
  source: {
    domain: $analyticsDomain,
    device: $applicationInfo,
    account: $session,
  },
  fn: ({ domain, device, account }, { contentId, contentType }) => ({
    domain,
    data: {
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
      contentId: String(contentId),
      contentType,
      eventType: AnalyticsEventTypeEnum.favoriteAdd,
    } as AnalyticsInfoFavoriteAdd,
  }),
  target: sendAnalyticsFavoriteAddFx,
})

// ************* favorite_remove *****************
export const sendAnalyticsFavoriteRemove = createEvent<{
  contentId: number
  contentType: ContentType
}>()

export interface AnalyticsInfoFavoriteRemove extends AnalyticsGenericInfo {
  eventType: AnalyticsEventTypeEnum.favoriteRemove
  contentId: string
  contentType: ContentType
}

export const sendAnalyticsFavoriteRemoveFx: Effect<
  { domain: string; data: AnalyticsInfoFavoriteRemove },
  void
> = createEffect()

sample({
  clock: sendAnalyticsFavoriteRemove,
  source: {
    domain: $analyticsDomain,
    device: $applicationInfo,
    account: $session,
  },
  fn: ({ domain, device, account }, { contentId, contentType }) => ({
    domain,
    data: {
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
      contentId: String(contentId),
      contentType,
      eventType: AnalyticsEventTypeEnum.favoriteRemove,
    } as AnalyticsInfoFavoriteRemove,
  }),
  target: sendAnalyticsFavoriteRemoveFx,
})

// ************* session_start *****************
export const sendAnalyticsSessionStart = createEvent<void>()

export interface AnalyticsInfoSessionStart extends AnalyticsGenericInfo {
  eventType: AnalyticsEventTypeEnum.sessionStart
}

export const sendAnalyticsSessionStartFx: Effect<
  { domain: string; data: AnalyticsInfoSessionStart },
  void
> = createEffect()

sample({
  clock: sendAnalyticsSessionStart,
  source: {
    domain: $analyticsDomain,
    device: $applicationInfo,
    account: $session,
  },
  fn: ({ domain, device, account }) => ({
    domain,
    data: {
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
      eventType: AnalyticsEventTypeEnum.sessionStart,
    } as AnalyticsInfoSessionStart,
  }),
  target: sendAnalyticsSessionStartFx,
})

// ************* session_stop *****************
export const sendAnalyticsSessionStop = createEvent<void>()

export interface AnalyticsInfoSessionStop extends AnalyticsGenericInfo {
  eventType: AnalyticsEventTypeEnum.sessionEnd
}

export const sendAnalyticsSessionStopFx: Effect<
  { domain: string; data: AnalyticsInfoSessionStop },
  void
> = createEffect()

sample({
  clock: sendAnalyticsSessionStop,
  source: {
    domain: $analyticsDomain,
    device: $applicationInfo,
    account: $session,
  },
  fn: ({ domain, device, account }) => ({
    domain,
    data: {
      ...getAnalyticsAppData(device),
      ...getAnalyticsFormattedAccData(account),
      eventType: AnalyticsEventTypeEnum.sessionEnd,
    } as AnalyticsInfoSessionStop,
  }),
  target: sendAnalyticsSessionStopFx,
})

sample({
  clock: updateTvChannelFx,
  filter: (clockData) => !!clockData.favorite, // favorite should be true
  fn: (clockData) => ({
    contentId: clockData.id,
    contentType: ContentType.tv,
  }),
  target: sendAnalyticsFavoriteAdd,
})

sample({
  clock: updateTvShowFx,
  filter: (clockData) => !!clockData.favorite, // favorite should be true
  fn: (clockData) => ({
    contentId: clockData.id,
    contentType: ContentType.show,
  }),
  target: sendAnalyticsFavoriteAdd,
})

sample({
  clock: updateVodFx,
  filter: (clockData) => !!clockData.favorite, // favorite should be true
  fn: (clockData) => ({
    contentId: clockData.id,
    contentType: ContentType.vod,
  }),
  target: sendAnalyticsFavoriteAdd,
})

// false
sample({
  clock: updateTvChannelFx,
  filter: (clockData) => !clockData.favorite, // favorite should be false
  fn: (clockData) => ({
    contentId: clockData.id,
    contentType: ContentType.tv,
  }),
  target: sendAnalyticsFavoriteRemove,
})

sample({
  clock: updateTvShowFx,
  filter: (clockData) => !clockData.favorite, // favorite should be false
  fn: (clockData) => ({
    contentId: clockData.id,
    contentType: ContentType.show,
  }),
  target: sendAnalyticsFavoriteRemove,
})

sample({
  clock: updateVodFx,
  filter: (clockData) => !clockData.favorite, // favorite should be false
  fn: (clockData) => ({
    contentId: clockData.id,
    contentType: ContentType.vod,
  }),
  target: sendAnalyticsFavoriteRemove,
})
