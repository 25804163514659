import type { API } from '@setplex/tria-api'
import { type HttpClient } from '../../http'
import { type AdapterDefaults } from '../../index.h'
import type {
  ApiCreateProfile,
  ApiProfile,
  ApiProfiles,
} from '../../interfaces/profiles'

export function use(
  http: HttpClient,
  profiles: API['profiles'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  profiles.getProfilesFx.use(async () => {
    const json = await http.get<ApiProfiles>(`/v3/profiles`)
    if (!json || !json.payload) {
      throw new Error('Empty answer in getProfilesFx')
    }

    return json.payload
  })

  profiles.getActiveProfileFx.use(async () => {
    const json = await http.get<ApiProfile>(`/v3/profiles/active`)
    if (!json || !json.payload) {
      throw new Error('Empty answer in getActiveProfileFx')
    }

    return json.payload
  })

  profiles.updateProfileFx.use(async (body) => {
    const { id } = body
    await http.put<void>(`/v3/profiles/${id}`, {
      json: body,
    })
  })

  profiles.removeProfileFx.use(async (profileId) => {
    await http.delete<void>(`/v3/profiles/${profileId}`)
  })

  profiles.updateProfileActiveStateFx.use(async (profileId) => {
    await http.patch<void>(`/v3/profiles/${profileId}/active`)
  })

  profiles.createProfileFx.use(async (body) => {
    const json = await http.post<ApiCreateProfile>(`/v3/profiles`, {
      json: body,
    })
    if (!json || !json.payload) {
      throw new Error('Empty answer in createProfileFx')
    }

    return json.payload
  })
}
