import { attach, createEffect, createStore } from 'effector'
import type { ApiEffect, PageNew, PageableInfoNew } from '../index.h'
import type { TvShowBundleInfo } from '../interfaces/bundles'
import type { TvShow } from '../interfaces/tvshows'

export interface ShowBundleWithContent extends TvShowBundleInfo {
  shows: TvShow[]
}

// *
// * EFFECTS
// *

// GET /v3/bundles/tv-shows
export const getShowBundlesFx: ApiEffect<
  {
    page: number
    count?: number
    sortOrder?: string
    sortBy?: string
  },
  PageNew<TvShowBundleInfo>
> = createEffect()

// GET /v3/bundles/tv-shows/{bundleId}
export const getShowBundleInfoFx: ApiEffect<
  { bundleId: number },
  TvShowBundleInfo
> = createEffect()

// GET /v3/bundles/tv-shows/{bundleId}/content
export const getShowBundleContentFx: ApiEffect<
  { bundleId: number; page: number; count?: number },
  PageNew<TvShow>
> = createEffect()

/**************** $ShowBundleWithContent *******************/
export const loadShowBundleWithContentFx: ApiEffect<
  { bundleId: number; page: number; count?: number },
  ShowBundleWithContent
> = createEffect()
export const loadShowBundleInfoForContent = attach({
  effect: getShowBundleInfoFx,
})
export const paginateShowBundleWithContent = attach({
  effect: getShowBundleContentFx,
}) // to paginate

export const $showBundleWithContent = createStore<ShowBundleWithContent | null>(
  null
)
  .on(loadShowBundleWithContentFx.doneData, (_, data) => data)
  .on(paginateShowBundleWithContent.doneData, (state, payload) => {
    if (!state) return null

    const currentShows = state.shows || []

    return {
      ...state,
      shows: [...currentShows, ...payload.content],
    }
  })

export const $showBundleWithContentPageInfo =
  createStore<PageableInfoNew | null>(null).on(
    paginateShowBundleWithContent.done,
    (_, { result: { content: _ignored, ...pageInfo }, params }) => ({
      ...pageInfo,
      params,
    })
  )
/**************** $ShowBundleWithContent *******************/

/**************** $ShowBundlesRow *******************/
export const initShowBundlesRowByPageFx = attach({
  effect: getShowBundlesFx,
  mapParams: (params) => ({ ...params, page: 0 }),
})

export const loadShowBundlesRowByPageFx = attach({ effect: getShowBundlesFx })

export const $showBundlesRow = createStore<TvShowBundleInfo[]>([])
  .on(initShowBundlesRowByPageFx.doneData, (_, page) => page.content)
  .on(loadShowBundlesRowByPageFx.doneData, (state, page) => [
    ...state,
    ...page.content,
  ])

/**************** $ShowBundlesRow *******************/
