import { T } from '@/helpers'
import { logger } from '@/logger'
import { api, type Features } from '@setplex/tria-api'
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
  type Store,
} from 'effector'

// get features flags and data
export const getFx = attach({ effect: api.subscriber.getFeaturesFx })

// feature flags setter
export const set = createEvent<Partial<Features>>()

// features store
export const $featuredFlags = createStore<Features>({})
  .on(getFx.doneData, (_, features) => features)
  .on(set, (features, partial) => ({ ...features, ...partial }))

// feature flags getter
export const get = <K extends keyof Features>(
  name: K,
  def: NonNullable<Features[K]>
): Store<NonNullable<Features[K]>> =>
  $featuredFlags.map((features) => features[name] ?? def)

// store for a flag, indicating, that flags were loaded
export const $ready = createStore<boolean>(false)

// log error
sample({
  clock: getFx.fail,
  fn: (fail) => ['failed to load features:', fail],
  target: logger.errorFx,
})

// on any response from getFeaturesFx -> set flags are ready
sample({
  clock: getFx.finally,
  fn: T,
  target: $ready,
})

//
// start timer 5 seconds
// if we don't get answer from v3/features for 5 seconds -> go ahead, to awoid locking sign-in form
//

let timeout: ReturnType<typeof setTimeout>
const timeoutFx = createEffect<void, void>(
  () => new Promise((resolve) => (timeout = setTimeout(resolve, 5000)))
)
const cancelTimeoutFx = createEffect<void, void>(() => clearTimeout(timeout))

// set timeout
sample({
  clock: getFx,
  target: timeoutFx,
})

// clear timeout on any response from v3/features
sample({
  clock: getFx.finally,
  target: cancelTimeoutFx,
})

// set readiness after 5 seconds regardless of v3/features absence of response
sample({
  clock: timeoutFx.done,
  fn: T,
  target: $ready,
})
