import type { ILocale, INoraTranslationsFormat } from '@setplex/tria-api'

export const translationsFromNoraFormat = (
  content: ILocale[]
): INoraTranslationsFormat => {
  return content.map(
    ({ locale, translations, languageName, defaultLang }: ILocale) => ({
      locale,
      languageName,
      translations,
      defaultLang,
    })
  )
}
