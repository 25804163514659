import type {
  NpawAnalyticsDimensions,
  NpawVideoDimensions,
} from '@setplex/player'
import type {
  FormattedPriceSettings,
  PurchaseInfo,
  Session,
} from '@setplex/tria-api'
import { runtime, version } from '~/app/environment'

export function createNpawAnalyticsOptions({
  providerId,
  packageName,
  subscriberName,
  deviceIdentifier,
  deviceExternalIP,
  deviceISP,
}: Session): NpawAnalyticsDimensions {
  return {
    'app.name': runtime.app.name, // got from era // e.g. 'tria',
    'app.releaseVersion': version, // versions.tria,

    'user.name': subscriberName,

    'device.id': deviceIdentifier || '<unknown>',
    'device.name': runtime.os.name || '<unknown>', // e.g. 'Android'
    'device.model': runtime.device.model || '<unknown>', // e.g. 's8'
    'device.brand': runtime.device.vendor || '<unknown>', // e.g. 'samsung'
    'device.type': runtime.device.libtype || runtime.device.type, // e.g. 'smartphone'
    'device.osName': runtime.os.name || '<unknown>',
    'device.osVersion': runtime.os.version || '<unknown>',
    'device.browserName': runtime.browser.name || '<unknown>',
    'device.browserVersion': runtime.browser.version || '<unknown>',
    'device.browserType': runtime.device.type,
    'device.browserEngine': runtime.browser.engine || '<unknown>',

    'content.package': packageName,
    'content.bitrate': -1, // default value in case player doesn't provide it
    'content.throughput': -1, // default value in case player doesn't provide it
    'content.customDimension.1': providerId,
    'content.customDimensions': {
      deviceId: deviceIdentifier || '<unknown>',
    },

    'network.ip': deviceExternalIP,
    'network.isp': deviceISP,

    'ad.ignore': true,
    'parse.cdnNode': false, // ? set `false` on smarttv
    'parse.manifest': true, // ? set `true` on smarttv
  } satisfies NpawAnalyticsDimensions
}

export function createNpawVideoOptions({
  id,
  name,
  program,
  type,
  isTrailer,
}: {
  id?: number
  name?: string
  priceSettings?: FormattedPriceSettings[]
  purchaseInfo?: PurchaseInfo
  program?: string
  type: 'vod' | 'tvshow' | 'catchup' | 'tv'
  isTrailer?: boolean
}): NpawVideoDimensions {
  return {
    'content.id': String(id),
    'content.title': name ? name + (isTrailer ? ' (Trailer)' : '') : '',
    'content.resource': '<undefined>',
    'content.program': program || 'N/A',
    'content.isLive': type === 'tv',
    'content.type': isTrailer ? 'trailer' : 'main',
    'content.playbackType': type,
  } satisfies NpawVideoDimensions
}
