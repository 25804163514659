import { type API } from '@setplex/tria-api'
import { type HttpClient } from '../../http'

import { type AdapterDefaults } from '../../index.h'
import type { ApiNoraTranslations } from '../../interfaces/i18n'
import { getFromCache, putToCache } from './cache'
import { translationsFromNoraFormat } from './i18n.format'

export function use(
  http: HttpClient,
  i18n: API['i18n'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  //GET /v3/i18n
  i18n.getAllFx.use(async () => {
    const cached = getFromCache()
    let useCached = false

    const json = await http
      .get<ApiNoraTranslations>(
        `/v3/i18n` + (cached ? `?timestamp=${cached[1]}` : '')
      )
      .catch((error) => {
        // error WG0304 in old implementation means that translations were not changed
        if (error?.payload?.errorCode === 'WG0304' && cached) {
          useCached = true
          return cached[0]
        }
        throw error
      })

    if (!json || !json.payload) {
      throw new Error('Empty answer in i18n.getAllFx')
    }

    if (!useCached) {
      putToCache(json)
    }

    return translationsFromNoraFormat(json.payload?.content || [])
  })
}
