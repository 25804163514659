import { createStore, is, sample, type Store, type Unit } from 'effector'

// it is ok for utility library functions to not use name conventions
/* eslint-disable effector/enforce-store-naming-convention */

export function history<S>({
  source,
  size,
}: {
  source: Unit<S>
  size?: number | Store<number>
}): Store<S[]> {
  if (!is.unit(source))
    throw new TypeError('source must be a unit from effector')

  const length = toStoreNumber(size)
  const stack = is.store(source)
    ? createStore<S[]>([source.defaultState])
    : createStore<S[]>([])

  sample({
    clock: source,
    source: { stack, length },
    fn: ({ stack, length }, data) => {
      stack = stack.slice(0, isNaN(length) ? undefined : length)
      stack.unshift(data)
      return stack
    },
    target: stack,
  })

  return stack.map((values) => values.slice(1))
}

function toStoreNumber(
  value: number | Store<number> | unknown = NaN
): Store<number> {
  if (is.store(value)) {
    return value
  }

  if (typeof value === 'number') {
    return createStore(value, { name: '$size' })
  }

  throw new TypeError(`size parameter should be number or Store`)
}
