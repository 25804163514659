import { register } from '!/repository'
import { model as sessionModel } from '$/session'
import { model as config, remote } from '@/config'
import { createMediaQueryStore } from '@/effector'
import { logger } from '@/logger'
import { decodeImg } from '@/tools'
import {
  createEffect,
  createEvent,
  createStore,
  sample,
  type Store,
} from 'effector'
import { aye } from '~/shared/helpers'
import { logoSizesFormatValidation, type ILogoSizes } from './lib'

const WINDOW_MEDIUM_SIZE_IN_PX = 1440

export const init = createEvent()
export const parseFx = createEffect<string, ILogoSizes>(
  logoSizesFormatValidation
)

export const DEFAULT_LOGO_URL = '/img/logo.svg'

export const $logoUrl = createStore<string>(DEFAULT_LOGO_URL)
export const $logoHeight = createStore<string | null>(null)

const $isMediaMatchWithSize: Store<boolean> = createMediaQueryStore(
  `(min-width: ${WINDOW_MEDIUM_SIZE_IN_PX}px)`,
  true
)

export const $logoStringHeights: Store<string> = config.get(
  remote.tria_packageLogoHeight
)

export const $logoHeights = createStore<ILogoSizes | null>(null)

export const $logoEnabled = config.get(remote.tria_isPackageLogoEnabled)

export const loadImageFx = createEffect<string, void, void>(decodeImg)

sample({
  clock: [sessionModel.$session, $logoEnabled, init],
  source: {
    enabledServerLogo: $logoEnabled,
    session: sessionModel.$session,
  },
  fn: ({ enabledServerLogo, session }) => {
    if (enabledServerLogo && session?.logoUrl) return session?.logoUrl
    return DEFAULT_LOGO_URL
  },
  target: loadImageFx,
})

sample({
  clock: loadImageFx.done,
  fn: ({ params }) => params,
  target: $logoUrl,
})

sample({
  clock: loadImageFx.fail,
  fn: () => DEFAULT_LOGO_URL,
  target: $logoUrl,
})

sample({ clock: $logoStringHeights, target: parseFx })

sample({
  clock: parseFx.doneData,
  target: $logoHeights,
})

sample({
  clock: [$logoHeights, $isMediaMatchWithSize],
  source: {
    logoHeights: $logoHeights,
    isLarge: $isMediaMatchWithSize,
  },
  filter: ({ logoHeights }) => aye(logoHeights),
  fn: ({ logoHeights, isLarge }) => {
    const { large, medium } = logoHeights!
    if (isLarge) return large
    return medium
  },
  target: $logoHeight,
})

sample({
  clock: parseFx.failData,
  fn: (error) => [error],
  target: logger.errorFx,
})

register($logoUrl, '$logoUrl')
