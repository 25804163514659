import type { API, BannerCarousel, FeaturedCarousel } from '@setplex/tria-api'
import { type HttpClient } from '../../http'
import { type AdapterDefaults } from '../../index.h'
import type {
  ApiAnswerBannerCarousel,
  ApiAnswerFeaturedCarousel,
} from '../../interfaces/banners'
import { formatBanners, formatFeaturedCarousel } from './banners.format'

export function use(
  http: HttpClient,
  banners: API['banners'],
  _api: API,
  // @ts-ignore
  defaults: AdapterDefaults
): void {
  // GET /v3/carousels/banners
  banners.getBannersFx.use(async () => {
    const json = await http.get<ApiAnswerBannerCarousel>(
      `/v3/carousels/banners`
    )
    if (!json || !json.payload) {
      throw new Error('Empty answer in getBannersFx')
    }
    return (json.payload || []).map(formatBanners) as BannerCarousel[]
  })

  // GET /v3/carousels/featured
  banners.getFeaturedCarouselFx.use(async () => {
    const json = await http.get<ApiAnswerFeaturedCarousel>(
      `/v3/carousels/featured`
    )
    if (!json || !json.payload) {
      throw new Error('Empty answer in getFeaturedCarouselFx')
    }
    return (json.payload || []).map(
      formatFeaturedCarousel
    ) as FeaturedCarousel[]
  })
}
