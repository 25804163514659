import { features } from '!/flags'
import { register } from '!/repository'
import { exists } from '@/helpers'
import { api } from '@setplex/tria-api'
import { attach, createStore, sample, type Store } from 'effector'
import { model as session } from '~/entities/session'
import { isGuestSession } from './lib'

// indicates about enabled or disabled guest mode feature
export const $enabled: Store<boolean> = // `false` by default
  features.get('guestModeEnabled', false)

export const guestInFx = attach({
  effect: api.session.guestInFx,
  mapParams: (_?: false) => undefined,
})

// store, indicating, if we logged in as guest
export const $guest: Store<boolean | null> = session.$session.map((session) =>
  exists(session) ? isGuestSession(session) : null
)

// authentication mode - user | guest
export const $mode = createStore<null | 'user' | 'guest'>(null)
  .on(api.session.signInFx.done, () => 'user')
  .on(api.session.guestInFx.done, () => 'guest')
  .on(session.$session, (current, session) =>
    session && typeof session.profileId === 'number' // eslint-disable-line no-nested-ternary
      ? isGuestSession(session)
        ? 'guest'
        : 'user'
      : current
  )

//
// disable guest mode in case of error WB0065
//

sample({
  clock: api.events.logic.guestModeDisabled,
  fn: () => ({ guestModeEnabled: false }),
  target: features.set,
})

//
// register stores and events
//

register($guest, '$feature/guest')
