import { api } from '@setplex/tria-api'
import { createEvent, sample } from 'effector'
import { previous } from 'patronum'
import { model as router } from '~/core/router'
import { isGuestSession } from '~/features/subscriber/guest'
import { F, T } from '~/shared/helpers'
import { keys } from '~/shared/jail'
import { type Cage, type Transit, type Trial } from '../index.h'

/*
 * ### Guest cage ###
 */

const init = createEvent<Transit>('init')
const trial = createEvent<Trial>('trial')
const seal = createEvent<boolean>('seal')

// out events
const subscriberSignedIn = createEvent('subscriberSignedIn')

export const guest = {
  name: 'guest',
  key: keys.guest,
  init,
  trial,
  seal,
  in: [],
  out: {
    subscriberSignedIn,
  },
} satisfies Cage

/*
 * cage logic
 */

sample({
  clock: trial,
  fn: () => '/',
  target: router.navigateReplace,
})

sample({
  clock: init,
  filter: ({ trial }) => trial != null && trial.route != null,
  fn: () => '/',
  target: router.navigateReplace,
})

// save previous session, to restore it after failed user sign in try
const $previousSession = previous(api.session.$session)

sample({
  clock: api.session.signInFx.fail,
  source: $previousSession,
  filter: isGuestSession, // restore only previous guest session
  target: api.session.$session,
})

sample({
  clock: api.session.signInFx,
  target: seal.prepend(T),
})

sample({
  clock: api.session.signInFx.finally,
  target: seal.prepend(F),
})

sample({
  clock: api.session.signInFx.done,
  target: subscriberSignedIn,
})
