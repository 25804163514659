import * as component from './component'
import * as event from './event'
import * as store from './store'

import { type as COMPONENT } from './component'
import { type as EVENT } from './event'
import { type as STORE } from './store'

export { COMPONENT, EVENT, STORE }

type AnyType = typeof EVENT | typeof STORE | typeof COMPONENT

export function register<T>(what: T, name?: string, type?: AnyType): T {
  if (store.check(what)) {
    return store.set(what, name) as T
  }
  if (event.check(what)) {
    return event.set(what, name) as T
  }
  if (component.check(what) && name != null && type === COMPONENT) {
    return component.set(what, name) as T
  }
  return what
}

export function get<T>(name: string, type: AnyType): T | null {
  if (type === STORE) {
    return store.get(name) as T
  }
  if (type === EVENT) {
    return event.get(name) as T
  }
  if (type === COMPONENT) {
    return component.get(name) as T
  }
  return null
}
