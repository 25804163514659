import { removeProfilePopupModel } from '$/profiles-popup/remove'
import { model as sessionModel } from '$/session'
import { aye, nay } from '@/helpers'
import {
  maxLength,
  minLength,
  required,
  withoutSpecSymbols,
} from '@/validators'
import { api } from '@setplex/tria-api'
import { combine, createEvent, createStore, restore, sample } from 'effector'
import { createForm } from 'effector-forms'
import { or } from 'patronum'
import { profilesModel } from '~/entities/profiles'
import { createProfilePopupModel } from '~/entities/profiles-popup/create'
import {
  ErrorTranslationsKeys,
  USERNAME_CHARS_MAX_COUNT,
  USERNAME_CHARS_MIN_COUNT,
} from './index.h'
import { hasProfileName } from './lib'
import { $createProfileTranslations } from './translations'

export const init = createEvent<void>()

export const cancel = createEvent<void>()

export const $formState = restore(
  createProfilePopupModel.openProfilesCreatePopup,
  null
).reset([
  profilesModel.updateProfileFx.done,
  profilesModel.createProfileFx.done,
  sessionModel.$session,
])

export const $formTitle = combine(
  $formState,
  $createProfileTranslations,
  (formState, { createProfileTitle, editProfileTitle }) => {
    if (formState?.isEditForm) return editProfileTitle
    return createProfileTitle
  }
)

export const $pending = or(
  profilesModel.createProfileFx.pending,
  profilesModel.updateProfileFx.pending,
  profilesModel.updateProfileActiveStateFx.pending,
  profilesModel.getActiveProfileFx.pending
)

export const $errorKey = createStore<string>('')
  .on(
    api.events.logic.maxProfilesCount,
    () => ErrorTranslationsKeys.MAX_COUNT_PROFILES
  )
  .reset([
    profilesModel.createProfileFx,
    createProfilePopupModel.closeProfilesCreatePopup,
    removeProfilePopupModel.closeProfilesRemovePopup,
    cancel,
  ])

export const $profilesCount = profilesModel.$optimisticProfiles.map(
  (profiles) => profiles.length
)

export const createProfileForm = createForm({
  fields: {
    username: {
      init: '',
      rules: [
        required(),
        withoutSpecSymbols(),
        minLength(USERNAME_CHARS_MIN_COUNT),
        maxLength(USERNAME_CHARS_MAX_COUNT),
      ],
    },
  },
  validateOn: ['submit'],
})

sample({ clock: init, target: createProfilePopupModel.init })

sample({
  clock: createProfilePopupModel.openProfilesCreatePopup,
  target: createProfileForm.set,
})

sample({
  clock: createProfileForm.formValidated,
  source: {
    formState: $formState,
    profiles: profilesModel.$optimisticProfiles,
  },
  filter: ({ formState, profiles }, { username }) =>
    nay(formState?.isEditForm) && nay(hasProfileName(profiles, username)),
  fn: (_, { username }) => ({ name: username }),
  target: profilesModel.createProfileFx,
})

sample({
  clock: createProfileForm.formValidated,
  source: profilesModel.$optimisticProfiles,
  filter: (profiles, { username }) => aye(hasProfileName(profiles, username)),
  fn: () => ({ rule: 'alreadyExists' }),
  target: createProfileForm.fields.username.addError,
})

sample({
  clock: createProfileForm.formValidated,
  source: {
    formState: $formState,
    profiles: profilesModel.$optimisticProfiles,
  },
  filter: ({ formState, profiles }, { username }) =>
    aye(formState?.isEditForm) && nay(hasProfileName(profiles, username)),
  fn: ({ formState }, { username }) => ({
    name: username,
    id: formState?.profileId,
  }),
  target: profilesModel.updateProfileFx,
})

sample({
  clock: cancel,
  source: $formState,
  filter: (form) => aye(form?.isPopupForm),
  target: createProfilePopupModel.closeProfilesCreatePopup,
})

sample({
  clock: [cancel, createProfilePopupModel.closeProfilesCreatePopup],
  target: createProfileForm.reset,
})
