import { get, STORE } from '!/repository'
import { logger } from '@/logger'
import { type Store } from 'effector'
import { useMemo } from 'react'
import { type SkinView } from '../../../index.h'
import { content, raw, type Scope } from '../scope'

/**
 * Paths from skin, which could potentially contain data sources, like Effector stores
 */
const sourcePaths: (keyof SkinView)[] = [
  'map',
  'over',
  'data',
  'link',
  'render',
  'match',
  'on',
]

/**
 * Regular expression used to search for Effector stores names in strings
 */
const effectorStoreRe = /[$|][\w<>|/+-]+/g // MUST have `g` modifier
const noStores = ['||', '|>'] // blacklist some grammar symbols

/**
 * Get all pattern matching substrings from string
 */
function match(value: string, pattern: RegExp): string[] {
  const matches: string[] | null = value.match(pattern)
  return matches || []
}

/**
 * Recursively unroll any nested data structure in order to extract data sources
 */
function unroll(sources: string[], from: unknown, pattern: RegExp): void {
  if (typeof from === 'string') {
    sources.push(...match(from, pattern))
  } else if (Array.isArray(from)) {
    for (const value of from) {
      unroll(sources, value, pattern)
    }
  } else if (typeof from === 'object' && from !== null) {
    for (const value of Object.values(from)) {
      unroll(sources, value, pattern)
    }
  }
}

/**
 * Extract list of all used in skin data sources
 */
function extract(
  skin: SkinView,
  paths: (keyof SkinView)[],
  pattern: RegExp
): string[] {
  const sources: string[] = []
  for (const path of paths) {
    unroll(sources, skin[path], pattern)
  }
  return sources.sort() // sort just in case
}

/**
 * Hook to get all data sources values used in skin
 */
export function useSources(skin: SkinView): Scope {
  const stores: Scope = useMemo(() => {
    // get effector stores names
    const names: string[] = extract(skin, sourcePaths, effectorStoreRe)

    // get all stores from registry
    const stores: Scope = {}
    for (const name of names) {
      if (noStores.includes(name)) continue // skip blacklisted names
      const store: Store<unknown> | null = get(name, STORE)
      if (store) {
        stores[name] = raw(`store: ${name}`, store, content)
      } else {
        logger.warn(`Store "${name}" is unregistered!`)
      }
    }

    // return all found stores (as scope data with type `store`)
    return stores
  }, [skin])

  // we could add more types of data sources here in future,
  // not just Effector stores, but something else

  // return record with scope source data
  return stores
}
