import type { API } from '@setplex/tria-api'
import type { AnnouncementListDto } from '@setplex/wbs-api-types'
import type { HttpClient } from '../../http'
import type { AdapterDefaults } from '../../index.h'

export function use(
  http: HttpClient,
  announcements: API['announcements'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  announcements.pushAnnouncementsFx.use(async () => {
    const json = await http.get<AnnouncementListDto>(`/device/announcements`)
    if (!json) {
      throw new Error('Empty answer in pushAnnouncementsFx')
    }
    return json ?? {}
  })
}
