import * as bunch from './keys'

export type Key = (typeof bunch)[keyof typeof bunch]

export const keys = {
  ...bunch,

  /**
   * always unique key, meaning, that these key will not be reused,
   * and if this key is set for cage - cage will not be accessible.
   * there is no sense to add unique key for cage,
   * because it will not grant access to any cage.
   */
  get lost() {
    return Symbol('lost') as Key
  },
}
