import { sample, type Event, type EventCallable, type Store } from 'effector'

export function postpone<T>({
  clock,
  until,
  target,
}: {
  clock: Event<T>
  until: Store<boolean>
  target: EventCallable<T>
}): EventCallable<T> {
  return sample({
    clock: [clock, until],
    source: clock,
    filter: until,
    target,
  })
}
