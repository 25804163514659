import type { PartnerProduct, PurchaseInfo } from '@setplex/tria-api'
import {
  MediaTypes,
  PriceSettingsType,
  PurchaseInfoContentPurchaseType as PurchaseInfoContentPurchaseTypeEnum,
} from '@setplex/tria-api'
import type { PartnerProductDetails } from '@setplex/tria-api/src/interfaces/partnerProgram'
import {
  type PartnerProductDto_SinglePartnerProductView,
  type PartnerProductDto_SlicedPartnerProductView,
} from '@setplex/wbs-api-types'
import { getBackgroundImage, getHorizontalImage } from '../../utils/images'
import { getMinPriceObject } from '../../utils/minPriceObj'
import { formatPriceSettings } from '../../utils/payment'

export const formatPartnerProduct = ({
  id,
  coverImageUrl,
  free,
  priceSettings,
  purchaseInfo,
  ...rest
}: PartnerProductDto_SlicedPartnerProductView): PartnerProduct => {
  const minPriceObj = getMinPriceObject(priceSettings)

  return {
    ...rest,
    id: id as number,
    coverImageUrl: getHorizontalImage(coverImageUrl),
    mediaType: MediaTypes.PARTNER_PRODUCT,
    free: free === undefined ? true : free,
    priceSettings: formatPriceSettings(priceSettings),
    purchaseInfo: purchaseInfo as PurchaseInfo | undefined,
    minCurrency: minPriceObj?.currency,
    minPrice: minPriceObj?.price,
    rented:
      purchaseInfo?.contentPurchaseType ===
      PurchaseInfoContentPurchaseTypeEnum.Rented,
  }
}

export const formatPartnerProductDetails = ({
  id,
  coverImageUrl,
  purchaseInfo,
  priceSettings,
  free,
  name,
  ...rest
}: PartnerProductDto_SinglePartnerProductView): PartnerProductDetails => {
  const minPriceObj = getMinPriceObject(priceSettings)

  return {
    ...rest,
    id: id as number,
    name: name as string,
    coverImageUrl: getBackgroundImage(coverImageUrl),
    mediaType: MediaTypes.PARTNER_PRODUCT,
    free: free === undefined ? true : free,
    purchaseInfo: purchaseInfo as PurchaseInfo | undefined,
    minCurrency: minPriceObj?.currency,
    minPrice: minPriceObj?.price,
    priceSettings: formatPriceSettings(priceSettings),
    priceType: PriceSettingsType.Rent,
    rented:
      purchaseInfo?.contentPurchaseType ===
      PurchaseInfoContentPurchaseTypeEnum.Rented,
  }
}
