import { type HttpClient } from '../../http'
import { type AdapterDefaults } from '../../index.h'
import type { API, ICreditCardsConfig } from '@setplex/tria-api'
import { type ApiAnswerGetCreditCards } from '../../interfaces/creditCards'

export function use(
  http: HttpClient,
  creditCards: API['creditCards'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  // GET /v3/creditcards
  creditCards.getSavedCreditCardsFx.use(async () => {
    const json = await http.get<ApiAnswerGetCreditCards>('/v3/creditcards')
    return json.payload as ICreditCardsConfig
  })
}
