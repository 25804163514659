import {
  attach,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector'
import {
  setUserProperties as fbSetUserProperties,
  getAnalytics,
  type Analytics,
  type CustomParams,
} from 'firebase/analytics'
import { type FirebaseApp } from 'firebase/app'
import { runtime } from '~/app/environment'
import { env } from '~/shared/config/env'

export const init = createEvent<FirebaseApp>()
export const setUserProperties = createEvent<CustomParams>()
export const done = createEvent<void>()

// get analytics
const getAnalyticsFx = createEffect<FirebaseApp, Analytics>(getAnalytics)
export const $analytics = restore<Analytics>(getAnalyticsFx.doneData, null)

// set user properties
// note, that Firebase allows to set up to 25 different user properties per project
export const setUserPropertiesFx = attach({
  source: $analytics,
  effect: (analytics, params: CustomParams) => {
    if (analytics) {
      fbSetUserProperties(analytics, params)
      return params
    }
  },
})

// defined custom user properties
export const $customParams = createStore<CustomParams>({}).on(
  setUserPropertiesFx.doneData,
  (has, set) => ({ ...has, ...set })
)

// init firebase analytics
sample({
  clock: init,
  target: getAnalyticsFx,
})

// set initial app commot properties
sample({
  clock: getAnalyticsFx.done,
  fn: () => ({
    appName: runtime.app.name,
    appEnvironment: env.TRIA_ENVIRONMENT,
  }),
  target: setUserPropertiesFx,
})

// trigger done after initial properties are set
// or if analytics initialization failed
sample({
  clock: [setUserPropertiesFx.done, getAnalyticsFx.fail],
  target: done,
})

// react on exposed setUserProperties event
sample({
  clock: setUserProperties,
  source: $analytics,
  filter: Boolean, // do nothing if analytics is not initialized
  fn: (_, params) => params,
  target: setUserPropertiesFx,
})
