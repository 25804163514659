import { isPromise } from '@/helpers'
import { logger } from '@/logger'
import { useContext, useLayoutEffect, useMemo, useState } from 'react'
import { type SkinView, type SkinViews } from '../../../index.h'
import { SkinContext } from '../../components'

/**
 * Hook to get skin definition from context
 * (to where it is _should_be_ loaded from Era service)
 */
export function useSkin(
  name: string,
  explicit?:
    | SkinViews
    | Promise<SkinViews>
    | (() => SkinViews | Promise<SkinViews>)
): SkinView | SkinView[] | null {
  // get skin views implicitly from context
  const implicit: SkinViews | Promise<SkinViews> | null | undefined =
    useContext(SkinContext)

  // if skin views are passed explicitly - it should be uses prior to context value
  const description = useMemo(() => {
    const init = explicit || implicit
    return typeof init === 'function' ? init() : init
  }, [explicit, implicit])

  // resolved skin views
  const [views, setViews] = useState(
    isPromise(description) ? null : description
  )

  // wait until skin views are resolved from promise
  useLayoutEffect(() => {
    let wait: Promise<SkinViews> | null = null

    if (isPromise(description)) {
      wait = description
      description.then((view) => {
        if (description === wait) {
          setViews(view)
        }
      })
    }

    return () => {
      wait = null
    }
  }, [description])

  // skin views are not passed at all
  if (description == null) {
    logger.warn('Skin is not provided')
    return null
  }

  // skin views are not resolved from promise yet, just wait
  if (views == null) {
    return null
  }

  let skin: SkinView | SkinView[] | undefined = views['export ' + name]

  // fallback to old behavior
  // TODO: remove this fallback in future
  if (!skin) {
    skin = views[name]
    if (skin) {
      logger.log(
        `%cSkin "${name}" is not exported! This is deprecated behavior! Export this skin!`,
        'font-size:3em;color:black;background:red;'
      )
    }
  }

  if (!skin) {
    logger.warn(`Skin "${name}" is not defined nor exported`)
    return null
  }

  return skin
}
