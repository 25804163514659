import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector'
import { produce } from 'immer'
import type { ApiEffect, Page, PageInfo, PageableInfo } from '../index.h'
import { type MediaUrl } from '../interfaces/generic'
import type {
  CategoryWithShows,
  CategoryWithShowsApi,
  CurrentTvShow,
  GetAllTvShowsParams,
  GetPurchasedTvShowsParams,
  IGenerateSeriesPlaylistParams,
  SeriesPlaylist,
  SeriesPlaylistRecalculate,
  TVShowsPlaylist,
  TvShow,
  TvShowCategory,
  TvShowEpisode,
  TvShowRecommended,
  TvShowSeason,
  TvShowUpdateObj,
  TvShowUpdated,
} from '../interfaces/tvshows'

const DEFAULT_PAGE_SIZE = 36

//*
//* EFFECTS
//*

// GET /v3/tvshows?sort-by=updatedTime&sort-order=desc
export const getLastAddedTvShowsFx: ApiEffect<
  { page: number; count?: number; categoryId?: number },
  Page<TvShow>
> = createEffect()

// GET /v3/tvshows
export const getAllTvShowsFx: ApiEffect<
  GetAllTvShowsParams,
  Page<TvShow>
> = createEffect()

// GET /v3/tvshows?only-favorites=true
export const getOnlyFavoritesTvShowsRowFx = attach({
  effect: getAllTvShowsFx,
  mapParams: () => ({ page: 0, onlyFavorites: true }),
})

export const getOnlyFavoritesTvShowsGridFx = attach({
  effect: getAllTvShowsFx,
  mapParams: (params?: GetAllTvShowsParams) => ({
    ...params,
    page: params?.page || 0,
    onlyFavorites: true,
  }),
})

// GET /v3/library/tvshows
export const getPurchasedTvShowsFx: ApiEffect<
  GetPurchasedTvShowsParams,
  Page<TvShow>
> = createEffect()

export const getPurchasedTvShowsRowFx = attach({
  effect: getPurchasedTvShowsFx,
  mapParams: (params?: GetPurchasedTvShowsParams) => ({
    ...params,
    page: params?.page || 0,
  }),
})

export const getPurchasedTvShowsGridFx = attach({
  effect: getPurchasedTvShowsFx,
  mapParams: (params?: GetPurchasedTvShowsParams) => ({
    ...params,
    page: params?.page || 0,
  }),
})

// GET /v3/tvshows?ids=tvShowId
export const getTvShowBaseFx: ApiEffect<{ tvShowId: number }, TvShow[]> =
  createEffect()
export const getTvShowFx = attach({ effect: getTvShowBaseFx })

// GET /v3/tvshows?categoryId=categoryId
export const getShowsByCategoryIdFx: ApiEffect<
  {
    page: number
    count?: number
    categoryId: number
  },
  Page<TvShow>
> = createEffect()

// GET /v3/tvshows?updated-only=true
export const getTvShowsUpdatedOnlyFx: ApiEffect<void, TvShowUpdated[]> =
  createEffect()

// GET /v3/tvshows/categories
export const getTvShowCategoriesFx: ApiEffect<
  { page: number; count?: number; sortOrder?: string },
  Page<TvShowCategory>
> = createEffect()

// GET /v3/tvshows/categories/{categoryId}/subcategories
export const getTvShowSubcategoriesByCategoryFx: ApiEffect<
  { page: number; categoryId: number; count?: number; sortOrder?: string },
  Page<TvShowCategory>
> = createEffect()

// GET /v3/tvshows/{tvShowId}/trailer/url
export const getTvShowTrailerUrlFx: ApiEffect<{ tvShowId: number }, MediaUrl> =
  createEffect()

// PATCH /v3/tvshows/{tvShowId}
export const updateTvShowFx: ApiEffect<TvShowUpdateObj, void> = createEffect()

// PUT /v3/tvshows/{tvShowId}/season/{seasonId}/episodes/{episodeId}/continue-watching
export const updateContinueWatchingTvShowFx: ApiEffect<
  {
    tvShowId: number
    seasonId: number
    episodeId: number
    stoppedTime: number
  },
  void
> = createEffect()

// DELETE /v3/tvshows/{tvShowId}/season/{seasonId}/episodes/{episodeId}/continue-watching
export const removeContinueWatchingTvShowFx: ApiEffect<
  {
    tvShowId: number
    seasonId: number
    episodeId: number
  },
  void
> = createEffect()

// PUT /v3/tvshows/{tvShowId}/episodes/{episodeId}/continue-watching
export const updateContinueWatchingWithoutSeasonFx: ApiEffect<
  { tvShowId: number; episodeId: number; stoppedTime: number },
  void
> = createEffect()

// DELETE /v3/tvshows/{tvShowId}/episodes/{episodeId}/continue-watching
export const removeContinueWatchingWithoutSeasonFx: ApiEffect<
  { tvShowId: number; episodeId: number },
  void
> = createEffect()

// GET /v3/tvshows/{tvShowId}/seasons/{seasonId}/episodes/{episodeId}
export const getTvShowEpisodeFx: ApiEffect<
  { tvShowId: number; episodeId: number; seasonId: number },
  TvShowEpisode
> = createEffect()

// GET /v3/tvshows/{tvShowId}/episodes/{episodeId}
export const getTvShowEpisodeWithoutSeasonFx: ApiEffect<
  { tvShowId: number; episodeId: number },
  TvShowEpisode
> = createEffect()

// GET /v3/tvshows/{tvShowId}/season/{seasonId}/episodes
export const getTvShowEpisodesBySeasonByPageBaseFx: ApiEffect<
  {
    tvShowId: number
    seasonId: number
    page: number
    count?: number
    sortBy?: string
    sortOrder?: string
  },
  Page<TvShowEpisode>
> = createEffect()

export const getTvShowEpisodesBySeasonByPageFx = attach({
  effect: getTvShowEpisodesBySeasonByPageBaseFx,
})

export const getPlayerTvShowEpisodesBySeasonByPageFx = attach({
  effect: getTvShowEpisodesBySeasonByPageBaseFx,
})
// GET /v3/tvshows/{tvShowId}/episodes
export const getTvShowEpisodesWithoutSeasonByPageBaseFx: ApiEffect<
  {
    tvShowId: number
    page: number
    count?: number
    sortBy?: string
    sortOrder?: string
  },
  Page<TvShowEpisode>
> = createEffect()
export const getTvShowEpisodesWithoutSeasonByPageFx = attach({
  effect: getTvShowEpisodesWithoutSeasonByPageBaseFx,
})
export const getPlayerTvShowEpisodesWithoutSeasonByPageFx = attach({
  effect: getTvShowEpisodesWithoutSeasonByPageBaseFx,
})
// GET /v3/tvshows/{tvShowId}/seasons
export const getTvShowSeasonsByPageBaseFx: ApiEffect<
  {
    tvShowId: number
    page: number
    count?: number
    sortBy?: string
    sortOrder?: string
  },
  Page<TvShowSeason>
> = createEffect()

export const getTvShowSeasonsByPageFx = attach({
  effect: getTvShowSeasonsByPageBaseFx,
})

// GET /v3/tvshows/{tvShowId}/seasons/{seasonId}
export const getTvShowSeasonByIdFx: ApiEffect<
  {
    tvShowId: number
    seasonId: number
  },
  TvShowSeason
> = createEffect()

// GET /v3/tvshows/{tvShowId}/seasons/{seasonId}/episodes/{episodeId}/url
export const getTvShowEpisodeUrlFx: ApiEffect<
  { tvShowId: number; seasonId: number; episodeId: number },
  MediaUrl
> = createEffect()

// GET /v3/tvshows/{tvShowId}/episodes/{episodeId}/url
export const getEpisodeUrlWithoutSeasonFx: ApiEffect<
  { tvShowId: number; episodeId: number },
  MediaUrl
> = createEffect()

// GET /v3/tvshows/{tvShowId}/seasons/{seasonId}/episodes/{episodeId}
// GET /v3/tvshows/{tvShowId}/episodes/{episodeId}
export const getTvShowEpisodesSmartFx: ApiEffect<
  {
    tvShowId: number
    page: number
    seasonId?: number
    count?: number
    sortBy?: string
    sortOrder?: string
  },
  Page<TvShowEpisode>
> = createEffect()

export const getAllSeasonsFx: ApiEffect<
  { tvShowId: number },
  Array<TvShowSeason>
> = createEffect()

export const getAllSeasonsByShowIdFx = attach({
  effect: getAllSeasonsFx,
})

export const getSeasonsWithEpisodesFx: ApiEffect<
  { tvShowId: number },
  TVShowsPlaylist
> = createEffect()

export const getAllEpisodesFx: ApiEffect<
  { tvShowId: number; seasonId?: number },
  Array<TvShowEpisode>
> = createEffect()

export const getAllEpisodesBySeasonFx = attach({
  effect: getAllEpisodesFx,
})

export const getCurrenShowByIdFx = attach({
  effect: getTvShowFx,
})

export const getAllEpisodesForSeasonsFx: ApiEffect<
  { seasons: Array<{ id: number }>; tvShowId: number },
  { [key: number]: Array<TvShowEpisode> }
> = createEffect()

// POST /v3/tvshows/{tvShowId}/seasons/{seasonId}/episodes/{episodeId}/watched
export const postMarkEpisodeWatchedFx: ApiEffect<
  { tvShowId: number; seasonId: number; episodeId: number },
  void
> = createEffect()

// POST /v3/tvshows/{tvShowId}/episodes/{episodeId}/watched
export const postMarkEpisodeWatchedWithoutSeasonFx: ApiEffect<
  { tvShowId: number; episodeId: number },
  void
> = createEffect()

// CUSTOM EFFECTS

export const getRecommendedTvShowsFx: ApiEffect<
  { categoryId: number; tvShowId: number; showIndex?: number },
  TvShowRecommended[]
> = createEffect()

// imitate GET /categories/{categoryId}
export const getTvShowCategoryFx: ApiEffect<
  { categoryId: number },
  TvShowCategory | null
> = createEffect()

export const getCategoriesWithShowsFx: ApiEffect<
  {
    page: number
    count?: number
  },
  Page<CategoryWithShowsApi>
> = createEffect()

// *
// * HANDLERS
// *

const updateSingleHandler = (
  item: TvShow | null,
  params: TvShowUpdateObj
): TvShow | null => {
  if (!item) return null
  if ('favorite' in params) {
    ;(item as any).__favorite = item.favorite // hack to preserve previous value
    item.favorite = params.favorite
  }
  return { ...item }
}
const updateSingleSuccessHandler = (
  item: TvShow | null,
  params: TvShowUpdateObj
) => {
  if (!item) return null
  if ('favorite' in params && '__favorite' in item) {
    delete (item as any).__favorite
  }
  return { ...item }
}
const updateSingleFailHandler = (
  item: TvShow | null,
  params: TvShowUpdateObj
) => {
  if (!item) return null
  if ('favorite' in params) {
    item.favorite =
      '__favorite' in item ? (item as any).__favorite : !params.favorite
  }
  return { ...item }
}

const onTvShowFavoriteHandler = (
  tvShows: TvShow[],
  params: TvShowUpdateObj
) => {
  const index = tvShows.findIndex((show) => show.id === params.id)
  if (index !== -1) {
    const mutableShow =
      updateSingleHandler(tvShows[index], params) || tvShows[index]
    tvShows.splice(index, 1, mutableShow)
    return tvShows
  }
}

const onTvShowFavoriteDoneHandler = (
  tvShows: TvShow[],
  { params }: { params: TvShowUpdateObj }
) => {
  const index = tvShows.findIndex((show) => show.id === params.id)
  if (index !== -1) {
    const mutableShow =
      updateSingleSuccessHandler(tvShows[index], params) || tvShows[index]
    tvShows.splice(index, 1, mutableShow)
    return tvShows
  }
}

const onTvShowFavoriteFailHandler = (
  tvShows: TvShow[],
  { params }: { params: TvShowUpdateObj }
) => {
  const index = tvShows.findIndex((show) => show.id === params.id)
  if (index !== -1) {
    const mutableShow =
      updateSingleFailHandler(tvShows[index], params) || tvShows[index]
    tvShows.splice(index, 1, mutableShow)
    return tvShows
  }
}

//*
//* STORES
//*
export const $lastAddedTvShowsPageInfo = createStore<PageInfo | null>(null).on(
  getLastAddedTvShowsFx.doneData,
  (_, { content: _ignored, ...pageInfo }) => pageInfo
)
export const $lastAddedTvShows = createStore<TvShow[]>([]).on(
  getLastAddedTvShowsFx.doneData,
  (_, page) => page.content || []
)

export const $tvShowCategories = createStore<TvShowCategory[]>([]).on(
  getTvShowCategoriesFx.doneData,
  (_, page) => page.content || []
)

export const $tvShowCategoriesPageInfo = createStore<PageInfo | null>(null).on(
  getTvShowCategoriesFx.doneData,
  (_, { content: _ignored, ...pageInfo }) => pageInfo
)

// GET /v3/tvshows/categories/{categoryId}/subcategories
export const $tvShowSubCategories = createStore<TvShowCategory[]>([]).on(
  getTvShowSubcategoriesByCategoryFx.doneData,
  (_, page) => page.content || []
)
export const $tvShowSubCategoriesPageInfo = createStore<PageInfo | null>(
  null
).on(
  getTvShowSubcategoriesByCategoryFx.doneData,
  (_, { content: _ignored, ...pageInfo }) => pageInfo
)

// GET /v3/tvshows/{tvShowId}/trailer/url
export const $tvShowTrailerUrl = createStore<MediaUrl | null>(null).on(
  getTvShowTrailerUrlFx.doneData,
  (_, urlObject) => urlObject
)

// GET /v3/tvshows
export const $allTvShows = createStore<TvShow[]>([])
  .on(getAllTvShowsFx.doneData, (_, page) => page.content || [])
  .on(updateTvShowFx, onTvShowFavoriteHandler)
  .on(updateTvShowFx.done, onTvShowFavoriteDoneHandler)
  .on(updateTvShowFx.fail, onTvShowFavoriteFailHandler)

export const $allTvShowsPageInfo = createStore<PageInfo | null>(null).on(
  getAllTvShowsFx.doneData,
  (_, { content: _ignored, ...pageInfo }) => pageInfo
)

// GET /v3/tvshows?&updatedTimeOnly=true
export const $tvShowsUpdatedOnly = createStore<TvShowUpdated[]>([]).on(
  getTvShowsUpdatedOnlyFx.doneData,
  (_, tvShows) => tvShows
)

// GET /v3/tvshows/{tvShowId}/seasons/{seasonId}/episodes/{episodeId}
// GET /v3/tvshows/{tvShowId}/episodes/{episodeId}
export const $tvShowEpisode = createStore<TvShowEpisode | null>(null)
  .on(getTvShowEpisodeFx.doneData, (_, episode) => episode)
  .on(getTvShowEpisodeWithoutSeasonFx.doneData, (_, episode) => episode)

const compareSortOrder = (
  a: TvShowSeason | TvShowEpisode,
  b: TvShowSeason | TvShowEpisode
) => Number(a?.sortOrder) - Number(b?.sortOrder)

const compareDisplayNumber = (
  a: TvShowSeason | TvShowEpisode,
  b: TvShowSeason | TvShowEpisode
) => Number(a?.displayNumber) - Number(b?.displayNumber)

// GET /v3/tvshows/{tvShowId}/season/{seasonId}/episodes
// GET /v3/tvshows/{tvShowId}/episodes
export const $tvShowSeasonEpisodes = createStore<TvShowEpisode[]>([])
  .on(
    getTvShowEpisodesBySeasonByPageFx.doneData,
    (_, page) => page.content.sort(compareSortOrder) || []
  )
  .on(
    getTvShowEpisodesWithoutSeasonByPageFx.doneData,
    (_, page) => page.content.sort(compareSortOrder) || []
  )

export const $tvShowEpisodesPageInfo = createStore<PageInfo | null>(null)
  .on(
    getTvShowEpisodesBySeasonByPageFx.doneData,
    (_, { content: _ignored, ...pageInfo }) => pageInfo
  )
  .on(
    getTvShowEpisodesWithoutSeasonByPageFx.doneData,
    (_, { content: _ignored, ...pageInfo }) => pageInfo
  )

// GET /v3/tvshows/{tvShowId}/seasons
export const $tvShowSeasons = createStore<TvShowSeason[]>([])
  .on(
    getTvShowSeasonsByPageFx.doneData,
    (_, page) => page.content.sort(compareDisplayNumber) || []
  )
  .reset(getTvShowSeasonsByPageFx)

export const $tvShowSeasonsPageInfo = createStore<PageInfo | null>(null)
  .on(
    getTvShowSeasonsByPageFx.doneData,
    (_, { content: _ignored, ...pageInfo }) => pageInfo
  )
  .reset(getTvShowSeasonsByPageFx)

const $firstSeason = createStore<TvShowSeason | null>(null).on(
  $tvShowSeasons,
  (_, tvShowSeasons) => {
    const seasons = (tvShowSeasons || []) as TvShowSeason[]
    const found = seasons.find((season) => season.sortOrder === 0)
    // second case is a fallback as sortOrder could be wrong - known issue form Nora
    return found || seasons[0] || null
  }
)

// GET /v3/tvshows/{tvShowId}/seasons/{seasonId}
export const $tvShowSeason = createStore<TvShowSeason | null>(null).on(
  getTvShowSeasonByIdFx.doneData,
  (_, season) => season
)

// GET /v3/tvshows/{tvShowId}/seasons/{seasonId}/episodes/{episodeId}/url
// GET /v3/tvshows/{tvShowId}/episodes/{episodeId}/url
export const $tvShowEpisodeUrl = createStore<MediaUrl | null>(null)
  .on(getTvShowEpisodeUrlFx.doneData, (_, urlObject) => urlObject)
  .on(getEpisodeUrlWithoutSeasonFx.doneData, (_, urlObject) => urlObject)

// CUSTOM
getTvShowEpisodesSmartFx.use(async ({ tvShowId, page, ...rest }) => {
  if ('seasonId' in rest && typeof rest?.seasonId === 'number')
    return await getPlayerTvShowEpisodesBySeasonByPageFx({
      tvShowId,
      seasonId: Number(rest.seasonId),
      page,
      ...rest,
    })

  return await getPlayerTvShowEpisodesWithoutSeasonByPageFx({
    tvShowId,
    page,
    ...rest,
  })
})

/**************** $seasonDetails *******************/

const loadSeason = attach({ effect: getTvShowSeasonByIdFx })

export const $seasonDetails = createStore<TvShowSeason | null>(null)
  .on(loadSeason.doneData, (_, season) => season)
  .on($firstSeason, (_ignore, season) => season)
/**************** $seasonDetails *******************/

/**************** $recommendedTvShows *******************/

export const $recommendedTvShows = createStore<TvShowRecommended[]>([]).on(
  getRecommendedTvShowsFx.doneData,
  (_, doneData) => doneData
)
/**************** $recommendedTvShows *******************/

/******************* initDetailsTvShows ********************/

export const $currentTvShow = createStore<CurrentTvShow | null>(null)
  .on(getTvShowFx.doneData, (_, shows) => shows?.[0] || null)
  .on(updateTvShowFx, (state, params) => {
    if (params.id === state?.id) return updateSingleHandler(state, params)
  })
  .on(updateTvShowFx.done, (state, { params }) => {
    if (params.id === state?.id)
      return updateSingleSuccessHandler(state, params)
  })
  .on(updateTvShowFx.fail, (state, { params }) => {
    if (params.id === state?.id) return updateSingleFailHandler(state, params)
  })

/******************* initDetailsTvShows ********************/

/******************* $tvShowEpisodesBySeason ********************/
// store that aggregates preloaded/previously loaded episodes to allow fast routing between seasons/episodes on client
// use getTvShowEpisodesBySeasonByPageFx to paginate over episodes
export const $showPlayList = createStore<TVShowsPlaylist>({})
  .on(getSeasonsWithEpisodesFx.doneData, (_, payload) => payload)
  .on(getSeasonsWithEpisodesFx.failData, (_, payload) => ({
    error: payload.payload.message,
  }))

/******************* $tvShowEpisodesBySeason ********************/

/***************** $tvShowCategoriesWithShows *******************/

export const initCategoriesWithShowsFx = attach({
  effect: getCategoriesWithShowsFx,
  mapParams: (params) => ({ ...params, page: 0 }),
})

export const loadCategoriesWithShowsFx = attach({
  effect: getCategoriesWithShowsFx, // use to paginate over subCategories
})

// to paginate over categories use - loadCategoriesWithChannelsFx
export const $tvShowCategoriesWithShows = createStore<CategoryWithShows[]>([])
  .on(initCategoriesWithShowsFx.doneData, (_, payload) => {
    return payload.content.map((page) => ({
      ...page,
      content: page.content.content,
    }))
  })
  .on(loadCategoriesWithShowsFx.doneData, (state, payload) => {
    const newContent = payload.content.map((page) => ({
      ...page,
      content: page.content.content,
    }))
    return [...state, ...newContent]
  })

// pagination of main array of categories $tvCategoriesWithChannels
export const $pageInfoCategoriesWithShows = createStore<PageableInfo | null>(
  null
).on(
  [initCategoriesWithShowsFx.done, loadCategoriesWithShowsFx.done],
  (_, { result: { content: _ignored, ...pageInfo }, params }) => ({
    ...pageInfo,
    params,
  })
)

/***************** $tvShowCategoriesWithShows *******************/

/***************** $seriesPlaylist *******************/

const playlistDefault = {
  isLooped: false,
  isAutoplay: true,
  nextEpisode: null,
  seasonsWithEpisodes: [],
  episodes: [],
  nextTvShows: null,
  nextTvShowId: null,
  nextSeasonId: null,
  currentEpisode: null,
  currentSeason: null,
  currentTvShow: null,
  categoryId: undefined,
  showIndex: undefined,
}

interface SeriesPlaylistNoSettings
  extends Omit<SeriesPlaylist, 'isLooped' | 'isAutoplay'> {}

export const generateSeriesPlaylistFx: ApiEffect<
  IGenerateSeriesPlaylistParams,
  SeriesPlaylistNoSettings
> = createEffect()

export const continueGenSeasonLessSeriesPlaylistFx: ApiEffect<
  {
    tvShowId: number
    episodeId?: number | null
    isLooped?: boolean
    isAutoplay?: boolean
    categoryId?: number | null
    showIndex?: number | null
  },
  SeriesPlaylistNoSettings
> = createEffect()

export const continueGenWithSeasonSeriesPlaylistFx: ApiEffect<
  {
    tvShowId: number
    seasonId: number
    episodeId?: number | null
    isLooped?: boolean
    isAutoplay?: boolean
    categoryId?: number | null
    showIndex?: number | null
  },
  SeriesPlaylistNoSettings
> = createEffect()

export const getNextShowFx: ApiEffect<
  {
    categoryId: number
    showIndex: number
    tvShowId: number
  },
  {
    nextTvShow: TvShow
    nextTvShows: TvShow[]
  }
> = createEffect()

export const recalculateCurrentSeriesPlaylist =
  createEvent<SeriesPlaylistRecalculate>() // call it after autoplay happened

export const $seriesPlaylist = createStore<SeriesPlaylist | null>(
  playlistDefault
).on(generateSeriesPlaylistFx.doneData, (state, payload) =>
  produce(state, (draft) => ({
    ...payload,
    isLooped: Boolean(draft?.isLooped),
    isAutoplay: Boolean(draft?.isAutoplay),
  }))
)

sample({
  clock: recalculateCurrentSeriesPlaylist,
  fn: (data) => ({
    tvShowId: data.tvShowId,
    seasonId: data.seasonId,
    episodeId: data.episodeId,
    categoryId: data.categoryId,
    showIndex: data.showIndex,

    isLooped: Boolean(data.isLooped),
    isAutoplay: Boolean(data.isAutoplay),
  }),
  target: generateSeriesPlaylistFx,
})

/***************** $seriesPlaylist *******************/

/**************** $showsByCategory *******************/

export const loadShowsFx = attach({ effect: getShowsByCategoryIdFx }) // for pagination over shows

export const initShowsCategoryFx: ApiEffect<
  {
    categoryId: number
    count?: number
  },
  CategoryWithShows | null
> = createEffect()

export const $showsByCategory = createStore<CategoryWithShows | null>(null)
  .on(initShowsCategoryFx.doneData, (_ignored, payload) => payload)
  .on(loadShowsFx.doneData, (state, { content }) => {
    return produce(state, (draft) => {
      if (draft) {
        draft.content = [...draft.content, ...content]
      }
    })
  })

export const $showsByCategoryPageInfo = createStore<PageableInfo | null>(
  null
).on(
  loadShowsFx.done,
  (_, { result: { content: _ignored, ...pageInfo }, params }) => ({
    ...pageInfo,
    params,
  })
)
/**************** $showsByCategory *******************/

/**************** $subCategoriesWithShows *******************/

export const loadSubCategoriesWithShowsFx: ApiEffect<
  { categoryId: number; page: number; count?: number },
  void
> = createEffect() // use to paginate over subCategories

export const initShowsSubCategoriesFx = attach({
  effect: loadSubCategoriesWithShowsFx,
  mapParams: (params) => ({ ...params, page: 0 }),
})
export const loadParentCategoryFx = attach({
  effect: getTvShowCategoryFx,
})
export const loadSubCategoriesShowsFx = attach({
  effect: getTvShowSubcategoriesByCategoryFx,
})
export const loadShowsOfSubcategoryFx = attach({
  effect: getShowsByCategoryIdFx,
}) // to paginate over sub arrays use - loadShowsOfSubcategoryFx

export const $parentCategoryShows = createStore<TvShowCategory | null>(null).on(
  loadParentCategoryFx.doneData,
  (_ignored, payload) => payload
)

export const $subCategoriesWithShows = createStore<CategoryWithShows[]>([])
  .on(loadSubCategoriesShowsFx.doneData, (state, payload) => {
    const newCategories = payload.content.map((category) => ({
      ...category,
      content: [],
      isMoreThanOnePageSubCategories:
        Number(category.subCategories?.length) > DEFAULT_PAGE_SIZE,
    }))
    return [...state, ...newCategories]
  })
  .on(loadShowsOfSubcategoryFx.done, (_, payload) => {
    const { content: newShows } = payload.result
    const { categoryId } = payload.params

    return produce(_, (draft) => {
      const arrayIndex = draft.findIndex(({ id }) => id === categoryId)

      if (!draft[arrayIndex]) return
      draft[arrayIndex].content.push(...newShows)
      draft[arrayIndex].isMoreThanOnePage =
        !payload.result.last ||
        draft[arrayIndex].content.length > DEFAULT_PAGE_SIZE
    })
  })

// pagination of main array of categories $subCategoriesWithShows
export const $pageInfoSubCategoriesWithShows = createStore<PageableInfo | null>(
  null
).on(
  loadSubCategoriesShowsFx.done,
  (_, { result: { content: _ignored, ...pageInfo }, params }) => ({
    ...pageInfo,
    params,
  })
)

/**************** $subCategoriesWithShows *******************/

/**************** $infiniteShowsCategory *******************/

export const initInfiniteShowsCategoryFx = attach({
  effect: getAllTvShowsFx,
  mapParams: (params) => ({ ...params, page: 0 }),
})

const fillFakeCategory = (
  shows: TvShow[],
  isMoreThanOnePage: boolean
): CategoryWithShows => ({
  id: 0,
  name: 'All',
  sortOrder: 0,
  parentCategoryId: 0,
  isMoreThanOnePage,
  isMoreThanOnePageSubCategories: false,
  content: shows,
  tvShowQuantity: 0,
  subCategories: [],
})

// to paginate over categories use - loadInfiniteShowsCategoryFx
export const $infiniteShowsCategory = createStore<CategoryWithShows | null>(
  null
).on(initInfiniteShowsCategoryFx.doneData, (_, payload) =>
  fillFakeCategory(payload.content, !payload.last)
)

/**************** $infiniteShowsCategory *******************/
