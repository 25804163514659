import { createEvent, sample } from 'effector'
import { model as session } from '~/entities/session'
import { keys } from '~/shared/jail'
import { type Cage, type Transit } from '../index.h'
import { tainter } from '../tools'

/*
 * ### TOA cage ###
 */

const init = createEvent<Transit>('init')

// in events
const shouldAcceptToa = createEvent('shouldAcceptToa')

// out events
const toaAccepted = createEvent('toaAccepted')

export const toa = {
  name: 'toa',
  key: keys.toa,
  init,
  in: [shouldAcceptToa],
  out: {
    toaAccepted,
  },
  ...tainter(/^\/toa/, '/toa'),
} satisfies Cage

/*
 * cage logic
 */

sample({
  clock: session.$toaAccepted,
  source: {
    isAuthenticated: session.$authenticated,
    isExpired: session.$expired,
  },
  filter: ({ isAuthenticated, isExpired }, isToaAccepted) =>
    isAuthenticated && !isExpired && isToaAccepted,
  target: toaAccepted,
})

sample({
  clock: session.$toaAccepted,
  source: {
    isAuthenticated: session.$authenticated,
    isExpired: session.$expired,
  },
  filter: ({ isAuthenticated, isExpired }, isToaAccepted) =>
    isAuthenticated && !isExpired && !isToaAccepted,
  target: shouldAcceptToa,
})
