import { type AccountDeviceInfo as OpenapiAccountDeviceInfo } from '@setplex/wbs-api-types'
import { createEffect, createEvent, createStore, sample } from 'effector'
import { http } from '../events'
import type { ApiEffect } from '../index.h'
import { type GenericApiResponse } from '../interfaces/generic'

export interface AccountDeviceInfo extends OpenapiAccountDeviceInfo {}

export interface Session extends Omit<AccountDeviceInfo, 'toa'> {
  package: string
  accountNumber: string
  lastName: string
  subscriberName: string
  toa: boolean | null
  guestId: string | null
}

export interface TOA {
  subject: string
  message: string
  buttonType: 'OK' | 'OK_CANCEL'
}

export interface SignInParams {
  // TODO use it in adapter
  username?: string
  password?: string
  rememberMe?: boolean
  model?: string
  foreignPlatformAccessToken?: string
}

//*
//* EFFECTS
//*

// GET /account | GET /account?refresh=true
export const getAccountFx: ApiEffect<{ refresh: boolean } | void, Session> =
  createEffect()

// registered subscriber sign in request
export const signInFx: ApiEffect<
  SignInParams,
  GenericApiResponse<Session>
> = createEffect()

// guest pseudo sign in request
export const guestInFx: ApiEffect<
  void,
  GenericApiResponse<Session>
> = createEffect()

// sign out request
export const signOutFx: ApiEffect<void, void> = createEffect()

// sign up request
export const signUpFx: ApiEffect<any, any> = createEffect()

// refresh session request
export const refreshFx: ApiEffect<void, void> = createEffect()

// authentication session management
export const set = createEvent<Session>()
export const reset = createEvent<void>()
export const $session = createStore<Session | null>(null)
  .on(set, (_, session) => session)
  .on(getAccountFx.doneData, (_, session) => session)
  .reset(reset, getAccountFx.fail)

// TOA management
export const getToaFx: ApiEffect<void, TOA> = createEffect()
export const acceptToaFx: ApiEffect<void, void> = createEffect()
export const $toa = createStore<TOA | null>(null)
  .on(getToaFx.doneData, (_, toa) => toa)
  .reset(getToaFx)

// on successful login - set session information
sample({
  clock: [signInFx.doneData, guestInFx.doneData],
  fn: ({ payload: session }) => session,
  target: set,
})

// on successful logout - reset session information
sample({
  clock: signOutFx.done,
  target: reset,
})

// on error 401 - reset session information
sample({
  clock: http.unauthorized,
  target: reset,
})
