import { required } from '@/validators'
import { createForm } from 'effector-forms'

// sign in form
export const signInForm = createForm({
  fields: {
    username: {
      init: '' as string,
      rules: [required()],
    },
    password: {
      init: '' as string,
      rules: [required()],
    },
  },
  validateOn: ['submit'],
})
