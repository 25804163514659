import { model as router } from '!/router'
import { model as config, env, remote } from '@/config'
import { postpone } from '@/effector'
import { history } from '@/effector/belt'
import { T } from '@/helpers'
import { logger } from '@/logger'
import * as Sentry from '@sentry/browser'
import {
  attach,
  createEvent,
  createStore,
  sample,
  scopeBind,
  type Store,
} from 'effector'
import { and } from 'patronum'
import { runtime, versions } from '~/app'
import {
  COUNT_OF_PREVIOUS_ROUTES_IN_BREADCRUMBS,
  type CaptureExceptionParams,
  type CaptureMessageParams,
  type SentryCaptureExceptionParams,
  type SentryCaptureMessageParams,
} from './index.h'
import { toBreadcrumbs } from './lib'

export const init = createEvent<void>()
export const captureException = createEvent<CaptureExceptionParams>()
export const captureMessage = createEvent<CaptureMessageParams>()

const awaited = createEvent<void>()

const $enabled: Store<boolean> = config.get(remote.tria_isSentryEnabled)
const $ignoreErrors: Store<string> = config.get(remote.tria_sentryIgnoreErrors)
const $isProduction = createStore(process.env.NODE_ENV === 'production')
const $ready = createStore<boolean>(false)

const $locations = history({
  source: router.$location,
  size: COUNT_OF_PREVIOUS_ROUTES_IN_BREADCRUMBS,
}).map(toBreadcrumbs)

const getBreadcrumbsFx = attach({
  source: $locations,
  effect: (from) => [
    {
      type: 'navigation',
      category: 'navigation.stack',
      data: {
        from: from.length ? from : null,
        to: location.href,
      },
      level: 'info',
    } as Sentry.Breadcrumb,
  ],
})

const initSentryFx = attach({
  source: { errosToIgnore: $ignoreErrors },
  effect({ errosToIgnore }) {
    const getBreadcrumbs = scopeBind(getBreadcrumbsFx, { safe: true })

    // allows to setup ignored errors via firebase remote config
    const ignoreErrors: RegExp[] | undefined = errosToIgnore
      ? (errosToIgnore
          .split(';')
          .map((err) => (err ? new RegExp(err, 'i') : null))
          .filter(Boolean) as RegExp[])
      : undefined

    Sentry.init({
      dsn: env.TRIA_CLIENT_SENTRY_DSN,
      debug: env.TRIA_CLIENT_SENTRY_DEBUG,
      release: versions.tria,
      environment: env.TRIA_ENVIRONMENT,
      integrations: [
        Sentry.dedupeIntegration(),
        Sentry.httpContextIntegration(),
        Sentry.inboundFiltersIntegration(),
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({ maskAllInputs: false }),
        Sentry.captureConsoleIntegration({ levels: ['error'] }),
      ],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,

      tracesSampleRate: 0.2,
      defaultIntegrations: false,

      ignoreErrors,

      beforeSend: async (event) => {
        return {
          ...event,
          breadcrumbs: await getBreadcrumbs(),
        }
      },
    })

    if (env.TRIA_CLIENT_SENTRY_DEBUG) {
      Sentry.addIntegration(Sentry.debugIntegration())
    }

    Sentry.getCurrentScope().setTag('app.name', runtime.app.appName)
    Sentry.getCurrentScope().setTag('domain', location.origin)
  },
})

const captureExceptionFx = attach({
  source: $ready,
  effect: (ready, params: SentryCaptureExceptionParams) =>
    ready ? Sentry.captureException(...params) : undefined,
})

const captureMessageFx = attach({
  source: $ready,
  effect: (ready, params: SentryCaptureMessageParams) =>
    ready ? Sentry.captureMessage(...params) : undefined,
})

//
// init sentry
//

postpone({
  clock: init,
  until: config.$ready,
  target: awaited,
})

sample({
  clock: awaited,
  filter: and($isProduction, $enabled),
  target: initSentryFx,
})

sample({
  clock: initSentryFx.done,
  fn: T,
  target: $ready,
})

sample({
  clock: initSentryFx.done,
  fn: () => ['Sentry initialized successfully'],
  target: logger.logFx,
})

sample({
  clock: initSentryFx.fail,
  fn: (error) => ['Sentry initialization error', error],
  target: logger.errorFx,
})

sample({
  clock: [captureExceptionFx.fail, captureMessageFx.fail],
  fn: (error) => ['Sentry failed to capture', error],
  target: logger.errorFx,
})

//
// capturing interface
//

sample({
  clock: captureException,
  fn: (params) =>
    (Array.isArray(params) ? params : [params]) as SentryCaptureExceptionParams,
  target: captureExceptionFx,
})

sample({
  clock: captureMessage,
  fn: (params) =>
    (Array.isArray(params) ? params : [params]) as SentryCaptureMessageParams,
  target: captureMessageFx,
})
