import { createEffect } from 'effector'
import type { ApiEffect } from '../../index.h'
import type { IProfile } from '../../interfaces/profiles'

//GET /v3/profiles
export const getProfilesFx: ApiEffect<void, Array<IProfile>> = createEffect()

//GET /v3/profiles/active
export const getActiveProfileFx: ApiEffect<void, IProfile> = createEffect()

//DELETE /v3/profiles/{profileId}
export const removeProfileFx: ApiEffect<number, void> = createEffect()

//PATCH /v3/profiles/{profileId}/active
export const updateProfileActiveStateFx: ApiEffect<number, void> =
  createEffect()

//POST /v3/profiles/{profileId}/active
export const createProfileFx: ApiEffect<
  IProfile,
  Pick<IProfile, 'id'>
> = createEffect()

//PUT /v3/profiles/{profileId}/active
export const updateProfileFx: ApiEffect<IProfile, void> = createEffect()
