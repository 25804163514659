import * as era from '!/era'
import { ui } from '!/skin'
import { aye } from '@/helpers'
import { useUnit } from 'effector-react'
import { profilesModel } from '~/entities/profiles'
import { model as session } from '~/entities/session'
import { model as guestModel } from '~/features/subscriber/guest'

// skin
const skin = era.skin('widgets/NavBar')

NavBar.displayName = 'NavBar'
export function NavBar(): JSX.Element | null {
  const isAuthenticated: boolean = useUnit(session.$authenticated)
  const activeProfile = useUnit(profilesModel.$activeProfile)
  const guest = useUnit(guestModel.$guest)

  const isNavbarShown = isAuthenticated && (aye(activeProfile) || aye(guest))

  return isNavbarShown ? (
    <ui.RenderSkin from={skin} name='navbar' key='navbar' />
  ) : null
}
