export interface ApplicationInfo {
  appId: string
  appName: string
  appVersion: string
  browserName: string
  browserVersion: string
  osName: string
  osVersion: string
}

export interface VideoSourceInfo {
  // all not generic analytics params
  contentId?: string
  contentUrl?: string
  contentType?: string
  audioLanguage?: string
  textLanguage?: string
  startedTime?: string // read as 'event happened time'
  duration?: string
}

export interface AnalyticsInfo
  extends VideoSourceInfo,
    ApplicationInfo,
    BEAnalyticsInfo {}

export interface AnalyticsGenericInfo
  extends VideoSourceInfo,
    ApplicationInfo,
    BEAnalyticsInfo {}

export enum AnalyticsEventType {
  playStart = `play_start`,
  playStop = `play_stop`,
  favoriteRemove = 'favorite_remove',
  favoriteAdd = 'favorite_add',
  sessionStart = 'session_start',
  sessionEnd = 'session_end',
}

export interface BEAnalyticsInfo {
  // data from b.e.
  deviceId: string
  networkIp: string
  networkIsp: string
  location: string
  contentProviderId: string
  contentUserSubscriberId: string
  contentUserAccountNumber: string
  contentUserProfileId: string
  contentUserProfileName: string
}

export interface ClientPartAnalytics extends VideoSourceInfo, ApplicationInfo {}

export interface ClientPartStartStopAnalytics
  extends VideoSourceInfo,
    ApplicationInfo {}

export interface IContentInfo {
  contentId?: string
  contentUrl?: string
  contentTitle?: string
  contentProgramm?: string
  contentType?: string
  contentOpenFrom?: string
  contentIsSuccessShow?: string
  contentErrorCode?: string
}

export interface AnalyticsWatchingContentInfo
  extends ApplicationInfo,
    BEAnalyticsInfo,
    IContentInfo {}
