import { model as i18n } from '!/i18n'
import { startOfDay } from 'date-fns'
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
  scopeBind,
} from 'effector'
import { not } from 'patronum'
import { ONE_HOUR_MS, ONE_MINUTE_MS } from '~/shared/constants/time'
import { TICK_FREQUENCY } from './index.h'

// setup global application closk
export const setup = createEvent()

// teardown global application closk
export const teardown = createEvent()

// tick event, ticks on every minute (for now there is no need to update more often)
export const tick = createEvent<number>()

// flag, which indicates that clock is ticking now
export const $ticking = createStore(false)

// timeout id, which is used to clear timeout on teardown
export const $timeout = createStore<ReturnType<typeof setTimeout> | null>(null)

// tick effect, which returns current timestamp, to pass it to `tick` event
export const tickFx = createEffect(() => Date.now())

// wind up effect, which waits until next minute and then triggers tick effect
export const windFx = createEffect((now = Date.now()) => {
  const untilNextTick = TICK_FREQUENCY - (now % TICK_FREQUENCY)
  const bindedTickFx = scopeBind(tickFx, { safe: true })
  return setTimeout(bindedTickFx, untilNextTick)
})

// unwind effect, which clears timeout on teardown
export const unwindFx = attach({
  source: $timeout,
  effect(timeout) {
    if (timeout != null) clearTimeout(timeout)
  },
})

// on `setup` event, emit initial tick (which will then trigger wind up effect)
sample({
  clock: setup,
  filter: not($ticking),
  fn: () => true,
  target: [$ticking, tickFx],
})

// on `teardown` event, unwind clock
sample({
  clock: teardown,
  fn: () => false,
  target: [$ticking, unwindFx],
})

// save timeout id
sample({
  clock: windFx.doneData,
  target: $timeout,
})

// on tick effect done, emit tick event
sample({
  clock: tickFx.doneData,
  target: tick,
})

// on tick event, wind up clock again
sample({
  clock: tick,
  filter: $ticking,
  target: windFx,
})

//
// Stores with time
//

// current timestamp, which is updated on every minute (if clock is ticking)
export const $minutes = createStore(0)
  .reset(teardown)
  .on(tick, (_, now) => Math.trunc(now / ONE_MINUTE_MS) * ONE_MINUTE_MS)
  .on(i18n.dateLanguageChanged, () => Date.now())

// current timestamp, which is updated on every hour (if clock is ticking)
export const $hours = createStore(0)
  .reset(teardown)
  .on(tick, (_, now) => Math.trunc(now / ONE_HOUR_MS) * ONE_HOUR_MS)
  .on(i18n.dateLanguageChanged, () => Date.now())

// timestamp of day beginning, which is updated on every day (if clock is ticking)
export const $days = createStore(0)
  .reset(teardown)
  .on(tick, (_, now) => startOfDay(now).getTime())
  .on(i18n.dateLanguageChanged, () => Date.now())
