import { type Rule } from 'effector-forms'

export const required = (): Rule<string> => ({
  name: 'required',
  validator: (value) => Boolean(value),
})

export const email = (): Rule<string> => ({
  name: 'email',
  validator: (value) => /.+@.+\..+$/.test(value),
})

export const password = (): Rule<string> => ({
  name: 'password',
  validator: (value) => /^[A-Za-z0-9@#$_&\-*!?.]+$/.test(value),
})

export const username = (): Rule<string> => ({
  name: 'username',
  validator: (value) => /^[A-Za-z0-9#$_&\-*!?.\s]+$/.test(value),
})

export const withoutSpecSymbols = (): Rule<string> => ({
  name: 'withoutSpecSymbols',
  validator: (value) => /^[-\p{L}0-9\s'`]+$/u.test(value),
})

export const minLength = (min: number): Rule<string> => ({
  name: 'minLength',
  validator: (value) => value.length >= min,
})

export const maxLength = (max: number): Rule<string> => ({
  name: 'maxLength',
  validator: (value) => value.length <= max,
})

export const equalTo = (field: string): Rule<string> => ({
  name: 'equalTo',
  validator: (value, form) => value === form[field],
})

export const digits = (): Rule<string> => ({
  name: 'digits',
  validator: (value) => /^\d+$/.test(value),
})
