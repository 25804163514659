// master key, gives access to all cages
export const master = Symbol('master')

// cages keys
export const app = Symbol('app')
export const expired = Symbol('expired')
export const geoblock = Symbol('geoblock')
export const guest = Symbol('guest')
export const profiles = Symbol('profiles')
export const sign = Symbol('sign')
export const toa = Symbol('toa')
