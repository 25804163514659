import { model as config, remote } from '@/config'
import { postpone } from '@/effector'
import { api } from '@setplex/tria-api'
import {
  createEffect,
  createEvent,
  sample,
  type Effect,
  type Store,
} from 'effector'
import { runtime, versions } from '~/app'
import { model as player } from '~/entities/player'
import { model as session } from '~/entities/session'
import { model as guest } from '~/features/subscriber/guest'
import { firebaseSharedModel as firebase } from '~/shared/firebase'
import * as launchProcess from './launch'

export const init = createEvent()

const $enabled: Store<boolean> = config.get(remote.tria_isAnalyticsEnabled)
const $domain: Store<string> = config.get(remote.tria_analyticsListenerDomain)

const awaited = createEvent()

const collectApplicationInfo = createEvent()
const sessionStart = createEvent()
const sessionStop = createEvent()

const watchSessionFx: Effect<void, void> = createEffect(() => {
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') sessionStart()
    if (document.visibilityState === 'hidden') sessionStop()
  })
})

// get domain for analytics
sample({
  clock: [init, $domain],
  source: $domain,
  target: api.analytics.$analyticsDomain,
})

// collect application info
sample({
  clock: collectApplicationInfo,
  fn: () => ({
    appId: runtime.app.domain,
    appName: runtime.app.name,
    appVersion: versions.tria,
    browserName: runtime.browser.name || 'Unknown',
    browserVersion: runtime.browser.version || '-',
    osName: runtime.os.name || 'Unknown',
    osVersion: runtime.os.version || '-',
  }),
  target: api.analytics.$applicationInfo,
})

// send `session_start` analytics event
sample({
  clock: sessionStart,
  filter: $enabled,
  target: api.analytics.sendAnalyticsSessionStart,
})

// send `session_end` analytics event
sample({
  clock: sessionStop,
  filter: $enabled,
  target: api.analytics.sendAnalyticsSessionStop,
})

// send `play_start` analytics event
sample({
  clock: player.play,
  filter: $enabled,
  target: api.analytics.sendAnalyticsStartWatching,
})

// send `play_stop` analytics event
sample({
  clock: player.stop,
  filter: $enabled,
  target: api.analytics.sendAnalyticsStopPauseWatching,
})

// send `play_start` analytics event
sample({
  clock: player.watchingContent,
  filter: $enabled,
  target: api.analytics.sendAnalyticsWatchingContent,
})

// on init -> collect application info
sample({
  clock: init,
  target: collectApplicationInfo,
})

// await for launch process is finished
postpone({
  clock: init,
  until: launchProcess.$ready,
  target: awaited,
})

// when session is ready -> collect application info and start session
sample({
  clock: awaited,
  target: [sessionStart, watchSessionFx],
})

// on change authentication status -> start new session
sample({
  clock: session.$authenticated,
  target: sessionStart,
})

// on change authentication status -> update Firebase user properties
// this will trigger remote config update
sample({
  clock: session.$session,
  source: guest.$guest,
  fn: (isGuest, session) => {
    return session
      ? {
          providerId: session.providerId,
          accountNumber: isGuest ? null : session.accountNumber,
        }
      : {
          // do not erase `providerId`, it is permanent for exact provider
          accountNumber: null,
        }
  },
  target: firebase.analytics.setUserProperties,
})
