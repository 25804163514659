import {
  attach,
  createEvent,
  createStore,
  restore,
  sample,
  scopeBind,
  split,
} from 'effector'
import { persist } from 'effector-storage/session'
import { holdup } from '~/shared/effector'
import { logger } from '~/shared/logger'
import {
  EVENT,
  SSE_PING_TIMEOUT,
  SUBSCRIBE,
  SystemEventType,
  type AnyEvent,
  type SystemEventPingPayload,
} from './index.h'
import {
  isDataEvent,
  isMessageEvent,
  isSystemEvent,
  isSystemEventClose,
  isSystemEventConnected,
  isSystemEventPing,
  isSystemEventReconnect,
} from './lib'

export const init = createEvent()
export const reset = createEvent()

export const connect = createEvent()
export const close = createEvent()
export const message = createEvent<AnyEvent>()
export const error = createEvent<Event>()

/*
 * prefiltered events' payloads
 */

const { messageEvent, systemEvent, dataEvent } = split(message, {
  messageEvent: isMessageEvent,
  systemEvent: isSystemEvent,
  dataEvent: isDataEvent,
})

export const messageMessage = messageEvent.map((event) => event.data)
export const systemMessage = systemEvent.map((event) => event.data)
export const dataMessage = dataEvent.map((event) => event.data)

/*
 * manage sse connection and subscriptions
 */

const $eventSource = createStore<EventSource | null>(null)

const setClientId = createEvent<string>()
const $clientId = restore(setClientId, null).reset(reset)

persist({
  store: $clientId,
  key: 'tria__sse-client-id',
})

// open connection
const openFx = attach({
  source: $clientId,
  async effect(clientId) {
    await closeFx()
    const id = clientId ? '/' + clientId : ''
    const url = '/wbs/api/v3/alpha/devices/messages'
    return new EventSource(url + id)
  },
})

// close connection
const closeFx = attach({
  source: $eventSource,
  effect(eventSource) {
    if (eventSource != null && eventSource.readyState !== EventSource.CLOSED) {
      eventSource.close()
    }
  },
})

// subscribe to errors and messages
const subscribeFx = attach({
  source: $eventSource,
  effect(eventSource) {
    if (eventSource == null) {
      throw new Error('SSE is not ready')
    }

    const err = scopeBind(error, { safe: true })
    eventSource.addEventListener('error', err)

    const msg = scopeBind(message, { safe: true })
    for (const type of SUBSCRIBE) {
      eventSource.addEventListener(type, (ev) => {
        let data = ev.data
        if (data != null) {
          try {
            data = JSON.parse(data)
          } catch (e) {
            // do nothing
          }
        }
        msg({ event: type, data })
      })
    }
  },
})

/// open connection

sample({
  clock: connect,
  target: openFx,
})

sample({
  clock: openFx.doneData,
  target: $eventSource,
})

sample({
  clock: openFx.fail,
  fn: (fail) => ['could not open SSE connection:', fail],
  target: logger.errorFx,
})

/// close connection

sample({
  clock: close,
  target: closeFx,
})

sample({
  clock: [closeFx.finally, openFx.fail],
  fn: () => null,
  target: $eventSource,
})

sample({
  clock: closeFx.fail,
  fn: (fail) => ['could not close SSE connection:', fail],
  target: logger.errorFx,
})

/// manage subscriptions and unsubscriptions

sample({
  clock: $eventSource,
  filter: Boolean,
  target: subscribeFx,
})

sample({
  clock: subscribeFx.fail,
  fn: (fail) => ['could not subscribe to SSE events:', fail],
  target: logger.errorFx,
})

sample({
  clock: error,
  fn: (fail) => ['SSE error:', fail],
  target: logger.errorFx,
})

sample({
  clock: messageMessage,
  fn: (msg) => ['SSE message:', msg],
  target: logger.logFx,
})

/*
 * handle system messages
 */

const {
  closeSystemEvent,
  connectedSystemEvent,
  pingSystemEvent,
  reconnectSystemEvent,
} = split(systemMessage, {
  closeSystemEvent: isSystemEventClose,
  connectedSystemEvent: isSystemEventConnected,
  pingSystemEvent: isSystemEventPing,
  reconnectSystemEvent: isSystemEventReconnect,
})

// old version of ping event from first version of api
const oldPingSystemEvent = sample({
  clock: message,
  filter: (x) => x.event === EVENT.ping,
  fn: () => ({ type: SystemEventType.PING } as SystemEventPingPayload),
})

/// close connection on close event

sample({
  clock: closeSystemEvent,
  target: close,
})

/// update client id on every new connection

sample({
  clock: connectedSystemEvent,
  fn: (msg) => msg.id,
  target: setClientId,
})

/// keep connection alive on ping event
/// reconnect on reconnect event

const delayedConnect = holdup({
  target: connect,
  cancel: [close, connect, reset],
})

sample({
  clock: [pingSystemEvent, oldPingSystemEvent],
  fn: () => SSE_PING_TIMEOUT,
  target: delayedConnect,
})

sample({
  clock: reconnectSystemEvent,
  fn: (msg) => msg.timeout || 0,
  target: [close, delayedConnect],
})
