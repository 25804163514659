import { useMemo } from 'react'
import { type SkinView } from '../../../index.h'
import {
  array,
  evaluate,
  express,
  extend,
  inherit,
  pack,
  raw,
  resolve,
  type Raw,
  type Scope,
} from '../scope'

export enum Special {
  Exact = '=',
  List = '*',
  Item = '.',
  Index = 'i',
  Evaluateable = '*',
}

export type ScopeList = {
  list: Raw | null
  name: string
  index: string
}

const lists: (keyof SkinView)[] = ['map', 'over'] // `over` should create child scope for `map`, so it should go second
const data: (keyof SkinView)[] = ['data']

/**
 * Hook to create scope for skin
 */
export function useScope(
  skin: SkinView,
  sources: Scope,
  parent?: Scope
): Scope {
  return useMemo(() => {
    let scope: Scope = extend(sources, /* -> */ inherit(parent))
    let locals: Scope

    // iterate over lists references and create child bindings for each one
    for (const path of lists) {
      locals = Object.create(null)

      let value: string | undefined
      let name: string | undefined
      let index: string | undefined
      let list: Raw | null

      const flap: unknown = skin[path]
      if (!flap) continue

      // if `map` is a string, referring to list/value
      if (typeof flap === 'string') {
        // this is extra semi added when formatting
        // eslint-disable-next-line @typescript-eslint/no-extra-semi
        ;[value, name, index] = flap.split('\\', 3)
        list = raw(`list: ${value}`, resolve(value, scope), array)
      }

      // `map` is a exact list/value
      else {
        list = raw(`list: ${flap}`, array(flap))
      }

      // '*map' or '*over'
      locals[Special.List + path] = pack(`[list]: ${value || flap}`, {
        list,
        name: name ?? Special.Item,
        index: index ?? Special.Index,
      } as ScopeList)

      // add list itself as '.' item, to be able to reference it
      // scope[Special.Item] = list

      // add extend scope and create heir scope
      scope = inherit(extend(locals, /* -> */ scope))
    }

    // iterate over data references and put resolved data values to scope
    locals = Object.create(null)
    for (const path of data) {
      let flap: unknown = skin[path]
      if (!flap) continue

      if (typeof flap !== 'object') {
        flap = { [Special.Exact]: flap }
      }
      let name, value
      for ([name, value] of Object.entries(flap as object)) {
        if (name.endsWith(Special.Evaluateable)) {
          name = name.slice(0, -Special.Evaluateable.length)
          locals[name] = evaluate(value, scope, locals)
        } else {
          locals[name] = express(value, scope, locals)
        }
      }
    }
    extend(locals, /* -> */ scope)

    return scope
  }, [skin, sources, parent])
}
