import { logger } from '@/logger'
import { captureException } from '@/sentry'
import { ErrorDescription } from '@setplex/arcana'
import type { ErrorInfo } from 'react'
import { locationAssign } from '~/core/router'
import { PageWrapper } from '~/core/skin/ui'
import { NavBar } from '~/widgets/NavBar'

interface Props {
  error: Error
}

ErrorFallback.displayName = 'ErrorFallback'
export function ErrorFallback({ error }: Props): JSX.Element {
  const returnToHomePage = () => locationAssign('/')
  return (
    <>
      <NavBar />
      <PageWrapper>
        <ErrorDescription
          error={error?.message || String(error)}
          handleButtonClick={returnToHomePage}
          buttonTitle={'Back to Home page'}
        />
      </PageWrapper>
    </>
  )
}

export function errorHandler(error: Error, info: ErrorInfo): void {
  logger.error('❤️‍🩹', error, info)
  captureException(error)
}
