import { type QueryValues } from './index.h'

export const UPDATE_QUERY_DEBOUNCE_TIMEOUT = 10

export const updateQuery = (query: URLSearchParams, values: QueryValues) => {
  let updated = false
  for (const [key, value] of Object.entries(values)) {
    const has = query.has(key)
    if (value == null) {
      if (has) {
        updated = true
        query.delete(key)
      }
    } else {
      if (!has || query.get(key) !== value) {
        updated = true
        query.set(key, value)
      }
    }
  }
  return updated
}
