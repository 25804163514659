import type {
  EpgInfo,
  EpgInfoDto,
  MediaOrderType,
  Page,
  PlaylistChannel,
  PurchaseInfo,
  TimeRange,
  TvChannel,
  TvEpg,
  TvEpgApiDto,
} from '@setplex/tria-api'
import {
  MIN_PROGRAM_LENGTH_MS,
  MediaOrderType as MediaOrderTypeEnum,
  MediaTypes,
  PurchaseInfoContentPurchaseType as PurchaseInfoContentPurchaseTypeEnum,
  type TvChannelUpdated,
} from '@setplex/tria-api'
import {
  type GetAllTvChannelsByPageParams,
  type GetPurchasedTvChannelsParams,
} from '@setplex/tria-api/src/interfaces/tv'
import { type ChannelDto } from '@setplex/wbs-api-types'
import { ONE_MINUTE_MS } from '../../constants/time'
import { getLogoImage } from '../../utils/images'
import { formatResolution } from '../../utils/media'
import { getMinPriceObject } from '../../utils/minPriceObj'
import { formatPriceSettings } from '../../utils/payment'

export const formatChannel = ({
  id,
  logoUrl,
  resolution,
  orderType,
  channelNumber,
  free,
  priceSettings,
  purchaseInfo,
  name,
  locked,
  ...rest
}: ChannelDto): TvChannel => {
  const safeOrderType = String(orderType || '').toUpperCase()
  const minPriceObj = getMinPriceObject(priceSettings)

  return {
    ...rest,
    id: id as number,
    name: name ?? '',
    logoUrl: getLogoImage(logoUrl),
    resolution: formatResolution({ resolution }),
    locked: Boolean(locked),
    number:
      safeOrderType !== MediaOrderTypeEnum.DEFAULT
        ? null
        : channelNumber || null,
    mediaType: MediaTypes.TV_CHANNEL,
    channelNumber,
    free: free === undefined ? true : free, // Set field free is true for Nora 1.46 version, where there is no field free
    orderType: safeOrderType as MediaOrderType,
    priceSettings: formatPriceSettings(priceSettings),
    purchaseInfo: purchaseInfo as PurchaseInfo | undefined,
    minCurrency: minPriceObj?.currency,
    minPrice: minPriceObj?.price,
    rented:
      purchaseInfo?.contentPurchaseType ===
      PurchaseInfoContentPurchaseTypeEnum.Rented,
    formattedName: `${channelNumber}. ${name}`,
  }
}

export const formatChannelPlaylist = ({
  id,
  channelNumber,
  orderType,
  name,
  free,
  purchaseInfo,
}: TvChannel): PlaylistChannel => {
  const safeOrderType = String(orderType || '').toUpperCase()
  return {
    id,
    name,
    channelNumber,
    free,
    purchaseInfo,
    number:
      safeOrderType !== MediaOrderTypeEnum.DEFAULT
        ? undefined
        : channelNumber || undefined,
  }
}

export const formatChannelUpdatedOnly = ({
  id,
  updated,
}: ChannelDto): TvChannelUpdated => {
  return {
    id,
    updated,
  }
}

export const formatEPGPrograms = (
  programsCollection: TvEpgApiDto,
  channels: TvChannel[] = [],
  period: TimeRange
): TvEpg => {
  const formattedPrograms: TvEpg = {}

  Object.keys(programsCollection).forEach((key) => {
    const channel = channels.find(({ epgId }) => epgId === key)
    const isExistingToFormatting =
      programsCollection[key]?.length > 0 && channel

    formattedPrograms[key] = isExistingToFormatting
      ? formatAndValidateEPGArray(key, programsCollection[key], period)
      : []

    if (!formattedPrograms[key].length) {
      formattedPrograms[key] = [
        generateFullEmpty({
          start: period.start,
          end: period.end,
          epgId: channel?.epgId || '',
        }),
      ]
    }
  })
  return formattedPrograms
}

const generateEmptyProgram = (
  start: number,
  stop: number,
  epgId: string
): EpgInfo => ({
  id: `${epgId}${start}${stop}`,
  epgId,
  name: undefined,
  description: undefined,
  startMs: start,
  stopMs: stop,
  actualStartMs: start,
  actualStopMs: stop,
  durationMs: stop - start,
  actualDurationMs: stop - start,
  categories: [],
  directors: [],
  actors: [],
})

const generateFullEmpty = ({
  start,
  end,
  epgId,
}: {
  start: number
  end: number
  epgId: string
}): EpgInfo => generateEmptyProgram(start, end, epgId)

export const fillGaps = (programs: EpgInfo[], period: TimeRange): EpgInfo[] => {
  const generateGap = ({
    start,
    end,
    epgId,
  }: {
    start: number
    end: number
    epgId: string
    slotDuration?: number | void
  }): EpgInfo | undefined => {
    if (start >= end) return
    return generateEmptyProgram(start, end, epgId)
  }

  return programs
    .sort((a, b) => a.startMs - b.startMs)
    .reduce((filled, item, index, originalArr) => {
      const gapStart: number =
        index === 0 ? period.start : originalArr[index - 1].stopMs
      const gapEnd: number = item.startMs

      const emptySlot = generateGap({
        start: gapStart,
        end: gapEnd,
        epgId: originalArr[0].epgId,
      })

      if (emptySlot) {
        filled.push(item, emptySlot)
      } else {
        filled.push(item)
      }

      if (index === originalArr.length - 1) {
        const endEmptySlot = generateGap({
          start: originalArr[index].stopMs,
          end: period.end,
          epgId: originalArr[0].epgId,
        })
        if (endEmptySlot) {
          filled.push(endEmptySlot)
        }
      }

      return filled
    }, [] as EpgInfo[])
}

export const fullIntersectionFilter = (programs: EpgInfo[]): EpgInfo[] => {
  // FP-740 case 1 and case 2
  return programs.reduce((filtered, item) => {
    let intersectedArr: EpgInfo[] = []

    // find all possible full-intersections for current 'item'
    intersectedArr = filtered.filter(
      (prog: EpgInfo) =>
        // 1`st program includes 2`nd ?
        (item.startMs >= prog.startMs &&
          item.startMs <= prog.stopMs &&
          item.stopMs >= prog.startMs &&
          item.stopMs <= prog.stopMs) ||
        // 2`nd program includes 1`st ?
        (prog.startMs >= item.startMs &&
          prog.startMs <= item.stopMs &&
          prog.stopMs >= item.startMs &&
          prog.stopMs <= item.stopMs)
    )

    if (!intersectedArr.length) {
      filtered.push(item)
      return filtered
    }

    // resolve all possible full-intersections for current 'item'
    if (intersectedArr.length) {
      intersectedArr.forEach((intersected) => {
        // choose longest
        if (intersected.durationMs < item.durationMs) {
          if (!filtered.some((f) => f.id === item.id)) filtered.push(item) // if item not in filtered put it only once
          filtered = filtered.filter((f) => f.id !== intersected?.id)
        }
      })

      return filtered
    }

    return filtered
  }, [] as EpgInfo[])
}

export const partIntersectionFilter = (programs: EpgInfo[]): EpgInfo[] => {
  // FP-740 case 4
  return programs
    .sort((a, b) => a.startMs - b.startMs)
    .reduce((trimmed, item) => {
      const previousSlot = trimmed[trimmed.length - 1]
      if (!previousSlot) {
        trimmed.push(item) // first slot could not be ever trimmed
        return trimmed
      }

      if (previousSlot.stopMs > item.startMs) {
        // check intersection
        trimmed.push(
          updateProgram(item, {
            startMs: previousSlot.stopMs,
            actualStartMs: previousSlot.stopMs,
          })
        )
      } else {
        trimmed.push(item)
      }

      return trimmed
    }, [] as EpgInfo[])
}

export const mergeShortProg = (
  programs: EpgInfo[],
  slotDuration: number = MIN_PROGRAM_LENGTH_MS
): EpgInfo[] => {
  const sortedProgs = programs.sort((a, b) => a.startMs - b.startMs)

  let shorts: { [key: string]: number } = {} // saves [Key - start position]: value - length of merge

  let index = 0
  let currentStart = -1
  let currentLength = -1
  while (index <= sortedProgs.length - 1) {
    if (sortedProgs[index].durationMs >= slotDuration) {
      if (
        currentStart !== -1 &&
        currentLength !== -1 &&
        currentLength > currentStart
      )
        shorts[currentStart] = currentLength
      currentStart = -1
      currentLength = -1
      index++
      continue // too long for merge
    }

    if (currentLength === -1) currentStart = index
    if (currentStart !== -1) currentLength = index

    if (index <= sortedProgs.length - 1) shorts[currentStart] = currentLength
    index++
  }

  const withoutMerged = sortedProgs.reduce((merged, item, index) => {
    const isForMerge = Object.keys(shorts).some(
      (key) => index >= Number(key) && index <= shorts[key]
    )
    if (!isForMerge) merged.push(item)
    return merged
  }, [] as EpgInfo[])

  const mergedSlots = Object.keys(shorts).map((key) => {
    const startIndex = Number(key)
    const stopIndex = shorts[key]

    return generateEmptyProgram(
      sortedProgs[startIndex].startMs,
      sortedProgs[stopIndex].stopMs,
      sortedProgs[startIndex].epgId
    )
  })

  return [...withoutMerged, ...mergedSlots]
}

const updateProgram = (
  program: EpgInfo,
  fields: {
    startMs?: number
    stopMs?: number
    actualStartMs?: number
    actualStopMs?: number
  }
) => {
  const newStart = fields.startMs || program.startMs
  const newStop = fields.stopMs || program.stopMs
  const newActualStartMs = fields.actualStartMs || program.actualStartMs
  const newActualStopMs = fields.actualStopMs || program.actualStopMs

  return {
    ...program,
    startMs: newStart,
    stopMs: newStop,
    durationMs: newStop - newStart,
    actualStartMs: newActualStartMs,
    actualStopMs: newActualStopMs,
    actualDurationMs: newActualStopMs - newActualStartMs,
  }
}

// TODO write tests
const cutStartEndValues = (
  sortedEpgArray: EpgInfo[],
  period: TimeRange
): EpgInfo[] =>
  sortedEpgArray.map((program, index) => {
    if (index === 0) return updateProgram(program, { startMs: period.start })
    if (index === sortedEpgArray.length - 1)
      return updateProgram(program, { stopMs: period.end })
    return program
  })

const sortAndValidatePrograms = (
  programs: EpgInfo[],
  period: TimeRange
): EpgInfo[] => {
  const noFullIntersectionProgs = fullIntersectionFilter(programs)
  const noPartIntersectionProgs = partIntersectionFilter(
    noFullIntersectionProgs
  )
  const filledGaps = fillGaps(noPartIntersectionProgs, period)
  const mergedShorts = mergeShortProg(filledGaps)

  const sorted = mergedShorts.sort((a, b) => a.startMs - b.startMs)

  return cutStartEndValues(sorted, period)
}

export const formatEPGArray = (
  epgId: string,
  channelProgramArr: EpgInfoDto[]
): EpgInfo[] =>
  channelProgramArr
    .filter((item) => item.title && item.description && item.start && item.stop)
    .map((item) => formatEPGProgram(item, epgId))
    .filter((item) => item.startMs <= item.stopMs)

export const formatAndValidateEPGArray = (
  epgId: string,
  channelProgramArr: EpgInfoDto[],
  period: TimeRange
): EpgInfo[] => {
  const formatted = formatEPGArray(epgId, channelProgramArr)

  return sortAndValidatePrograms(formatted, period)
}

const setZeroSecondsMs = (date: Date | number): number => {
  const curDateNumber = typeof date === 'number' ? date : Number(date)
  const minutes = Math.trunc(curDateNumber / ONE_MINUTE_MS)
  return minutes * ONE_MINUTE_MS
}

export const formatEPGProgram = (item: EpgInfoDto, epgId: string): EpgInfo => {
  const start = setZeroSecondsMs(+new Date(item.start))
  const stop = setZeroSecondsMs(+new Date(item.stop))
  return {
    id: `${epgId}${start}${stop}`,
    epgId,
    name: item.title,
    description: item?.description !== 'N/A' ? item.description : '',
    startMs: start,
    stopMs: stop,
    actualStartMs: start,
    actualStopMs: stop,
    durationMs: stop - start,
    actualDurationMs: stop - start,
    subtitle: item?.subtitle !== 'N/A' ? item.subtitle : undefined,
    categories: item.categories,
    icon: item?.icon !== 'N/A' ? item.icon : undefined,
    date: item?.date !== 'N/A' ? item.date : undefined,
    rating: item?.rating !== 'N/A' ? item.rating : undefined,
    directors: item.directors,
    actors: item.actors,
    title: item?.title,
    episode: item?.episode !== 'N/A' ? item.episode : undefined,
  }
}

interface GetPaginatedTvPlaylistParamsI {
  getTvChannelsFX: (
    params: GetPurchasedTvChannelsParams | GetAllTvChannelsByPageParams
  ) => Promise<Page<TvChannel>>
  categoryId?: number | null
}

export async function getPaginatedTvPlaylist({
  getTvChannelsFX,
  categoryId,
}: GetPaginatedTvPlaylistParamsI) {
  const { content: tvChannels, ...pageInfo } = await getTvChannelsFX({
    page: 0,
    categoryId: categoryId || 0,
  })

  const firstTvChannels = tvChannels.map(formatChannelPlaylist)

  if (pageInfo.last) {
    return {
      categoryId: categoryId || 0,
      channels: firstTvChannels,
      pageInfo,
    }
  }

  const allPages = pageInfo.totalPages
    ? new Array(pageInfo.totalPages - 1).fill(null)
    : []

  const allPagesPromises = allPages.map((_, index) =>
    getTvChannelsFX({
      page: index + 1,
      categoryId: categoryId || 0,
    })
  )
  const allTvChannels = await Promise.all(allPagesPromises)
  const { content: _, ...lastPageInfo } =
    allTvChannels[allTvChannels.length - 1]

  const flattedAllTvChannels = allTvChannels.reduce(
    (acc, tvCannels) => [...acc, ...tvCannels.content],
    [] as TvChannel[]
  )

  return {
    categoryId: categoryId || 0,
    channels: [
      ...firstTvChannels,
      ...flattedAllTvChannels.map(formatChannelPlaylist),
    ],
    pageInfo: lastPageInfo,
  }
}
