import { exists } from '@/helpers'
import { D } from '@mobily/ts-belt'
import { memo, useMemo, type MemoExoticComponent } from 'react'
import { type SkinView } from '../../index.h'
import { key, substitute, useRaw, type Scope } from '../core'
import { type Recursive } from './Recursive'

export type LinkSkinView = SkinView & Required<Pick<SkinView, 'link'>>
export type NoLinksSkinView = Omit<SkinView, 'link'>

interface Props {
  skin: LinkSkinView
  parent: Scope
  Recursive: Recursive
  [key: string]: unknown // any additional props, will be propogated to all children
}

// exports memoized component
export const Link: MemoExoticComponent<typeof _Link> = memo(_Link)

// _Link.whyDidYouRender = true
_Link.displayName = 'Link'
function _Link({
  skin,
  parent,
  Recursive,
  ...props
}: Props): JSX.Element | null {
  const link: LinkSkinView['link'] = skin.link
  const rawHref = substitute(
    typeof link === 'string' ? link : link.href,
    parent
  )
  const href = (useRaw(rawHref) as string) || '#'
  const internal = !href.startsWith('http')

  // it is crucial to omit `link` field from skin, to prevent infinite loop
  const skinNoLink: NoLinksSkinView = useMemo(
    () => D.deleteKey(skin, 'link'),
    [skin]
  )

  // external link
  if (!internal) {
    return (
      <a href={href} target='_blank' rel='noreferrer' key={key(skin)}>
        <Recursive {...props} skin={skinNoLink} parent={parent} />
      </a>
    )
  }

  // internal application link
  return (
    <a href={href} key={key(skin)}>
      <Recursive {...props} skin={skinNoLink} parent={parent} />
    </a>
  )
}

export function hasLink(skin: SkinView): skin is LinkSkinView {
  return exists(skin.link)
}
