import { logger } from '@/logger'
import { type SkinView } from '../../../index.h'
import { get, type Scope } from '../scope'
import { useRaw } from './useRaw'
import { Special } from './useScope'

// debug flag
const debug =
  process.env.NODE_ENV !== 'production' &&
  typeof document !== 'undefined' &&
  globalThis.debugUseData

export type DataRecord = { [name: string]: unknown }

/**
 * Hook to get resolved data, defined in skin
 */
export function useData(
  alias: string,
  data: SkinView['data'],
  scope: Scope
): DataRecord | undefined {
  if (!data || typeof data !== 'object') return

  // prettier-ignore
  if (debug) {
    logger.groupCollapsed(`💼 %cdata%c ${alias} %c<+`,'background:burlywood;border-radius:3px;padding:0 2px;color:black;font-weight:normal','font-weight:normal','color:pink')
    logger.log('💅', data)
    logger.log('🧅', scope)
  }

  const result: DataRecord = {}
  for (let name of Object.keys(data)) {
    if (name.endsWith(Special.Evaluateable)) {
      name = name.slice(0, -Special.Evaluateable.length)
    }

    // this is ok, because skin is static and never changes on the fly
    // eslint-disable-next-line react-hooks/rules-of-hooks
    result[name] = useRaw(get(name, scope))
  }

  if (debug) {
    logger.log('📦', result)
    logger.groupEnd()
  }

  return result
}
