import { getToken } from 'firebase/messaging'
export enum MessageTypes {
  INITIALIZE_PUSH_NOTIFICATIONS = 'INITIALIZE_PUSH_NOTIFICATIONS',
  UNREGISTER_PUSH_NOTIFICATIONS = 'UNREGISTER_PUSH_NOTIFICATIONS',
  SET_APP_ICON = 'SET_APP_ICON',
}

export enum NotificationPermission {
  DEFAULT = 'default',
  DENIED = 'denied',
  GRANTED = 'granted',
}

export type Messaging = Parameters<typeof getToken>[0]
