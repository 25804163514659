import type { API } from '@setplex/tria-api'
import {
  type ApiAnswerChannelBundleDto_BundleSingleView,
  type ApiAnswerSliceDtoChannelBundleDto_BundlePageView,
  type ApiAnswerSliceDtoChannelDto,
} from '@setplex/wbs-api-types'
import { DEFAULT_PAGE_SIZE } from '../../constants/generic'
import { type HttpClient } from '../../http'
import { type AdapterDefaults } from '../../index.h'
import { formatChannel } from '../tv/tv.format'
import { formatTvBundle } from './tvBundles.format'

export function use(
  http: HttpClient,
  tvBundles: API['tvBundles'],
  _api: API,
  defaults: AdapterDefaults
): void {
  // GET /v3/bundles/channels
  tvBundles.getTvBundlesFx.use(
    async ({
      page,
      sortBy,
      sortOrder = defaults.sortOrder,
      count = defaults.count || DEFAULT_PAGE_SIZE,
      channelId,
    }) => {
      const params = new URLSearchParams()
      if (page) params.set('page', String(page))
      if (count) params.set('count', String(count))
      if (sortBy) params.set('sortBy', String(sortBy))
      if (sortOrder) params.set('sortOrder', String(sortOrder))
      if (channelId) params.set('channelId', String(channelId))

      const json =
        await http.get<ApiAnswerSliceDtoChannelBundleDto_BundlePageView>(
          `/v3/bundles/channels?${params}`
        )
      if (!json || !json.payload) {
        throw new Error('Empty answer in getTvBundlesFx')
      }
      return {
        ...json.payload,
        content: (json.payload?.content || []).map(formatTvBundle),
      }
    }
  )

  // GET /v3/bundles/channels/{bundleId}
  tvBundles.getTvBundleInfoFx.use(async ({ bundleId }) => {
    const json = await http.get<ApiAnswerChannelBundleDto_BundleSingleView>(
      `/v3/bundles/channels/${bundleId}`
    )
    if (!json || !json.payload) {
      throw new Error('Empty answer in getTvBundleInfoFx')
    }
    return formatTvBundle(json.payload)
  })

  // GET /v3/bundles/channels/{id}/content
  tvBundles.getTvBundleContentFx.use(
    async ({ bundleId, page, count = defaults.count || DEFAULT_PAGE_SIZE }) => {
      const params = new URLSearchParams()
      if (page) params.set('page', String(page))
      if (count) params.set('count', String(count))

      const json = await http.get<ApiAnswerSliceDtoChannelDto>(
        `/v3/bundles/channels/${bundleId}/content?${params}`
      )
      if (!json || !json.payload) {
        throw new Error('Empty answer in getTvBundleContentFx')
      }

      return {
        ...json.payload,
        content: (json.payload?.content || []).map(formatChannel),
      }
    }
  )

  /**************** $TvBundleWithContent *******************/
  // to paginate over - paginateTvBundleWithContent
  tvBundles.loadTvBundleWithContentFx.use(async ({ count, bundleId }) => {
    const bundleInfo = await tvBundles.loadTvBundleInfoForContent({ bundleId })
    const channels =
      (
        await tvBundles.paginateTvBundleWithContent({
          page: 0,
          count,
          bundleId: Number(bundleId),
        })
      ).content || []

    return {
      ...bundleInfo,
      channels,
    }
  })
  /**************** $TvBundleWithContent *******************/
}
