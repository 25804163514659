import {
  createEffect,
  createEvent,
  createStore,
  sample,
  type Effect,
} from 'effector'
import { navigatePush } from './model'

// init dom model
export const init = createEvent<{ container: HTMLElement }>()

// effect to add `click` handler to the root container
const addClickEventListenerFx: Effect<HTMLElement, void> = createEffect<
  HTMLElement,
  void
>(addClickEventListener)

sample({
  clock: init,
  fn: ({ container }) => container,
  target: addClickEventListenerFx,
})

export const $documentTitle = createStore<string>('')

/**
 * Add global `onclick` handler for _any_ <a> tag on the page,
 * it will prevent default behaviour and calls hostory.push (via `navigatePush` event)
 *
 * This is a copy-paste from Storeon Router, it occurs to me that this might be
 * more convenient, than creating special <Link> component - just use normal <a>
 *
 * @see https://github.com/storeon/router/blob/97481758e76988b6b63f086c8001ba1878c2c4e0/index.js#L60
 */
function addClickEventListener(container?: HTMLElement) {
  if (typeof container !== 'undefined' && typeof location !== 'undefined') {
    container.addEventListener('click', (event) => {
      const target: Element | null = event.target as Element | null
      if (target) {
        const link = target.closest('a')
        if (
          !event.defaultPrevented && // event already default-prevented
          link != null && // has <a> tag at all
          link.href.indexOf(location.origin) === 0 && // local link
          link.target !== '_blank' && // not new page link
          link.dataset.ignoreRouter == null && // has no flag to ignore router
          event.button === 0 && // left button click
          !event.metaKey && // no special key is pressed
          !event.ctrlKey &&
          !event.shiftKey &&
          !event.altKey
        ) {
          event.preventDefault()
          navigatePush(link.href.slice(location.origin.length))
        }
      }
    })
  }
}
