import { attach, createEffect, createStore } from 'effector'
import type { ApiEffect, PageNew, PageableInfoNew } from '../index.h'
import type { VodBundleInfo, VodBundleWithContent } from '../interfaces/bundles'
import type { Vod } from '../interfaces/vods'

// *
// * EFFECTS
// *

// GET /v3/bundles/vods
export const getVodBundlesFx: ApiEffect<
  {
    page: number
    count?: number
    sortOrder?: string
    sortBy?: string
  },
  PageNew<VodBundleInfo>
> = createEffect()

// GET /v3/bundles/vods/{bundleId}
export const getVodBundleInfoFx: ApiEffect<
  { bundleId: number },
  VodBundleInfo
> = createEffect()

// GET /v3/bundles/vods/{bundleId}/content
export const getVodBundleContentFx: ApiEffect<
  { bundleId: number; page: number; count?: number },
  PageNew<Vod>
> = createEffect()

/**************** $VodBundleWithContent *******************/
export const loadVodBundleWithContentFx: ApiEffect<
  { bundleId: number; page: number; count?: number },
  VodBundleWithContent
> = createEffect()
export const loadVodBundleInfoForContent = attach({
  effect: getVodBundleInfoFx,
})
export const paginateVodBundleWithContent = attach({
  effect: getVodBundleContentFx,
}) // to paginate

export const $vodBundleWithContent = createStore<VodBundleWithContent | null>(
  null
)
  .on(loadVodBundleWithContentFx.doneData, (_, data) => data)
  .on(paginateVodBundleWithContent.doneData, (state, payload) => {
    if (!state) return null

    const currentVods = state.vods || []

    return {
      ...state,
      vods: [...currentVods, ...payload.content],
    }
  })

export const $vodBundleWithContentPageInfo =
  createStore<PageableInfoNew | null>(null).on(
    paginateVodBundleWithContent.done,
    (_, { result: { content: _ignored, ...pageInfo }, params }) => ({
      ...pageInfo,
      params,
    })
  )
/**************** $VodBundleWithContent *******************/

/**************** $VodBundlesRow *******************/
export const initVodBundlesRowByPageFx = attach({
  effect: getVodBundlesFx,
  mapParams: (_) => ({ ..._, page: 0 }),
})

export const loadVodBundlesRowByPageFx = attach({ effect: getVodBundlesFx })

export const $vodBundlesRow = createStore<VodBundleInfo[]>([])
  .on(initVodBundlesRowByPageFx.doneData, (_, page) => page.content)
  .on(loadVodBundlesRowByPageFx.doneData, (state, page) => [
    ...state,
    ...page.content,
  ])

/**************** $VodBundlesRow *******************/
