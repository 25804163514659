import { createMediaQueryStore } from '@/effector'
import { combine, type Store } from 'effector'
import {
  DeviceType,
  WINDOW_DESKTOP_MIN_SIZE_IN_PX,
  WINDOW_MOBILE_MAX_SIZE_IN_PX,
  WINDOW_TABLET_MAX_SIZE_IN_PX,
  WINDOW_TABLET_MIN_SIZE_IN_PX,
} from './index.h'

const $mobile: Store<boolean> = createMediaQueryStore(
  `(max-width: ${WINDOW_MOBILE_MAX_SIZE_IN_PX}px)`,
  false
)

const $tablet: Store<boolean> = createMediaQueryStore(
  `(min-width: ${WINDOW_TABLET_MIN_SIZE_IN_PX}px) and (max-width: ${WINDOW_TABLET_MAX_SIZE_IN_PX}px)`,
  false
)

const $desktop: Store<boolean> = createMediaQueryStore(
  `(min-width: ${WINDOW_DESKTOP_MIN_SIZE_IN_PX}px)`,
  true
)

export const $deviceType = combine(
  $mobile,
  $tablet,
  $desktop,
  (mobile, tablet, desktop) => {
    if (mobile) return DeviceType.Mobile
    if (tablet) return DeviceType.Tablet
    if (desktop) return DeviceType.Desktop
    return null
  }
)
