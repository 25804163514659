import { type RouterResponse } from '!/router'
import { ui, type SkinViews } from '!/skin'
import { isArray } from '@/helpers'
import {
  createElement,
  type ComponentClass,
  type FunctionComponent,
} from 'react'

interface Props {
  route: RouterResponse | undefined
}

Page.displayName = 'Page'
export function Page({ route }: Props): JSX.Element | null {
  if (!route || !route.component) {
    return null
  }

  return (
    <ui.SkinProvider value={(route.skin as SkinViews) || null}>
      {isArray(route.component)
        ? route.component.map((c, i) => render(c, route.props, i))
        : render(route.component, route.props)}
    </ui.SkinProvider>
  )
}

/**
 * Creates page single component, using `displayName` as a key
 */
function render(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: FunctionComponent<any> | ComponentClass<any, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: any,
  i?: number
) {
  if (!component) return null
  const key = `${component.displayName || component.name}-${i ?? ''}`
  return createElement(component, { key, ...props })
}
