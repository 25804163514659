export interface AdapterDefaults {
  count?: number
  sortOrder?: string
}

export enum DeviceType {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
}
