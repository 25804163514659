import { api } from '@setplex/tria-api'
import { createEvent, sample } from 'effector'
import { features } from '~/core/flags'
import { model as session } from '~/entities/session'
import { T } from '~/shared/helpers'
import { keys } from '~/shared/jail'
import { FORCE_IN, type Cage, type Transit } from '../index.h'
import { tainter } from '../tools'

/*
 * ### Geoblock cage ###
 */

const init = createEvent<Transit>('init')
const seal = createEvent<boolean>('seal')

// in events
const accountIpACLLock = createEvent('accountIpACLLock')

export const geoblock = {
  name: 'geoblock',
  key: keys.geoblock,
  init,
  seal,
  in: [accountIpACLLock],
  out: {},
  ...tainter(/^\/geoblock/, '/geoblock', true),
} satisfies Cage

/*
 * cage logic
 */

const activateGeoblock = createEvent()

sample({
  clock: init,
  target: seal.prepend(T),
})

// in case of any WB0051 | WG0062 error -> move to lock cage
// BUT! after authentication all WG0062 errors - are content geoblock
// this should be fixed later by backend (new error code)
sample({
  clock: api.events.logic.accountIpACLLock,
  filter: session.$notAuthenticated,
  target: activateGeoblock,
})

// in case of WG0062 | WB0051 error on features request -> lock state
sample({
  clock: [
    features.getFx.failData,
    api.session.signInFx.failData,
    api.subscriber.signUpFx.failData,
    api.subscriber.initResetPasswordFx.failData,
  ],
  filter: (data) =>
    data?.payload?.errorCode === 'WG0062' ||
    data?.payload?.errorCode === 'WB0051', // FIXME: hardcoded codes
  target: activateGeoblock,
})

sample({
  clock: activateGeoblock,
  target: accountIpACLLock.prepend(() => FORCE_IN),
})
