import { dom, model as router, type History, type Route } from '!/router'
import { model as sse } from '!/sse'
import { noop } from '@/helpers'
import { createEvent, sample } from 'effector'
import { analytics, jail, launch, navigation, sw } from '~/processes'

// init application
export const init = createEvent<{
  history: History
  container: HTMLElement
  routes: Route
}>()

// init router model at application init
sample({
  clock: init,
  fn: ({ history, routes }) => ({ history, routes }),
  target: router.init,
})

// init dom model at application init
sample({
  clock: init,
  fn: ({ container }) => ({ container }),
  target: dom.init,
})

// init SSE at application init
sample({
  clock: init,
  fn: noop,
  target: sse.init,
})

// init service worker at application init
sample({
  clock: init,
  fn: noop,
  target: sw.init,
})

// init application navigation process
sample({
  clock: init,
  fn: noop,
  target: [jail.init, navigation.init],
})

// init application launch process
sample({
  clock: init,
  fn: noop,
  target: launch.init,
})

// init analytics process
sample({
  clock: init,
  fn: noop,
  target: analytics.init,
})

//
// handlers for external usages
//

export const onLocationChange = navigation.renderFx.use
