import { isArray } from '@/helpers'
import { type SkinView, type SkinViews } from '../../index.h'
import { key, useSkin } from '../core'
import { Recursive } from './Recursive'

interface Props {
  name: string
  from?: SkinViews | Promise<SkinViews> | (() => SkinViews | Promise<SkinViews>)
  [key: string]: unknown // any additional props, will be propogated to ALL children
}

// RenderSkin.whyDidYouRender = true
RenderSkin.displayName = 'RenderSkin'
export function RenderSkin({
  name,
  from,
  ...props
}: Props): JSX.Element | null {
  const skin: SkinView | SkinView[] | null = useSkin(name, from)

  if (skin == null) {
    return null
  }

  return isArray(skin) ? (
    <>
      {skin.map((skin, i) => (
        <Recursive {...props} skin={skin} key={key(skin, i)} />
      ))}
    </>
  ) : (
    <Recursive {...props} skin={skin} key={key(skin)} />
  )
}
