import type { PurchaseInfo, TvShowBundleInfo } from '@setplex/tria-api'
import { type TvShowBundleDto_BundleSingleView } from '@setplex/wbs-api-types'
import { getBackgroundImage, getHorizontalImage } from '../../utils/images'
import { getMinPriceObject } from '../../utils/minPriceObj'
import { formatPriceSettings } from '../../utils/payment'

export const formatShowBundle = (
  bundle: TvShowBundleDto_BundleSingleView
): TvShowBundleInfo => {
  const minPriceObj = getMinPriceObject(bundle.priceSettings)

  return {
    id: bundle.id!,
    name: bundle.name!,
    description: bundle.description,
    landscapeImageUrl: getHorizontalImage(bundle.coverImageUrl),
    backgroundImageUrl: getBackgroundImage(bundle.coverImageUrl),
    contentQuantity: bundle.contentQuantity!,
    updatedTime: bundle.updatedTime!,
    tvShowIds: bundle.tvShowIds!,
    seasonIds: bundle.seasonIds!,
    episodeIds: bundle.episodeIds!,
    purchaseInfo: bundle.purchaseInfo as PurchaseInfo,
    priceSettings: formatPriceSettings(bundle.priceSettings) || [],
    free: bundle.priceSettings?.length === 0,
    minCurrency: minPriceObj?.currency,
    minPrice: minPriceObj?.price,
    externalId: bundle.externalId,
  }
}
