import * as v from 'valibot'

const NpawConfigBalancerOptionsSchema = v.object({
  profileName: v.nullish(v.string(), undefined),
  bucketName: v.nullish(v.string(), undefined),
})

export type NpawConfig = v.InferOutput<typeof NpawConfigSchema>
export const NpawConfigSchema = v.object({
  enabled: v.nullish(v.boolean(), false),
  accountCode: v.string(),
  balancerOptions: v.nullish(NpawConfigBalancerOptionsSchema, undefined),
})
