import { type StoreWritable, type Unit } from 'effector'
import { type Location, type RouterResponse } from '~/core/router'
import { type Key } from '~/shared/jail'

// if `in` event is triggered with FORCE_IN - it will bypass sealed state
export const FORCE_IN = Symbol('force_in')

/* eslint-disable @typescript-eslint/no-explicit-any */
export type Cage = {
  name: string
  key?: Key
  init: Unit<Transit>
  trial?: Unit<Trial>
  taint?: (location: Location, reason?: string) => Location | undefined
  cleanse?: (location: Location) => Location | undefined
  seal?: Unit<boolean>
  $active?: StoreWritable<boolean>
  in: Unit<any> | Unit<any>[]
  out: {
    [name: string]: Unit<any>
  }
}

export type Extradition = [
  Unit<any> | Unit<any>[],
  Cage | ((param: any) => Cage)
]

export type Confinement = [Cage, Extradition[]]

export type Jail = Confinement[]

export enum ConfinementDirection {
  IN = 'in',
  OUT = 'out',
}

export type Transit = {
  from: Cage
  to: Cage
  direction: ConfinementDirection
  reason: string
  trial?: Trial | null
}

export type Trial = {
  location: Location
  route: RouterResponse
}
