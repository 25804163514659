import { memo, useMemo, type MemoExoticComponent } from 'react'
import { type SkinView } from '../../index.h'
import { extend, inherit, key, raw, Special, type Scope } from '../core'
import { type Recursive } from './Recursive'

interface Props {
  i: number
  item: unknown
  name: string
  index: string
  skin: SkinView
  parent: Scope
  Recursive: Recursive
  [key: string]: unknown // any additional props, will be propogated to all rendered elements
}

export const ManyItem: MemoExoticComponent<typeof _ManyItem> = memo(_ManyItem)

// _ManyItem.whyDidYouRender = true
_ManyItem.displayName = 'ManyItem'
function _ManyItem({
  i,
  item,
  name,
  index,
  skin,
  parent,
  Recursive,
  ...props
}: Props): JSX.Element | null {
  const scope: Scope = useMemo(() => {
    return extend(
      {
        [Special.Item]: raw(`item: ${Special.Item}`, item),
        [name]: raw(`item: ${name}`, item),
        [index]: raw(`index: ${index}`, i),
      },
      /* -> */ inherit(parent)
    )
  }, [i, item, name, index, parent])

  return <Recursive {...props} key={key(skin, i)} skin={skin} parent={scope} />
}
