import './PageWrapper.css'

interface Props {
  children: React.ReactNode
  className?: string
}

// PageWrapper.whyDidYouRender = true
PageWrapper.displayName = 'PageWrapper'
export function PageWrapper({ className = '', children }: Props): JSX.Element {
  return <div className={`page-wrapper ${className}`}>{children}</div>
}
