import { api, type IProfile } from '@setplex/tria-api'
import { attach, combine, createStore, sample } from 'effector'
import { type OptimisticProfile } from './index.h'
import { add, remove, set } from './lib'

export const getProfilesFx = attach({
  effect: api.profiles.getProfilesFx,
})

export const getActiveProfileFx = attach({
  effect: api.profiles.getActiveProfileFx,
})

export const createProfileFx = attach({
  effect: api.profiles.createProfileFx,
})

export const updateProfileFx = attach({
  effect: api.profiles.updateProfileFx,
})

export const removeProfileFx = attach({
  effect: api.profiles.removeProfileFx,
})

export const updateProfileActiveStateFx = attach({
  effect: api.profiles.updateProfileActiveStateFx,
})

/**
 * optimistic profiles list
 */
const $optimisticRawProfiles = createStore<OptimisticProfile[]>([])
  // load all profiles
  .on(getProfilesFx.doneData, (_, profiles) => profiles)

  // load active profile
  .on(getActiveProfileFx.doneData, (list, profile) => add(list, profile))

  // create profile
  .on(createProfileFx, (list, profile) =>
    add(list, { ...profile, id: Infinity })
  )
  .on(createProfileFx.done, (list, { result }) =>
    set(list, Infinity, (p) => ({ ...p, ...result }))
  )
  .on(createProfileFx.fail, (list) => remove(list, Infinity))

  // update profile
  .on(updateProfileFx, (list, profile) =>
    set(list, profile.id, (p) => ({ ...profile, _original: p }))
  )
  .on(updateProfileFx.done, (list, { params: profile }) =>
    set(list, profile.id, ({ _original, ...p }) => p)
  )
  .on(updateProfileFx.fail, (list, { params: profile }) =>
    set(list, profile.id, ({ _original }) => _original!)
  )

  // remove profile
  .on(removeProfileFx, (list, id) =>
    set(list, id, (p) => ({ ...p, _deleted: true }))
  )
  .on(removeProfileFx.done, (list, { params: id }) => remove(list, id))
  .on(removeProfileFx.fail, (list, { params: id }) =>
    set(list, id, ({ _deleted, ...p }) => p)
  )

  // reset list
  .reset(api.session.signOutFx.doneData, api.events.http.unauthorized)

/**
 * optimistic profiles list
 */
export const $optimisticProfiles = $optimisticRawProfiles.map((list) =>
  list
    .filter((p) => !p._deleted)
    .map(({ _original, _deleted, ...p }) => p as IProfile)
)

/**
 * optimistic active profile raw id
 */
const $activeProfileRawId = createStore<[number, number?]>([0])
  .on(api.session.$session, (_, session) => [session?.profileId ?? 0])
  .on(getActiveProfileFx.doneData, (_, { id }) => [id ?? 0])
  .on(updateProfileActiveStateFx, ([old], id) => [id, old])
  .on(updateProfileActiveStateFx.done, ([id]) => [id])
  .on(updateProfileActiveStateFx.fail, ([_, old]) => [old ?? 0])
  .reset(api.session.signOutFx.doneData, api.events.http.unauthorized)

// update profiles when some error on profile select
sample({
  clock: updateProfileActiveStateFx.fail,
  target: getProfilesFx,
})

/**
 * optimistic active profile id
 */
export const $activeProfileId = $activeProfileRawId.map(([id]) => id)

/**
 * optimistic active profile
 */
export const $activeProfile = combine(
  $optimisticProfiles,
  $activeProfileId,
  (profiles, id) =>
    profiles.length === 0 || id === 0
      ? null
      : profiles.find((p) => p.id === id) ?? null
)
