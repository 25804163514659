import { attach, createEffect } from 'effector'
import type { ApiEffect, Page } from '../index.h'
import type {
  GetPartnerProductParams,
  GetPartnerProductsByPageParams,
  PartnerProduct,
  PartnerProductDetails,
} from '../interfaces/partnerProgram'

// GET /v3/partner-product
export const getPartnerProductsByPageFx: ApiEffect<
  GetPartnerProductsByPageParams,
  Page<PartnerProduct>
> = createEffect()

export const getPartnerProductsByPageRowFx = attach({
  effect: getPartnerProductsByPageFx,
  mapParams: (params: GetPartnerProductsByPageParams) => params,
})

export const getPartnerProductsByPageGridFx = attach({
  effect: getPartnerProductsByPageFx,
  mapParams: (params: GetPartnerProductsByPageParams) => params,
})

// GET /v3/partner-product/{partnerProductId}
export const getPartnerProductFx: ApiEffect<
  GetPartnerProductParams,
  PartnerProductDetails
> = createEffect()

export const getPartnerProductDetailsFx = attach({
  effect: getPartnerProductFx,
})
