import { type OptimisticProfile } from './index.h'

//
// utility functions to work with profiles list
//

export const set = (
  list: OptimisticProfile[],
  id: number | undefined,
  modifier: (profile: OptimisticProfile) => OptimisticProfile
): OptimisticProfile[] => {
  const idx = list.findIndex((p) => p.id === id)
  return idx === -1 ? list : list.toSpliced(idx, 1, modifier(list[idx]))
}

export const add = (
  list: OptimisticProfile[],
  profile: OptimisticProfile
): OptimisticProfile[] => {
  const idx = list.findIndex((p) => p.id === profile.id)
  return idx === -1 ? list.concat(profile) : list.toSpliced(idx, 1, profile)
}

export const remove = (
  list: OptimisticProfile[],
  id: number
): OptimisticProfile[] => {
  const idx = list.findIndex((p) => p.id === id)
  return idx === -1 ? list : list.toSpliced(idx, 1)
}

//
// utility functions to work with profile id
//

// backend type for profile id is `int` (32-bit signed integer),
// which has a range from -2147483648 to 2147483647
// which is far less than MAX_SAFE_INTEGER (9007199254740991) in javascript
// we can use this fact to mark profile id as "optimistic" via math magic
const MAX_INT = 2147483647
const MAX_UINT = MAX_INT * 2 + 1

export const mark = (id: number): number => id + MAX_UINT // -> new id > MAX_INT
export const marked = (id: number): boolean => id > MAX_INT // safely handle negative numbers
export const unmark = (id: number): number => id - MAX_UINT
