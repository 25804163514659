export class ResolveRouteError extends Error {
  constructor(message: string) {
    super('ResolveRouteError: ' + message)
    this.name = 'ResolveRouteError'
  }
}

export class ResolveLoadError extends ResolveRouteError {
  constructor(message: string) {
    super('ResolveLoadError: ' + message)
    this.name = 'ResolveLoadError'
  }
}

export class ResolveSkinError extends ResolveRouteError {
  constructor(message: string) {
    super('ResolveSkinError: ' + message)
    this.name = 'ResolveSkinError'
  }
}
