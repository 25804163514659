import { createEvent } from 'effector'

export type ErrorPayload = {
  remainsSeconds: number
}

export interface LogicError {
  errorCode: string
  message?: string
  payload?: any
}

export interface LogicErrorWithSeconds extends Omit<LogicError, 'payload'> {
  payload?: ErrorPayload
}

export const anyLogicError = createEvent<LogicError>()

export const accountIpACLLock = createEvent<LogicError>()
export const addressNotValid = createEvent<LogicError>()
export const apiBlocked = createEvent<LogicError>()
export const apiSuccess = createEvent<LogicError>()
export const authNotAllowedIPAddress = createEvent<LogicError>()
export const authorizationPaymentTransactionFailed = createEvent<LogicError>()
export const badCredentials = createEvent<LogicError>()
export const capturePaymentTransactionFailed = createEvent<LogicError>()
export const cartLimitExceeded = createEvent<LogicError>()
export const cartShouldBeChecked = createEvent<LogicError>()
export const cityNotValid = createEvent<LogicError>()
export const confirmationCodeInvalid = createEvent<LogicError>()
export const confirmationLinkExpired = createEvent<LogicError>()
export const confirmationLinkNotExist = createEvent<LogicError>()
export const confirmationLinkSettingsNotFound = createEvent<LogicError>()
export const contentGeoLock = createEvent<LogicError>()
export const contentSetItemsChanged = createEvent<LogicError>()
export const countryNotValid = createEvent<LogicError>()
export const couponCannotBeApplied = createEvent<LogicError>()
export const deviceAlreadyAssigned = createEvent<LogicError>()
export const deviceNoSubscriberAssigned = createEvent<LogicError>()
export const deviceRedirectFailed = createEvent<LogicError>()
export const deviceToAnotherSubscriber = createEvent<LogicError>()
export const deviceTypeNotAllowed = createEvent<LogicError>()
export const deviceUnassignFailed = createEvent<LogicError>()
export const discountUsageLimitExceeded = createEvent<LogicError>()
export const emailAlreadyConfirmed = createEvent<LogicError>()
export const emailNotUnique = createEvent<LogicError>()
export const emailNotValid = createEvent<LogicError>()
export const enterSecurityCode = createEvent<LogicError>()
export const firstNameNotValid = createEvent<LogicError>()
export const guestModeDisabled = createEvent<LogicError>()
export const inAppRegistrationDisabled = createEvent<LogicError>()
export const internalServerError = createEvent<LogicError>()
export const invalidPaymentData = createEvent<LogicError>()
export const invalidReferer = createEvent<LogicError>()
export const invalidRequest = createEvent<LogicError>()
export const invalidRequestCode = createEvent<LogicError>()
export const invoiceCodeInvalid = createEvent<LogicError>()
export const invoiceCodeRequired = createEvent<LogicError>()
export const lastNameNotValid = createEvent<LogicError>()
export const maxNumberDevicesAssigned = createEvent<LogicError>()
export const noItemsCheckout = createEvent<LogicError>()
export const noItemsToBuy = createEvent<LogicError>()
export const noMoreCodeRequestsAllowed = createEvent<LogicError>()
export const noMoreConfirmationAttempts = createEvent<LogicError>()
export const noMoreConfirmationAttemptsAllowed = createEvent<LogicError>()
export const noMoreRequestsForEmailAllowed = createEvent<LogicError>()
export const noMoreRequestsForIPAllowed = createEvent<LogicError>()
export const notAvailableForPurchase = createEvent<LogicError>()
export const originalPriceChanged = createEvent<LogicError>()
export const packageNotAllowed = createEvent<LogicError>()
export const passwordNotMatchRE = createEvent<LogicError>()
export const passwordNotValid = createEvent<LogicError>()
export const passwordResetBlockedForAccount = createEvent<LogicError>()
export const passwordResetDisabled = createEvent<LogicError>()
export const passwordTooLong = createEvent<LogicError>()
export const passwordTooShort = createEvent<LogicError>()
export const paymentMethodNotAvailable = createEvent<LogicError>()
export const paymentMethodNotSelected = createEvent<LogicError>()
export const phoneNotValid = createEvent<LogicError>()
export const previousSubscriptionNotExpired = createEvent<LogicError>()
export const subscriptionHasBeenExpired = createEvent<LogicError>()
export const accountOrDeviceHasBeenSuspended = createEvent<LogicError>()
export const redirectSuccessful = createEvent<LogicError>()
export const refundPaymentTransactionFailed = createEvent<LogicError>()
export const resetPasswordCodeInvalid = createEvent<LogicError>()
export const resetPasswordCodeSessionExpired = createEvent<LogicError>()
export const serverUnderMaintenance = createEvent<LogicError>()
export const sessionInvalid = createEvent<LogicError>()
export const stateNotValid = createEvent<LogicError>()
export const subscriberMissingProfile = createEvent<LogicError>()
export const subscriberPasswordRequired = createEvent<LogicError>()
export const subscriberUsernameRequired = createEvent<LogicError>()
export const subscriptionNotActive = createEvent<LogicError>()
export const timezoneNotValid = createEvent<LogicError>()
export const TOANotAccepted = createEvent<LogicError>()
export const tooManyCodeRequests = createEvent<LogicError>()
export const unexpectedError = createEvent<LogicError>()
export const unhandledError = createEvent<LogicError>()
export const unsupportedPlatform = createEvent<LogicError>()
export const usernameNotNotMatchRE = createEvent<LogicError>()
export const usernameNotUnique = createEvent<LogicError>()
export const usernameTooLong = createEvent<LogicError>()
export const voidPaymentTransactionFailed = createEvent<LogicError>()
export const webDeviceInitError = createEvent<LogicError>()
export const webDeviceNotFound = createEvent<LogicError>()
export const yourIPAddressLocked = createEvent<LogicError>()
export const ZIPNotValid = createEvent<LogicError>()
export const signInByPhoneDisabled = createEvent<LogicError>()
export const signUpByPhoneDisabled = createEvent<LogicError>()
export const noMoreAttemptsOneIp = createEvent<LogicErrorWithSeconds>()
export const noMoreAttemptsOnePhoneNumber = createEvent<LogicErrorWithSeconds>()
export const sessionNotExist = createEvent<LogicError>()
export const noMoreVerifyAttempts = createEvent<LogicErrorWithSeconds>()
export const phoneNumberNotValid = createEvent<LogicError>()
export const codeIsNotValid = createEvent<LogicError>()
export const maxProfilesCount = createEvent<LogicError>()
