import { model as router } from '!/router'
import { api } from '@setplex/tria-api'
import { createEvent, sample, split, type Store } from 'effector'
import { interval, not } from 'patronum'
import { model as guest } from '~/features/subscriber/guest'

export const init = createEvent()

const INTERVAL_PING_WBS_SEC = 540 // 9 minutes
const PAGES = ['/player', '/epg']

const start = createEvent()
const stop = createEvent()

// pinger is active only on some pages
const $active: Store<boolean> = router.$pathname.map(
  (pathname) =>
    Boolean(pathname) && PAGES.some((page) => pathname!.startsWith(page))
)

// tick every INTERVAL_PING_WBS_SEC second after `start` and until `stop`
const { tick } = interval({
  timeout: INTERVAL_PING_WBS_SEC * 1000,
  start,
  stop,
  leading: false,
  trailing: false,
})

// start if we already on needed page
sample({
  clock: init,
  source: $active,
  filter: Boolean,
  target: start,
})

// start on activate and stop on deactivate
split({
  source: $active,
  match: (active) => (active ? 'start' : 'stop'),
  cases: { start, stop },
})

// send refresh request on each interval tick
sample({
  clock: tick,
  filter: not(guest.$guest),
  target: api.session.refreshFx,
})
