import { type Merr } from '@setplex/merr'

/**
 * Request input for `fx` effect
 */
export type RequestInput = Parameters<Merr>[0]

/**
 * Response output of `fx` effect
 */
export type RequestOutput = {
  response?: Response
  error?: RequestError
  json?: any
}

/**
 * Base error class for all request errors
 */
export class RequestError extends Error {
  constructor(error: Error | string) {
    super(typeof error === 'string' ? error : error.message)
  }
}

/**
 * HTTP error
 */
export class RequestHttpError extends RequestError {
  constructor(public readonly response: Response) {
    super('HTTP error: ' + response.status + ' ' + response.statusText)
    this.name = 'RequestHttpError'
  }
}

/**
 * Request failed error
 */
export class RequestFailedError extends RequestError {
  constructor(public readonly failure: Error) {
    super(failure)
    this.name = 'RequestFailedError'
  }
}

/**
 * Request failed due to cancel error
 */
export class RequestCancelledError extends RequestFailedError {
  constructor(public override readonly failure: Error) {
    super(failure)
    this.name = 'RequestCancelledError'
  }
}

/**
 * Request failed due to timeout error
 */
export class RequestTimeoutError extends RequestFailedError {
  constructor(public override readonly failure: Error) {
    super(failure)
    this.name = 'RequestTimeoutError'
  }
}
