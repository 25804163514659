import { api, type Session } from '@setplex/tria-api'
import { createStore, type Store } from 'effector'

export interface Options {
  bannerCarouselsEnabled?: boolean
  catchupEnabled?: boolean
  chatEnabled?: boolean
  epgEnabled?: boolean
  featuredCarouselsEnabled?: boolean
  inAppSubscriptionManagementEnabled?: boolean
  npvrEnabled?: boolean
  radioEnabled?: boolean
  tvEnabled?: boolean
  tvShowEnabled?: boolean
  vodEnabled?: boolean
  liveEventEnabled?: boolean
  liveEventRecordsEnabled?: boolean

  // more available options for future use:
  //   backgroundColor?: string | null
  //   backgroundUrl?: string | null
  //   customVideoUrl?: string | null
  //   deviceThemes?: string | null
  //   logoUrl?: string | null
  //   menuTheme?: string | null
  //   screensaver?: number
  //   startPage?: string | null
  //   subTheme?: string | null
  //   themeVideoType?: string | null
}

// options store
export const $optionsFlags = createStore<Options>({})
  .on(api.session.signInFx.doneData, (ops, { payload }) => pick(payload, ops))
  .on(api.session.guestInFx.doneData, (ops, { payload }) => pick(payload, ops))
  .on(api.session.getAccountFx.doneData, (ops, session) => pick(session, ops))
  .reset(api.session.signOutFx.finally)

// options getter
export const get = <K extends keyof Options>(
  name: K,
  def: NonNullable<Options[K]>
): Store<NonNullable<Options[K]>> =>
  $optionsFlags.map((options) => options[name] ?? def)

/**
 * Pick options fields from session
 */
function pick(
  {
    bannerCarouselsEnabled,
    catchupEnabled,
    chatEnabled,
    epgEnabled,
    featuredCarouselsEnabled,
    inAppSubscriptionManagementEnabled,
    npvrEnabled,
    radioEnabled,
    tvEnabled,
    tvShowEnabled,
    vodEnabled,
    liveEventEnabled,
    liveEventRecordsEnabled,
  }: Session,
  options: Options
): Options {
  return {
    ...options,
    bannerCarouselsEnabled,
    catchupEnabled,
    chatEnabled,
    epgEnabled,
    featuredCarouselsEnabled,
    inAppSubscriptionManagementEnabled,
    npvrEnabled,
    radioEnabled,
    tvEnabled,
    tvShowEnabled,
    vodEnabled,
    liveEventEnabled,
    liveEventRecordsEnabled,
  }
}

export const $vodEnabled = get('vodEnabled', false)
export const $tvEnabled = get('tvEnabled', false)
export const $tvShowEnabled = get('tvShowEnabled', false)
export const $bannerCarouselsEnabled = get('bannerCarouselsEnabled', false)
export const $featuredCarouselsEnabled = get('featuredCarouselsEnabled', false)
export const $liveEventEnabled = get('liveEventEnabled', false)
export const $liveEventRecordsEnabled = get('liveEventRecordsEnabled', false)
