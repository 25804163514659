import { Loader } from '@/ui/components/Loader'
import { ThemeProvider } from './ThemeProvider'
import classes from './style.module.css'

Bootstrap.displayName = 'Bootstrap'
export function Bootstrap(): JSX.Element {
  return (
    <ThemeProvider>
      <Loader />
      <div className={classes.wrapper}>
        <div className={classes.loader}></div>
      </div>
    </ThemeProvider>
  )
}
