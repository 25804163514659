import type { API } from '@setplex/tria-api'
import { type HttpClient } from '../../http'
import { type AdapterDefaults } from '../../index.h'
import type { ApiContinueWatching } from '../../interfaces/continue-watching'
import { urlSearchParamsBuilder } from '../../utils/urlSearchParams'
import { formatContinueWatching } from './continueWatching.format'

export function use(
  http: HttpClient,
  continueWatching: API['continueWatching'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  continueWatching.getContinueWatchingFx.use(async (args) => {
    const params = urlSearchParamsBuilder(args)

    const json = await http.get<ApiContinueWatching>(
      `/v3/continue-watching?${params}`
    )
    if (!json || !json.payload) {
      throw new Error('Empty answer in getContinueWatchingFx')
    }

    return formatContinueWatching(json.payload)
  })
}
