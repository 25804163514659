import { type ComponentType } from 'react'
import * as is from 'react-is'

const map = new Map<string, ComponentType<unknown>>()

export const type = Symbol()

export function check(x: unknown): x is ComponentType<unknown> {
  return is.isValidElementType(x)
}

export function set<T extends ComponentType<unknown>>(
  component: T,
  name: string
): T {
  map.set(name, component)
  return component
}

export function get<T extends ComponentType<unknown>>(name: string): T | null {
  return (map.get(name) as T) ?? null
}
