import { type Location } from 'history'
import { FromComeLocationEnum } from './index.h'

interface GetFromComeToPlayerHrefParamsI {
  previousLocation: Location | null
  currentEventType: FromComeLocationEnum
}

export function getFromComeHref({
  previousLocation,
  currentEventType,
}: GetFromComeToPlayerHrefParamsI) {
  const previousLocationHref = previousLocation
    ? `${window.location.origin}${previousLocation?.pathname}${previousLocation?.search}`
    : window.location.href

  const fromComeHref =
    currentEventType === FromComeLocationEnum.LEFT ? null : previousLocationHref

  return fromComeHref
}
