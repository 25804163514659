import { model as i18n } from '!/i18n'
import { combine } from 'effector'

export const $loginPasswordTranslations = combine(i18n.$t, ({ t }) => {
  return {
    emailLabel: t('username_email'),
    emailPlaceholder: t('username_email_placeholder'),
    emailErrors: {
      required: t('username_email_required_error'),
      unique: t('email_unique_error'),
    },
    passwordLabel: t('password'),
    passwordPlaceholder: t('enter_password'),
    passwordErrors: {
      required: t('password_required_error'),
    },
  }
})
export const $signInByEmailTranslations = combine(i18n.$t, ({ t }) => {
  return {
    forgotTitle: t('forgot_password'),
    submitTitle: t('sign_in'),
    rememberMe: t('remember_me'),
  }
})
