import type {
  Catchup,
  CatchupProgram,
  CatchupProgramsWithDates,
} from '@setplex/tria-api'
import { ScheduleType } from '@setplex/tria-api'
import {
  type CatchupDto_CatchupListView,
  type ProgrammeDto,
} from '@setplex/wbs-api-types'
import { ONE_SECOND_MS } from '../../constants/time'
import { formatChannel } from '../tv/tv.format'

function formatCatchupSheduleType(type: string): ScheduleType {
  if (type === 'HOURLY') return ScheduleType.HOURLY
  return ScheduleType.EPG
}

export const formatCatchupChannel = ({
  id,
  days,
  scheduleType,
  sortOrder,
  channel,
}: CatchupDto_CatchupListView): Catchup => {
  return {
    id: id!,
    days: days!,
    scheduleType: formatCatchupSheduleType(scheduleType!),
    sortOrder: sortOrder!,
    channel: formatChannel(channel!),
  }
}

export const formatCatchupsProgram = ({
  id = 0,
  name = '',
  startSec,
  endSec,
}: ProgrammeDto): CatchupProgram => {
  const startMs = startSec! * ONE_SECOND_MS
  const endMs = endSec! * ONE_SECOND_MS
  const dateStartMs = +new Date(startMs).setHours(0, 0, 0, 0)
  return {
    id,
    startMs,
    endMs,
    name,
    dateStartMs,
  }
}

export const formatProgramsArrayToObject = (
  programsList: CatchupProgram[]
): CatchupProgramsWithDates => {
  const programs: CatchupProgramsWithDates = {}
  for (const program of programsList) {
    const date = program.dateStartMs
    if (programs[date] == null) {
      programs[date] = []
    }
    programs[date].push(program)
  }
  return programs
}

const getDateList = (programs: CatchupProgramsWithDates): number[] => {
  return Object.keys(programs)
    .map((program) => +program)
    .sort((a, b) => b - a)
}

export const getProgramsAndDateListFromProgramsArray = (
  programsList: CatchupProgram[]
): {
  programs: CatchupProgramsWithDates
  dateList: number[]
} => {
  const programs = formatProgramsArrayToObject(programsList)
  const dateList = getDateList(programs)
  return {
    programs,
    dateList,
  }
}
