import type { API } from '@setplex/tria-api'
import {
  type ApiAnswerListPaymentChannelInfo,
  type ApiAnswerPurchaseDto,
} from '@setplex/wbs-api-types'
import { type HttpClient } from '../../http'
import { type AdapterDefaults } from '../../index.h'
import {
  formatPaymentChannels,
  formatPurchaseProcess,
} from './purchases.format'

export function use(
  http: HttpClient,
  purchases: API['purchases'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  // POST /v3/purchases/payment
  purchases.initializePurchaseProcessFx.use(async (data) => {
    const response = await http.post<ApiAnswerPurchaseDto>(
      '/v3/purchases/payment',
      {
        json: data,
      }
    )
    return formatPurchaseProcess(response.payload || {})
  })

  // GET /v3/purchases/payment/channels
  purchases.getListPaymentChannelsFx.use(async () => {
    const response = await http.get<ApiAnswerListPaymentChannelInfo>(
      '/v3/purchases/payment/channels'
    )
    return formatPaymentChannels(response.payload)
  })
}
