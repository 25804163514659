import { exists } from '@/helpers'
import { logger } from '@/logger'
import { useNearestChild } from 'its-fine'
import { memo, type ComponentType, type MemoExoticComponent } from 'react'
import { type SkinView } from '../../index.h'
import {
  key,
  useData,
  useEvents,
  type DataRecord,
  type OnsRecord,
  type Scope,
} from '../core'
import { type Recursive } from './Recursive'

// debug flag
const debug =
  process.env.NODE_ENV !== 'production' &&
  typeof document !== 'undefined' &&
  globalThis.debugExact

export type AliasedSkinView = SkinView & Required<Pick<SkinView, 'alias'>>

interface Props {
  skin: AliasedSkinView
  parent: Scope
  Component: ComponentType<any>
  Recursive: Recursive
  [key: string]: unknown // any additional props, will be propogated to all children
}

// exports memoized component
export const Exact: MemoExoticComponent<typeof _Exact> = memo(_Exact)

// _Exact.whyDidYouRender = true
_Exact.displayName = 'Exact'
function _Exact({
  skin,
  parent,
  Component,
  Recursive,
  ...props
}: Props): JSX.Element | null {
  const data: DataRecord | undefined = useData(skin.alias, skin.data, parent)
  const ons: OnsRecord | undefined = useEvents(skin.alias, skin.on, parent)

  // prettier-ignore
  if (debug) {
    // this is ok, because skin is static and never changes on the fly
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const child = useNearestChild()
    logger.groupCollapsed(
      `🌠 %ccomponent%c ${skin.alias}`,
      'background:lightyellow;border-radius:3px;padding:0 2px;color:black;font-weight:normal',
      'font-weight:normal'
    )
    logger.log('💅', skin)
    logger.log('🏠', child)
    if (data && Object.keys(data).length > 0) logger.log('📦', data)
    if (ons && Object.keys(ons).length > 0) logger.log('🪝', ons)
    logger.log('🧅', parent)
    logger.groupEnd()
  }

  return (
    <Component key={key(skin)} {...props} {...data} {...ons} {...skin.styles}>
      {skin.components && skin.components.length > 0
        ? skin.components
            // choose only components without explicit `as` type or with `as: child`
            .filter((skin) => !skin.as || skin.as === 'child')
            // recursively render each subcomponent in components list
            .map((skin, i) => {
              return (
                <Recursive
                  {...props}
                  key={key(skin, i)}
                  skin={skin}
                  parent={parent}
                />
              )
            })
        : data?.content ?? null}
    </Component>
  )
}

export function hasAlias(skin: SkinView): skin is AliasedSkinView {
  return exists(skin.alias)
}
