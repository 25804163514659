import { api } from '@setplex/tria-api'
import { attach, createEvent, createStore, sample } from 'effector'
import { features } from '~/core/flags'
import { model as session } from '~/entities/session'
import { aye, nay } from '~/shared/helpers'
import { formatAppTheme } from './lib'

// Needed for first app loading for correct initialisation
const getThemeFx = attach({
  effect: (flags) => {
    if (nay(flags.appearanceCustomizationEnabled)) {
      throw new Error('appearanceCustomizationEnabled is falsy')
    }

    return api.appearance.getColorSchemeFx()
  },
  source: features.$featuredFlags,
})

export const $appTheme = createStore<React.CSSProperties | null>(null)

sample({
  source: { session: session.$session, features: features.$featuredFlags },
  filter: ({ features, session }) =>
    aye(features.appearanceCustomizationEnabled) && aye(session),
  target: getThemeFx,
})

sample({
  clock: getThemeFx.doneData,
  fn: formatAppTheme,
  target: $appTheme,
})

// reinits theme
const reinitTheme = createEvent()

sample({
  clock: session.$session,
  filter: nay,
  target: reinitTheme,
})

sample({
  clock: session.$expired,
  filter: aye,
  target: reinitTheme,
})

sample({
  clock: reinitTheme,
  target: $appTheme.reinit,
})
