import {
  PaymentEnvEnum,
  PurchasePaymentChannelEnum,
  type IPaymentListChannels,
  type IPurchaseInvoice,
  type IPurchasePayment,
  type IPurchasePaymentItem,
  type IPurchasesPaymentInfo,
  type PaymentStatus,
} from '@setplex/tria-api'
import {
  type ApiAnswerPurchaseDto,
  type PaymentChannelInfo,
} from '@setplex/wbs-api-types'

// TODO: Replace string with wbs type after wbs fix
const formatPaymentInfoEnvironment = (environment: string): PaymentEnvEnum => {
  if (environment === 'production') return PaymentEnvEnum.production

  return PaymentEnvEnum.sandbox
}

export const formatPaymentChannels = (
  payload?: PaymentChannelInfo[]
): IPaymentListChannels => {
  const payloadRequired = payload as Required<PaymentChannelInfo>[]
  if (!payload) return []

  return payloadRequired.map((paymentInfo) => {
    return {
      ...paymentInfo,
      paymentChannel: PurchasePaymentChannelEnum[paymentInfo.paymentChannel!],
      environment: formatPaymentInfoEnvironment(paymentInfo.environment!),
    }
  })
}

export const formatPurchaseProcess = (
  payload: ApiAnswerPurchaseDto['payload']
): IPurchasesPaymentInfo => ({
  orderNumber: payload!.orderNumber as string,
  placementTime: payload!.placementTime as string,
  status: payload!.status as PaymentStatus,
  payment: payload!.payment as IPurchasePayment,
  invoice: payload!.invoice as IPurchaseInvoice,
  items: payload!.items as Array<IPurchasePaymentItem>,
  totalPrice: payload!.totalPrice as number,
  currency: payload!.currency as string,
})
