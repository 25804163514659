import { createEffect, sample } from 'effector'
import { http } from '../events'
import { type RequestInput, type RequestOutput } from './io'

export * from './io'

export const fx = createEffect<RequestInput, RequestOutput, never>()

sample({
  clock: fx,
  fn: (input) => ({ input }),
  target: http.request,
})

sample({
  clock: fx.done,
  filter: ({ result }) => !!result.response,
  fn: ({ params, result }) => ({
    input: params,
    response: result.response as Response,
    error: result.error,
    json: result.json,
  }),
  target: http.response,
})

sample({
  clock: fx.done,
  filter: ({ result }) => !result.response,
  fn: ({ params, result }) => ({
    input: params,
    error: result.error,
  }),
  target: http.error,
})
