export interface FormattedPriceSettings {
  priceSettingsId?: number
  type?: PriceSettingsType
  paymentMethod?: PriceSettingsPaymentMethod
  price?: number
  currency?: string
  availabilityPeriodLength?: number
  availabilityPeriodTimeUnit?: PriceSettingsAvailabilityPeriodTimeUnit
  bundle?: {
    id?: number
    externalId?: string
    name?: string
  }
  formattedType?: PriceSettingsFormattedType
  priceString?: string
  text: string
}

export enum PriceSettingsFormattedType {
  Buy = 'BUY',
  Rent = 'RENT',
}

export enum PriceSettingsType {
  Purchase = 'PURCHASE',
  Rent = 'RENT',
}

export enum PriceSettingsPaymentMethod {
  PerItem = 'PER_ITEM',
  PerSet = 'PER_SET',
}

export enum PriceSettingsAvailabilityPeriodTimeUnit {
  Day = 'DAY',
  Month = 'MONTH',
  Year = 'YEAR',
}
