import { exists } from '@/helpers'
import { memo, type MemoExoticComponent } from 'react'
import { type SkinView } from '../../index.h'
import { key, type Scope } from '../core'
import { type Recursive } from './Recursive'

export type ComponentedSkinView = SkinView &
  Required<Pick<SkinView, 'components'>>

interface Props {
  skin: ComponentedSkinView
  parent: Scope
  Recursive: Recursive
  [key: string]: unknown // any additional props, will be propogated to all children
}

// exports memoized component
export const Fragment: MemoExoticComponent<typeof _Fragment> = memo(_Fragment)

// _Fragment.whyDidYouRender = true
_Fragment.displayName = 'Fragment'
function _Fragment({
  skin,
  parent,
  Recursive,
  ...props
}: Props): JSX.Element | null {
  return skin.components && skin.components.length > 0 ? (
    <>
      {skin.components
        // choose only components without explicit `as` type or with `as: child`
        .filter((skin) => !skin.as || skin.as === 'child')
        // recursively render each subcomponent in components list
        .map((skin, i) => {
          return (
            <Recursive
              {...props}
              key={key(skin, i)}
              skin={skin}
              parent={parent}
            />
          )
        })}
    </>
  ) : null
}

export function hasComponents(skin: SkinView): skin is ComponentedSkinView {
  return exists(skin.components)
}
