import { model as i18nModel } from '!/i18n'
import { combine } from 'effector'
import { USERNAME_CHARS_MAX_COUNT, USERNAME_CHARS_MIN_COUNT } from './index.h'

export const $createProfileTranslations = combine(i18nModel.$t, ({ t }) => {
  return {
    createProfileTitle: t('profile_create'),
    editProfileTitle: t('profile_edit'),
    username: {
      title: t('profile_name'),
      placeholder: t('profile_name_enter'),
      errors: {
        minLength: t('profile_name_length_error', {
          min_length: USERNAME_CHARS_MIN_COUNT,
          max_length: USERNAME_CHARS_MAX_COUNT,
        }),
        maxLength: t('profile_name_length_error', {
          min_length: USERNAME_CHARS_MIN_COUNT,
          max_length: USERNAME_CHARS_MAX_COUNT,
        }),
        alreadyExists: t('same_profile_name_error'),
        required: t('profile_name_length_error', {
          min_length: USERNAME_CHARS_MIN_COUNT,
          max_length: USERNAME_CHARS_MAX_COUNT,
        }),
        withoutSpecSymbols: t('profile_name_error'),
      },
    },
    usernamePlaceholder: t('profile_name_enter'),
    save: t('save'),
    cancel: t('cancel'),
  }
})
