import { type SkinView } from '../../../index.h'
import { express, type Scope } from '../scope'
import { useRaw } from './useRaw'

/**
 * Hook to resolve skin `render` field
 * (should component be rendered or no)
 */
export function useRender(skin: SkinView, scope: Scope): boolean {
  if (!('render' in skin)) {
    return true
  }

  if (skin.render === undefined) {
    return false
  }

  if (typeof skin.render !== 'string') {
    return Boolean(skin.render)
  }

  const raw = express(skin.render, scope)

  // this is ok, because skin is static and never changes on the fly
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const resolved = useRaw(raw)

  return Boolean(Array.isArray(resolved) ? resolved.length : resolved)
}
