import { isTimeoutError } from '@setplex/merr'

/*
 * Client retry policy documentation:
 * https://setplexapps.atlassian.net/wiki/spaces/MW/pages/2682454084/Client+App+Request+Policy
 *
 * Based on Firebase SDK backoff calculation methof:
 * https://github.com/firebase/firebase-js-sdk/blob/7481098d47d14acce901fa4c065ceff0cbf07d3d/packages/util/src/exponential_backoff.ts
 *
 * Which is based on backoff method from Google Closure Library:
 * https://github.com/google/closure-library/blob/master/closure/goog/math/exponentialbackoff.js
 */

/**
 * List of retriable errors:
 *  - 408 Request Timeout
 *  - 413 Content Too Large
 *  - 429 Too Many Requests
 *  - 500 Internal Server Error
 *  - 502 Bad Gateway
 *  - 503 Service Unavailable
 *  - 504 Gateway Timeout
 */
const RETRIABLE_ERRORS = [408, 413, 429, 500, 502, 503, 504]

/**
 * List of retriable http methods
 * Until backend doesn't support idempotency, retries only applies to default idempotent requests
 * https://developer.mozilla.org/en-US/docs/Glossary/Idempotent
 */
const RETRIABLE_METHODS = ['GET', 'PUT', 'HEAD', 'DELETE', 'OPTIONS', 'TRACE']

/**
 * Retries count (1 means -> 1 request + 1 retry)
 */
const RETRY_LIMIT = 1

/**
 * The amount of milliseconds to exponentially increase
 */
const INTERVAL_MILLIS = 500 // 0.5 sec

/**
 * The factor to backoff by
 */
const BACKOFF_FACTOR = 2

/**
 * The maximum milliseconds to increase to
 */
const BACKOFF_LIMIT_MILLIS = 10 * 60 * 1000 // 10 min

/**
 * The percentage of backoff time to randomize by
 */
const RANDOM_FACTOR = 0.2

/* eslint-disable max-params */
export function retry(
  attempt: number,
  minDelay: number,
  request?: Request,
  response?: Response,
  error?: Error
): number | false {
  // do not retry on timeout error
  // maybe change in future
  if (isTimeoutError(error)) {
    return false
  }

  // do not retry on non-retriable http response codes
  if (response && !RETRIABLE_ERRORS.includes(response.status)) {
    return false
  }

  // do not retry on non-retriable http methods
  if (request && !RETRIABLE_METHODS.includes(request.method.toUpperCase())) {
    return false
  }

  // limit retries count
  if (attempt >= RETRY_LIMIT) {
    return false
  }

  // calculate exponentially increasing backoff delay otherwise
  const base = INTERVAL_MILLIS * BACKOFF_FACTOR ** attempt

  // random `fuzz` to avoid waves of retries
  const fuzz = Math.round(
    base *
      RANDOM_FACTOR * // fraction of the backoff value to add/subtract
      ((Math.random() - 0.5) * 2) // random float in the range [-1, 1], determines if we add or subtract
  )

  // limits backoff to max to avoid effectively permanent backoff
  // also limits backoff to min to avoid very small delays (shorter that `Retry-After` header asks)
  const delay = Math.max(minDelay, Math.min(BACKOFF_LIMIT_MILLIS, base + fuzz))

  // if calculated delay is bigger than BACKOFF_LIMIT_MILLIS, then don't retry
  if (delay > BACKOFF_LIMIT_MILLIS) {
    return false
  }

  return delay
}
