import { model as i18nModel } from '!/i18n'
import { register } from '!/repository'
import { model as config, remote } from '@/config'
import { combine, createEffect, createEvent, restore, sample } from 'effector'
import { formatRemoteLanguages } from './lib'

export const init = createEvent()

const parseFx = createEffect<string, Array<{ id: string; value: string }>>(
  formatRemoteLanguages
)

const $remoteLanguages = config.get(remote.tria_languages)

export const $fBLanguages = restore(parseFx.doneData, null)
export const $languages = combine(
  $fBLanguages,
  i18nModel.$languagesFromNora,
  i18nModel.$noraLocalizationEnabled,
  (fBLanguages, languagesFromNora, noraLocalizationEnabled) => {
    if (noraLocalizationEnabled != null) {
      return noraLocalizationEnabled ? languagesFromNora : fBLanguages
    }

    return null
  }
)

const $activeLanguage = combine(
  $languages,
  i18nModel.$locale,
  (languages, activeLanguage) =>
    languages?.find(({ id }) => id === activeLanguage) ?? null
)

sample({
  clock: $remoteLanguages,
  target: parseFx,
})

register($languages, '$languages')
register(i18nModel.languageChange, 'changeLanguage')
register($activeLanguage, '$activeLanguage')
