import type { TvShow } from '@setplex/tria-api'
import { api } from '@setplex/tria-api'
import { createEvent, sample, type Event } from 'effector'
import { condition, not } from 'patronum'

type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U

interface ICWatching {
  tvShow: TvShow
  seasonId?: number | null
  episodeId: number
  stoppedTime: number
}
interface ICWatchingFormatted {
  tvShowId: number
  isWithSeason: boolean
  seasonId?: number | null
  episodeId: number
  stoppedTime: number
}

interface ICWatchingRemove {
  tvShow: TvShow
  seasonId?: number | null
  episodeId: number
}
interface ICWatchingRemoveFormatted {
  tvShowId: number
  isWithSeason: boolean
  seasonId?: number | null
  episodeId: number
}

type IUpdateShow = Omit<ICWatching, 'stoppedTime'>
type IUpdateShowFormat = Omit<ICWatchingFormatted, 'stoppedTime'>

export const updateShow = createEvent<IUpdateShow>()

const updateShowFormat: Event<IUpdateShowFormat> =
  updateShow.map<IUpdateShowFormat>(
    ({ tvShow: { id, isWithSeason }, seasonId, episodeId }) => ({
      tvShowId: id,
      seasonId,
      episodeId,
      isWithSeason: Boolean(isWithSeason),
    })
  )

export const updateTime = createEvent<ICWatching>()

const updateTimeFormat: Event<ICWatchingFormatted> =
  updateTime.map<ICWatchingFormatted>(
    ({ tvShow: { id, isWithSeason }, stoppedTime, seasonId, episodeId }) => ({
      tvShowId: id,
      seasonId,
      episodeId,
      isWithSeason: Boolean(isWithSeason),
      stoppedTime,
    })
  )

const updateTimeWithSeason =
  createEvent<Overwrite<ICWatchingFormatted, { seasonId: number }>>()

const updateTimeWOSeason = createEvent<ICWatchingFormatted>()

condition({
  source: updateTimeFormat,
  if: ({ isWithSeason }) => isWithSeason,
  then: updateTimeWithSeason,
  else: updateTimeWOSeason,
})

sample({
  clock: updateTimeWithSeason,
  filter: not(api.tvshows.postMarkEpisodeWatchedFx.pending),
  target: api.tvshows.updateContinueWatchingTvShowFx,
})

sample({
  clock: updateTimeWOSeason,
  filter: not(api.tvshows.postMarkEpisodeWatchedWithoutSeasonFx.pending),
  target: api.tvshows.updateContinueWatchingWithoutSeasonFx,
})

const updateShowWithSeason =
  createEvent<Overwrite<ICWatchingFormatted, { seasonId: number }>>()

const updateShowWOSeason = createEvent<ICWatchingFormatted>()

condition({
  source: updateShowFormat,
  if: ({ isWithSeason }) => isWithSeason,
  then: updateShowWithSeason,
  else: updateShowWOSeason,
})

sample({
  clock: updateShowWithSeason,
  target: api.tvshows.postMarkEpisodeWatchedFx,
})

sample({
  clock: updateShowWOSeason,
  target: api.tvshows.postMarkEpisodeWatchedWithoutSeasonFx,
})

export const removeTime = createEvent<ICWatchingRemove>()

const removeTimeFormat: Event<ICWatchingRemoveFormatted> =
  removeTime.map<ICWatchingRemoveFormatted>(
    ({ tvShow: { id, isWithSeason }, seasonId, episodeId }) => ({
      tvShowId: id,
      seasonId,
      episodeId,
      isWithSeason: Boolean(isWithSeason),
    })
  )

const removeTimeWithSeason =
  createEvent<Overwrite<ICWatchingRemoveFormatted, { seasonId: number }>>()

const removeTimeWOSeason = createEvent<ICWatchingRemoveFormatted>()

condition({
  source: removeTimeFormat,
  if: ({ isWithSeason }) => isWithSeason,
  then: removeTimeWithSeason,
  else: removeTimeWOSeason,
})

sample({
  clock: removeTimeWOSeason,
  target: api.tvshows.removeContinueWatchingWithoutSeasonFx,
})

sample({
  clock: removeTimeWithSeason,
  target: api.tvshows.removeContinueWatchingTvShowFx,
})
