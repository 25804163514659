import { createEvent, sample } from 'effector'
import { model as session } from '~/entities/session'
import { model as subscriberEntitiesModel } from '~/entities/subscriber'
import { $mode } from './guest/model'

export const init = createEvent()

// in case session was changed, and this is not a guest
//   -> request subscriber information
sample({
  clock: session.$session,
  source: {
    isAuthenticated: session.$authenticated,
    toaAccepted: session.$toaAccepted,
    isExpired: session.$expired,
    authMode: $mode,
  },
  filter: ({ isAuthenticated, toaAccepted, isExpired, authMode }, session) =>
    isAuthenticated &&
    session != null &&
    authMode === 'user' &&
    !isExpired &&
    toaAccepted,
  target: subscriberEntitiesModel.getSubscriberFx,
})
