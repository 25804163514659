import { F } from '@mobily/ts-belt'
import { createEvent, createStore, sample, split } from 'effector'
import { not } from 'patronum'
import { model as session } from '~/entities/session'
import { model as guest } from '~/features/subscriber/guest'
import {
  model as signIn,
  signInByTrueIdFeatureModel as trueid,
} from '~/features/subscriber/sign-in'
import { keys } from '~/shared/jail'
import { type Cage, type Transit } from '../index.h'

/*
 * ### Hallway cage ###
 * App goes here almost every time, when it is needed to decide where to go next.
 */

const init = createEvent<Transit>('init')
const $active = createStore(false)

// in events
const sessionChanged = createEvent('sessionChanged')

// out events
const activeGuestSession = createEvent('activeGuestSession')
const activeSubscriberSession = createEvent('activeSubscriberSession')
const shouldAcceptToa = createEvent('shouldAcceptToa')
const shouldBuySubscription = createEvent('shouldBuySubscription')
const shouldSignInByTrueid = createEvent('shouldSignInByTrueid')
const shouldSignInAsGuest = createEvent('shouldSignInAsGuest')
const shouldSignIn = createEvent('shouldSignIn')

export const hallway = {
  name: 'hallway',
  key: keys.lost,
  init,
  $active,
  in: [sessionChanged],
  out: {
    activeGuestSession,
    activeSubscriberSession,
    shouldAcceptToa,
    shouldBuySubscription,
    shouldSignInByTrueid,
    shouldSignInAsGuest,
    shouldSignIn,
  },
} satisfies Cage

/*
 * cage logic
 */

const enum OUT {
  ACTIVE_GUEST_SESSION,
  ACTIVE_SUBSCRIBER_SESSION,
  SHOULD_ACCEPT_TOA,
  SHOULD_BUY_SUBSCRIPTION,
  SHOULD_SIGN_IN_BY_TRUEID,
  SHOULD_SIGN_IN_AS_GUEST,
  SHOULD_SIGN_IN,
  __SHOULD_SIGN_OUT__, // intermediate, not out event
}

const out = createEvent<OUT>()

split({
  source: out,
  match: F.identity,
  cases: {
    [OUT.ACTIVE_GUEST_SESSION]: activeGuestSession,
    [OUT.ACTIVE_SUBSCRIBER_SESSION]: activeSubscriberSession,
    [OUT.SHOULD_ACCEPT_TOA]: shouldAcceptToa,
    [OUT.SHOULD_BUY_SUBSCRIPTION]: shouldBuySubscription,
    [OUT.SHOULD_SIGN_IN_BY_TRUEID]: shouldSignInByTrueid,
    [OUT.SHOULD_SIGN_IN_AS_GUEST]: shouldSignInAsGuest,
    [OUT.SHOULD_SIGN_IN]: shouldSignIn,
  },
})

//
// check trueid session status, if enabled
//

const decide = createEvent()

split({
  source: init,
  match: trueid.$enabled.map(String),
  cases: {
    false: decide,
    true: trueid.trueIdGetStatusFx,
  },
})

sample({
  clock: trueid.trueIdGetStatusFx.finally,
  target: decide,
})

//
// decide where to go next
//

sample({
  clock: decide,
  source: {
    isGuestEnabled: guest.$enabled,
    isTrueidEnabled: trueid.$enabled,
    isTrueidAuthed: trueid.$isTrueIdAuthed,
    isAuthenticated: session.$authenticated,
    isToaAccepted: session.$toaAccepted,
    isExpired: session.$expired,
    isGuest: guest.$guest,
  },
  fn: ({
    isGuestEnabled,
    isTrueidEnabled,
    isTrueidAuthed,
    isAuthenticated,
    isToaAccepted,
    isExpired,
    isGuest,
  }) => {
    if (isAuthenticated) {
      if (isExpired) {
        return OUT.SHOULD_BUY_SUBSCRIPTION
      }

      if (isGuest) {
        return OUT.ACTIVE_GUEST_SESSION
      }

      if (isTrueidEnabled && !isTrueidAuthed) {
        return OUT.__SHOULD_SIGN_OUT__
      }

      if (isToaAccepted) {
        return OUT.ACTIVE_SUBSCRIBER_SESSION
      }

      return OUT.SHOULD_ACCEPT_TOA
    }

    if (isTrueidEnabled) {
      return OUT.SHOULD_SIGN_IN_BY_TRUEID
    }

    if (isGuestEnabled) {
      return OUT.SHOULD_SIGN_IN_AS_GUEST
    }

    return OUT.SHOULD_SIGN_IN
  },
  target: out,
})

sample({
  clock: out,
  filter: (reason) => reason === OUT.__SHOULD_SIGN_OUT__,
  target: signIn.signOut,
})

// on any session change -> open hallway
sample({
  clock: [session.$session, session.$authenticated],
  filter: not($active),
  target: sessionChanged,
})

// or redecide, in case hallway is already active
sample({
  clock: [session.$session, session.$authenticated],
  filter: $active,
  target: decide,
})
