import * as Sentry from '@sentry/browser'

export const COUNT_OF_PREVIOUS_ROUTES_IN_BREADCRUMBS = 5
export const UNKNOWN_PREVIOUS_LOCATION = '~ unknown ~'

export type SentryCaptureExceptionParams = Parameters<
  typeof Sentry.captureException
>
export type SentryCaptureMessageParams = Parameters<
  typeof Sentry.captureMessage
>

export type CaptureExceptionParams =
  | SentryCaptureExceptionParams
  | SentryCaptureExceptionParams[0] // this is actually `any` and whole type is `any` in result

export type CaptureMessageParams =
  | SentryCaptureMessageParams
  | SentryCaptureMessageParams[0]
