export const WINDOW_MOBILE_MAX_SIZE_IN_PX = 599

export const WINDOW_TABLET_MIN_SIZE_IN_PX = 600

export const WINDOW_TABLET_MAX_SIZE_IN_PX = 1199

export const WINDOW_DESKTOP_MIN_SIZE_IN_PX = 1200

export enum DeviceType {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
}
