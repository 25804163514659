import { model as config, remote } from '@/config'
import type { NpawPlayerOptions } from '@setplex/player'
import { createEffect, createEvent, createStore, sample } from 'effector'
import * as v from 'valibot'
import { model as session } from '~/entities/session'
import { aye, nay } from '~/shared/helpers'
import { logger } from '~/shared/logger'
import { createNpawAnalyticsOptions } from './helpers'
import { NpawConfigSchema, type NpawConfig } from './schemas'

export const init = createEvent()

//
// NPAW configuration
//

const $stringifiedConfig = config.get(remote.tria_playerNpawPluginConfig)
const $config = createStore<NpawConfig | null>(null)

const parseConfigFx = createEffect((config?: string | null) => {
  return typeof config === 'string' && config !== ''
    ? v.parse(NpawConfigSchema, JSON.parse(config))
    : null
})

sample({
  clock: [init, $stringifiedConfig],
  source: $stringifiedConfig,
  target: parseConfigFx,
})

sample({
  clock: parseConfigFx.doneData,
  target: $config,
})

sample({
  clock: parseConfigFx.fail,
  fn: (fail) => ['fail to parse npaw configuration:', fail],
  target: logger.errorFx,
})

//
// NPAW plugin configuration
// combined with default NPAW data
// for the player
//

export const $playerNpaw = createStore<NpawPlayerOptions | null>(null)

// update on every session or config change
sample({
  source: {
    session: session.$session,
    config: $config,
  },
  filter: ({ config, session }) =>
    aye(config) && aye(session) && config!.enabled,
  fn: ({ config, session }) => {
    const { balancerOptions, accountCode } = config!
    return {
      accountCode,
      balancerOptions,
      analyticsOptions: createNpawAnalyticsOptions(session!),
    } satisfies NpawPlayerOptions
  },
  target: $playerNpaw,
})

// reset on empty config or empty session
sample({
  source: {
    session: session.$session,
    config: $config,
  },
  filter: ({ config, session }) =>
    nay(config) || nay(session) || nay(config!.enabled),
  target: $playerNpaw.reinit,
})
