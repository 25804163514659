import type { API } from '@setplex/tria-api'
import {
  type ApiAnswerListCatchupDto_CatchupListView,
  type ApiAnswerListProgrammeDto,
  type ApiAnswerMediaUrlDto,
} from '@setplex/wbs-api-types'
import type { HttpClient } from '../../http'
import type { AdapterDefaults } from '../../index.h'
import { formatMediaUrl } from '../../utils/mediaUrl'
import {
  formatCatchupChannel,
  formatCatchupsProgram,
  getProgramsAndDateListFromProgramsArray,
} from './catchups.format'

export function use(
  http: HttpClient,
  catchups: API['catchups'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  catchups.getChannelsWithCatchupsFx.use(async () => {
    const json = await http.get<ApiAnswerListCatchupDto_CatchupListView>(
      `/v3/channels/tv/catchups`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in getChannelsWithCatchupsFx')
    }

    return json.payload
      .map(formatCatchupChannel)
      .sort((a, b) => a.sortOrder - b.sortOrder)
  })

  catchups.getChannelCatchupProgramsFx.use(async ({ channelId, catchupId }) => {
    const json = await http.get<ApiAnswerListProgrammeDto>(
      `/v3/channels/tv/${channelId}/catchups/${catchupId}/programmes`
    )
    if (!json || !json.payload) {
      throw new Error('Empty answer in getChannelCatchupProgramsFx')
    }

    const programsList = json.payload.map(formatCatchupsProgram)

    return getProgramsAndDateListFromProgramsArray(programsList)
  })

  catchups.getCatchupUrlFx.use(
    async ({ channelId, catchupId, programmeId }) => {
      const json = await http.get<ApiAnswerMediaUrlDto>(
        `/v3/channels/tv/${channelId}/catchups/${catchupId}/programmes/${programmeId}/url`
      )

      if (!json || !json.payload) {
        throw new Error('Empty answer in getCatchupUrlFx')
      }

      return formatMediaUrl(json.payload)
    }
  )
}
