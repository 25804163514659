import { type Raw } from './raw'

export type Scope = {
  [name: string]: Raw | null
}

/**
 * Create new descendant scope
 *
 * Scopes uses prototype mechanism to make ancestor→descendant relations
 * Prototype chain ends with null prototype (scopes do not inherit `Object` prototype)
 *
 * [descendant scope]
 *   ↳ [prototype: ancestor scope]
 *       ↳ [prototype: ancestor scope]
 *           ↳ ...
 *               ↳ null
 *
 * @param parent - ancestor scope
 * @returns - new descendant scope
 */
export const inherit = (parent?: Scope): Scope => {
  if (!parent) return Object.create(null)
  return mean(parent) ? Object.create(parent) : parent
}

/**
 * Extends scope with values,
 * does not change scope prototype chain (= ancestor scopes)
 *
 * @param source - source scope with values
 * @param target - target (extended) scope
 * @returns - target scope
 */
export const extend = (source: Scope, target: Scope): Scope =>
  Object.assign(target, source)

/*
 * Helpers
 */

/**
 * Check if scope is meaningful
 * @param scope - scope to check
 * @returns `true` if scope is meaningful (not empty), `false` otherwise
 */
function mean(scope: Scope): boolean {
  for (const name in scope) {
    if (Object.prototype.hasOwnProperty.call(scope, name)) {
      return true
    }
  }
  return false
}
