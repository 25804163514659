import { combine } from 'effector'
import { firebaseSharedModel as firebase } from '~/shared/firebase'
import { defaults } from './defaults'
import { $config } from './model'

export { remote } from './defaults'
export * as model from './model'

// export firebase remote config values as static variable,
// in case it is needed somewhere in imperative logic.
// ⚠️ DO NOT ABUSE
export const config = defaults

// eslint-disable-next-line effector/no-watch
combine({
  config: $config,
  properties: firebase.analytics.$customParams,
}).watch(({ config: c, properties }) => {
  Object.assign(config, c, { __properties__: properties })
})
