import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector'

import type { ApiEffect } from '../index.h'
import { type MediaUrl } from '../interfaces/generic'
import { type PlayerUrlError } from '../interfaces/player'
import { getCatchupUrlFx } from './catchups'
import { getLiveEventUrlByIdFx } from './live-events'
import { getTvChannelUrlFx } from './tv'
import {
  getEpisodeUrlWithoutSeasonFx,
  getTvShowEpisodeUrlFx,
  getTvShowTrailerUrlFx,
} from './tvshows'
import { getTrailerUrlFx, getUrlFx } from './vods'

export const loadVodUrl = attach({
  effect: getUrlFx,
})

export const loadChannelUrlFx = attach({
  effect: getTvChannelUrlFx,
})

export const loadEpisodeUrlPlayer: ApiEffect<
  { tvShowId: number; seasonId?: number; episodeId: number },
  MediaUrl
> = createEffect()

loadEpisodeUrlPlayer.use(async (params) => {
  if (params.seasonId === undefined || params.seasonId === null) {
    // no seasons case
    return await getEpisodeUrlWithoutSeasonFx(params)
  }
  // @ts-ignore
  return await getTvShowEpisodeUrlFx(params)
})

// TODO names below missing 'Fx'

export const loadShowTrailerUrl = attach({
  effect: getTvShowTrailerUrlFx,
})

export const loadVodTrailerUrl = attach({
  effect: getTrailerUrlFx,
})

export const loadLiveEventUrlFx = attach({
  effect: getLiveEventUrlByIdFx,
})

export const loadLiveEventUrlEvent = createEvent<{
  eventId: number
  startTime?: number
}>()

sample({
  clock: loadLiveEventUrlEvent,
  target: loadLiveEventUrlFx,
})

export const loadCatchupUrlFx = attach({ effect: getCatchupUrlFx })

// *
// * STORES
// *

// TODO each effect could have its own store, but current flow allows - one generic store

export const $currentPlayerPageUrl = createStore<MediaUrl | null>(null)
  .on(
    [
      loadVodUrl.doneData,
      loadChannelUrlFx.doneData,
      loadEpisodeUrlPlayer.doneData,
      loadShowTrailerUrl.doneData,
      loadVodTrailerUrl.doneData,
      loadLiveEventUrlFx.doneData,
      loadCatchupUrlFx.doneData,
    ],
    (_, urlObject) => urlObject
  )
  .reset([
    loadVodUrl,
    loadChannelUrlFx,
    loadEpisodeUrlPlayer /* Store reset may be later than you expect */,
    loadShowTrailerUrl,
    loadVodTrailerUrl,
    loadLiveEventUrlFx,
    loadCatchupUrlFx,
  ])
export const resetCurrentPlayerPageUrlError = createEvent()
export const $currentPlayerPageUrlError = createStore<PlayerUrlError | null>(
  null
)
  .on(
    [
      getUrlFx.fail,
      getTvChannelUrlFx.fail,
      getEpisodeUrlWithoutSeasonFx.fail,
      getTvShowEpisodeUrlFx.fail,
      getTvShowTrailerUrlFx.fail,
      getTrailerUrlFx.fail,
      getLiveEventUrlByIdFx.fail,
      getCatchupUrlFx.fail,
    ],
    (_, { error }) => {
      return {
        code: error.error?.response?.status,
        message: error.error?.response?.statusText,
        errorCode: error.payload?.errorCode || '',
      }
    }
  )
  .reset([
    getUrlFx,
    getTvChannelUrlFx,
    getEpisodeUrlWithoutSeasonFx,
    getTvShowEpisodeUrlFx,
    getTvShowTrailerUrlFx,
    getTrailerUrlFx,
    getLiveEventUrlByIdFx,
    getCatchupUrlFx,
    resetCurrentPlayerPageUrlError,
  ])
