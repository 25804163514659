import type { API, LogicError } from '@setplex/tria-api'
import { sample, split, type Event } from 'effector'
import { requestFx } from '../http'

export function use(api: API) {
  const logic = api.events.logic
  const anyError = api.events.logic.anyLogicError

  sample({
    source: requestFx.doneData,
    filter: ({ json }) => Boolean(json && json.errorCode), // filter out only logical errors
    fn: ({ json }): LogicError => ({
      errorCode: json!.errorCode!,
      message: json!.message,
      payload: json!.payload,
    }),
    target: anyError,
  })

  split({
    source: anyError,
    match: ({ errorCode }): any => errorCode,

    // prettier-ignore
    /* @formatter:off */
    cases: cases([
      // EC×××× codes -> https://setplexapps.atlassian.net/wiki/spaces/MW/pages/8093697/WBS+Response+Error+Codes
      // WB×××× codes -> https://setplexapps.atlassian.net/wiki/spaces/MW/pages/519700693/WBS+Gateway+Response+Error+codes
      // WG×××× codes -> https://setplexapps.atlassian.net/wiki/spaces/MW/pages/519700693/WBS+Gateway+Response+Error+codes
      [logic.accountIpACLLock,                      [        'WB0051', 'WG0062']],
      [logic.addressNotValid,                       [                  'WG0028']],
      [logic.apiBlocked,                            ['EC300',          'WG0019']],
      [logic.apiSuccess,                            ['EC01',           'WG0001']],
      [logic.authNotAllowedIPAddress,               ['EC50',           'WG0017']],
      [logic.authorizationPaymentTransactionFailed, ['EC50', 'WB0015', 'WG0017']],
      [logic.badCredentials,                        ['EC10',           'WG0008']],
      [logic.capturePaymentTransactionFailed,       [        'WB0017'          ]],
      [logic.cartLimitExceeded,                     [        'WB0002', 'WG0051']],
      [logic.cartShouldBeChecked,                   [        'WB0004', 'WG0053']],
      [logic.cityNotValid,                          [                  'WG0029']],
      [logic.codeIsNotValid,                        [                  'WG0100']],
      [logic.confirmationCodeInvalid,               [                  'WG0063']],
      [logic.confirmationLinkExpired,               [                  'WG0038']],
      [logic.confirmationLinkNotExist,              [                  'WG0039']],
      [logic.confirmationLinkSettingsNotFound,      [                  'WG0077']],
      [logic.contentGeoLock,                        [        'WB0052'          ]],
      [logic.contentSetItemsChanged,                [        'WB0020'          ]],
      [logic.countryNotValid,                       [                  'WG0031']],
      [logic.couponCannotBeApplied,                 [        'WB0003', 'WG0052']],
      [logic.deviceAlreadyAssigned,                 ['EC02',           'WG0002']],
      [logic.deviceNoSubscriberAssigned,            ['EC12',           'WG0010']],
      [logic.deviceRedirectFailed,                  ['EC14',           'WG0012']],
      [logic.deviceToAnotherSubscriber,             ['EC03',           'WG0003']],
      [logic.deviceTypeNotAllowed,                  ['EC21',           'WG0016']],
      [logic.deviceUnassignFailed,                  ['EC20'                    ]],
      [logic.discountUsageLimitExceeded,            [        'WB0012', 'WG0061']],
      [logic.emailAlreadyConfirmed,                 [                  'WG0037']],
      [logic.emailNotUnique,                        [                  'WG0022']],
      [logic.emailNotValid,                         [                  'WG0024']],
      [logic.enterSecurityCode,                     ['EC15',           'WG0013']],
      [logic.firstNameNotValid,                     [                  'WG0025']],
      [logic.guestModeDisabled,                     [        'WB0065'          ]],
      [logic.inAppRegistrationDisabled,             [                  'WG0066']],
      [logic.internalServerError,                   ['500',  'WB0500', 'WG0500']],
      [logic.invalidPaymentData,                    [        'WB0014'          ]],
      [logic.invalidReferer,                        [                  'WG0021']],
      [logic.invalidRequest,                        [        'WB0400', 'WG0400']],
      [logic.invalidRequestCode,                    ['EC05',           'WG0005']],
      [logic.invoiceCodeInvalid,                    [        'WB0011', 'WG0059']],
      [logic.invoiceCodeRequired,                   [        'WB0008', 'WG0057']],
      [logic.lastNameNotValid,                      [                  'WG0026']],
      [logic.maxNumberDevicesAssigned,              ['EC06',           'WG0006']],
      [logic.maxProfilesCount,                      [                  'WG0400', 'MW0400']], // FIXME: remove MW0400 after updating Nora to all providers
      [logic.noItemsCheckout,                       [        'WB0007', 'WG0054']],
      [logic.noItemsToBuy,                          [        'WB0009', 'WG0058']],
      [logic.noMoreAttemptsOneIp,                   [                  'WG0096']],
      [logic.noMoreAttemptsOnePhoneNumber,          [                  'WG0097']],
      [logic.noMoreCodeRequestsAllowed,             [                  'WG0048', 'WG0067']], // two WG codes
      [logic.noMoreConfirmationAttempts,            [                  'WG0047']],
      [logic.noMoreConfirmationAttemptsAllowed,     [                  'WG0064']],
      [logic.noMoreRequestsForEmailAllowed,         [                  'WG0072']],
      [logic.noMoreRequestsForIPAllowed,            [                  'WG0071']],
      [logic.noMoreVerifyAttempts,                  [                  'WG0099']],
      [logic.notAvailableForPurchase,               [        'WB0001', 'WG0050']],
      [logic.originalPriceChanged,                  [        'WB0013'          ]],
      [logic.packageNotAllowed,                     ['EC04',           'WG0004']],
      [logic.passwordNotMatchRE,                    [                  'WG0081']],
      [logic.passwordNotValid,                      [                  'WG0034']],
      [logic.passwordResetBlockedForAccount,        [                  'WG0069']],
      [logic.passwordResetDisabled,                 [                  'WG0068']],
      [logic.passwordTooLong,                       [                  'WG0079']],
      [logic.passwordTooShort,                      [                  'WG0080']],
      [logic.paymentMethodNotAvailable,             [        'WB0006', 'WG0056']],
      [logic.paymentMethodNotSelected,              [        'WB0005', 'WG0055']],
      [logic.phoneNotValid,                         [                  'WG0027']],
      [logic.phoneNumberNotValid,                   [                  'WG0027']],
      [logic.previousSubscriptionNotExpired,        ['EC07',           'WG0007']],
      [logic.subscriptionHasBeenExpired,            ['EC11',           'WG0009']],
      [logic.accountOrDeviceHasBeenSuspended,       ['EC17',           'WG0015']],
      [logic.redirectSuccessful,                    ['EC13',           'WG0011']],
      [logic.refundPaymentTransactionFailed,        [        'WB0019'          ]],
      [logic.resetPasswordCodeInvalid,              [                  'WG0045']],
      [logic.resetPasswordCodeSessionExpired,       [                  'WG0046']],
      [logic.serverUnderMaintenance,                ['503'                     ]],
      [logic.sessionInvalid,                        [                  'WG0044']],
      [logic.sessionNotExist,                       [                  'WG0098']],
      [logic.signInByPhoneDisabled,                 [                  'WG0094']],
      [logic.signUpByPhoneDisabled,                 [                  'WG0094']],
      [logic.stateNotValid,                         [                  'WG0033']],
      [logic.subscriberMissingProfile,              [        'WB0010', 'WG0060']],
      [logic.subscriberPasswordRequired,            [                  'WG0078']],
      [logic.subscriberUsernameRequired,            [                  'WG0073']],
      [logic.subscriptionNotActive,                 [                  'WG0023']],
      [logic.timezoneNotValid,                      [                  'WG0032']],
      [logic.TOANotAccepted,                        ['EC16',           'WG0014']],
      [logic.tooManyCodeRequests,                   [                  'WG0065', 'WG0070', 'WG0095']], // three WG codes
      [logic.unexpectedError,                       [                  'WG9999']],
      [logic.unhandledError,                        ['__'                      ]], // <- catches all unknown codes
      [logic.unsupportedPlatform,                   [                  'WG0035']],
      [logic.usernameNotNotMatchRE,                 [                  'WG0074']],
      [logic.usernameNotUnique,                     [                  'WG0075']],
      [logic.usernameTooLong,                       [                  'WG0076']],
      [logic.voidPaymentTransactionFailed,          [        'WB0018'          ]],
      [logic.webDeviceInitError,                    [                  'WG0020']],
      [logic.webDeviceNotFound,                     ['EC22'                    ]],
      [logic.yourIPAddressLocked,                   ['EC55',           'WG0018']],
      [logic.ZIPNotValid,                           [                  'WG0030']],
    ]),
  })
}

function cases(events: [Event<LogicError>, string[]][]) {
  const cases: { [code: string]: Event<LogicError> } = {}
  for (const event of events) {
    for (const code of event[1]) {
      cases[code] = event[0]
    }
  }
  return cases
}
