import type { Features, Options } from '!/flags'
import type { SkinViews } from '!/skin'
import { isFunction, isPlainObject, isPromise } from '@/helpers'
import type { Key } from '@/jail'
import { logger } from '@/logger'
import type { Session } from '@setplex/tria-api'
import { type Effect, type Unit } from 'effector'
import { type ComponentClass, type FunctionComponent } from 'react'
import UniversalRouter, {
  type Route,
  type RouteContext,
  type RouteParams,
  type RouterContext,
} from 'universal-router'
import { ResolveLoadError } from './errors'

// re-export from 'universal-router' to lessen dependency, just in case
export { type Route }

/* eslint-disable @typescript-eslint/no-explicit-any */
export type RouterResponse = {
  keys?: Key | Key[]
  filter?: (ctx: {
    options: Options
    features: Features
    session: Session
  }) => boolean | undefined
  title?: string | (() => string) | Array<string | (() => string)>
  component?:
    | FunctionComponent<any>
    | ComponentClass<any>
    | Array<FunctionComponent<any> | ComponentClass<any>>
  skin?: SkinViews | Promise<SkinViews> | (() => SkinViews | Promise<SkinViews>)
  props?: any
  // error?: Error
  wait?: Effect<any, any>
  init?: Unit<any>
  left?: Unit<any>
  redirect?: string
  __resolved?: unknown // actual resolved route, stable by reference
}
/* eslint-enable @typescript-eslint/no-explicit-any */

type RouterResponseEx = RouterResponse | undefined | null

// top-level routes object
const routes: Route = {}

// router instance
export const router: UniversalRouter<RouterResponse> =
  new UniversalRouter<RouterResponse>(routes, { resolveRoute })

/**
 * Re-Instantiate router with new routes
 */
export function init(appRoutes: Route) {
  Object.assign(routes, impregnate(appRoutes))
}

/**
 * Overridden resolve route for universal router,
 * handles dynamic routes and other custom properties
 */
function resolveRoute(
  context: RouteContext<RouterResponse, RouterContext>,
  params: RouteParams
): RouterResponseEx | Promise<RouterResponseEx> {
  const { route, options, features, session } = context

  if (route.load) {
    if (isPlainObject(route.load)) {
      return resolveSyncRoute(context, params)
    }

    return resolveAsyncRoute(context, params).catch((error) => {
      logger.error('🔁', error)

      // bust import cache
      // dynamic imports are caching results, regardless of success or error
      // there is a crunchy ad hoc solution for this
      // see https://medium.com/@alonmiz1234/retry-dynamic-imports-with-react-lazy-c7755a7d557a
      if (/Failed to fetch dynamically imported module/.test(error.message)) {
        // extract url from error
        const url = new URL(
          error.message
            .replace('Failed to fetch dynamically imported module: ', '')
            .trim()
        )
        // add a timestamp to the url to force a reload the module (and not use the cached version - cache busting)
        url.searchParams.set('t', `${Date.now()}`)
        // replace load function for route with the new url
        route.load = () => import(/* @vite-ignore */ url.href)
      }

      // TODO: think about how to handle different errors here
      // I couldn't find any way to distinguish between network errors and server errors here
      // dynamic import will fail with the same error on 404 and when offline :(
      throw new ResolveLoadError(error)
    })
  }

  if (isPromise(route.skin) || isFunction(route.skin)) {
    // TODO: catch error and throw `ResolveSkinError` (synchronously or asynchronously)
    return resolveSkin(context, params)
  }

  if (isFunction(route.action)) {
    const result: RouterResponseEx | Promise<RouterResponseEx> = //
      route.action(context, params)

    return isPromise(result)
      ? Promise.resolve(result).then((result) => merge(context, result))
      : merge(context, result)
  }

  if (isFunction(route.filter)) {
    const result: boolean | undefined = route.filter({
      options,
      features,
      session,
    })
    if (result === false) {
      return null // `null` means skip this and all nested routes, and go to the next sibling route
    }
  }

  if (route.component) {
    return merge(context, {})
  }

  return undefined // undefined means router will try to match the child routes
}

/**
 * Synchronously resolves route for universal router
 */
function resolveSyncRoute(
  context: RouteContext<RouterResponse, RouterContext>,
  params: RouteParams
): RouterResponseEx | Promise<RouterResponseEx> {
  const { route } = context

  const extend = route.load as Route
  delete route.load // load only once
  delete route.children // orphan all children, so universal router could do some optimisations
  Object.assign(route, impregnate(extend)) // but route could contain more loaders

  return resolveRoute(context, params)
}

/**
 * Asynchronously resolves route for universal router,
 * recursively calls `resolveRoute` after async load
 */
async function resolveAsyncRoute(
  context: RouteContext<RouterResponse, RouterContext>,
  params: RouteParams
): Promise<RouterResponseEx> {
  const { route } = context

  const load = isFunction(route.load) ? route.load() : route.load
  const mod = await Promise.resolve(load)

  // extends current context route
  if (mod && 'default' in mod && mod.default) {
    const extend: Route = mod.default
    delete route.load // load only once
    delete route.children // orphan all children, so universal router could do some optimisations
    Object.assign(route, impregnate(extend)) // but route could contain more loaders
  }

  return resolveRoute(context, params)
}

/**
 * Resolve skin object, synchronously or asynchronously
 */
function resolveSkin(
  context: RouteContext<RouterResponse, RouterContext>,
  params: RouteParams
): RouterResponseEx | Promise<RouterResponseEx> {
  const { route } = context

  const skin: SkinViews | Promise<SkinViews> | undefined = //
    isFunction(route.skin) //
      ? route.skin()
      : route.skin

  const resolve = (skin?: SkinViews) => {
    route.skin = skin
    return resolveRoute(context, params)
  }

  return isPromise(skin) //
    ? skin.then(resolve)
    : resolve(skin)
}

/**
 * Adds empty children array to route in case it contains load function
 */
function impregnate(route: Route): Route {
  // has loader and has no children -> add empty children array
  if (route.load && !route.children) {
    route.children = []
  }

  // has children -> recursively impregnate each child
  else if (route.children && route.children.length) {
    for (const child of route.children) {
      impregnate(child)
    }
  }

  return route
}

/**
 * Merges route with `action` function result
 */
function merge(
  context: RouterContext,
  result?: RouterResponseEx
): RouterResponseEx {
  if (result != null) {
    // autogenerate breadcrumbs-like document.title
    // NB! will be overridden in case `action` returns own title
    // and also collect `keys`
    let keys: Key | Key[] | undefined
    const title = []
    let route = context.route
    while (route) {
      if (keys == null) {
        keys = route.keys
      }
      if (route.title) {
        title.unshift(route.title)
      }
      route = route.parent
    }

    // return merged route result
    return {
      keys,
      title,
      component: context.route.component,
      skin: context.route.skin as SkinViews | undefined,
      wait: context.route.wait,
      init: context.route.init,
      left: context.route.left,
      ...result,
      props: {
        query: context.query as URLSearchParams,
        ...context.params,
        ...result.props,
      },
      __resolved: context.route,
    }
  }

  return result // null or undefined
}
