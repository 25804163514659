import { EventStatus, type API, type MediaUrl } from '@setplex/tria-api'
import {
  type ApiAnswerLiveEventDto,
  type ApiAnswerMediaUrlDto,
} from '@setplex/wbs-api-types'
import { DEFAULT_PAGE_SIZE } from '../../constants/generic'
import { type HttpClient } from '../../http'
import { type AdapterDefaults } from '../../index.h'
import type {
  ApiAnswerGetLiveEvents,
  ApiAnswerGetPurchasedLiveEvents,
} from '../../interfaces/live-events'
import { formatLiveEvent } from './live-events.format'

export function use(
  http: HttpClient,
  liveEvents: API['liveEvents'],
  _api: API,
  defaults: AdapterDefaults
): void {
  // GET /v3/live-events
  liveEvents.getLiveEventsFx.use(
    async ({
      page,
      count = defaults.count || DEFAULT_PAGE_SIZE,
      sortOrder = defaults.sortOrder || 'asc',
      sortBy = 'startTime',
      ids,
      title,
      status,
      free,
    }) => {
      const params = new URLSearchParams()
      params.set('page', String(page))
      params.set('count', String(count))
      params.set('status', String(status))
      if (sortOrder) params.set('sortOrder', String(sortOrder))
      if (sortBy) params.set('sortBy', String(sortBy))
      if (ids) params.set('ids', String(ids))
      if (title) params.set('title', String(title))
      if (free) params.set('free', String(free))
      if (status === EventStatus.ENDED) params.set('only-recorded', 'true')

      const json = await http.get<ApiAnswerGetLiveEvents>(
        `/v3/live-events?${params}`
      )
      if (!json || !json.payload) {
        throw new Error('Empty answer in getLiveEventsFx')
      }

      return {
        ...json.payload,
        content: (json.payload?.content || []).map(formatLiveEvent),
      }
    }
  )

  // GET /v3/library/live-events
  liveEvents.getPurchasedLiveEventsFx.use(
    async ({
      page = 0,
      count = defaults.count || 36,
      sortBy,
      sortOrder,
      myList,
    }) => {
      let json
      try {
        json = await http.get<ApiAnswerGetPurchasedLiveEvents>(
          `/v3/live-events`,
          {
            searchParams: {
              page,
              count,
              myList,
              'sort-by': sortBy,
              'sort-order': sortOrder,
            },
          }
        )
      } catch (apiCallError) {
        console.log('getPurchasedLiveEventsFx error ', apiCallError)
      }

      if (!json || !json.payload) {
        throw new Error('Empty answer in getPurchasedLiveEventsFx')
      }

      return {
        ...json.payload,
        content: (json.payload.content || []).map(formatLiveEvent),
      }
    }
  )

  // GET /v3/live-events/{eventId}
  liveEvents.getLiveEventByIdFx.use(async ({ eventId }) => {
    const json = await http.get<ApiAnswerLiveEventDto>(
      `/v3/live-events/${eventId}`
    )
    if (!json || !json.payload) {
      throw new Error('Empty answer in getLiveEventByIdFx')
    }
    return formatLiveEvent(json.payload)
  })

  // GET /v3/live-events/{eventId}/url
  liveEvents.getLiveEventUrlByIdFx.use(async ({ eventId, startTime }) => {
    const url = startTime
      ? `/v3/live-events/${eventId}/url?startTime=${startTime}&live-stream-supported=true`
      : `/v3/live-events/${eventId}/url`
    const json = await http.get<ApiAnswerMediaUrlDto>(url)
    if (!json || !json.payload) {
      throw new Error('Empty answer in getLiveEventUrlByIdFx')
    }

    return {
      ...json.payload,
      timeShift: startTime,
      rewindTimeEnded: startTime ? Math.round(Date.now() / 1000) : undefined,
    } as MediaUrl
  })
}
