import { memo, type MemoExoticComponent } from 'react'
import { get, key, Special, useRaw, type Scope, type ScopeList } from '../core'
import { hasMap, Map, type MapSkinView } from './Map'
import { hasOver, Over, type OverSkinView } from './Over'
import { type Recursive } from './Recursive'

interface Props {
  skin: MapSkinView | OverSkinView
  parent: Scope
  Recursive: Recursive
  [key: string]: unknown // any additional props, will be propogated to all elements in list
}

// exports memoized component
export const Many: MemoExoticComponent<typeof _Many> = memo(_Many)

// _Many.whyDidYouRender = true
_Many.displayName = 'Many'
function _Many({
  skin,
  parent,
  Recursive,
  ...props
}: Props): JSX.Element | null {
  let listName: 'map' | 'over' | undefined
  let MapOrOver: typeof Map | typeof Over | undefined

  if (hasMap(skin)) {
    listName = 'map'
    MapOrOver = Map
  } else if (hasOver(skin)) {
    listName = 'over'
    MapOrOver = Over
  }

  if (listName && MapOrOver) {
    const rawList = get(Special.List + listName, parent)

    // this is ok, because skin is static and never changes on the fly
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const list = useRaw(rawList) as ScopeList
    if (list) {
      return (
        <MapOrOver
          {...props}
          scopeList={list}
          key={key(skin)}
          skin={skin as MapSkinView & OverSkinView}
          parent={parent}
          Recursive={Recursive}
        />
      )
    }
  }

  return null
}

export { hasMap, hasOver }
