import type { API } from '@setplex/tria-api'
import { type HttpClient } from '../../http'
import type { AdapterDefaults } from '../../index.h'
import type {
  ApiAnswerPartnerProgram,
  ApiAnswerPartnerProgramByPage,
} from '../../interfaces/partnerProgram'
import {
  formatPartnerProduct,
  formatPartnerProductDetails,
} from './partnerProgram.format'

export function use(
  http: HttpClient,
  partnerProgram: API['partnerProgram'],
  _api: API,
  defaults: AdapterDefaults
): void {
  // GET /v3/partner-product
  partnerProgram.getPartnerProductsByPageFx.use(
    async ({ page = 0, count = defaults.count || 36, sortBy, sortOrder }) => {
      const params = new URLSearchParams()
      params.set('page', String(page))
      params.set('count', String(count))
      if (sortBy) params.set('sort-by', String(sortBy))
      if (sortOrder) params.set('sort-order', String(sortOrder))

      let json
      try {
        json = await http.get<ApiAnswerPartnerProgramByPage>(
          `/v3/partner-product?${params}`
        )
      } catch (apiCallError) {
        console.log('getPartnerProductsByPageFx error ', apiCallError)
      }

      if (!json || !json.payload) {
        throw new Error('Empty answer in getPartnerProductsByPageFx')
      }

      return {
        ...json.payload,
        content: (json.payload.content || []).map(formatPartnerProduct),
      }
    }
  )

  // GET /v3/partner-product/{partnerProductId}
  partnerProgram.getPartnerProductFx.use(async ({ partnerProductId }) => {
    let json
    try {
      json = await http.get<ApiAnswerPartnerProgram>(
        `/v3/partner-product/${partnerProductId}`
      )
    } catch (apiCallError) {
      console.log('getPartnerProductFx error ', apiCallError)
    }

    if (!json || !json.payload) {
      throw new Error('Empty answer in getPartnerProductFx')
    }
    return formatPartnerProductDetails(json.payload)
  })
}
