import { createEffect } from 'effector'
import type { ApiEffect } from '../index.h'
import type { IFinalProcessPayment } from '../interfaces/cart'
import type { IPurchasesPaymentInfo } from '../interfaces/purchases'
import { type IPaymentListChannels } from '../interfaces/purchases'

export const initializePurchaseProcessFx: ApiEffect<
  IFinalProcessPayment,
  IPurchasesPaymentInfo
> = createEffect()

export const getListPaymentChannelsFx: ApiEffect<void, IPaymentListChannels> =
  createEffect()
