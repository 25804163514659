import { createEvent } from 'effector'

export {
  createMediaQueryStore,
  history,
  holdup,
  lookbehind,
  postpone,
} from './belt'
export {
  genNestedPagesLogic,
  genPagesLogic,
  genPagesLogicNew,
} from './genPagesLogic'
export { getNextPage } from './pagination-by-cursor'

// event to do nothing, can be used anywhere to swallow a signal, hence accepts `any`
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const noop = createEvent<any | void>()
