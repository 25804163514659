import { model as i18n } from '!/i18n'
import { combine } from 'effector'

export const $signInFormTranslations = combine(i18n.$t, ({ t }) => ({
  phoneNumber: {
    label: t('phone_number'),
    errors: {
      minLength: t('enter_phone_number'),
    },
  },
  submitButton: t('sign_in'),
}))
