import { createFactory, invoke } from '@withease/factories'
import { attach, createEvent, createStore, launch, sample } from 'effector'
import { model as router, type RouterResponse } from '~/core/router'
import {
  ConfinementDirection,
  FORCE_IN,
  type Cage,
  type Jail,
  type Transit,
  type Trial,
} from './index.h'
import { jail } from './jail'
import { accessible, visitable } from './tools'

export const init = createEvent()

const createWarden = createFactory((jail: Jail) => {
  const init = createEvent()

  // current cage
  const setCage = createEvent<Transit>()
  const $cage = createStore<Cage>(jail[0][0]) //
    .on(setCage, (_, set) => set.to)

  // current route, set from navigation
  const $route = createStore<RouterResponse | null>(null)

  // route under trial
  const trial = createEvent<RouterResponse>()
  const clear = createEvent()
  const $trial = createStore<Trial | null>(null) //
    .reset(clear)

  // if jail is sealed - `in` events are ignored
  // reset sealed state on cage change
  const $sealed = createStore(false).reset($cage)

  // init current cage
  const initFx = attach({
    source: { cage: $cage, trial: $trial },
    effect({ cage, trial }, set: Transit) {
      if (set.to !== cage) {
        throw new Error(
          `cage was already set to ${cage.name}, cannot init ${set.to.name}`
        )
      }

      trial = trial || (set.trial ?? null)
      if (trial && accessible(cage, trial.route.keys)) {
        router.navigateReplace(trial.location) // rerender current location
        trial = null
      }

      // console.log('👉', cage.name, { ...set, trial })
      launch({
        target: cage.init,
        params: { ...set, trial },
      })
    },
  })

  // taint/cleanse current location against current cage
  const taintFx = attach({
    source: {
      cage: $cage,
      route: $route,
      trial: $trial,
      location: router.$location,
    },
    effect({ cage, route, trial, location }, reason?: string) {
      if (!location) return

      const r = trial?.route ?? route
      if (!r) return

      let modified = false

      // other cages cleanse location
      for (const [c] of jail) {
        if (
          c !== cage &&
          c.cleanse &&
          visitable(cage) &&
          !accessible(cage, r.keys)
        ) {
          const loc = c.cleanse(location)
          if (loc) {
            location = loc
            modified = true
          }
        }
      }

      // current cage taints location
      if (cage.taint && !accessible(cage, r.keys)) {
        const loc = cage.taint(location, reason)
        if (loc) {
          location = loc
          modified = true
        }
      }

      if (modified) {
        throw router.navigateReplace(location)
      }
    },
  })

  // trial route against current cage
  const trialFx = attach({
    source: { cage: $cage, trial: $trial },
    effect({ cage, trial }) {
      if (cage.trial != null && trial && !accessible(cage, trial.route.keys)) {
        launch({
          target: cage.trial,
          params: trial,
        })
      }
    },
  })

  // init
  sample({
    clock: init,
    source: $cage,
    fn: (initialCage) => ({
      from: null as unknown as Cage,
      to: initialCage,
      direction: ConfinementDirection.IN,
      reason: 'init',
    }),
    target: initFx,
  })

  // in/out
  sample({
    clock: $cage,
    source: setCage,
    target: initFx,
  })

  // taint current location
  sample({
    clock: initFx.done,
    fn: ({ params }) => params.reason,
    target: taintFx,
  })

  // trial and taint current location
  sample({
    clock: trial,
    source: router.$location,
    fn: (location, route) => ({
      route,
      location: location!,
    }),
    target: [$trial, taintFx],
  })

  // trial if taint done nothing
  sample({
    clock: taintFx.done,
    target: trialFx,
  })

  for (const [cage, extradition] of jail) {
    // add active state to cage
    // set internal $active state of a cage to `true` if cage is active
    if (cage.$active) {
      sample({
        clock: $cage,
        fn: (me) => cage === me,
        target: cage.$active,
      })
    }

    // seal
    if (cage.seal) {
      sample({
        clock: cage.seal,
        source: $cage,
        filter: (current) => cage === current,
        fn: (_, seal) => seal,
        target: $sealed,
      })
    }

    // in
    const inUnits = Array.isArray(cage.in) ? cage.in : [cage.in]
    for (const unit of inUnits) {
      sample({
        clock: unit,
        source: {
          sealed: $sealed,
          from: $cage,
          route: $route,
          location: router.$location,
        },
        filter: ({ sealed, from }, arg) =>
          (!sealed || arg === FORCE_IN) && from !== cage,
        fn: ({ from, route, location }) => ({
          from,
          to: cage,
          direction: ConfinementDirection.IN,
          reason: 'shortName' in unit ? String(unit.shortName ?? '') : '',
          trial: location && route ? { location, route } : undefined,
        }),
        target: setCage,
      })
    }

    // out
    for (const [reason, to] of extradition) {
      const outUnits = Array.isArray(reason) ? reason : [reason]
      for (const unit of outUnits) {
        sample({
          clock: unit,
          source: {
            from: $cage,
            route: $route,
            location: router.$location,
          },
          filter: ({ from }) => from === cage,
          fn: ({ from, route, location }, param) => ({
            from,
            to: typeof to === 'function' ? to(param) : to,
            direction: ConfinementDirection.OUT,
            reason: 'shortName' in unit ? String(unit.shortName ?? '') : '',
            trial: location && route ? { location, route } : undefined,
          }),
          target: setCage,
        })
      }
    }
  }

  // $cage.watch((cage) => console.log('📦', cage.name, cage))

  return {
    init,
    trial,
    clear,
    $cage,
    $route,
    $trial,
  }
})

export const warden = invoke(createWarden, jail)

sample({
  clock: init,
  target: warden.init,
})
