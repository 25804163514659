import type { TvChannel } from './tv'

export enum ScheduleType {
  HOURLY = 'HOURLY',
  EPG = 'EPG',
}

export interface Catchup {
  id: number
  sortOrder: number
  days: number
  scheduleType: ScheduleType
  channel: TvChannel
}

export interface CatchupProgram {
  id: number
  name: string
  startMs: number
  endMs: number
  dateStartMs: number
}

export type CatchupProgramsWithDates = { [timestamp: number]: CatchupProgram[] }
export type CatchupsDateList = number[]

export interface CatchupProgramsFormatedToObjectWithDatelist {
  dateList: CatchupsDateList
  programs: CatchupProgramsWithDates
}
