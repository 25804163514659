import type { API } from '@setplex/tria-api'
import { type HttpClient } from './http'
import type { AdapterDefaults } from './index.h'

export type SignInParams = {
  username?: string
  password?: string
  rememberMe?: boolean
  model?: string
  foreignPlatformAccessToken?: string
}

export function use(
  http: HttpClient,
  session: API['session'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  // login request
  session.signInFx.use(({ username, ...params }: SignInParams) =>
    http.post(`/../auth`, {
      json: {
        email: username, // field is called `email` by historical reasons
        manualProfileManagement: true, // prevents automatic profile creation on the backend
        ...params,
      },
      headers: {
        'MW-Paid-Content-Allowed': 'true',
        'MW-InApp-Subscription-Payment-Supported': 'true',
      },
    })
  )

  // guest login request
  session.guestInFx.use(() => http.post(`/../auth/guest`))

  // logout request
  session.signOutFx.use(() => http.post(`/../logout`))
}
