import type { API } from '@setplex/tria-api'
import {
  type ApiAnswerSliceDtoTvShowBundleDto_BundlePageView,
  type ApiAnswerSliceDtoTvShowDto_TvShowPageView,
  type ApiAnswerTvShowBundleDto_BundleSingleView,
} from '@setplex/wbs-api-types'
import { DEFAULT_PAGE_SIZE } from '../../constants/generic'
import { type HttpClient } from '../../http'
import { type AdapterDefaults } from '../../index.h'
import { formatShow } from '../tvshows/tvshows.format'
import { formatShowBundle } from './showBundles.format'

export function use(
  http: HttpClient,
  showBundles: API['showBundles'],
  _api: API,
  defaults: AdapterDefaults
): void {
  // GET /v3/bundles/tv-shows
  showBundles.getShowBundlesFx.use(
    async ({
      page,
      sortBy,
      sortOrder = defaults.sortOrder,
      count = defaults.count || DEFAULT_PAGE_SIZE,
    }) => {
      const params = new URLSearchParams()
      if (page) params.set('page', String(page))
      if (count) params.set('count', String(count))
      if (sortBy) params.set('sortBy', String(sortBy))
      if (sortOrder) params.set('sortOrder', String(sortOrder))

      const json =
        await http.get<ApiAnswerSliceDtoTvShowBundleDto_BundlePageView>(
          `/v3/bundles/tv-shows?${params}`
        )
      if (!json || !json.payload) {
        throw new Error('Empty answer in getShowBundlesFx')
      }
      return {
        ...json.payload,
        content: (json.payload?.content || []).map(formatShowBundle),
      }
    }
  )

  // GET /v3/bundles/tv-shows/{bundleId}
  showBundles.getShowBundleInfoFx.use(async ({ bundleId }) => {
    const json = await http.get<ApiAnswerTvShowBundleDto_BundleSingleView>(
      `/v3/bundles/tv-shows/${bundleId}`
    )
    if (!json || !json.payload) {
      throw new Error('Empty answer in getShowBundleInfoFx')
    }
    return formatShowBundle(json.payload)
  })

  // GET /v3/bundles/tv-shows/{id}/content
  showBundles.getShowBundleContentFx.use(
    async ({ bundleId, page, count = defaults.count || DEFAULT_PAGE_SIZE }) => {
      const params = new URLSearchParams()
      if (page) params.set('page', String(page))
      if (count) params.set('count', String(count))

      const json = await http.get<ApiAnswerSliceDtoTvShowDto_TvShowPageView>(
        `/v3/bundles/tv-shows/${bundleId}/content?${params}`
      )
      if (!json || !json.payload) {
        throw new Error('Empty answer in getShowBundleContentFx')
      }

      return {
        ...json.payload,
        content: (json.payload?.content || []).map(formatShow),
      }
    }
  )

  /**************** $ShowBundleWithContent *******************/
  // to paginate over - paginateShowBundleWithContent
  showBundles.loadShowBundleWithContentFx.use(async ({ count, bundleId }) => {
    const bundleInfo = await showBundles.loadShowBundleInfoForContent({
      bundleId,
    })
    const shows =
      (
        await showBundles.paginateShowBundleWithContent({
          page: 0,
          count,
          bundleId: Number(bundleId),
        })
      ).content || []

    return {
      ...bundleInfo,
      shows,
    }
  })
  /**************** $ShowBundleWithContent *******************/
}
