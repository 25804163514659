import { env } from '@/config'
import { MeterProvider } from '@opentelemetry/sdk-metrics'
import { attach, createEvent, createStore, sample, type Store } from 'effector'
import { initTelemetry, logger } from '../lib'

export const init = createEvent<void>()

// TODO: change to firebase check later
// think about asynchronous initialization, now `addMetricReader` method is deprecated,
// so it is required to create a reader and pass it to the provider constructor
// but if we want to change or set the URL, we need to recreate the exporter...
export const $enabled = createStore(
  ['local-dev', 'by-dev'].includes(env.TRIA_ENVIRONMENT)
)

// TODO: change to firebase later
const $collectorUrl: Store<string> = createStore(
  // '/v1/metrics'
  // 'http://localhost:4317/v1/metrics'
  'https://by-otel-collector-client-http.spnode.net/v1/metrics'
)

// meter provider to create meters across the application
export const $meterProvider = createStore<MeterProvider | null>(null)

const initTelemetryFx = attach({
  source: $collectorUrl,
  effect: initTelemetry,
})

//
// init OpenTelemetry
//

sample({
  clock: init,
  filter: $enabled,
  target: initTelemetryFx,
})

sample({
  clock: initTelemetryFx.doneData,
  target: $meterProvider,
})

sample({
  clock: initTelemetryFx.done,
  fn: () => ['initialized successfully'],
  target: logger.logFx,
})

sample({
  clock: initTelemetryFx.fail,
  fn: (fail) => ['initialization error', fail],
  target: logger.errorFx,
})
