import { model as i18n } from '!/i18n'
import { combine } from 'effector'

export const $navbarTabTitles = combine(i18n.$t, ({ t }) => ({
  home: t('home'),
  live: t('live'),
  movies: t('movies'),
  shows: t('series'),
  epg: t('epg'),
  events: t('event_other'),
  catchups: t('catchup_other'),
}))

export const $navbarDropDownTitles = combine(i18n.$t, ({ t }) => ({
  signIn: t('sign_in'),
  logOut: t('log_out'),
  applicationInfo: t('account_details'),
  myList: t('my_list'),
  accountSettings: t('account_settings'),
}))
