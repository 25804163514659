import { createEffect } from 'effector'
import type { ApiEffect, PageNew } from '../../index.h'
import type { AppearanceRow, AppearanceStyleSet } from './index.h'

export * from './index.h'

// GET /v3/appearance
export const getColorSchemeFx: ApiEffect<void, AppearanceStyleSet> =
  createEffect()

// GET /v3/appearance/modules/home/rows
export const getHomeRowsFx: ApiEffect<
  { page?: number; count?: number } | undefined | void,
  PageNew<AppearanceRow>
> = createEffect()

// GET resource row
export const getRowFx: ApiEffect<AppearanceRow, any> = createEffect()
