import { format } from 'date-fns'
import { runtime, version } from '~/app/environment'
import { clip } from '~/shared/helpers'
import { DATE_FORMATS } from '~/shared/helpers/date'

export function getDescriptionUrl(fromUrl?: string | null, pathname?: string) {
  const isEpgPage = pathname?.includes('/epg')
  return fromUrl && !isEpgPage ? fromUrl : window.location.href
}

interface SetUrlMacrosToParamsParams {
  url: string
  nonce?: string
}

interface Placeholders {
  [key: string]: (value: string | undefined, key: string) => string | undefined
}

export function setUrlMacrosToParams({
  url,
  nonce,
}: SetUrlMacrosToParamsParams): string {
  const now = new Date()

  // https://setplexapps.atlassian.net/wiki/spaces/NORA/pages/2723315713/NF-87.+AVOD.+Macros+support+for+Televen+-+hardcode
  const placeholders: Placeholders = {
    // Platform app name (AV_APPNAME)
    STPLX_ANM: () => clip(runtime.app.name, 30),
    // App version (AV_APPVERS)
    STPLX_AVER: () => clip(`v${version}`, 20),
    // Current date in the format yyyy-MM-dd (AVC_DATE)
    STPLX_DT: () => format(now, DATE_FORMATS.YYYY_MM_DD),
    // Do track flag (defines the permission to data collection).
    STPLX_DTRACK: () => '1',
    // Do not track flag (defines the prohibition of data collection)
    STPLX_DNT: () => '0',
    // Current time in format HH-mm-ss (AVC_TIME)
    STPLX_TM: () => format(now, DATE_FORMATS.HH_MM_SS),
    // Encrypted nonce parameter for Google Ad Manager (AV_UPAL)
    STPLX_UPAL: () => nonce,
    // Domain, used in URL of page, where the player is running (AV_URL and AV_VIDEOURL)
    STPLX_DOMAIN: (value, key) =>
      clip(value?.replace(key, window.location.hostname), 100),
  }

  const modifiedUrl = new URL(url)
  for (const [name, value] of modifiedUrl.searchParams) {
    let newValue: string | undefined

    for (const [pname, mod] of Object.entries(placeholders)) {
      if (value === pname || value.includes(pname)) {
        newValue = mod(value, pname)
      }
    }

    if (newValue != null) {
      modifiedUrl.searchParams.set(name, newValue)
    }
  }

  return String(modifiedUrl)
}
