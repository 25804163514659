import { model as i18n } from '!/i18n'
import { register } from '!/repository'
import { replaceTokens } from '@/helpers'
import { formatExpirationDate } from '@/helpers/date'
import { api } from '@setplex/tria-api'
import { attach, combine, createEvent, createStore, sample } from 'effector'
import { debounce, not } from 'patronum'
import type {
  AnnouncementDto,
  AnnouncementListDto,
  IAnnouncementsPopup,
} from './index.h'

export const init = createEvent()

const getAnnouncementsFx = attach({
  effect: api.announcements.pushAnnouncementsFx,
})

const getAnnouncements = createEvent()
export const openAnnouncementsPopup = createEvent<AnnouncementListDto>()

export const nextAnnouncementPopup = createEvent<void>()
export const closeAnnouncementsPopup = createEvent<void>()
export const handleClickButtonAnnouncementsPopup = createEvent<void>()

export const $announcementsPopup = createStore<IAnnouncementsPopup>({
  announcements: [],
  condition: false,
})
  .on(openAnnouncementsPopup, (data, payload) => {
    const { tokens } = payload
    if (tokens?.$expirationDate)
      tokens.$expirationDate = formatExpirationDate(tokens.$expirationDate)

    const announcements = payload.announcements
      ?.filter(
        (item) => item.type === 'Announcement' || item.type === 'Reminder'
      )
      .map((announcement) => {
        const { message, subject } = announcement

        return {
          ...announcement,
          subject: replaceTokens(subject ?? '', tokens),
          message: replaceTokens(message ?? '', tokens),
        }
      }) as AnnouncementDto[]

    const updatedAnnouncements =
      data && data.announcements
        ? data.announcements.concat(announcements)
        : announcements

    const condition = updatedAnnouncements.length > 0

    return {
      announcements: updatedAnnouncements,
      condition,
    } as IAnnouncementsPopup
  })
  .reset(closeAnnouncementsPopup)

export const $totalAnnouncements = $announcementsPopup.map(
  ({ announcements }) => announcements?.length ?? 0
)

export const $currentCountAnnouncement = createStore<number>(1)
  .on(nextAnnouncementPopup, (data) => data + 1)
  .reset(closeAnnouncementsPopup)

export const $currentAnnouncement = combine(
  $announcementsPopup,
  $currentCountAnnouncement,
  (total, current) => total?.announcements?.[current - 1] ?? null
)

export const $counter = combine(
  $totalAnnouncements,
  $currentCountAnnouncement,
  (total, current) => (total > 1 ? `${current}/${total}` : '')
)

export const $announcementButtonPopup = combine(i18n.$t, ({ t }) => ({
  okButton: t('ok'),
  nextButton: t('next'),
}))

export const $buttonTextAnnouncementsPopup = combine(
  {
    counter: $counter,
    current: $currentCountAnnouncement,
    total: $totalAnnouncements,
    translations: $announcementButtonPopup,
  },
  ({ counter, current, total, translations }) => {
    if (Boolean(counter) && current < total) return translations.nextButton
    return translations.okButton
  }
)

sample({
  clock: api.events.http.response,
  filter: ({ response }) =>
    response.ok &&
    response.headers.get('x-announcement') === 'true' &&
    !response.url.match('/device/announcements'),
  target: getAnnouncements,
})

sample({
  clock: debounce({ source: getAnnouncements, timeout: 500 }),
  filter: not(getAnnouncementsFx.pending),
  target: getAnnouncementsFx,
})

sample({
  clock: getAnnouncementsFx.doneData,
  filter: ({ announcements }) =>
    Boolean(announcements && announcements.length > 0),
  target: openAnnouncementsPopup,
})

sample({
  clock: handleClickButtonAnnouncementsPopup,
  source: {
    counter: $counter,
  },
  filter: ({ counter }) => Boolean(counter),
  target: nextAnnouncementPopup,
})

sample({
  clock: handleClickButtonAnnouncementsPopup,
  source: {
    counter: $counter,
    current: $currentCountAnnouncement,
    total: $totalAnnouncements,
  },
  filter: ({ counter, current, total }) => !counter || current > total,
  target: closeAnnouncementsPopup,
})

register(openAnnouncementsPopup, 'openAnnouncementsPopup')
register(nextAnnouncementPopup, 'nextAnnouncementPopup')
register(closeAnnouncementsPopup, 'closeAnnouncementsPopup')
register(
  handleClickButtonAnnouncementsPopup,
  'handleClickButtonAnnouncementsPopup'
)
register($announcementsPopup, '$announcementsPopup')
register($totalAnnouncements, '$totalAnnouncements')
register($currentAnnouncement, '$currentAnnouncement')
register($currentCountAnnouncement, '$currentCountAnnouncement')
register($counter, '$counterAnnouncement')
register($buttonTextAnnouncementsPopup, '$buttonTextAnnouncementsPopup')
