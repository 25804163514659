import {
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector'
import { initializeApp, type FirebaseApp } from 'firebase/app'
import { combineEvents } from 'patronum'
import { T } from '~/shared/helpers'
import * as analytics from './analytics'
import * as remote from './remote-config'

// re-export dependant modules
export { analytics, remote }

export const init = createEvent<void>()

const setReady = createEvent<void>()
export const $ready = createStore<boolean>(false).on(setReady, T)

// initialize firebase application with config from Era
// https://firebase.google.com/docs/web/learn-more#config-object
const initializeAppFx = createEffect<void, FirebaseApp>(() => {
  const firebaseConfig = window.ERA_CONFIG?.firebaseConfig
  if (firebaseConfig) {
    return initializeApp(firebaseConfig)
  }
  throw new Error('no firebase config, firebase was not initialized')
})

// firebase app instance
export const $firebaseApp = restore<FirebaseApp>(initializeAppFx.doneData, null)

// initialize firebase app on init
sample({
  clock: init,
  target: initializeAppFx,
})

// init dependant modules
sample({
  clock: initializeAppFx.doneData,
  target: [analytics.init, remote.init],
})

// set ready after all dependant modules are initialized
// or if initialization failed
sample({
  clock: [
    combineEvents({ events: [analytics.done, remote.done] }),
    initializeAppFx.fail,
  ],
  target: setReady,
})
