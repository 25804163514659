export interface IInfoPopupProps {
  condition: boolean
  episodeId: number | null
  eventDescription: string | null
  title: string | null
  name: string | null
  description: string | null
  directors: string | null
  stars: string | null
  length: string | null
  labels: string[] | null
}

export interface IPinLockPopupProps {
  condition: boolean
  name: string
  id: number | null
  invalid: boolean
  disabled: boolean
  errorMessage: string
  locked: boolean
  onChange: (value: string) => void
  onComplete: (value: string) => void
  //it is necessary not to lose the character input after the arrival of an external invalid prop. which leads to the component being unmounted
  valueAfterInvalid: string
}

export const DEFAULT_UNLOCK_ID = 0
