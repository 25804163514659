/*
 * This module uses old approach to handle i18n endpoint caching
 * Should be revised and updated after https://setplexapps.atlassian.net/browse/SAC-98 is done
 */

import type { ApiNoraTranslations } from '../../interfaces/i18n'

// cache i18n data in `localStorage`
const KEY = 'tria__v3-i18n-cache__old'

/**
 * Ensure that data is valid translations object
 */
function isValid(data: unknown): data is ApiNoraTranslations {
  return (
    data != null &&
    typeof data === 'object' &&
    'payload' in data &&
    data.payload != null &&
    typeof data.payload === 'object' &&
    'content' in data.payload &&
    Array.isArray(data.payload.content) &&
    data.payload.content.length > 0 &&
    data.payload.content.every(
      (item) =>
        item != null &&
        typeof item === 'object' &&
        'timestamp' in item &&
        typeof item.timestamp === 'number' &&
        'translations' in item &&
        item.translations != null &&
        typeof item.translations === 'object'
    )
  )
}

/**
 * Get timestamp from first translation object
 * In the current implementation all timestamps are the same
 */
function getTimestamp(data: ApiNoraTranslations): number | undefined {
  let content = data.payload.content
  if (content && content.length > 0) {
    return content[0].timestamp
  }
}

/**
 * Get translations object from cache, with timestamp for convenience
 */
export function getFromCache(): [ApiNoraTranslations, number] | null {
  try {
    const raw = localStorage.getItem(KEY)
    if (raw) {
      const data = JSON.parse(raw)
      if (isValid(data)) {
        const timestamp = getTimestamp(data)
        if (timestamp != null) {
          return [data, timestamp]
        }
      }
    }
  } catch (e) {
    // do nothing
  }
  return null
}

/**
 * Put translations object to cache
 */
export function putToCache(data: ApiNoraTranslations): void {
  try {
    if (isValid(data)) {
      localStorage.setItem(KEY, JSON.stringify(data))
    }
  } catch (e) {
    // do nothing
  }
}
