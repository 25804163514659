import { path } from './mappers'
import { raw, type Raw } from './raw'
import { inherit, type Scope } from './scope'

// disable eslint here because `grammar.mjs` is autogenerated and has no typings
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { parse } from '../evaluate/grammar.mjs'

/**
 * Get raw value from scope + locals
 *
 * @param value - string name and path to find
 * @param scope - scope to find value in
 * @param locals - optional locals to find value in
 * @returns - raw value or null
 */
export const get = (
  value: string,
  scope: Scope,
  locals?: Scope
): Raw | null => {
  const [name, ...tail] = value.split('.')
  const got: Raw | null | undefined =
    locals && name in locals
      ? locals[name] // get value from locals
      : scope[name || '.'] // get value from scope
  return got == null || !tail || !tail[0]
    ? got ?? null // got null/undefined or there is no tail
    : raw(`path: .${tail.join('.')}`, got, path(tail)) // there is tail -> add path getter in raw chain
}

/**
 * Helper to resolve value(s) against scope
 *
 * @param value - value to resolve
 * @param scope - scope to find value in
 * @param locals - optional locals to find value in
 * @returns - raw value or null
 */
export const resolve = (
  value: unknown,
  scope: Scope,
  locals?: Scope
): Raw | null => {
  if (typeof value === 'number') {
    return raw(`number: ${value}`, value)
  }

  if (typeof value === 'string') {
    if (
      value.startsWith('$') ||
      value.startsWith('.') ||
      value.startsWith('|')
    ) {
      return get(value, scope, locals)
    }

    if (value.startsWith('\\')) {
      return get(value.slice(1), scope, locals)
    }

    if (value.startsWith('@')) {
      const index: string = value.slice(1) || 'i'
      return get(index, scope, locals)
    }

    return raw(`string: ${value}`, value)
  }

  if (Array.isArray(value)) {
    return raw(
      `list: ${value.join('.')}`,
      value.map((v) => resolve(v, scope, locals))
    )
  }

  return raw(`unknown: ${value}`, value)
}

// regexp to get variables from expression
const EXPR_VAR = /[$.\\@a-zA-Z0-9_>|/+-]+/g // this regexp will catch some useless string, but this is fine

/**
 * Helper to evaluate expressions using PEG.js grammar
 *
 * @param value - expression to evaluate
 * @param scope - scope to find value in
 * @param locals - optional locals to find value in
 * @returns - raw value or null
 */
export const express = (
  value: unknown,
  scope: Scope,
  locals?: Scope
): Raw | null => {
  // if this is not an expression -> just deindex it
  if (!value || typeof value !== 'string' || !value.trim().startsWith('=')) {
    return deindex(value, scope, locals)
  }

  // get all variables, required for the expression
  const variables: string[] | null = value.match(EXPR_VAR)
  const required: Scope = inherit()
  if (variables) {
    for (const variable of variables) {
      required[variable] = resolve(variable, scope, locals)
    }
  }

  return raw(
    `expression: ${value.trim()}`,
    value,
    (expr, unscope) =>
      parse(expr, {
        resolve: (x: unknown) =>
          unscope && typeof x === 'string' ? unscope[x] : x,
      }),
    required
  )
}

// regexp to get indexes
const INDEXED_RE = /\[([^[\]]+)/g // substitute strings like `[...]`
const DEINDEX_RE = /([^[\]]+)\[([^[\]]+)\]/ // find most nested index []

/**
 * Helper to deindex string with values
 *
 * @param value - string
 * @param scope - scope to find values in
 * @param locals - optional locals to find values in
 * @returns - raw value or null
 */
export const deindex = (
  value: unknown,
  scope: Scope,
  locals?: Scope
): Raw | null => {
  // if there is no indexes here -> just substitute it
  if (!value || typeof value !== 'string' || !INDEXED_RE.test(value)) {
    return substitute(value, scope, locals)
  }

  // get all variables, required for indexing
  const variables: string[] | null = value.match(INDEXED_RE)
  const required: Scope = inherit()
  if (variables) {
    for (const variable of variables) {
      const name = variable.slice(1)
      required[name] = resolve(name, scope, locals)
    }
  }

  // get first variable
  const main = value.slice(0, value.indexOf('['))
  required[main] = resolve(main, scope, locals)

  return raw(
    `deindexing: ${value.trim()}`,
    value,
    (value: string, unscope) => {
      if (!unscope) unscope = {}

      // unwrap all [...] one by one from bottom to top
      let match
      while ((match = value.match(DEINDEX_RE)) != null) {
        const matched = match[0]
        const renamed = matched.replace('[', '(').replace(']', ')')
        const object = unscope[match[1]]
        const key = (unscope[match[2]] as string) || match[2]
        if (typeof object === 'object' && object != null) {
          unscope[renamed] = object[key as keyof typeof object]
        }
        value = value.replace(matched, renamed)
      }

      // return latest unwrapped value, which is our required value
      return unscope[value]
    },
    required
  )
}

// regexp to get strings to substitute
const SUBSTITUTE_RE = /\{(.+?)\}/g // substitute strings like `{...}`

/**
 * Helper to substitute template string with values
 *
 * @param value - template string
 * @param scope - scope to find values in
 * @param locals - optional locals to find values in
 * @returns - raw value or null
 */
export const substitute = (
  value: unknown,
  scope: Scope,
  locals?: Scope
): Raw | null => {
  // if this is not a string or doesn't need to be substituted -> just resolve it
  if (!value || typeof value !== 'string' || !SUBSTITUTE_RE.test(value)) {
    return resolve(value, scope, locals)
  }

  // get all variables, required for the expression
  const variables: string[] | null = value.match(SUBSTITUTE_RE)
  const required: Scope = inherit()
  if (variables) {
    for (const variable of variables) {
      const name = variable.slice(1, -1)
      required[name] = resolve(name, scope, locals)
    }
  }

  return raw(
    `substitution: ${value.trim()}`,
    value,
    (value: string, unscope) =>
      unscope
        ? value.replace(SUBSTITUTE_RE, (__, name) => String(unscope[name]))
        : value,
    required
  )
}

/**
 * Helper to evaluate template string as JS code, deprecated
 *
 * @deprecated
 * @param value - template string
 * @param scope - scope to find values in
 * @param locals - optional locals to find values in
 * @returns - raw value or null
 */
export const evaluate = (
  value: unknown,
  scope: Scope,
  locals?: Scope
): Raw | null => {
  return raw(
    `eval: ${String(value).trim()}`,
    substitute(value, scope, locals),
    (body: string) => Function('"use strict";return (' + body + ')')()
  )
}
