import { type PageDtoContinueWatchingDto_ContinueWatchingPageView } from '@setplex/wbs-api-types'
import type { IContinueWatching } from '../../interfaces/continue-watching'
import {
  getBackgroundImage,
  getHorizontalImage,
  getVerticalImage,
} from '../../utils/images'
import { formatDuration } from '../../utils/media'

export const formatContinueWatching = ({
  content,
}: PageDtoContinueWatchingDto_ContinueWatchingPageView): Array<IContinueWatching> => {
  if (!content) return []
  return content.map(formatContinueWatchingItem)
}

export const formatContinueWatchingItem = (contentItem: IContinueWatching) => ({
  ...contentItem,
  duration: formatDuration({ length: contentItem.length }),
  landscapeImageUrl: getHorizontalImage(contentItem.landscapeImageUrl),
  portraitImageUrl: getVerticalImage(contentItem.portraitImageUrl),
  backgroundImageUrl: getBackgroundImage(contentItem.backgroundImageUrl),
})
