import { api } from '@setplex/tria-api'
import { attach, createEvent } from 'effector'

export const init = createEvent()

// get subscriber effect
export const getSubscriberFx = attach({ effect: api.subscriber.getFx })

// subscriber store
export const $subscriber = api.subscriber.$subscriber
