import { model as config, remote } from '@/config'
import { loadChromecast } from '@setplex/player'
import { createEffect, createEvent, sample } from 'effector'

export const init = createEvent()

const loadChromecastFx = createEffect<void, void>(loadChromecast)

export const $chromecastEnabled = config.get(remote.tria_isChromecastEnabled)

sample({
  clock: [init, $chromecastEnabled],
  filter: $chromecastEnabled,
  target: loadChromecastFx,
})
