export let logger = new Proxy(console, {
  get() {
    return () => {
      // do nothing
    }
  },
})

// Left any because couldn't get type for Logger from tria and this function used only for debugging and development
export function setLogger(newLogger: any) {
  logger = newLogger
}
