import {
  app,
  expired,
  geoblock,
  guest,
  hallway,
  hallway2guest,
  hallway2profiles,
  hallway2trueid,
  lobby,
  profiles,
  sign,
  toa,
} from './cages'
import { type Confinement, type Extradition, type Jail } from './index.h'

const on = (...x: Extradition): Extradition => [...x]
const cage = (...x: Confinement): Confinement => [...x]

export const jail: Jail = [
  // initial bootstrap cage
  cage(lobby, [
    on(lobby.out.preparationsReady, () => hallway), //
  ]),

  // main guiding cage
  cage(hallway, [
    on(hallway.out.activeGuestSession, () => guest),
    on(hallway.out.activeSubscriberSession, () => hallway2profiles),
    on(hallway.out.shouldAcceptToa, () => toa),
    on(hallway.out.shouldBuySubscription, () => expired),
    on(hallway.out.shouldSignInByTrueid, () => hallway2trueid),
    on(hallway.out.shouldSignInAsGuest, () => hallway2guest),
    on(hallway.out.shouldSignIn, () => sign),
  ]),

  // intermediate guiding to guest cage
  cage(hallway2guest, [
    on(hallway2guest.out.signedInAsGuest, () => guest),
    on(hallway2guest.out.failedToSignInAsGuest, () => sign),
  ]),

  // intermediate guiding to profiles cage
  cage(hallway2profiles, [
    on(hallway2profiles.out.singleActiveProfile, () => app),
    on(hallway2profiles.out.selectedActiveProfile, () => app),
    on(hallway2profiles.out.shouldCreateProfile, () => profiles),
    on(hallway2profiles.out.shouldChooseProfile, () => profiles),
  ]),

  // intermediate guiding to trueid cage
  cage(hallway2trueid, [
    on(hallway2trueid.out.shouldSignInByTrueId, () => sign),
    on(hallway2trueid.out.failedToSignInByTrueId, () => sign),
  ]),

  // app cage, all main app routes located here
  cage(app, []),

  // guest cage, all guest routes located here (= all app routes + sign routes)
  cage(guest, [
    on(guest.out.subscriberSignedIn, () => hallway), //
  ]),

  // geoblock cage
  cage(geoblock, []),

  // cage to choose profile
  cage(profiles, [
    on(profiles.out.chooseProfile, () => app), //
  ]),

  // cage to sign in and sign up
  cage(sign, []),

  // cage to accept terms of agreement
  cage(toa, [
    on(toa.out.toaAccepted, () => hallway2profiles), //
  ]),

  // cage to handle expired subscription
  cage(expired, []),
]
