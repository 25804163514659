import { type Location } from '~/core/router'
import { keys as bunch, type Key } from '~/shared/jail'
import { type Cage } from './index.h'

//
// cage accessibility helpers
//

/**
 * Check if the cage is accessible by the given keys
 */
export function accessible(cage: Cage, keys: Key | Key[] | undefined): boolean {
  // cage is open, and doesn't require any keys
  if (cage.key == null) return true

  // cage is closed, but there are no keys
  if (keys == null) return false

  // cage is not visitable
  if (!visitable(cage)) return false

  // given bunch of keys
  if (Array.isArray(keys)) {
    return (
      keys.includes(cage.key) || // required key is in the given bunch
      keys.includes(bunch.master) // master key is in the given bunch
    )
  }

  // given single key
  return (
    keys === cage.key || // required key is the given one
    keys === bunch.master // master key is the given one
  )
}

/**
 * Check if the cage is theoretically visitable, meaning it has valid key (not lost)
 */
export function visitable(cage: Cage): boolean {
  if (cage.key == null) return true
  return cage.key.description !== 'lost'
}

//
// taint/cleanse location helpers
//

type DefaultedRecord = {
  [reason: string]: string
  __: string
}

/**
 * helper to create taint/cleanse pair
 */
export function tainter(
  match: RegExp,
  path: string | DefaultedRecord,
  exact = false
) {
  return {
    taint(location: Location, reason?: string): Location | undefined {
      if (match.test(location.pathname)) return // already tainted

      const head =
        typeof path === 'string' ? path : path[reason || '__'] || path.__
      const delimeter = head.endsWith('/') ? '~' : '/~'
      const tail =
        location.pathname === '/' ? '' : delimeter + location.pathname

      return exact
        ? { ...location, pathname: head, search: '', hash: '' }
        : { ...location, pathname: head + tail }
    },

    cleanse(location: Location): Location | undefined {
      if (!match.test(location.pathname)) return // not tainted

      const matched = location.pathname.match(/~(.*)$/)
      return { ...location, pathname: matched ? matched[1] || '/' : '/' }
    },
  }
}
