import {
  EventStatus,
  PurchaseInfoContentPurchaseType as PurchaseInfoContentPurchaseTypeEnum,
  type LiveEvent,
  type PurchaseInfoContentPurchaseType,
} from '@setplex/tria-api'
import { type LiveEventDto } from '@setplex/wbs-api-types'
import {
  getBackgroundImage,
  getHorizontalImage,
  getVerticalImage,
} from '../../utils/images'
import { getMinPriceObject } from '../../utils/minPriceObj'
import { formatPriceSettings } from '../../utils/payment'

const formatEventStatus = (status: LiveEventDto['status']): EventStatus => {
  return status as EventStatus
}

export const formatLiveEvent = (event: LiveEventDto): LiveEvent => {
  const minPriceObj = getMinPriceObject(event.priceSettings)

  const purchaseInfo = event.purchaseInfo && {
    contentPurchaseType: event.purchaseInfo
      ?.contentPurchaseType as PurchaseInfoContentPurchaseType,
    rentedUntil: event.purchaseInfo?.rentedUntil,
  }

  const recordAvailableFromTimeMS = event.recordAvailableToTime
    ? +new Date(event.recordAvailableToTime!).setHours(0, 0, 0, 0)
    : undefined

  return {
    ...event,
    id: event.id!,
    status: formatEventStatus(event.status),
    // changed `getVerticalImage` to `getBackgroundImage` because we use `verticalPosterUrl`as background
    // for banner on details page and in https://setplexapps.atlassian.net/browse/FP-694 we have issues
    // about bad resoultion of images on mobile devices
    // TODO: if we will use `verticalPosterUrl` as background for cards we should optimize this place
    //  by another way
    verticalPosterUrl: getBackgroundImage(event.verticalPosterUrl),
    videoVerticalPosterUrl: getVerticalImage(event.verticalPosterUrl),
    videoPosterUrl: getHorizontalImage(event.videoPosterUrl),
    bannerPosterUrl: getBackgroundImage(event.videoPosterUrl),
    priceSettings: formatPriceSettings(event.priceSettings),
    isLive: event.status === EventStatus.LIVE,
    minCurrency: minPriceObj?.currency,
    minPrice: minPriceObj?.price,
    purchaseInfo,
    rented:
      purchaseInfo?.contentPurchaseType ===
      PurchaseInfoContentPurchaseTypeEnum.Rented,
    recordAvailableFromTimeMS,
  }
}
