import { createEvent, sample } from 'effector'
import { profilesFeaturesModel } from '~/features/profiles'
import { keys } from '~/shared/jail'
import { type Cage, type Transit } from '../index.h'
import { tainter } from '../tools'

/*
 * ### Profiles cage ###
 */

const init = createEvent<Transit>('init')

// out events
const chooseProfile = createEvent('chooseProfile')

export const profiles = {
  name: 'profiles',
  key: keys.profiles,
  init,
  in: [],
  out: {
    chooseProfile,
  },
  ...tainter(/^\/profiles/, {
    __: '/profiles/choose',
    shouldCreateProfile: '/profiles/create',
  }),
} satisfies Cage

/*
 * cage logic
 */

sample({
  clock: profilesFeaturesModel.selectedProfileId,
  target: chooseProfile,
})
