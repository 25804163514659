import { is, type Event } from 'effector'

const map: Map<string, Event<unknown>> = new Map<string, Event<unknown>>()

export const type = Symbol()

export function check(x: unknown): x is Event<unknown> {
  return is.event(x)
}

export function set(event: Event<unknown>, name?: string): Event<unknown> {
  name = name ?? event.shortName
  map.set(name, event)
  return event
}

export function get<T = unknown>(name: string): Event<T> | null {
  return (map.get(name) as Event<T>) ?? null
}
