import * as era from '!/era'
import { ui } from '!/skin'

// skin
const skin = era.skin('shared/ui/components/language-picker')

LanguagePicker.displayName = 'LanguagePicker'
export function LanguagePicker(): JSX.Element | null {
  return (
    <ui.RenderSkin from={skin} name='languagePicker' key='languagePicker' />
  )
}
