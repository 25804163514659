import { attach, createEffect, createStore } from 'effector'
import { produce } from 'immer'
import type { ApiEffect } from '../../index.h'
import { GlobalSearchMediaTypes } from '../../interfaces/generic'
import type {
  GlobalSearchPageInfo,
  GlobalSearchPayload,
  GlobalSearchResults,
} from './index.h'

// Effects

interface SearchParams {
  q: string
  count?: number
  contentType?: GlobalSearchMediaTypes
  contentTypes?: string // Comma-separated list of content types to search for (LIVE_EVENT, RADIO_CHANNEL, TV_CHANNEL, TV_SHOW, VOD). All allowed by default
  cursor?: string
}

//GET /v3/search
export const getSearchFx: ApiEffect<SearchParams, GlobalSearchPayload> =
  createEffect()

export const loadSearchEventsFx = attach({
  effect: getSearchFx,
  mapParams: (params: SearchParams) => ({
    ...params,
    contentType: GlobalSearchMediaTypes.LIVE_EVENT,
  }),
})

export const loadSearchTvChannelsFx = attach({
  effect: getSearchFx,
  mapParams: (params: SearchParams) => ({
    ...params,
    contentType: GlobalSearchMediaTypes.TV_CHANNEL,
  }),
})

export const loadSearchTvShowsFx = attach({
  effect: getSearchFx,
  mapParams: (params: SearchParams) => ({
    ...params,
    contentType: GlobalSearchMediaTypes.TV_SHOW,
  }),
})

export const loadSearchVodsFx = attach({
  effect: getSearchFx,
  mapParams: (params: SearchParams) => ({
    ...params,
    contentType: GlobalSearchMediaTypes.VOD,
  }),
})

// Stores

export const $searchResults = createStore<GlobalSearchResults | null>(null).on(
  getSearchFx.doneData,
  (state, { liveEvents, tvChannels, tvShows, vods, radioChannels }) => {
    const obj = {
      liveEvents: [...(state?.liveEvents || [])],
      tvChannels: [...(state?.tvChannels || [])],
      tvShows: [...(state?.tvShows || [])],
      vods: [...(state?.vods || [])],
      radioChannels: [...(state?.radioChannels || [])],
    }

    return produce(obj, (draft) => {
      draft.liveEvents = draft.liveEvents.concat(liveEvents?.content)
      draft.tvChannels = draft.tvChannels.concat(tvChannels?.content)
      draft.tvShows = draft.tvShows.concat(tvShows?.content)
      draft.vods = draft.vods.concat(vods?.content)
      draft.radioChannels = draft.radioChannels.concat(radioChannels?.content)
    })
  }
)

export const $searchPageInfo = createStore<GlobalSearchPageInfo | null>(
  null
).on(
  getSearchFx.doneData,
  (
    state,
    {
      liveEvents: { content: _ignoredEvents, ...eventsPageInfo },
      tvChannels: { content: _ignoredTvChannels, ...tvChannelsPageInfo },
      tvShows: { content: _ignoredTvShows, ...tvShowsPageInfo },
      vods: { content: _ignoredVods, ...vodsPageInfo },
      radioChannels: {
        content: _ignoredRadioChannels,
        ...radioChannelsPageInfo
      },
    }
  ) => ({
    liveEvents: { ...(state?.liveEvents || {}), ...(eventsPageInfo || {}) },
    tvChannels: { ...(state?.tvChannels || {}), ...(tvChannelsPageInfo || {}) },
    tvShows: { ...(state?.tvShows || {}), ...(tvShowsPageInfo || {}) },
    vods: { ...(state?.vods || {}), ...(vodsPageInfo || {}) },
    radioChannels: {
      ...(state?.radioChannels || {}),
      ...(radioChannelsPageInfo || {}),
    },
  })
)

export const $searchEventsPageInfo = $searchPageInfo.map(
  (store) => store?.liveEvents ?? null
)
export const $searchTvChannelsPageInfo = $searchPageInfo.map(
  (store) => store?.tvChannels ?? null
)
export const $searchTvShowsPageInfo = $searchPageInfo.map(
  (store) => store?.tvShows ?? null
)
export const $searchVodsPageInfo = $searchPageInfo.map(
  (store) => store?.vods ?? null
)
