import type {
  MediaOrderType,
  PurchaseInfoContentPurchaseType,
  Vod,
  VodUpdated,
} from '@setplex/tria-api'
import {
  MediaTypes,
  PurchaseInfoContentPurchaseType as PurchaseInfoContentPurchaseTypeEnum,
} from '@setplex/tria-api'
import { type VodDto } from '@setplex/wbs-api-types'
import {
  getBackgroundImage,
  getHorizontalImage,
  getVerticalImage,
} from '../../utils/images'
import {
  formatDuration,
  formatResolution,
  humanizeLengthWithoutSeconds,
} from '../../utils/media'
import { getMinPriceObject } from '../../utils/minPriceObj'
import { formatPriceSettings } from '../../utils/payment'

export const formatVod = ({
  imageHorizontalUrl,
  imageUrl,
  imageBackgroundUrl,
  resolution,
  length,
  watched,
  orderType,
  free,
  priceSettings,
  stoppedTime,
  ...rest
}: VodDto): Vod => {
  const minPriceObj = getMinPriceObject(priceSettings)
  const purchaseInfo =
    rest.purchaseInfo?.contentPurchaseType || rest.purchaseInfo?.rentedUntil
      ? {
          contentPurchaseType: rest.purchaseInfo
            ?.contentPurchaseType as PurchaseInfoContentPurchaseType,
          rentedUntil: rest.purchaseInfo?.rentedUntil,
        }
      : undefined

  return {
    ...rest,
    trailerPresent: rest.trailerPresent!,
    imageHorizontalUrl: getHorizontalImage(imageHorizontalUrl),
    imageBackgroundUrl: getBackgroundImage(imageBackgroundUrl),
    imageUrl: getVerticalImage(imageUrl),
    imageMobileBackgroundUrl: getBackgroundImage(imageUrl),
    length: humanizeLengthWithoutSeconds({ length }),
    resolution: formatResolution({ resolution }),
    mediaType: MediaTypes.VOD,
    orderType: String(orderType || '').toUpperCase() as MediaOrderType,
    watched: Boolean(watched), // somewhy bool | null from openapi
    free: free === undefined ? true : free, // Set field free is true for Nora 1.46 version, where there is no field free
    priceSettings: formatPriceSettings(priceSettings),
    minCurrency: minPriceObj?.currency,
    minPrice: minPriceObj?.price,
    purchaseInfo,
    rented:
      purchaseInfo?.contentPurchaseType ===
      PurchaseInfoContentPurchaseTypeEnum.Rented,
    stoppedTime,
    duration: formatDuration({ length }),
  }
}

export const formatVodUpdatedOnly = ({ id, updated }: VodDto): VodUpdated => {
  return {
    id,
    updated,
  }
}
