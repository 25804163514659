import type {
  API,
  GenericApiResponse,
  MediaUrl,
  Page,
  Vod,
  VodCategory,
} from '@setplex/tria-api'
import {
  RECOMMENDED_AMOUNT_VODS,
  RECOMMENDED_AMOUNT_VODS_AND_CURRENT,
} from '@setplex/tria-api'
import { type CategoryWithVodsApi } from '@setplex/tria-api/src/interfaces/vods'
import { type VodDto } from '@setplex/wbs-api-types'
import { sample } from 'effector'
import {
  DEFAULT_PAGE_SIZE,
  RECOMMENDED_PLAYLIST_COUNT,
} from '../../constants/generic'
import { type HttpClient } from '../../http'
import type { AdapterDefaults } from '../../index.h'
import type {
  ApiAnswerListVod,
  ApiAnswerMediaUrl,
  ApiAnswerPageDtoVod,
  ApiAnswerPageDtoVodCategory,
  ApiAnswerVod,
} from '../../interfaces/vods'
import { getFirstPagePaginateInfo } from '../../utils/pageInfo'
import { formatVod, formatVodUpdatedOnly } from './vods.format'

const featuredCategoryMock = {
  id: 0,
  name: 'Featured',
  sortOrder: 0,
  vodQuantity: 100, // not used atm, but not clear what to leave there
  parentCategoryId: undefined,
  subCategories: [],
}

export function use(
  http: HttpClient,
  vods: API['vods'],
  _api: API,
  defaults: AdapterDefaults
): void {
  // GET /v3/vods
  vods.getPageFx.use(
    async ({
      page,
      count = defaults.count || DEFAULT_PAGE_SIZE,
      ids,
      categoryId,
      q,
      tags,
      'only-favorites': onlyFavorites,
    }) => {
      const params = new URLSearchParams()
      params.set('page', String(page))
      params.set('count', String(count))
      if (ids != null) params.set('ids', String(ids))
      if (categoryId != null) params.set('categoryId', String(categoryId))
      if (q != null) params.set('q', String(q))
      if (tags != null) params.set('tags', String(tags))
      if (onlyFavorites != null)
        params.set('only-favorites', String(onlyFavorites))

      const json = await http.get<ApiAnswerPageDtoVod>(`/v3/vods?${params}`)
      if (!json || !json.payload) {
        throw new Error('Empty answer')
      }

      return {
        ...json.payload,
        content: (json.payload.content || []).map(formatVod),
      } as Page<Vod>
    }
  )

  // GET /v3/library/vods
  vods.getPurchasedVodsFx.use(
    async ({
      page = 0,
      count = defaults.count || 36,
      rented = true,
      purchased = true,
    }) => {
      let json
      try {
        json = await http.get<ApiAnswerPageDtoVod>(`/v3/library/vods`, {
          searchParams: {
            page,
            count,
            rented,
            purchased,
          },
        })
      } catch (apiCallError) {
        console.log('getPurchasedVodsFx error ', apiCallError)
      }

      if (!json || !json.payload) {
        throw new Error('Empty answer in getPurchasedVodsFx')
      }

      return {
        ...json.payload,
        content: (json.payload.content || []).map(formatVod),
      }
    }
  )

  // GET /v3/vods?categoryId=categoryId
  vods.getVodsByCategoryIdFx.use(
    async ({
      page,
      count = defaults.count || DEFAULT_PAGE_SIZE,
      categoryId,
    }) => {
      const params = new URLSearchParams()
      params.set('page', String(page))
      params.set('count', String(count))
      if (categoryId != null) params.set('categoryId', String(categoryId))

      let json
      try {
        json = await http.get<ApiAnswerPageDtoVod>(`/v3/vods?${params}`)
      } catch (apiCallError) {
        console.log('getVodsByCategoryIdFx error ', apiCallError)
      }
      if (!json || !json.payload) {
        throw new Error('Empty answer')
      }

      return {
        ...json.payload,
        content: (json.payload.content || []).map(formatVod),
      } as Page<Vod>
    }
  )

  // GET /v3/vods?updatedTimeOnly=true
  vods.getUpdatedOnlyFx.use(async () => {
    const json = await http.get<ApiAnswerListVod>(
      `/v3/vods?updatedTimeOnly=true`
    )
    if (!json || !json.payload) {
      throw new Error('Empty answer')
    }

    return json.payload.map(formatVodUpdatedOnly)
  })

  // GET /v3/vods/{vodId}
  vods.getVodFx.use(async ({ vodId }) => {
    const json = await http.get<ApiAnswerVod>(`/v3/vods/${vodId}`)
    if (!json || !json.payload) {
      throw new Error('Empty answer')
    }

    return formatVod(json.payload)
  })

  // GET /v3/vods/{vodId}/url
  vods.getUrlFx.use(async ({ vodId }) => {
    const json = await http.get<ApiAnswerMediaUrl>(`/v3/vods/${vodId}/url`)
    if (!json || !json.payload) {
      throw new Error('Empty answer')
    }

    return json.payload as MediaUrl
  })

  // GET /v3/vods/{vodId}/trailer/url
  vods.getTrailerUrlFx.use(async ({ vodId }) => {
    const json = await http.get<ApiAnswerMediaUrl>(
      `/v3/vods/${vodId}/trailer/url`
    )
    if (!json || !json.payload) {
      throw new Error('Empty answer')
    }

    return json.payload as MediaUrl
  })

  // GET /v3/vods/categories
  vods.getCategoriesFx.use(
    async ({ page, count = defaults.count || DEFAULT_PAGE_SIZE }) => {
      const params = new URLSearchParams()
      params.set('page', String(page))
      params.set('count', String(count))

      const json = await http.get<ApiAnswerPageDtoVodCategory>(
        `/v3/vods/categories?${params}`
      )
      if (!json || !json.payload) {
        throw new Error('Empty answer')
      }

      return json.payload as Page<VodCategory>
    }
  )

  const getDefaultAllCategory = (): VodCategory => ({
    id: 0,
    name: 'All Movies',
    sortOrder: 0,
    vodQuantity: 0,
    parentCategoryId: 0,
    subCategories: [],
  })

  // GET /v3/vods/categories/{categoryId}
  vods.getVodCategoryFx.use(async ({ categoryId }) => {
    // as wbs got default category 'All' - 0, but could not return it, this tweak done
    if (categoryId === 0) return getDefaultAllCategory()

    let json
    try {
      json = await http.get<ApiAnswerPageDtoVodCategory>(
        `/v3/vods/categories/${categoryId}`
      )
    } catch (apiCallError) {
      console.log('getVodCategoryFx error ', apiCallError)
    }

    if (!json || !json.payload) {
      throw new Error('Empty answer')
    }

    return json.payload as VodCategory
  })

  // GET /v3/vods/categories/{categoryId}/subcategories
  vods.getSubcategoriesFx.use(
    async ({
      categoryId,
      page,
      count = defaults.count || DEFAULT_PAGE_SIZE,
    }) => {
      const params = new URLSearchParams()
      params.set('page', String(page))
      params.set('count', String(count))

      const json = await http.get<ApiAnswerPageDtoVodCategory>(
        `/v3/vods/categories/${categoryId}/subcategories?${params}`
      )
      if (!json || !json.payload) {
        throw new Error('Empty answer')
      }

      return json.payload as Page<VodCategory>
    }
  )

  // PATCH /v3/vods/{vodId}
  vods.updateVodFx.use(
    async ({
      id,
      watched,
      favorite,
    }: {
      id: number
      watched?: boolean
      favorite?: boolean
    }) => {
      const body: { watched?: boolean; favorite?: boolean; id?: number } = {}
      if (watched != null) body.watched = watched
      if (favorite != null) body.favorite = favorite
      body.id = id

      await http.patch(`/v3/vods/${id}`, {
        json: body,
      })
    }
  )

  // PUT /v3/vods/{vodId}/continue-watching
  vods.updateContinueWatchingFx.use(async ({ vodId, stoppedTime }) => {
    const body: { stoppedTime: number } = { stoppedTime: 0 }

    if (stoppedTime == null)
      throw new Error('Empty stoppedTime arg in updateContinueWatchingFx')

    body.stoppedTime = Number(stoppedTime)

    await http.put(`/v3/vods/${vodId}/continue-watching`, {
      json: body,
    })
  })

  // DELETE /v3/vods/{vodId}/continue-watching
  vods.removeContinueWatchingFx.use(async ({ vodId }) => {
    await http.delete(`/v3/vods/${vodId}/continue-watching`)
  })

  // GET /v3/vods/?sort-by=updatedTime&sort-order=desc
  vods.getLastAddedMoviesFx.use(
    async ({
      page,
      count = defaults.count || DEFAULT_PAGE_SIZE,
      categoryId = 0,
    }) => {
      const json = await http.get<ApiAnswerPageDtoVod>(
        `/v3/vods?categoryId=${categoryId}&page=${page}&count=${count}&sort-by=updatedTime&sort-order=desc` // however name is 'LastAdded' - 'sort-by=updatedTime' is default
      )

      if (!json || !json.payload) {
        throw new Error('Empty answer in getLastAddedMoviesFx')
      }

      return {
        ...json.payload,
        content: (json.payload.content || []).map(formatVod),
      } as Page<Vod>
    }
  )

  vods.generateVodPlaylistFx.use(
    async ({ categoryId, vodIndex, vodId, isAutoplay, isLooped }) => {
      const count = defaults.count || DEFAULT_PAGE_SIZE
      let vodPage = 0
      let nextIndex = 0

      if (vodIndex) {
        const indexInPage = vodIndex % count
        const isLastItemInPage = indexInPage === count - 1
        const currentItemPage = Math.floor(vodIndex / count)

        vodPage = isLastItemInPage ? currentItemPage + 1 : currentItemPage
        nextIndex = isLastItemInPage ? 0 : indexInPage
      }

      const vodsArr = (
        await vods.getVodsByCategoryIdFx({
          page: Number(vodPage),
          categoryId: categoryId ?? 0,
        })
      ).content

      if (!vodIndex) {
        const currentVodIndex = vodsArr.findIndex(({ id }) => id === vodId) ?? 0

        nextIndex = currentVodIndex + 1
      }

      const currentVod = await vods.getVodFx({ vodId })

      const nextVod = vodsArr[nextIndex]

      return {
        categoryId: categoryId,
        vodId: vodId,
        vodIndex: vodIndex + 1,
        currentVod: currentVod ? formatVod(currentVod) : null,
        nextVods: vodsArr.slice(
          nextIndex,
          nextIndex + RECOMMENDED_PLAYLIST_COUNT
        ),
        nextVod: nextVod ? formatVod(nextVod) : null,
        isAutoplay,
        isLooped,
      }
    }
  )

  const formatToRecommendedVod = (categoryId: number) => (vod: Vod) => ({
    ...vod,
    categoryId,
  })

  vods.getRecommendedVodsFx.use(async ({ categoryId, vodId, ...rest }) => {
    let category: VodCategory

    if (categoryId === 0) {
      // You could not get that Featured category from b.e.
      category = featuredCategoryMock
    } else {
      category = await vods.getVodCategoryFx({ categoryId })
    }

    const formatVod = formatToRecommendedVod(categoryId)

    // FP-199
    const orderRecommended = (vodsArr: Array<Vod>) => {
      const foundIndex = vodsArr.findIndex((show) => show.id === vodId)
      const currentIndex = foundIndex === -1 ? 0 : foundIndex

      const firstPart = vodsArr.slice(
        currentIndex,
        RECOMMENDED_AMOUNT_VODS_AND_CURRENT
      )
      const secondPart = vodsArr.slice(0, currentIndex)
      const orderedRecommended =
        currentIndex >= 0 ? [...firstPart, ...secondPart] : vodsArr

      const filterNoCurrent = orderedRecommended
        .filter((vod) => vod.id !== vodId)
        .slice(0, RECOMMENDED_AMOUNT_VODS)

      return {
        ...category,
        content: filterNoCurrent.map(formatVod),
        description: 'string', // TODO temporary mock for show, refactor later
      }
    }

    if ('vodIndex' in rest && typeof rest.vodIndex === 'number') {
      const count = defaults.count || DEFAULT_PAGE_SIZE

      if (count <= RECOMMENDED_AMOUNT_VODS)
        throw new Error('Issue with amounts of recommended vods logic')

      const indexInPage = rest.vodIndex % count

      const vodPageumber = Math.floor(rest.vodIndex / count)

      const isRightOffsetEnough = count - indexInPage >= RECOMMENDED_AMOUNT_VODS

      const firstRecommendedPage = await vods.getVodsByCategoryIdFx({
        page: vodPageumber,
        categoryId,
      })
      let secondRecommendedPage: Array<Vod> = []
      if (!isRightOffsetEnough && !firstRecommendedPage.last)
        secondRecommendedPage = (
          await vods.getVodsByCategoryIdFx({
            page: vodPageumber + 1,
            categoryId,
          })
        ).content

      const twoPagesOfVods = [
        ...firstRecommendedPage.content,
        ...secondRecommendedPage,
      ]
      const amount = twoPagesOfVods.length

      if (amount <= RECOMMENDED_AMOUNT_VODS_AND_CURRENT)
        return orderRecommended(twoPagesOfVods)
      if (indexInPage + RECOMMENDED_AMOUNT_VODS > amount)
        return orderRecommended(
          twoPagesOfVods.slice(
            amount - RECOMMENDED_AMOUNT_VODS_AND_CURRENT,
            amount
          )
        )
      if (indexInPage + RECOMMENDED_AMOUNT_VODS <= amount)
        return orderRecommended(
          twoPagesOfVods.slice(
            indexInPage,
            indexInPage + RECOMMENDED_AMOUNT_VODS + 1
          )
        )
    }

    // Fallback algo. where 'vodIndex' not provided
    const vodsArr = await vods.getVodsByCategoryIdFx({
      page: 0,
      categoryId,
      count: RECOMMENDED_AMOUNT_VODS_AND_CURRENT,
    })

    return orderRecommended(vodsArr.content)
  })

  vods.initVodsCategoryFx.use(async ({ categoryId, count }) => {
    const category = await vods.getVodCategoryFx({ categoryId })

    const vodsArr = await vods.loadVodsFx({ categoryId, page: 0, count })

    return {
      ...category,
      content: vodsArr.content,
    }
  })

  sample({
    clock: vods.initVodsSubCategoryFx,
    target: vods.loadParentCategoryVodFx,
  })

  vods.loadSubCategoriesWithVodsFx.use(async ({ page, count, categoryId }) => {
    // TODO fix that effect as this one is used as side effect
    const pageOfCategories = await vods.loadSubCategoriesFx({
      page,
      count,
      categoryId,
    })
    const categoriesIdsArray = pageOfCategories.content.map(
      (category) => category.id
    )
    const promises = categoriesIdsArray.map((categoryId) =>
      vods
        .loadVodsOfSubcategoryFx({
          page: 0,
          categoryId: Number(categoryId),
        })
        .catch(() => ({ content: [] }))
    )
    await Promise.all(promises)
  })

  // GET /v3/vods?only-recommended=true
  vods.getRealRecommendedVodsFx.use(
    async ({ page, count = defaults.count || DEFAULT_PAGE_SIZE }) => {
      const params = new URLSearchParams()
      params.set('page', String(page))
      params.set('count', String(count))

      let json
      try {
        // /v3/vods?only-recommended=true
        json = await http.get<GenericApiResponse<Page<VodDto>>>(
          `/v3/vods?only-recommended=true&${params}`
        )
      } catch (apiCallError) {
        console.log(' error ', apiCallError)
      }
      if (!json || !json.payload) {
        throw new Error('Empty answer getRealRecommendedVodsChannelsFx')
      }

      return {
        ...json.payload,
        content: (json.payload.content || []).map(formatVod),
      }
    }
  )

  vods.getCategoriesWithVodsFx.use(async ({ page, count }) => {
    const pageOfCategories = await vods.getCategoriesFx({ page, count })
    const categoriesIdsArray = pageOfCategories.content.map(
      (category) => category.id
    )

    const vodsPromises = categoriesIdsArray.map((categoryId) =>
      vods
        .getVodsByCategoryIdFx({
          page: 0,
          categoryId: Number(categoryId),
        })
        .then((data) => ({
          categoryId: Number(categoryId),
          data,
        }))
        .catch(() => ({
          categoryId: Number(categoryId),
          data: undefined,
        }))
    )

    const vodsPages = await Promise.all(vodsPromises)

    const formattedCategory = pageOfCategories.content.map(
      (category): CategoryWithVodsApi => {
        const vodsPageOfCategory = vodsPages.find(
          (vods) => vods.categoryId === category.id
        )?.data

        const isMoreThanOnePage =
          !vodsPageOfCategory?.last ||
          vodsPageOfCategory?.content.length > DEFAULT_PAGE_SIZE

        const isVodsExist = vodsPageOfCategory?.content.length
        const pageOfVods: Page<Vod> = isVodsExist
          ? {
              ...vodsPageOfCategory,
              content: vodsPageOfCategory.content,
            }
          : { content: [], ...getFirstPagePaginateInfo({ totalElements: 0 }) }

        return {
          ...category,
          content: pageOfVods,
          isMoreThanOnePage,
          isMoreThanOnePageSubCategories:
            Number(category.subCategories?.length) > DEFAULT_PAGE_SIZE,
          subCategories: category.subCategories?.slice(0, DEFAULT_PAGE_SIZE),
        }
      }
    )

    return {
      ...pageOfCategories,
      content: formattedCategory,
    }
  })
}
