import type {
  API,
  CategoryWithShowsApi,
  MediaUrl,
  Page,
  PlaylistSeason,
  TvShow,
  TvShowCategory,
  TvShowEpisode,
  TvShowSeason,
} from '@setplex/tria-api'
import {
  RECOMMENDED_AMOUNT_SHOWS,
  RECOMMENDED_AMOUNT_SHOWS_AND_CURRENT,
} from '@setplex/tria-api'
import {
  type ApiAnswerPageDtoTvShowCategoryDto,
  type EpisodeDto,
} from '@setplex/wbs-api-types'
import { sample } from 'effector'
import {
  DEFAULT_PAGE_SIZE,
  RECOMMENDED_PLAYLIST_COUNT,
} from '../../constants/generic'
import { type HttpClient } from '../../http'
import type { AdapterDefaults } from '../../index.h'
import type {
  ApiAnswerTvShowCategories,
  ApiAnswerTvShowEpisode,
  ApiAnswerTvShowEpisodesByPage,
  ApiAnswerTvShowSeason,
  ApiAnswerTvShowSeasonsByPage,
  ApiAnswerTvShowUrl,
  ApiAnswerTvShowsByPage,
  ApiAnswerTvShowsUpdatedOnly,
} from '../../interfaces/tvshows'
import {
  type ApiAnswerTvShowsList,
  type ApiTvShowCategory,
} from '../../interfaces/tvshows'
import { getFirstPagePaginateInfo } from '../../utils/pageInfo'
import {
  formatEpisode,
  formatSeason,
  formatSeasonPlaylist,
  formatShow,
  formatTvShowUpdatedOnly,
} from './tvshows.format'

const arrayToQueryParam = (param: string, array: number[]) =>
  array?.map((id) => `&${param}=${String(id)}`).join('')

export function use(
  http: HttpClient,
  tvshows: API['tvshows'],
  _api: API,
  defaults: AdapterDefaults
): void {
  // GET /v3/tvshows?sort-by=updatedTime&sort-order=desc
  tvshows.getLastAddedTvShowsFx.use(
    async ({
      page,
      count = defaults.count || DEFAULT_PAGE_SIZE,
      categoryId,
    }) => {
      const params = new URLSearchParams()
      params.set('page', String(page))
      params.set('count', String(count))
      if (categoryId) params.set('categoryId', String(categoryId))

      const json = await http.get<ApiAnswerTvShowsByPage>(
        `/v3/tvshows?${params}&sort-by=updatedTime&sort-order=desc` // however name is 'LastAdded' - 'sort-by=updatedTime' is default
      )
      if (!json || !json.payload) {
        throw new Error('Empty answer in getLastAddedTvShowsFx')
      }
      return {
        ...json.payload,
        content: (json.payload.content || []).map(formatShow),
      } as Page<TvShow>
    }
  )

  // GET /v3/tvshows
  tvshows.getAllTvShowsFx.use(
    async ({
      page,
      count = defaults.count || DEFAULT_PAGE_SIZE,
      categoryId,
      sortOrder,
      sortBy,
      q,
      onlyFavorites,
      ids,
    }) => {
      const params = new URLSearchParams()
      params.set('page', String(page))
      params.set('count', String(count))
      if (sortBy) params.set('sort-by', String(sortBy))
      if (sortOrder) params.set('sort-order', String(sortOrder))
      if (q) params.set('q', String(q))
      if (categoryId != null) params.set('categoryId', String(categoryId))
      if (onlyFavorites) params.set('only-favorites', String(onlyFavorites))
      const idsQuery = arrayToQueryParam('ids', ids || [])

      let json
      try {
        json = await http.get<ApiAnswerTvShowsByPage>(
          `/v3/tvshows?${params}${idsQuery}`
        )
      } catch (apiCallError) {
        console.log('getAllTvShowsFx error ', apiCallError)
      }

      if (!json || !json.payload) {
        throw new Error('Empty answer in getAllTvShowsFx')
      }
      return {
        ...json.payload,
        content: (json.payload.content || []).map(formatShow),
      } as Page<TvShow>
    }
  )

  // GET /v3/library/tvshows
  tvshows.getPurchasedTvShowsFx.use(
    async ({
      page = 0,
      count = defaults.count || 36,
      rented = true,
      purchased = true,
    }) => {
      let json
      try {
        json = await http.get<ApiAnswerTvShowsByPage>(`/v3/library/tvshows`, {
          searchParams: {
            page,
            count,
            rented,
            purchased,
          },
        })
      } catch (apiCallError) {
        console.log('getPurchasedTvShowsFx error ', apiCallError)
      }

      if (!json || !json.payload) {
        throw new Error('Empty answer in getPurchasedTvShowsFx')
      }

      return {
        ...json.payload,
        content: (json.payload.content || []).map(formatShow),
      }
    }
  )

  // GET /v3/tvshows?ids=tvShowId
  tvshows.getTvShowBaseFx.use(async ({ tvShowId }) => {
    // without pagination, other params will be ignored
    let json
    try {
      json = await http.get<ApiAnswerTvShowsList>(`/v3/tvshows?ids=${tvShowId}`)
    } catch (apiCallError) {
      console.log('getTvShowsFx error ', apiCallError)
    }

    if (!json || !json.payload) {
      throw new Error('Empty answer in getTvShowsFx')
    }
    const arrayOfShows = json.payload
    return arrayOfShows.map(formatShow)
  })

  // GET /v3/tvshows?categoryId=categoryId
  tvshows.getShowsByCategoryIdFx.use(
    async ({
      page,
      count = defaults.count || DEFAULT_PAGE_SIZE,
      categoryId,
    }) => {
      const params = new URLSearchParams()
      params.set('page', String(page))
      params.set('count', String(count))
      if (categoryId) params.set('categoryId', String(categoryId))

      let json
      try {
        json = await http.get<ApiAnswerTvShowsByPage>(`/v3/tvshows?${params}`)
      } catch (apiCallError) {
        console.log('getShowsByCategoryIdWithParamsFx error ', apiCallError)
      }

      if (!json || !json.payload) {
        throw new Error('Empty answer in getShowsByCategoryIdWithParamsFx')
      }
      return {
        ...json.payload,
        content: (json.payload.content || []).map(formatShow),
      } as Page<TvShow>
    }
  )

  // GET /v3/tvshows?&updatedTimeOnly=true
  tvshows.getTvShowsUpdatedOnlyFx.use(async () => {
    let json
    try {
      json = await http.get<ApiAnswerTvShowsUpdatedOnly>(
        `/v3/tvshows?&updatedTimeOnly=true`
      )
    } catch (apiCallError) {
      console.log('getTvShowsUpdatedOnlyFx error ', apiCallError)
    }

    if (!json || !json.payload) {
      throw new Error('Empty answer in getLastAddedTvShowsFx')
    }
    return json.payload.map(formatTvShowUpdatedOnly)
  })

  // GET /v3/tvshows/categories
  tvshows.getTvShowCategoriesFx.use(
    async ({
      page,
      count = defaults.count || DEFAULT_PAGE_SIZE,
      sortOrder = defaults.sortOrder || 'asc',
    }) => {
      const params = new URLSearchParams()
      params.set('page', String(page))
      params.set('count', String(count))
      params.set('sortOrder', String(sortOrder))

      let json
      try {
        json = await http.get<ApiAnswerPageDtoTvShowCategoryDto>(
          `/v3/tvshows/categories?${params}`
        )
      } catch (apiCallError) {
        console.log('getTvShowCategoriesFx error ', apiCallError)
      }

      if (!json || !json.payload) {
        throw new Error('Empty answer in getTvShowCategoriesFx')
      }
      return {
        ...json.payload,
        content: json.payload.content || [],
      } as Page<TvShowCategory>
    }
  )

  // GET /v3/tvshows/categories/{categoryId}/subcategories
  tvshows.getTvShowSubcategoriesByCategoryFx.use(
    async ({
      categoryId,
      page,
      count = defaults.count || DEFAULT_PAGE_SIZE,
      sortOrder = defaults.sortOrder || 'asc',
    }) => {
      const params = new URLSearchParams()
      params.set('page', String(page))
      params.set('count', String(count))
      params.set('sortOrder', String(sortOrder))

      let json
      try {
        json = await http.get<ApiAnswerTvShowCategories>(
          `/v3/tvshows/categories/${categoryId}/subcategories?${params}`
        )
      } catch (apiCallError) {
        console.log('getTvShowCategoriesFx error ', apiCallError)
      }

      if (!json || !json.payload) {
        throw new Error('Empty answer in getTvShowSubcategoriesByCategoryFx')
      }
      return {
        ...json.payload,
        content: json.payload.content || [],
      } as Page<TvShowCategory>
    }
  )

  // GET /v3/tvshows/{tvShowId}/trailer/url
  tvshows.getTvShowTrailerUrlFx.use(async ({ tvShowId }) => {
    const json = await http.get<ApiAnswerTvShowUrl>(
      `/v3/tvshows/${tvShowId}/trailer/url`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in getTvShowTrailerUrlFx')
    }
    return json.payload as MediaUrl
  })

  // PATCH /v3/tvshows/{tvShowId}
  tvshows.updateTvShowFx.use(async ({ id, favorite }) => {
    const body: { id?: number; favorite?: boolean } = {}
    if (id != null) body.id = Number(id)
    if (favorite != null) body.favorite = Boolean(favorite)

    try {
      return await http.patch(`/v3/tvshows/${id}`, {
        json: body,
      })
    } catch (apiCallError) {
      console.log('updateTvShowFx error ', apiCallError)
    }
  })

  // PUT /v3/tvshows/{tvShowId}/seasons/{seasonId}/episodes/{episodeId}/continue-watching
  tvshows.updateContinueWatchingTvShowFx.use(
    async ({ tvShowId, seasonId, episodeId, stoppedTime }) => {
      const body: { stoppedTime: number } = { stoppedTime: 0 }
      if (stoppedTime == null)
        throw new Error(
          'Empty stoppedTime arg in updateContinueWatchingTvShowFx'
        )

      body.stoppedTime = Number(stoppedTime)

      return await http.put(
        `/v3/tvshows/${tvShowId}/seasons/${seasonId}/episodes/${episodeId}/continue-watching`,
        {
          json: body,
        }
      )
    }
  )

  tvshows.removeContinueWatchingTvShowFx.use(
    async ({ tvShowId, seasonId, episodeId }) => {
      return await http.delete(
        `/v3/tvshows/${tvShowId}/seasons/${seasonId}/episodes/${episodeId}/continue-watching`
      )
    }
  )

  // PUT /v3/tvshows/{tvShowId}/episodes/{episodeId}/continue-watching
  tvshows.updateContinueWatchingWithoutSeasonFx.use(
    async ({ tvShowId, episodeId, stoppedTime }) => {
      const body: { stoppedTime: number } = { stoppedTime: 0 }
      if (stoppedTime == null)
        throw new Error(
          'Empty stoppedTime arg in updateContinueWatchingWithoutSeasonFx'
        )

      body.stoppedTime = Number(stoppedTime)

      return await http.put(
        `/v3/tvshows/${tvShowId}/episodes/${episodeId}/continue-watching`,
        {
          json: body,
        }
      )
    }
  )

  // DELETE /v3/tvshows/{tvShowId}/episodes/{episodeId}/continue-watching
  tvshows.removeContinueWatchingWithoutSeasonFx.use(
    async ({ tvShowId, episodeId }) => {
      return await http.delete(
        `/v3/tvshows/${tvShowId}/episodes/${episodeId}/continue-watching`
      )
    }
  )

  // GET /v3/tvshows/{tvShowId}/seasons/{seasonId}/episodes/{episodeId}
  tvshows.getTvShowEpisodeFx.use(async ({ tvShowId, seasonId, episodeId }) => {
    let json
    try {
      json = await http.get<ApiAnswerTvShowEpisode>(
        `/v3/tvshows/${tvShowId}/seasons/${seasonId}/episodes/${episodeId}`
      )
    } catch (apiCallError) {
      console.log('getTvShowEpisodeFx error ', apiCallError)
    }

    if (!json || !json.payload) {
      throw new Error('Empty answer in getTvShowEpisodeFx')
    }
    return formatEpisode(json.payload)
  })

  // GET /v3/tvshows/{tvShowId}/episodes/{episodeId}
  tvshows.getTvShowEpisodeWithoutSeasonFx.use(
    async ({ tvShowId, episodeId }) => {
      let json
      try {
        json = await http.get<ApiAnswerTvShowEpisode>(
          `/v3/tvshows/${tvShowId}/episodes/${episodeId}`
        )
      } catch (apiCallError) {
        console.log('getTvShowWithoutSeasonFx error ', apiCallError)
      }

      if (!json || !json.payload) {
        throw new Error('Empty answer in getTvShowWithoutSeasonFx')
      }
      return json.payload as TvShowEpisode //format
    }
  )

  // GET /v3/tvshows/{tvShowId}/season/{seasonId}/episodes
  tvshows.getTvShowEpisodesBySeasonByPageBaseFx.use(
    async ({
      tvShowId,
      seasonId,
      page,
      sortBy = 'updatedTime',
      count = defaults.count || DEFAULT_PAGE_SIZE,
      sortOrder = defaults.sortOrder || 'asc',
    }) => {
      const params = new URLSearchParams()
      params.set('page', String(page))
      if (sortBy) params.set('sort-by', String(sortBy))
      if (count) params.set('count', String(count))
      if (sortOrder) params.set('sortOrder', String(sortOrder))

      let json
      try {
        json = await http.get<ApiAnswerTvShowEpisodesByPage>(
          `/v3/tvshows/${tvShowId}/seasons/${seasonId}/episodes?${params}`
        )
      } catch (apiCallError) {
        console.log(
          'getTvShowEpisodesBySeasonByPageBaseFx error ',
          apiCallError
        )
      }

      if (!json || !json.payload) {
        throw new Error('Empty answer in getTvShowEpisodesBySeasonByPageBaseFx')
      }
      return {
        ...json.payload,
        content: (json.payload.content || []).map(formatEpisode),
      } as Page<TvShowEpisode>
    }
  )

  // GET /v3/tvshows/{tvShowId}/episodes
  tvshows.getTvShowEpisodesWithoutSeasonByPageBaseFx.use(
    async ({
      tvShowId,
      page,
      count = defaults.count || DEFAULT_PAGE_SIZE,
      sortBy = 'updatedTime',
      sortOrder = defaults.sortOrder || 'asc',
    }) => {
      const params = new URLSearchParams()
      params.set('page', String(page))
      if (count) params.set('count', String(count))
      if (sortBy) params.set('sort-by', String(sortBy))
      if (sortOrder) params.set('sortOrder', String(sortOrder))

      let json
      try {
        json = await http.get<ApiAnswerTvShowEpisodesByPage>(
          `/v3/tvshows/${tvShowId}/episodes?${params}`
        )
      } catch (apiCallError) {
        console.log(
          'getTvShowEpisodesWithoutSeasonByPageBaseFx error ',
          apiCallError
        )
      }

      if (!json || !json.payload) {
        throw new Error(
          'Empty answer in getTvShowEpisodesWithoutSeasonByPageBaseFx'
        )
      }
      return {
        ...json.payload,
        content: (json.payload.content || []).map(formatEpisode),
      } as Page<TvShowEpisode>
    }
  )

  // GET /v3/tvshows/{tvShowId}/seasons/{seasonId}
  tvshows.getTvShowSeasonByIdFx.use(async ({ tvShowId, seasonId }) => {
    let json
    try {
      json = await http.get<ApiAnswerTvShowSeason>(
        `/v3/tvshows/${tvShowId}/seasons/${seasonId}`
      )
    } catch (apiCallError) {
      console.log('getTvShowSeasonByIdFx error ', apiCallError)
    }

    if (!json || !json.payload) {
      throw new Error('Empty answer in getTvShowSeasonByIdFx')
    }
    return formatSeason(json.payload)
  })

  // GET /v3/tvshows/{tvShowId}/seasons
  tvshows.getTvShowSeasonsByPageBaseFx.use(
    async ({
      tvShowId,
      page,
      count = defaults.count || DEFAULT_PAGE_SIZE,
      sortBy = 'updatedTime',
      sortOrder = defaults.sortOrder || 'asc',
    }) => {
      const params = new URLSearchParams()
      params.set('page', String(page))
      if (count) params.set('count', String(count))
      if (sortBy) params.set('sort-by', String(sortBy))
      if (sortOrder) params.set('sortOrder', String(sortOrder))

      let json
      try {
        json = await http.get<ApiAnswerTvShowSeasonsByPage>(
          `/v3/tvshows/${tvShowId}/seasons?${params}`
        )
      } catch (apiCallError) {
        console.log('getTvShowSeasonsByPageBaseFx error ', apiCallError)
      }

      if (!json || !json.payload) {
        throw new Error('Empty answer in getTvShowSeasonsByPageBaseFx')
      }
      return {
        ...json.payload,
        content: (json.payload.content || []).map(formatSeason),
      } as Page<TvShowSeason>
    }
  )

  // GET /v3/tvshows/{tvShowId}/seasons/{seasonId}/episodes/{episodeId}/url
  tvshows.getTvShowEpisodeUrlFx.use(
    async ({ tvShowId, seasonId, episodeId }) => {
      const json = await http.get<ApiAnswerTvShowUrl>(
        `/v3/tvshows/${tvShowId}/seasons/${seasonId}/episodes/${episodeId}/url`
      )

      if (!json || !json.payload) {
        throw new Error('Empty answer in getTvShowEpisodeUrlFx')
      }
      return json.payload as MediaUrl
    }
  )

  // GET /v3/tvshows/{tvShowId}/episodes/{episodeId}/url
  tvshows.getEpisodeUrlWithoutSeasonFx.use(async ({ tvShowId, episodeId }) => {
    const json = await http.get<ApiAnswerTvShowUrl>(
      `/v3/tvshows/${tvShowId}/episodes/${episodeId}/url`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in getEpisodeUrlWithoutSeasonFx')
    }
    return json.payload as MediaUrl
  })

  // POST /v3/tvshows/{tvShowId}/seasons/{seasonId}/episodes/{episodeId}/watched
  tvshows.postMarkEpisodeWatchedFx.use(
    async ({ tvShowId, seasonId, episodeId }) => {
      try {
        return await http.post(
          `/v3/tvshows/${tvShowId}/seasons/${seasonId}/episodes/${episodeId}/watched`
        )
      } catch (apiCallError) {
        console.log('postMarkEpisodeWatchedFx error ', apiCallError)
      }
    }
  )

  // POST /v3/tvshows/{tvShowId}/episodes/{episodeId}/watched
  tvshows.postMarkEpisodeWatchedWithoutSeasonFx.use(
    async ({ tvShowId, episodeId }) => {
      try {
        return await http.post(
          `/v3/tvshows/${tvShowId}/episodes/${episodeId}/watched`
        )
      } catch (apiCallError) {
        console.log(
          'postMarkEpisodeWatchedWithoutSeasonFx error ',
          apiCallError
        )
      }
    }
  )

  tvshows.getSeasonsWithEpisodesFx.use(async ({ tvShowId }) => {
    const currentTvShow = await tvshows.getCurrenShowByIdFx({
      tvShowId,
    })
    const { withSeason } = currentTvShow?.[0] || {}
    const getEpisodes = async ({
      seasonId,
      tvShowId,
    }: {
      seasonId?: number
      tvShowId: number
    }) =>
      await tvshows.getAllEpisodesBySeasonFx({
        seasonId,
        tvShowId,
      })

    if (!withSeason) {
      const episodes = await getEpisodes({ tvShowId })
      return { episodes }
    }

    const seasons = await tvshows.getAllSeasonsByShowIdFx({
      tvShowId,
    })

    const promises = seasons.map(async ({ id: seasonId, ...rest }) => {
      const episodes = await getEpisodes({ seasonId, tvShowId })
      return {
        ...rest,
        id: seasonId,
        episodes,
        episodesAmount: episodes.length,
      }
    })

    const seasonsWithEpisodes = await Promise.all(promises)
    return { seasonsWithEpisodes }
  })

  tvshows.getAllSeasonsFx.use(async ({ tvShowId }) => {
    const firstPageSeasons = await tvshows.getTvShowSeasonsByPageFx({
      tvShowId,
      page: 0,
    })

    let restSeasons: Array<TvShowSeason> = []
    if (!firstPageSeasons.last) {
      const pagesToFetch = Array.from(
        { length: firstPageSeasons.totalPages - 1 },
        (_, i) => i + 1
      )

      const promises = pagesToFetch.map((page) =>
        tvshows.getTvShowSeasonsByPageFx({ page: page, tvShowId })
      )
      const seasonsData = await Promise.all(promises)

      restSeasons = seasonsData
        .map((page) => page.content)
        .reduce((acc, val) => acc.concat(val), [])
    }
    return [...firstPageSeasons.content, ...restSeasons]
  })

  tvshows.getAllEpisodesFx.use(async ({ tvShowId, seasonId, ...rest }) => {
    // also works for seasonId = undefined
    const firstPageEpisodes = await tvshows.getTvShowEpisodesSmartFx({
      tvShowId,
      seasonId,
      page: 0,
      ...rest,
    })

    let restEpisodes: Array<TvShowEpisode> = []
    if (!firstPageEpisodes.last) {
      const pagesToFetch = Array.from(
        { length: firstPageEpisodes.totalPages - 1 },
        (_, i) => i + 1
      )

      const promises = pagesToFetch.map((page) =>
        tvshows.getTvShowEpisodesSmartFx({
          page: page,
          seasonId,
          tvShowId,
          ...rest,
        })
      )
      const episodesData: Array<Page<EpisodeDto>> = await Promise.all(promises)

      restEpisodes = episodesData
        .map((page) => page.content.map(formatEpisode))
        .reduce((acc, val) => acc.concat(val), [])
    }

    return [...firstPageEpisodes.content, ...restEpisodes]
  })

  tvshows.getAllEpisodesForSeasonsFx.use(async ({ seasons, tvShowId }) => {
    let episodesBySeason: { [key: number]: Array<TvShowEpisode> } = {}
    const promises = seasons.map(async (season) => {
      const episodes = await tvshows.getAllEpisodesFx({
        tvShowId,
        seasonId: season.id,
      })
      return { [season.id]: episodes }
    })

    const episodesData = await Promise.all(promises)

    episodesBySeason = episodesData.reduce(
      (acc, val) => ({ ...acc, ...val }),
      {}
    )

    return episodesBySeason
  })

  tvshows.getNextShowFx.use(async ({ categoryId, showIndex, tvShowId }) => {
    const count = defaults.count || DEFAULT_PAGE_SIZE

    let showPage = 0
    let nextIndex = 0

    if (showIndex) {
      const indexInPage = showIndex % count
      const isLastItemInPage = indexInPage === count - 1
      const currentItemPage = Math.floor(showIndex / count)
      showPage = isLastItemInPage ? currentItemPage + 1 : currentItemPage
      nextIndex = isLastItemInPage ? 0 : indexInPage
    }

    const showsArr = (
      await tvshows.getShowsByCategoryIdFx({
        page: Number(showPage),
        categoryId,
      })
    ).content.filter(({ episodeCount }) => episodeCount)

    if (!showIndex) {
      const currentTvShowIndex =
        showsArr.findIndex(({ id }) => id === tvShowId) ?? 0
      nextIndex = currentTvShowIndex + 1
    }

    return {
      nextTvShow: showsArr[nextIndex],
      nextTvShows: showsArr.slice(
        nextIndex,
        nextIndex + RECOMMENDED_PLAYLIST_COUNT
      ),
    }
  })

  tvshows.continueGenSeasonLessSeriesPlaylistFx.use(
    async ({
      tvShowId,
      episodeId,
      isAutoplay,
      isLooped,
      categoryId,
      showIndex,
    }) => {
      const currentTvShow = (await tvshows.getTvShowBaseFx({ tvShowId }))[0]

      const allEpisodes = (
        await tvshows.getAllEpisodesFx({
          tvShowId,
        })
      ).map(formatEpisode)
      const currentEpisodeIndex = allEpisodes.findIndex(
        (ep) => ep.id === episodeId
      )

      // last episode in show case
      if (allEpisodes.length - 1 === currentEpisodeIndex) {
        let nextShowData = null
        if (Number.isFinite(categoryId) && Number.isFinite(showIndex)) {
          nextShowData = await tvshows.getNextShowFx({
            categoryId: Number(categoryId),
            tvShowId,
            showIndex: Number(showIndex),
          })
        }
        const nextTvShowId = nextShowData?.nextTvShow?.id
        const nextTvShows = nextShowData?.nextTvShows || null

        // const firstEpisode = (
        //   await tvshows.getTvShowEpisodesWithoutSeasonByPageFx({
        //     tvShowId,
        //     page: 0,
        //   })
        // ).content?.[0]

        return {
          nextEpisode: null, // null for last episode in show case
          currentEpisode: allEpisodes[currentEpisodeIndex],
          currentSeason: null,
          currentTvShow,
          episodes: allEpisodes,
          nextTvShowId: nextTvShowId ? Number(nextTvShowId) : null,
          nextTvShows,
          nextSeasonId: null,
          categoryId: Number(categoryId),
          isAutoplay,
          isLooped,
        }
      }

      const nextEpisode = allEpisodes[currentEpisodeIndex + 1]

      return {
        nextEpisode: nextEpisode || null,
        currentEpisode: allEpisodes[currentEpisodeIndex],
        currentSeason: null,
        currentTvShow,
        episodes: allEpisodes,
        nextTvShowId: tvShowId,
        nextTvShows: null,
        nextSeasonId: null,
        categoryId: Number(categoryId),
        isAutoplay,
        isLooped,
      }
    }
  )

  tvshows.continueGenWithSeasonSeriesPlaylistFx.use(
    async ({
      tvShowId,
      seasonId,
      episodeId,
      isAutoplay,
      isLooped,
      showIndex,
      categoryId,
    }) => {
      const currentTvShow = (await tvshows.getTvShowBaseFx({ tvShowId }))[0]

      const seasons = await tvshows.getAllSeasonsFx({
        tvShowId,
      })

      const episodesBySeason = await tvshows.getAllEpisodesForSeasonsFx({
        tvShowId,
        seasons: seasons.map((season) => ({ id: Number(season.id) })),
      })

      const seasonsWithEpisodes = seasons
        .map((season) => {
          const seasonId = Number(season.id)
          const seasonEpisodes = episodesBySeason[seasonId] || []

          return formatSeasonPlaylist({
            season,
            episodesAmount: seasonEpisodes.length,
            episodes: seasonEpisodes.map(formatEpisode),
          })
        })
        .filter((season) => season.episodesAmount)

      let currentSeason: PlaylistSeason
      currentSeason =
        seasonsWithEpisodes.find((season) =>
          season.episodes.some((episode) => episodeId === episode.id)
        ) || seasonsWithEpisodes[0]

      const currentEpisodes = currentSeason?.episodes || []

      const currentEpisode =
        currentEpisodes.find((episode) => episodeId === episode.id) ?? null

      const currentEpPosition = currentEpisodes.findIndex(
        (ep) => ep === currentEpisode
      )

      const isLastEpisode = currentEpisodes.length - 1 === currentEpPosition

      const isLastSeason =
        currentSeason.id ===
        seasonsWithEpisodes[seasonsWithEpisodes.length - 1].id

      // common case - next episode
      const nextEpisode = currentEpisodes[currentEpPosition + 1]

      if (isLastSeason && isLastEpisode) {
        let nextShowData = null

        if (Number.isFinite(categoryId) && Number.isFinite(showIndex)) {
          nextShowData = await tvshows.getNextShowFx({
            categoryId: Number(categoryId),
            tvShowId,
            showIndex: Number(showIndex),
          })
        }

        const nextTvShowId = nextShowData?.nextTvShow?.id
        const nextTvShows = nextShowData?.nextTvShows || null

        let newSeasonId: number | null = null

        if (nextTvShowId) {
          const seasons = await tvshows.getTvShowSeasonsByPageFx({
            page: 0,
            tvShowId: Number(nextTvShowId),
          })

          if (seasons?.content.length > 0) {
            const firstSeason = seasons?.content.find(
              (season) => season.sortOrder === 0
            )
            newSeasonId = Number(firstSeason?.id)
          }
        }
        /**
         *  @todo: fix recomended cover and replace nextEpisode: undefined with real data.
         * */

        return {
          nextEpisode: null,
          currentEpisode,
          currentSeason,
          currentTvShow,
          seasonsWithEpisodes,
          nextTvShowId: nextTvShowId ? Number(nextTvShowId) : null,
          nextTvShows,
          nextSeasonId: newSeasonId,
          categoryId: Number(categoryId),
          isAutoplay,
          isLooped,
          showIndex,
        }
      }

      if (isLastEpisode) {
        const currentSeasonIndex = seasonsWithEpisodes.findIndex(
          ({ id }) => currentSeason.id === id
        )
        const nextSeasonIndex =
          currentSeasonIndex !== -1 ? currentSeasonIndex + 1 : 0

        const nextSeason =
          seasonsWithEpisodes[nextSeasonIndex] &&
          seasonsWithEpisodes[nextSeasonIndex]!.episodes?.length
            ? seasonsWithEpisodes[nextSeasonIndex]
            : null
        // : seasonsWithEpisodes[nextSeasonIndex + 1]

        return {
          nextEpisode: nextSeason?.episodes?.[0] || null,
          currentEpisode,
          currentSeason,
          currentTvShow,
          seasonsWithEpisodes,
          nextTvShowId: tvShowId,
          nextTvShows: null,
          nextSeasonId: nextSeason?.id ?? seasonId,
          categoryId: Number(categoryId),
          isAutoplay,
          isLooped,
          showIndex,
        }
      }

      return {
        nextEpisode: nextEpisode || null,
        currentEpisode,
        currentSeason,
        currentTvShow,
        seasonsWithEpisodes,
        nextTvShowId: tvShowId,
        nextSeasonId: seasonId,
        nextTvShows: null,
        categoryId: Number(categoryId),
        isAutoplay,
        isLooped,
        showIndex,
      }
    }
  )

  tvshows.generateSeriesPlaylistFx.use(
    async ({
      tvShowId,
      seasonId,
      episodeId,
      categoryId,
      showIndex,
      ...rest
    }) => {
      const isSeasonLess = !seasonId && typeof seasonId !== 'number'

      if (isSeasonLess) {
        return await tvshows.continueGenSeasonLessSeriesPlaylistFx({
          tvShowId,
          episodeId,
          categoryId,
          showIndex,
          ...rest,
        })
      }

      return await tvshows.continueGenWithSeasonSeriesPlaylistFx({
        tvShowId,
        seasonId,
        episodeId,
        categoryId,
        showIndex,
        ...rest,
      })
    }
  )

  const formatToRecommendedShow = (categoryId: number) => (show: TvShow) => ({
    ...show,
    categoryId,
  })

  tvshows.getRecommendedTvShowsFx.use(
    async ({ categoryId, tvShowId, ...rest }) => {
      const formatShow = formatToRecommendedShow(categoryId)

      // FP-199
      const orderRecommended = (shows: Array<TvShow>) => {
        // it is not possible to get exact Category data from b.e!!!!!
        const foundIndex = shows.findIndex((show) => show.id === tvShowId)
        const currentIndex = foundIndex === -1 ? 0 : foundIndex

        // ordering from FP-199
        const firstPart = shows.slice(
          currentIndex,
          RECOMMENDED_AMOUNT_SHOWS_AND_CURRENT
        )
        const secondPart = shows.slice(0, currentIndex)
        const orderedRecommended =
          currentIndex >= 0 ? [...firstPart, ...secondPart] : shows

        const filterNoCurrent = orderedRecommended
          .filter((show) => show.id !== tvShowId)
          .slice(0, RECOMMENDED_AMOUNT_SHOWS)

        return filterNoCurrent.map(formatShow)
      }

      if ('showIndex' in rest && typeof rest.showIndex === 'number') {
        const count = defaults.count || DEFAULT_PAGE_SIZE

        if (count <= RECOMMENDED_AMOUNT_SHOWS)
          throw new Error('Issue with amounts of recommended shows logic')

        const indexInPage = rest.showIndex % count

        const showPageNumber = Math.floor(rest.showIndex / count)

        const isRightOffsetEnough =
          count - indexInPage >= RECOMMENDED_AMOUNT_SHOWS

        const firstRecommendedPage = await tvshows.getLastAddedTvShowsFx({
          page: showPageNumber,
          categoryId,
        })

        let secondRecommendedPage: Array<TvShow> = []
        if (!isRightOffsetEnough && !firstRecommendedPage.last)
          secondRecommendedPage = (
            await tvshows.getLastAddedTvShowsFx({
              page: showPageNumber + 1,
              categoryId,
            })
          ).content

        const twoPagesOfShows = [
          ...firstRecommendedPage.content,
          ...secondRecommendedPage,
        ]
        const amount = twoPagesOfShows.length

        if (amount <= RECOMMENDED_AMOUNT_SHOWS_AND_CURRENT)
          return orderRecommended(twoPagesOfShows)
        if (indexInPage + RECOMMENDED_AMOUNT_SHOWS > amount)
          return orderRecommended(
            twoPagesOfShows.slice(
              amount - RECOMMENDED_AMOUNT_SHOWS_AND_CURRENT,
              amount
            )
          )
        if (indexInPage + RECOMMENDED_AMOUNT_SHOWS <= amount)
          return orderRecommended(
            twoPagesOfShows.slice(
              indexInPage,
              indexInPage + RECOMMENDED_AMOUNT_SHOWS + 1
            )
          )
      }

      const tvShows = await tvshows.getLastAddedTvShowsFx({
        page: 0,
        categoryId,
        count: RECOMMENDED_AMOUNT_SHOWS_AND_CURRENT,
      })

      return orderRecommended(tvShows.content)
    }
  )

  const getDefaultAllCategory = (): TvShowCategory => ({
    id: 0,
    name: 'All Series',
    sortOrder: 0,
    parentCategoryId: 0,
    tvShowQuantity: 0,
    subCategories: [],
  })
  tvshows.getTvShowCategoryFx.use(async ({ categoryId }) => {
    if (categoryId === 0) return getDefaultAllCategory()

    const json = await http.get<ApiTvShowCategory>(
      `/v3/tvshows/categories/${categoryId}`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in getTvShowCategoryFx')
    }
    return json.payload
  })

  tvshows.initShowsCategoryFx.use(async ({ categoryId, count }) => {
    const category = await tvshows.getTvShowCategoryFx({ categoryId })

    const shows = await tvshows.loadShowsFx({ categoryId, page: 0, count })

    return {
      ...category,
      content: shows.content,
    }
  })

  sample({
    clock: tvshows.initShowsSubCategoriesFx,
    target: tvshows.loadParentCategoryFx,
  })

  tvshows.loadSubCategoriesWithShowsFx.use(
    async ({ page, count, categoryId }) => {
      // TODO fix that effect as this one is used as side effect
      const pageOfCategories = await tvshows.loadSubCategoriesShowsFx({
        page,
        count,
        categoryId,
      })
      const categoriesIdsArray = pageOfCategories.content.map(
        (category) => category.id
      )
      const promises = categoriesIdsArray.map((categoryId) =>
        tvshows
          .loadShowsOfSubcategoryFx({
            page: 0,
            categoryId: Number(categoryId),
          })
          .catch(() => ({ content: [] }))
      )
      await Promise.all(promises)
    }
  )

  tvshows.getCategoriesWithShowsFx.use(async ({ page, count }) => {
    const pageOfCategories = await tvshows.getTvShowCategoriesFx({
      page,
      count,
    })
    const categoriesIdsArray = pageOfCategories.content.map(
      (category) => category.id
    )

    const showsPromises = categoriesIdsArray.map((categoryId) =>
      tvshows
        .getShowsByCategoryIdFx({
          page: 0,
          categoryId: Number(categoryId),
        })
        .then((data) => ({
          categoryId: Number(categoryId),
          data,
        }))
        .catch(() => ({ categoryId: Number(categoryId), data: undefined }))
    )

    const showsPages = await Promise.all(showsPromises)

    const formattedCategory = pageOfCategories.content.map(
      (category): CategoryWithShowsApi => {
        const showsPageOfCategory = showsPages.find(
          (shows) => shows.categoryId === category.id
        )?.data

        const isMoreThanOnePage =
          !showsPageOfCategory?.last ||
          showsPageOfCategory?.content.length > DEFAULT_PAGE_SIZE

        const isShowExist = showsPageOfCategory?.content.length
        const pageOfShows: Page<TvShow> = isShowExist
          ? {
              ...showsPageOfCategory,
              content: showsPageOfCategory.content,
            }
          : { content: [], ...getFirstPagePaginateInfo({ totalElements: 0 }) }

        return {
          ...category,
          content: pageOfShows,
          isMoreThanOnePage,
          isMoreThanOnePageSubCategories:
            Number(category.subCategories?.length) > DEFAULT_PAGE_SIZE,
          subCategories: category.subCategories?.slice(0, DEFAULT_PAGE_SIZE),
        }
      }
    )

    return {
      ...pageOfCategories,
      content: formattedCategory,
    }
  })
}
