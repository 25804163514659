export const urlSearchParamsBuilder = (
  params: Record<string, unknown>
): URLSearchParams => {
  const searchParams = new URLSearchParams()
  for (const key in params) {
    if (params.hasOwnProperty(key) && params[key] !== undefined) {
      searchParams.set(key, String(params[key]))
    }
  }
  return searchParams
}
