import { createEvent, createStore } from 'effector'
import { readonly } from 'patronum'

export const createMediaQueryStore = (query: string, defaults: boolean) => {
  const supportMatchMedia =
    typeof window !== 'undefined' && //
    typeof window.matchMedia !== 'undefined'

  if (!supportMatchMedia) {
    return createStore(defaults)
  }

  const mediaQueryList: MediaQueryList = window.matchMedia(query)

  const match = createEvent<boolean>()
  const $matches = createStore<boolean>(mediaQueryList.matches) //
    .on(match, (_, payload) => payload)

  const onChange = (e: MediaQueryListEvent) => match(e.matches)

  // old deprecated method for older browsers
  // https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
  if (mediaQueryList.addListener) {
    mediaQueryList.addListener(onChange)
  } else {
    mediaQueryList.addEventListener('change', onChange)
  }

  return readonly($matches)
}
