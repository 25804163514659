import type {
  API,
  ICartConfirmPurchaseInfo,
  ICartFullInfo,
} from '@setplex/tria-api'
import { type HttpClient } from '../../http'
import { type AdapterDefaults } from '../../index.h'
import type {
  ApiAnswerAddCartProperties,
  ApiAnswerAddItemToCart,
  ApiAnswerCheckoutCartItems,
  ApiAnswerConfirmCartPurchase,
} from '../../interfaces/cart'

export function use(
  http: HttpClient,
  cart: API['cart'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  // POST /v3/cart/items
  cart.postItemToCartFx.use(
    async ({
      originalPrice,
      channel,
      channelSet,
      vod,
      vodSet,
      tvShow,
      tvShowSet,
      tvShowSeason,
      tvShowEpisode,
      liveEvent,
    }) => {
      const response = await http.post<ApiAnswerAddItemToCart>(
        '/v3/cart/items',
        {
          json: {
            originalPrice,
            channel,
            channelSet,
            vod,
            vodSet,
            tvShow,
            tvShowSet,
            season: tvShowSeason,
            episode: tvShowEpisode,
            liveEvent,
          },
        }
      )
      return response.payload as ICartFullInfo
    }
  )

  // PUT /v3/cart/properties
  cart.setPaymentDataCartFx.use(async ({ payment }) => {
    const response = await http.put<ApiAnswerAddCartProperties>(
      '/v3/cart/properties',
      {
        json: {
          payment,
        },
      }
    )
    return response.payload as ICartFullInfo
  })

  // POST /v3/cart/checkout
  cart.checkoutSelectedContentItemFx.use(async () => {
    const response = await http.post<ApiAnswerCheckoutCartItems>(
      '/v3/cart/checkout'
    )
    return response.payload as ICartFullInfo
  })

  // POST /v3/cart/confirm
  cart.confirmCartPurchaseFx.use(async () => {
    const response = await http.post<ApiAnswerConfirmCartPurchase>(
      '/v3/cart/confirm'
    )
    return response.payload as ICartConfirmPurchaseInfo
  })
}
