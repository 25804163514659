import { model as i18nModel } from '!/i18n'
import { register } from '!/repository'
import { aye } from '@/helpers'
import { api } from '@setplex/tria-api'
import {
  combine,
  createEvent,
  createStore,
  restore,
  sample,
  type Store,
} from 'effector'
import { persist } from 'effector-storage/session'
import type { IInfoPopupProps, IPinLockPopupProps } from './model.h'
import { DEFAULT_UNLOCK_ID } from './model.h'

export const init = createEvent()
export const openInfoPopup = createEvent<IInfoPopupProps>()

const emptyState = {
  condition: false,
  episodeId: null,
  description: null,
  directors: null,
  stars: null,
  resolution: null,
  length: null,
  title: null,
  name: null,
  labels: null,
  eventDescription: null,
}

export const closeInfoPopup = createEvent()

export const $infoPopup = createStore<IInfoPopupProps>(emptyState)
  .on(openInfoPopup, (_, payload) => ({ ...payload, condition: true }))
  .on(closeInfoPopup, () => emptyState)

/* Start PinLock Popup */
export const emptyPinLockState: IPinLockPopupProps = {
  condition: false,
  name: '',
  id: null,
  invalid: false,
  disabled: false,
  errorMessage: '',
  onChange: () => null,
  onComplete: () => null,
  locked: false,
  valueAfterInvalid: '',
}

export const openPinLockPopup = createEvent<IPinLockPopupProps>()
export const closePinLockPopup = createEvent()
export const autoSubmitPinCode = createEvent<{ value: string }>()
export const changePinCode = createEvent<{ value: string }>()
export const resetValidPinCode = createEvent()
export const updateUnlockId = createEvent<number>()
export const resetUnlockId = createEvent()

export const $validPinCode: Store<string | null> = api.session.$session.map(
  (session) => session?.subscriberPin ?? null
)
export const $isValidPinCode = createStore<boolean>(false).reset([
  openPinLockPopup,
  resetValidPinCode,
  closePinLockPopup,
])

export const $unlockedId = createStore<number>(DEFAULT_UNLOCK_ID)
  .on(updateUnlockId, (_, payload) => payload)
  .reset(resetUnlockId)

persist({
  store: $unlockedId,
  key: 'tria__unlocked-content-id',
})

export const $pinLockPopup = createStore<IPinLockPopupProps>(emptyPinLockState)
  .on(openPinLockPopup, (_, payload) => ({ ...payload, condition: true }))
  .reset(closePinLockPopup)

// TODO need to move such stores to one place, for example, a shared directory
export const $pinLockPopupDescription: Store<string> = combine(
  i18nModel.$t,
  ({ t }) => t('locked_popup_hint')
)

export const $pinLockPopupErrorMessage: Store<string> = combine(
  i18nModel.$t,
  ({ t }) => t('pin_locked_error')
)

sample({
  clock: $isValidPinCode,
  source: restore(openPinLockPopup, null).reset(updateUnlockId),
  filter: (targetChannel, valid) => aye(valid) && targetChannel != null,
  fn: (targetChannel) => targetChannel?.id ?? DEFAULT_UNLOCK_ID,
  target: [updateUnlockId, closePinLockPopup],
})

sample({
  clock: autoSubmitPinCode,
  source: {
    pin: $validPinCode,
    popup: $pinLockPopup,
    errorMessageLocale: $pinLockPopupErrorMessage,
  },
  fn: ({ pin, popup, errorMessageLocale }, { value }) => {
    const invalid = pin !== value
    const errorMessage = invalid ? errorMessageLocale : ''
    return {
      ...popup,
      invalid,
      valueAfterInvalid: '',
      errorMessage,
    }
  },
  target: $pinLockPopup,
})

sample({
  clock: autoSubmitPinCode,
  source: { pin: $validPinCode },
  fn: ({ pin }, { value }) => pin === value,
  target: $isValidPinCode,
})

// remove error state when new valid value provided
sample({
  clock: changePinCode,
  source: $pinLockPopup,
  filter: ({ invalid }, { value }) => invalid && Boolean(value.length),
  fn: (state, { value }) => {
    return {
      ...state,
      invalid: false,
      valueAfterInvalid: value,
      errorMessage: '',
    }
  },
  target: $pinLockPopup,
})

/* End PinLock Popup */

register($infoPopup, '$infoPopup')
register(openInfoPopup, 'openInfoPopup')
register(closeInfoPopup, 'closeInfoPopup')
register($pinLockPopup, '$popups/pinLockPopup')
register(openPinLockPopup, 'popups/openPinLockPopup')
register(closePinLockPopup, 'popups/closePinLockPopup')
register(changePinCode, 'popups/changePinCode')
register(autoSubmitPinCode, 'popups/autoSubmitPinCode')
/** translated stores **/
register($pinLockPopupDescription, '$popups/pinLockPopupDescription')
