import { type MediaResolution } from '@setplex/tria-api'
import { ONE_HOUR_MS, ONE_MINUTE_MS, ONE_SECOND_MS } from '../constants/time'

/**
 * Humanize Media length without seconds
 * @example 01:40 -> 1h 40m, 00:40:15 -> 40m
 */
export const humanizeLengthWithoutSeconds = ({
  // TODO fix - this is generic formatter but got types tied to Vod - should be openapi for incoming and tria-api type for outcoming
  length: mediaLength,
}: {
  length?: string
}): string | undefined => {
  if (typeof mediaLength !== 'string') return undefined

  const mediaLengthWithoutSeconds = mediaLength.substring(0, 5)

  const validFormat = /^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])(:[0-5][0-9])?$/
  const invalidFormat = '00:00'

  const stringLength = 5 // 01:30 - 5 symbols

  const isInvalidRegExp = mediaLengthWithoutSeconds.search(validFormat) === -1
  const isInvalidLength = mediaLengthWithoutSeconds.length !== stringLength

  if (
    isInvalidRegExp ||
    isInvalidLength ||
    invalidFormat.includes(mediaLengthWithoutSeconds)
  ) {
    return undefined
  }

  const t = (v: number, name: string, plural?: boolean) =>
    v > 0 ? String(v) + name + (v > 1 && plural ? 's' : '') : ''
  const [hours = 0, minutes = 0] = mediaLength.split(':').map(Number)

  return [t(hours, 'h'), t(minutes, 'm')].join(' ').trim()
}

export const formatResolution = ({
  resolution,
}: {
  resolution: 'HD' | 'SD' | 'FHD' | '_2K' | '_4K' | undefined
}): MediaResolution | undefined => {
  if (!resolution) return undefined
  if (resolution.startsWith('_')) return resolution.slice(1) as MediaResolution
  return resolution as MediaResolution
}

/**
 * Humanize Media duration in milliseconds
 * @example 00:10:48 -> 648000
 */
export const formatDuration = ({
  length,
}: {
  length?: string
}): number | null => {
  if (!length) return null

  const [seconds, minutes, hours] = length.split(':').reverse()

  const duration =
    Number(hours ?? 0) * ONE_HOUR_MS +
    Number(minutes ?? 0) * ONE_MINUTE_MS +
    Number(seconds ?? 0) * ONE_SECOND_MS

  return duration
}
