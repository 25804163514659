import { type PageInfo } from '@setplex/tria-api'

interface GetFirstPagePageInfoParamsI {
  totalElements: number
}

export function getFirstPagePaginateInfo({
  totalElements,
}: GetFirstPagePageInfoParamsI): Omit<PageInfo, 'content'> {
  return {
    totalElements,
    totalPages: 1,
    number: 1,
    size: totalElements,
    first: true,
    last: true,
  }
}
