import { model as config, remote } from '@/config'
import { firebaseSharedModel as firebase } from '@/firebase'
import { exists } from '@/helpers'
import { logger } from '@/logger'
import { api } from '@setplex/tria-api'
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
  type Store,
} from 'effector'
import { getMessaging } from 'firebase/messaging'
import type { Workbox } from 'workbox-window'
import type { Messaging } from './index.h'
import {
  requestPermission,
  requestToken,
  setLogoUrlToPushNotifications,
  unregisterPushNotification,
} from './lib'

export const init = createEvent()
export const setup = createEvent<{
  workbox: Workbox
  serviceWorkerRegistration: ServiceWorkerRegistration
}>()

export const setLogoUrl = createEvent<string>()

const getMessagingFx = createEffect(getMessaging)

const getPermissionFx = createEffect(requestPermission)

const getTokenFx = createEffect(requestToken)

export const unregisterPushNotificationFx = createEffect(
  unregisterPushNotification
)

export const setLogoUrlToPushNotificationsFx = createEffect(
  setLogoUrlToPushNotifications
)

const pushTokenFx = attach({
  effect: api.notifications.pushTokenFx,
})

export const $messaging = restore<Messaging>(getMessagingFx.doneData, null)

const $workbox = createStore<Workbox | null>(null).on(
  setup,
  (_, { workbox }) => workbox
)

const $registration = createStore<ServiceWorkerRegistration | null>(null).on(
  setup,
  (_, { serviceWorkerRegistration }) => serviceWorkerRegistration
)

export const $pushNotificationsEnabled: Store<boolean> = config.get(
  remote.tria_isPushNotificationsEnabled
)

sample({
  clock: [init, setup, $pushNotificationsEnabled],
  filter: $pushNotificationsEnabled,
  target: getPermissionFx,
})

sample({
  clock: getPermissionFx.doneData,
  source: firebase.$firebaseApp,
  filter: (firebase, permissionGranted) =>
    permissionGranted && exists(firebase),
  fn: (firebase) => firebase!,
  target: getMessagingFx,
})

sample({
  clock: getMessagingFx.doneData,
  source: {
    serviceWorkerRegistration: $registration,
    messaging: $messaging,
  },
  filter: ({ messaging, serviceWorkerRegistration }) => {
    return exists(messaging) && exists(serviceWorkerRegistration)
  },
  fn: ({ messaging, serviceWorkerRegistration }) => ({
    messaging: messaging!,
    serviceWorkerRegistration: serviceWorkerRegistration!,
  }),
  target: getTokenFx!,
})

sample({
  clock: getTokenFx.doneData,
  fn: (token) => [`Token: ${token}`],
  target: logger.logFx,
})

sample({
  clock: getTokenFx.doneData,
  target: pushTokenFx,
})

sample({
  clock: getTokenFx.failData,
  fn: (error) => [error],
  target: logger.errorFx,
})

sample({
  clock: [setLogoUrl, setup],
  source: {
    workbox: $workbox,
    iconUrl: restore(setLogoUrl, null),
  },
  filter: ({ workbox, iconUrl }) => exists(workbox) && exists(iconUrl),
  fn: ({ workbox, iconUrl }) => {
    return { workbox: workbox!, iconUrl: iconUrl! }
  },
  target: setLogoUrlToPushNotificationsFx,
})
