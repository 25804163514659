import { COMPONENT, get as getLocal } from '!/repository'
import { logger } from '@/logger'
import { get } from '@setplex/arcana'
import { memo, type ComponentType, type MemoExoticComponent } from 'react'
import { type SkinView } from '../../index.h'
import { key, type Scope } from '../core'
import { Exact, hasAlias } from './Exact'
import { Fragment, hasComponents } from './Fragment'
import { type Recursive } from './Recursive'

interface Props {
  skin: SkinView
  parent: Scope
  Recursive: Recursive
  [key: string]: unknown // any additional props, will be propogated to all children
}

// exports memoized component
export const One: MemoExoticComponent<typeof _One> = memo(_One)

// _One.whyDidYouRender = true
_One.displayName = 'One'
function _One({
  skin,
  parent,
  Recursive,
  ...props
}: Props): JSX.Element | null {
  if (!hasAlias(skin) && hasComponents(skin)) {
    return (
      <Fragment
        {...props}
        key={key(skin)}
        skin={skin}
        parent={parent}
        Recursive={Recursive}
      />
    )
  }

  if (!hasAlias(skin)) {
    logger.warn(`There is no "alias" field, don't know what to render:`, skin)
    return null
  }

  const ArcanaComponent: ComponentType<unknown> | undefined | null =
    getLocal(skin.alias, COMPONENT) || get(skin.alias)

  if (!ArcanaComponent) {
    logger.error(
      `Cannot get component #${skin.id} (${skin.alias}) from arcana library`,
      skin
    )
    return null
  }

  if (!ArcanaComponent.displayName) {
    ArcanaComponent.displayName = skin.alias
  }

  return (
    <Exact
      {...props}
      key={key(skin)}
      skin={skin}
      parent={parent}
      Component={ArcanaComponent}
      Recursive={Recursive}
    />
  )
}
