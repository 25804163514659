import { api, type LiveEvent } from '@setplex/tria-api'
import { attach, createEvent, restore, sample } from 'effector'

export const loadLiveEventFx = attach({
  effect: api.liveEvents.getLiveEventByIdFx,
})

export const $currentEvent = restore(loadLiveEventFx.doneData, null)

export const getEventMediaUrl = createEvent<LiveEvent>()

export const loadLiveEventUrlSyncedFx = attach({
  effect: api.player.loadLiveEventUrlFx,
})

sample({
  clock: getEventMediaUrl,
  fn: (event) => {
    const { id } = event as LiveEvent

    return {
      eventId: id,
    }
  },
  target: loadLiveEventUrlSyncedFx,
})
