import { minLength } from '@/validators'
import { api } from '@setplex/tria-api'
import { attach, sample } from 'effector'
import { createForm } from 'effector-forms'

// sign up request effect
export const signInByPhoneFx = attach({
  effect: api.subscriber.signUpByPhoneFx,
})

// sign up form
export const signInByPhoneForm = createForm({
  fields: {
    phoneNumber: {
      init: '+' as string,
      rules: [minLength(2)],
    },
  },
  validateOn: ['submit'],
})

// send sign-up request
sample({
  clock: signInByPhoneForm.formValidated,
  target: signInByPhoneFx,
})
