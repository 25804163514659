import { Logger } from '@/logger'
import { AggregationTemporalityPreference } from '@opentelemetry/exporter-metrics-otlp-http'
import { Resource } from '@opentelemetry/resources'
import {
  MeterProvider,
  PeriodicExportingMetricReader,
} from '@opentelemetry/sdk-metrics'
import {
  ATTR_CLIENT_ADDRESS,
  ATTR_SERVICE_NAME,
  ATTR_SERVICE_VERSION,
} from '@opentelemetry/semantic-conventions'
import { version } from '~/app/environment'
import { OTLPMetricExporterOnlyWhenNeeded } from './exporter'

export const logger = new Logger('tria/opentelemetry')

/**
 * Initialize OpenTelemetry metrics
 */
export function initTelemetry(url: string): MeterProvider {
  // diag.setLogger(new DiagConsoleLogger(), DiagLogLevel.DEBUG)

  const exporter = new OTLPMetricExporterOnlyWhenNeeded({
    url,
    headers: {},
    // concurrencyLimit: 1,
    temporalityPreference: AggregationTemporalityPreference.DELTA,
  })

  const resource = new Resource({
    [ATTR_SERVICE_NAME]: 'tria',
    [ATTR_SERVICE_VERSION]: version,
    [ATTR_CLIENT_ADDRESS]: location.hostname,
  })

  const meterProvider = new MeterProvider({
    resource,
    readers: [
      new PeriodicExportingMetricReader({
        exporter,
        exportIntervalMillis: 10000, // export metrics every 10 seconds
      }),
    ],
  })

  // metrics.setGlobalMeterProvider(meterProvider)

  return meterProvider
}
