import { type MerrInit } from '@setplex/merr'
import {
  type ApiCommonResponse,
  type RequestInput,
  type RequestOutput,
} from '@setplex/tria-api'
import { createEffect, type Effect } from 'effector'

/**
 * Base prefix for all Nora API request
 */
const base: string = '/wbs/web/api'

/**
 * Utility type to assert OK response type from Nora API
 * (and keep old API response untouched)
 */
export type OK<T> = T extends { status?: 'OK' | 'ERROR' | undefined }
  ? Omit<T, 'status'> & { status: 'OK' }
  : T

/**
 * Utility type to assert ERROR response type from Nora API
 * (and keep old API response untouched)
 */
export type ERROR<T> = T extends { status?: 'OK' | 'ERROR' | undefined }
  ? Omit<T, 'status' | 'errorCode'> & { status: 'ERROR'; errorCode: string }
  : T

/**
 *
 */
export const requestFx = createEffect<
  { url: string; init: MerrInit },
  { json?: ApiCommonResponse; error?: Error; response?: Response },
  never
>()

/**
 * Type for HTTP client
 */
export type HttpClient = ReturnType<typeof http>

/**
 * HTTP client request factory
 */
function request(method: string) {
  const fetch = (raw?: boolean) => {
    return async (url: string, init: MerrInit = {}) => {
      const { response, json, error } = await requestFx({
        url,
        init: { method, ...init },
      })

      // filter out OK responses (and should keep old version responses)
      if (error || (json && 'status' in json && json.status !== 'OK')) {
        const payload: ERROR<any> = json
        throw { error, payload }
      }

      const payload: OK<any> = json
      return raw ? { response, payload } : payload
    }
  }

  // prettier-ignore
  const http: {
    <T>(url: string, init?: MerrInit): Promise<OK<T>>
    raw<T>(url: string, init?: MerrInit): Promise<{ response: Response; payload: OK<T> }>
  } = fetch() as any
  http.raw = fetch(true)

  return http
}

/**
 * Creates HTTP client, to interract with Nora API
 */
export function http(fx: Effect<RequestInput, RequestOutput, never>) {
  requestFx.use(({ url, init }) => fx({ input: base + url, init }))
  return {
    get: request('GET'),
    put: request('PUT'),
    post: request('POST'),
    patch: request('PATCH'),
    delete: request('DELETE'),
  }
}
