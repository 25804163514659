import { ExportResultCode, type ExportResult } from '@opentelemetry/core'
import { OTLPMetricExporter } from '@opentelemetry/exporter-metrics-otlp-http'
import {
  DataPointType,
  type DataPoint,
  type ExponentialHistogram,
  type Histogram,
  type MetricData,
  type ResourceMetrics,
  type ScopeMetrics,
} from '@opentelemetry/sdk-metrics'

/*
 * copied from https://github.com/open-telemetry/opentelemetry-js/issues/3105#issuecomment-2097357442
 */

const numericDataPointHasValue = (datapoint: DataPoint<number>) => {
  return datapoint.value !== 0 // todo: this will ignore 0 values, should we?
}
const histogramHasDataPoints = (histogram: DataPoint<Histogram>) => {
  return (
    // histogram.value.buckets.counts.some((count) => count !== 0) ||
    histogram.value.count !== 0
  )
}
const exponentialHistogramHasDataPoints = (
  histogram: DataPoint<ExponentialHistogram>
) => {
  return (
    // histogram.value.negative.bucketCounts.some((count) => count !== 0) ||
    // histogram.value.positive.bucketCounts.some((count) => count !== 0) ||
    histogram.value.count !== 0 || histogram.value.zeroCount !== 0
  )
}

const filterDatapoints = (instrument: MetricData) => {
  const { dataPointType } = instrument
  switch (dataPointType) {
    case DataPointType.EXPONENTIAL_HISTOGRAM:
      return instrument.dataPoints.filter((datapoint) =>
        exponentialHistogramHasDataPoints(datapoint)
      )
    case DataPointType.HISTOGRAM:
      return instrument.dataPoints.filter((datapoint) =>
        histogramHasDataPoints(datapoint)
      )
    case DataPointType.GAUGE:
    case DataPointType.SUM:
      return instrument.dataPoints.filter((datapoint) =>
        numericDataPointHasValue(datapoint)
      )
    default:
      throw new Error(`Unknown data point type: ${dataPointType}`)
  }
}

const getMappedInstrument = (instrument: MetricData): MetricData => {
  return {
    ...instrument,
    dataPoints: filterDatapoints(instrument),
  } as MetricData
}

const getMappedMeter = (meter: ScopeMetrics): ScopeMetrics => {
  const metrics = meter.metrics
    .map(getMappedInstrument)
    .filter((instrument) => instrument.dataPoints.length > 0)

  return {
    ...meter,
    // filter out metrics with no data points
    metrics,
  }
}

const filterMetricsWithNoDatapoints = (
  metrics: ResourceMetrics
): ResourceMetrics => {
  const scopeMetrics = metrics.scopeMetrics
    .map(getMappedMeter)
    // filter out meters with no metrics
    .filter((meter) => meter.metrics.length > 0)
  return {
    ...metrics,
    scopeMetrics,
  }
}

export class OTLPMetricExporterOnlyWhenNeeded extends OTLPMetricExporter {
  override export(
    metrics: ResourceMetrics,
    resultCallback: (result: ExportResult) => void
  ) {
    const filteredMetrics = filterMetricsWithNoDatapoints(metrics)
    if (filteredMetrics.scopeMetrics.length > 0) {
      super.export(filteredMetrics, resultCallback)
    } else {
      resultCallback({ code: ExportResultCode.SUCCESS })
    }
  }
}
