import { features } from '!/flags'
import { register } from '!/repository'
import { noop } from '@/effector'
import { getOnlyStoresFromApiDomains } from '@/effector/getOnlyStoresFromApiDomains'
import { F, T, aye, nay } from '@/helpers'
import { api } from '@setplex/tria-api'
import { attach, createEvent, createStore, sample, type Store } from 'effector'
import { persist } from 'effector-storage/local'
import { produce } from 'immer'
import { delay, reset } from 'patronum'
import { runtime } from '~/app'
import { type ViewError } from './index.h'
import { signInByTrueIdFeatureModel } from './sign-in-by-trueId'

type SignInParams = {
  username?: string
  password?: string
  rememberMe?: boolean
  model?: string
  foreignPlatformAccessToken?: string
}

export const signIn = createEvent<SignInParams>()
export const signOut = createEvent()
export const toggleRememberMe = createEvent()

export const signInFx = attach({
  effect: api.session.signInFx,
  mapParams: produce<SignInParams>((params) => {
    params.model = `${runtime.browser.name} ${runtime.browser.version}`
  }),
})
export const signOutFx = attach({ effect: api.session.signOutFx })

export const $rememberMe = createStore<boolean>(false).on(
  toggleRememberMe,
  (state) => !state
)

// flag, indicating explicit sign out (= not authentication lost due to session expiration)
export const $explicit = createStore<boolean>(false)
  .on(signOutFx, T)
  .on(delay({ source: signOutFx.finally, timeout: 10 }), F)

// debounced error, in order to prevent react to render component with old data
export const $viewError = createStore<ViewError>(null)

persist({
  store: $rememberMe,
  key: 'tria__remember-me',
  fail: noop,
})

sample({
  clock: signIn,
  target: signInFx,
})

sample({
  clock: signOut,
  source: signInByTrueIdFeatureModel.$isTrueIdAuthed,
  filter: (isTrueIdAuthed) => nay(isTrueIdAuthed),
  target: signOutFx,
})

sample({
  clock: signOut,
  source: signInByTrueIdFeatureModel.$isTrueIdAuthed,
  filter: (isTrueIdAuthed) => aye(isTrueIdAuthed),
  target: signInByTrueIdFeatureModel.trueIdLogoutFx,
})

// cleanup stores after sign-out
reset({
  clock: signOutFx.finally,
  target: getOnlyStoresFromApiDomains([
    api.subscriber,
    api.player,
    api.vods,
    api.tv,
    api.tvshows,
    api.banners,
    api.liveEvents,
    api.tvBundles,
    api.showBundles,
    api.vodBundles,
    api.cart,
  ]),
})

// indicates about enabled or disabled registration by phone
export const $signInWithPhoneEnabled: Store<boolean> = features.get(
  'signInWithPhoneEnabled',
  false
) // `false` by default

// indicates country
export const $detectedCountry: Store<{ country: string }> = features.get(
  'countryLocation',
  {
    country: 'US',
  }
) // `US` by default

//
// register stores and events
//

register(signIn, 'feature/subscriber/sign-in/signIn')
register(signOut, 'feature/subscriber/sign-in/signOut')
