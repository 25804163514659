import { formatAccountNumber } from '@/formatters/user'
import type { AccountAccessToken, Session } from '@setplex/tria-api'

interface GetPortalAuthenticatedUrlParams {
  baseUrl?: string
  token?: AccountAccessToken | null
  accountNumber?: string | null
  lastName?: string | null
}
export function getPortalAuthenticatedUrl({
  baseUrl,
  token,
  accountNumber,
  lastName,
}: GetPortalAuthenticatedUrlParams): URL | null {
  if (!baseUrl) {
    return null
  }

  const url = new URL(baseUrl)

  if (token) {
    url.searchParams.set('token', token.accessToken)
    return url
  }

  if (accountNumber && lastName) {
    url.searchParams.set('accountNumber', formatAccountNumber(accountNumber))
    url.searchParams.set('lastName', lastName)
    return url
  }

  return null
}

interface GetSubscriberAccountSettingsUrlHelper {
  baseUrl: string
  session: Session | null
  token: AccountAccessToken | null
}
export function getSubscriberAccountSettingsUrl({
  baseUrl,
  session,
  token,
}: GetSubscriberAccountSettingsUrlHelper) {
  const url = getPortalAuthenticatedUrl({ baseUrl, token, ...session })
  return url ? url.toString() : null
}
