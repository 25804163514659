import { type LaData, type MediaUrl } from '@setplex/tria-api'
import { type MediaUrlDto } from '@setplex/wbs-api-types'

export const formatMediaUrl = (media: MediaUrlDto): MediaUrl => ({
  playbackUrl: media.playbackUrl!,
  drm: media.drm as
    | {
        la: Record<string, LaData>
      }
    | undefined,
  stoppedTime: media.stoppedTime!,
})
