import { type ContinueWatchingDto_ContinueWatchingPageView } from '@setplex/wbs-api-types'
import { attach, createEffect, createStore } from 'effector'
import type { ApiEffect } from '../../index.h'

//GET /v3/continue-watching
export const getContinueWatchingFx: ApiEffect<
  {
    page: number
    count?: number
    sortOrder?: string
    sortBy?: string
  },
  Array<ContinueWatchingDto_ContinueWatchingPageView>
> = createEffect()

export const initContinueWatchingRowByPageFx = attach({
  effect: getContinueWatchingFx,
  mapParams: (args) => ({ ...args, page: 0 }),
})

export const loadContinueWatchingRowByPageFx = attach({
  effect: getContinueWatchingFx,
})

export const $continueWatchingRow = createStore<
  Array<ContinueWatchingDto_ContinueWatchingPageView>
>([])
  .on(initContinueWatchingRowByPageFx.doneData, (_, page) => page)
  .on(loadContinueWatchingRowByPageFx.doneData, (content, page) => [
    ...content,
    ...page,
  ])
