import { createEvent, sample } from 'effector'
import { model as router } from '~/core/router'
import { model as guestFeatureModel } from '~/features/subscriber/guest'
import { model as signinFeatureModel } from '~/features/subscriber/sign-in'
import { keys } from '~/shared/jail'
import { type Cage, type Transit, type Trial } from '../index.h'
import { tainter } from '../tools'

/*
 * ### Sign cage ###
 */

const init = createEvent<Transit>('init')
const trial = createEvent<Trial>('trial')

export const sign = {
  name: 'sign',
  key: keys.sign,
  init,
  trial,
  in: [],
  out: {},
  ...tainter(/^\/(sign-in|sign-up)/, '/sign-in'),
} satisfies Cage

/*
 * cage logic
 */

// redirect to / (and then to /sign-in) if user explicitly signed out and guest feature is disabled
sample({
  clock: [trial, init],
  source: {
    explicit: signinFeatureModel.$explicit,
    guestEnabled: guestFeatureModel.$enabled,
  },
  filter: ({ explicit, guestEnabled }) => explicit && !guestEnabled,
  fn: () => '/',
  target: router.navigatePush,
})
