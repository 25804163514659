import { createEvent } from 'effector'
import { keys } from '~/shared/jail'
import { type Cage, type Transit } from '../index.h'
import { tainter } from '../tools'

/*
 * ### Expired cage ###
 */

const init = createEvent<Transit>('init')

export const expired = {
  name: 'expired',
  key: keys.expired,
  init,
  in: [],
  out: {},
  ...tainter(/^\/expired/, '/expired'),
} satisfies Cage

/*
 * cage logic
 */
