export const ONE_SECOND_MS = 1000

export const ONE_MINUTE_IN_SEC = 60
export const ONE_MINUTE_MS = ONE_MINUTE_IN_SEC * ONE_SECOND_MS

export const ONE_HOUR_IN_SEC = 60 * ONE_MINUTE_IN_SEC
export const ONE_HOUR_MS = 60 * ONE_MINUTE_MS

export const ONE_DAY_IN_SEC = 24 * ONE_HOUR_IN_SEC
export const ONE_DAY_MS = 24 * ONE_HOUR_MS

export const TWO_DAYS_MS = 2 * ONE_DAY_MS
export const TWO_MINUTE_MS = 2 * ONE_MINUTE_MS
