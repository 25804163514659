import { exists } from '@/helpers'
import { D } from '@mobily/ts-belt'
import { memo, useCallback, useMemo, type MemoExoticComponent } from 'react'
import { type SkinView } from '../../index.h'
import { key, useRaw, type Scope, type ScopeList } from '../core'
import { ManyItem } from './ManyItem'
import { type Recursive } from './Recursive'

export type OverSkinView = SkinView & Required<Pick<SkinView, 'over'>>
export type NoOverSkinView = Omit<SkinView, 'over'>

interface Props {
  scopeList: ScopeList
  skin: OverSkinView
  parent: Scope
  Recursive: Recursive
  [key: string]: unknown // any additional props, will be propogated to all rendered elements
}

// exports memoized component
export const Over: MemoExoticComponent<typeof _Over> = memo(_Over)

// _Over.whyDidYouRender = true
_Over.displayName = 'Over'
function _Over({
  scopeList,
  skin,
  parent,
  Recursive,
  ...props
}: Props): JSX.Element | null {
  const { list: rawList, name, index } = scopeList
  const list = useRaw(rawList) as unknown[] | null

  // it is crucial to omit `over` field from skin, to prevent infinite loop
  const skinNoOver: NoOverSkinView = useMemo(
    () => D.deleteKey(skin, 'over'),
    [skin]
  )

  const render: (i: number) => JSX.Element | null = useCallback(
    (i) => {
      // choose skin to render from components list
      let kid: SkinView | undefined
      if (skin.components && skin.components.length > 0) {
        // if there is just single component without explicit `as: map` -> use it
        if (skin.components.length === 1) {
          if (skin.components[0].as !== 'child') {
            kid = skin.components[0]
          }
        }

        // otherwise, find the component with `as: over` and use it
        else {
          kid = skin.components.find((skin) => skin.as === 'over')
        }
      }

      return list && list.length && list[i] && kid ? (
        <ManyItem
          {...props}
          key={key(skin, i)}
          i={i}
          item={list[i]}
          name={name}
          index={index}
          skin={kid}
          parent={parent}
          Recursive={Recursive}
        />
      ) : null
    },
    [Recursive, index, list, name, parent, props, skin]
  )

  return (
    <Recursive
      {...props}
      content={list}
      itemCount={list ? list.length : 0}
      render={render}
      key={key(skin)}
      skin={skinNoOver}
      parent={parent}
    />
  )
}

export function hasOver(skin: SkinView): skin is OverSkinView {
  return exists(skin.over)
}
