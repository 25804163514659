import type { Meter, MeterProvider } from '@opentelemetry/api'
import { createEffect, createEvent, sample } from 'effector'
import { logger } from '../lib'

export const init = createEvent<MeterProvider>()

const getMeterFx = createEffect((meterProvider: MeterProvider) => {
  return meterProvider.getMeter('http-metrics')
})

/*

+-----+
| DNS | <- http_responses_domain_lookup_duration_milliseconds
+-----+
      |
      +-----+-----+
      | TCP | TLS |
      +-----+-----+ <- http_responses_connection_duration_milliseconds
      |   QUIC    |
      +-----------+
                  |
                  +---------+
                  | Request | <- http_responses_request_duration_milliseconds
                  +---------+
                            |
                            +------------+
                            |  Response  | <- http_responses_response_duration_milliseconds
                            +------------+

<- http_responses_duration_milliseconds ->

*/
const createMetricsFx = createEffect((meter: Meter) => {
  // prettier-ignore
  // Response whole duration, from sending request to the last byte of response, in milliseconds
  const httpResponseDuration = meter.createHistogram('http_responses_duration_milliseconds', {
    description: 'Response whole duration',
    unit: 'ms',
  })

  // prettier-ignore
  // DNS lookup duration, in milliseconds
  const dnsLookupDuration = meter.createHistogram('http_responses_domain_lookup_duration_milliseconds', {
    description: 'DNS lookup duration',
    unit: 'ms',
  })

  // prettier-ignore
  // Connection (TCP + TLS handshake) duration, in milliseconds
  const connectionDuration = meter.createHistogram('http_responses_connection_duration_milliseconds', {
    description: 'Connection (TCP + TLS handshake) duration',
    unit: 'ms',
  })

  // prettier-ignore
  // Request send duration, in milliseconds
  const requestDuration = meter.createHistogram('http_responses_request_duration_milliseconds', {
    description: 'Request send duration',
    unit: 'ms',
  })

  // prettier-ignore
  // Receive response duration, in milliseconds
  const responseDuration = meter.createHistogram('http_responses_response_duration_milliseconds', {
    description: 'Receive response duration',
    unit: 'ms',
  })

  // prettier-ignore
  // Size of response body (uncompressed), in bytes
  const payloadSize = meter.createHistogram('http_responses_payload_size_bytes', {
    description: 'Size of response body',
    unit: 'bytes',
  })

  // prettier-ignore
  // Actually received through network payload, in bytes. Could be 0 in case whole response got from HTTP client cache.
  const transferSize = meter.createHistogram('http_responses_transfer_size_bytes', {
    description: 'Actually received through network payload',
    unit: 'bytes',
  })

  //
  //
  //

  // create the performance observer
  const po = new PerformanceObserver((list) => {
    for (const entry of list.getEntries()) {
      const t = entry as PerformanceResourceTiming
      if (t.initiatorType === 'xmlhttprequest' || t.initiatorType === 'fetch') {
        let url: URL // | undefined = undefined
        try {
          url = new URL(t.name)
        } catch (ignore) {
          return
        }

        if (!url.pathname.startsWith('/wbs')) return

        const attr = {
          method: 'GET',
          path: url.pathname,
          status: (t as any).responseStatus, // eslint-disable-line @typescript-eslint/no-explicit-any
        }

        // Response whole duration, from sending request to the last byte of response, in milliseconds
        // -> http_responses_duration_milliseconds
        httpResponseDuration.record(t.duration, attr)

        // DNS lookup duration, in milliseconds
        // -> http_responses_domain_lookup_duration_milliseconds
        dnsLookupDuration.record(t.domainLookupEnd - t.domainLookupStart, attr)

        // Connection (TCP + TLS handshake) duration, in milliseconds
        // -> http_responses_connection_duration_milliseconds
        connectionDuration.record(t.connectEnd - t.connectStart, attr)

        // Request send duration, in milliseconds
        // -> http_responses_request_duration_milliseconds
        requestDuration.record(t.responseStart - t.requestStart, attr)

        // Receive response duration, in milliseconds
        // -> http_responses_response_duration_milliseconds
        responseDuration.record(t.responseEnd - t.responseStart, attr)

        // Size of response body (uncompressed), in bytes
        // -> http_responses_payload_size_bytes
        payloadSize.record(t.decodedBodySize, attr)

        // Actually received through network payload, in bytes. Could be 0 in case whole response got from HTTP client cache.
        // -> http_responses_transfer_size_bytes
        transferSize.record(t.transferSize, attr)
      }
    }
  })

  // start listening for `resource` entries to be dispatched
  po.observe({ type: 'resource', buffered: true })
})

//
//
//

sample({
  clock: init,
  target: getMeterFx,
})

sample({
  clock: getMeterFx.doneData,
  target: createMetricsFx,
})

sample({
  clock: getMeterFx.done,
  fn: () => ['http meter created'],
  target: logger.logFx,
})

sample({
  clock: getMeterFx.fail,
  fn: (fail) => ['cannot create meter', fail],
  target: logger.errorFx,
})

sample({
  clock: createMetricsFx.done,
  fn: () => ['http metrics created'],
  target: logger.logFx,
})

sample({
  clock: createMetricsFx.fail,
  fn: (fail) => ['cannot create metrics', fail],
  target: logger.errorFx,
})
