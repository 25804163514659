import type { ContentPriceSettingsDto } from '@setplex/wbs-api-types'

export function getMinPriceObject(
  priceSettings: ContentPriceSettingsDto[] | undefined
) {
  return priceSettings?.reduce(
    (prev, min) => (Number(prev.price) < Number(min.price) ? prev : min),
    {}
  )
}
interface GetTvShowContentMinPriceObjectParams {
  episodePriceSettings?: ContentPriceSettingsDto[]
  seasonPriceSettings?: ContentPriceSettingsDto[]
  tvShowPriceSettings?: ContentPriceSettingsDto[]
}
export function getTvShowContentMinPriceObject({
  episodePriceSettings,
  seasonPriceSettings,
  tvShowPriceSettings,
}: GetTvShowContentMinPriceObjectParams) {
  if (episodePriceSettings?.length) {
    return getMinPriceObject(episodePriceSettings)
  }

  if (seasonPriceSettings?.length) {
    return getMinPriceObject(seasonPriceSettings)
  }

  if (tvShowPriceSettings?.length) {
    return getMinPriceObject(tvShowPriceSettings)
  }
}
