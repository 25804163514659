import { createEvent } from 'effector'
import { keys } from '~/shared/jail'
import { type Cage, type Transit } from '../index.h'

/*
 * ### App cage ###
 */

const init = createEvent<Transit>('init')

export const app = {
  name: 'app',
  key: keys.app,
  init,
  in: [],
  out: {},
} satisfies Cage

/*
 * cage logic
 */
