import { api } from '@setplex/tria-api'

// in http client hook `beforeRequest` we need PID and PackageID
// to send them in 'mw-pid' and 'mw-package-id' headers accordingly,
// but this information exists only in effector's store.
// in the current http layer implementation, hooks are direct imperative code,
// which not plays well with declarative effector's code.
// there are two escape hatches to use store value in imperative code ->
// -> `store.getState()` and `store.watch()`
// `store.getState()` executes every time imperative code got called,
// `store.watch()` executes only when store value changed,
// so here we use second approach -> on any store value change, save new value
// in module-global exported variable, which is used in hook later.

export const session: {
  pid?: string | number | null
  packageid?: string | number | null
} = {}

// eslint-disable-next-line effector/no-watch
api.session.$session.watch((s) => {
  session.pid = s?.providerId
  session.packageid = s?.packageId
})
