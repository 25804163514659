import { type To } from './index.h'
import * as model from './model'

export * as dom from './dom'
export { ResolveLoadError, ResolveRouteError, ResolveSkinError } from './errors'
export * from './index.h'
export * from './router'
export * as scroll from './scroll'
export * from './stage'
export { model }

/**
 * Helper for navigation
 */
export const navigate = (to: To): To => model.navigatePush(to)
navigate.push = model.navigatePush
navigate.replace = model.navigateReplace
navigate.go = model.navigateGo
navigate.back = model.navigateBack
navigate.forward = model.navigateForward

export const locationReplace = (to: string) => window.location.replace(to)
export const locationAssign = (to: string) => window.location.assign(to)
export const openWindow = (to: string, target: string) =>
  window.open(to, target)
