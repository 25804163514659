import type { AppearanceStyleSet } from '@setplex/tria-api'
import type {
  ApiAnswerColorSchemeDto,
  ColorSchemeDto,
} from '@setplex/wbs-api-types'
import * as v from 'valibot'
import { response, type ApiType } from '../../../shared/schemas'

export const FALLBACK_STYLE = { type: 'UNSET' } as const

// use custom schema instead of `v.pipe(v.string(), v.hexColor())`
// to infer type as `#${string}` instead of `string`
export const HexColorSchema = v.custom<`#${string}`>(
  (value: unknown) => typeof value === 'string' && /^#[0-9a-f]{6}$/i.test(value)
)

export const UnsetStyleSchema = v.object({
  type: v.literal('UNSET'),
})

export const ColorStyleSchema = v.object({
  type: v.literal('COLOR'),
  color: HexColorSchema,
})

export const ImageStyleSchema = v.object({
  type: v.literal('IMAGE'),
  imageUrl: v.pipe(v.string(), v.url()),
})

export const LinearGradiendConfigurationSchema = v.object({
  type: v.literal('LINEAR'),
  angle: v.pipe(v.number(), v.minValue(0), v.maxValue(360)),
  list: v.array(
    v.object({
      color: HexColorSchema,
      percentage: v.pipe(v.number(), v.minValue(0), v.maxValue(100)),
    })
  ),
})

export const GradientStyleSchema = v.object({
  type: v.literal('GRADIENT'),
  configuration: v.variant('type', [
    LinearGradiendConfigurationSchema, // <- only linear gradient is supported for now
  ]),
})

export const StyleSchema = v.fallback(
  v.variant('type', [
    UnsetStyleSchema,
    ColorStyleSchema,
    ImageStyleSchema,
    GradientStyleSchema,
  ]),
  FALLBACK_STYLE
)

export const StyleSetSchema = v.object({
  mainStyle: v.nullish(StyleSchema, FALLBACK_STYLE),
  additionalStyle: v.nullish(StyleSchema, FALLBACK_STYLE),
  backgroundStyle: v.nullish(StyleSchema, FALLBACK_STYLE),
}) satisfies ApiType<ColorSchemeDto, AppearanceStyleSet>

export const StyleSetResponseSchema = response(
  StyleSetSchema
) satisfies ApiType<ApiAnswerColorSchemeDto, any>
