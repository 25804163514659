const SHORT_UTC = /:\d\dZ$/
const LONG_UTC = /\.\d\d\dZ$/
const SHORT_NO_UTC = /:\d\d$/
const LONG_NO_UTC = /\.\d\d\d$/
export function toDate(date?: string): Date | undefined {
  if (!date) {
    return
  }

  // like '2021-12-31T12:17:11.080Z'
  if (LONG_UTC.test(date)) {
    return new Date(date)
  }

  // like '2021-12-31T12:17:11'
  if (SHORT_NO_UTC.test(date)) {
    return new Date(date + '.000Z')
  }

  // like '2021-12-31T12:17:11.080'
  if (LONG_NO_UTC.test(date)) {
    return new Date(date + 'Z')
  }

  // like '2021-12-31T12:17:11Z'
  if (SHORT_UTC.test(date)) {
    return new Date(date.slice(0, -1) + '.000Z')
  }

  // don't know what to do with this date
  // just hope everything will be right
  return new Date(date)
}
