import type {
  BannerCarousel,
  FeaturedCarousel,
  MediaTypes,
  ResourceTypes,
} from '@setplex/tria-api'
import { CarouselTypes, MediaTypes as MediaTypesEnum } from '@setplex/tria-api'
import {
  type BannerCarouselDto,
  type ChannelDto,
  type FeaturedCarouselDto,
  type TvShowDto,
  type VodDto,
} from '@setplex/wbs-api-types'
import { getBackgroundImage } from '../../utils/images'
import { transformSecondsToMilliseconds } from '../../utils/time'
import { formatChannel } from '../tv/tv.format'
import { formatShow } from '../tvshows/tvshows.format'
import { formatVod } from '../vods/vods.format'

export const formatFeaturedCarousel = ({
  content,
  ...rest
}: FeaturedCarouselDto): FeaturedCarousel => {
  const formattedContent = content.map((item) => {
    let media
    switch (rest.type) {
      case CarouselTypes.VOD: {
        media = {
          ...item,
          vod: formatVod(item.vod || {}),
        }
        break
      }
      case CarouselTypes.TV_SHOW: {
        media = {
          ...item,
          tvShow: formatShow(item.tvShow || {}),
        }
        break
      }
      case CarouselTypes.TV_CHANNEL: {
        media = {
          ...item,
          channel: formatChannel(item.channel || {}),
        }
        break
      }
    }
    return { carouselMediaId: rest.id, ...media }
  })

  return {
    ...rest,
    content: formattedContent,
  } as FeaturedCarousel
}

const formatResourceType = (
  resourceType: 'INTERNAL' | 'EXTERNAL' | 'WITHOUT_LINK' | undefined
): ResourceTypes | undefined => {
  return (resourceType ? String(resourceType).toUpperCase() : undefined) as
    | ResourceTypes
    | undefined
}

// TODO somewhy Pick<> do not work there correctly :(
const formatContentType = (
  contentType:
    | 'TV_CHANNEL'
    | 'TV_SHOW'
    | 'VOD'
    | 'TV_CATEGORY'
    | 'TV_SUBCATEGORY'
    | 'VOD_CATEGORY'
    | 'VOD_SUBCATEGORY'
    | 'TV_SHOW_CATEGORY'
    | 'TV_SHOW_SUBCATEGORY'
    | undefined
): MediaTypes | undefined => {
  return (contentType ? String(contentType).toUpperCase() : undefined) as
    | MediaTypes
    | undefined
}

export const formatBanners = ({
  autoScrollIn,
  bannerBundles,
  ...rest
}: BannerCarouselDto): BannerCarousel => {
  return {
    autoScrollIn: transformSecondsToMilliseconds(autoScrollIn),
    bannerBundles: bannerBundles?.map(
      ({
        content,
        resourceType: resType,
        contentType: contType,
        tabletBannerUrl,
        mobileBannerUrl,
        ...rest
      }) => {
        const resourceType = formatResourceType(resType)
        const contentType = formatContentType(contType)
        const formattedMobileBannerUrl = getBackgroundImage(mobileBannerUrl)
        const formattedTabletBannerUrl = getBackgroundImage(tabletBannerUrl)

        // prettier-ignore
        return {
          ...rest,
          resourceType,
          contentType,
          mobileBannerUrl: formattedMobileBannerUrl,
          tabletBannerUrl: formattedTabletBannerUrl,
          content:
            contentType === MediaTypesEnum.VOD        ? formatVod(content as VodDto)
          : contentType === MediaTypesEnum.TV_SHOW    ? formatShow(content as TvShowDto)
          : contentType === MediaTypesEnum.TV_CHANNEL ? formatChannel(content as ChannelDto)
                                                      : content,
        }
      }
    ),
    ...rest,
  }
}
