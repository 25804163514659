import { MINIMUM_QUERY_LENGTH_OF_SEARCH_INPUT } from '@/constants/strings'

/**
 * Clip any string value to max length
 */
export const clip = (
  value: string | undefined,
  length: number
): string | undefined => value?.slice(0, length)

/**
 * Left pad any value
 */
export const leftpad = (value: unknown, length: number, char: string) =>
  String(value).padStart(length, char)

/**
 * Check is search query valid according required rules
 * @param value
 */
export const checkIsSearchQueryValid = (value: string) =>
  value?.trim().length >= MINIMUM_QUERY_LENGTH_OF_SEARCH_INPUT

/**
 * Function for formatting a time string.
 * @param {string} inputString - Input time string (e.g., '1 hour 51 minutes').
 * @returns {string} - Formatted time string (e.g., '1h 51m').
 */
export const formatTimeString = (inputString: string) => {
  // Regular expression to match numbers and units of measurement in the string
  const regex = /(\d+)\s*([a-zA-Z]+)/g

  // Use replace to process each match
  return inputString.replace(regex, (_, value, unit) => {
    // Convert the unit to lowercase and take the first letter
    const shortUnit = unit.toLowerCase().charAt(0)

    // Build the string with the format '1h 51m'
    return `${value}${shortUnit} `
  })
}
