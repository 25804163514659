import { register } from '!/repository'
import { createEvent, sample } from 'effector'
import * as features from './features'
import * as options from './options'

export const init = createEvent()

// load features on init
sample({
  clock: init,
  target: features.getFx,
})

//
// register stores and events
//

register(features.$featuredFlags, '$flags/features')
register(options.$optionsFlags, '$flags/options')
