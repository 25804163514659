import { createEvent, sample, split } from 'effector'
import { signInByTrueIdFeatureModel as trueid } from '~/features/subscriber/sign-in'
import { keys } from '~/shared/jail'
import { type Cage, type Transit } from '../index.h'

/*
 * ### Hallway->Trueid intermediate cage ###
 */

const init = createEvent<Transit>('init')

// out events
const failedToSignInByTrueId = createEvent('failedToSignInByTrueId')
const shouldSignInByTrueId = createEvent('shouldSignInByTrueId')

export const hallway2trueid = {
  name: 'hallway2trueid',
  key: keys.lost,
  init,
  in: [],
  out: {
    failedToSignInByTrueId,
    shouldSignInByTrueId,
  },
} satisfies Cage

/*
 * cage logic
 */

split({
  source: init,
  match: trueid.$isTrueIdAuthed.map(String),
  cases: {
    true: trueid.trueIdAutoLogin,
    false: shouldSignInByTrueId,
  },
})

sample({
  clock: trueid.trueIdAutoLoginLostAuthFx.fail,
  target: failedToSignInByTrueId,
})
