import { features, options } from '!/flags'
import { model as i18n } from '!/i18n'
import {
  Action,
  dom as domModel,
  model as router,
  scroll,
  stage,
  router as universalRouter,
  type Location,
  type RouterResponse,
  type Staged,
} from '!/router'
import { exists, isArray, isFunction, nay, noop } from '@/helpers'
import { logger } from '@/logger'
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  launch,
  sample,
  type Effect,
} from 'effector'
import { model as session } from '~/entities/session'
import { accessible, warden } from './jail'

// initialize process
export const init = createEvent()

// start preparations to render exact route (e.g. call `wait` effect)
const prerender = createEvent<RouterResponse>()

// start actual render of exact route
const render = createEvent<RouterResponse>()

// pre-set route-to-be
const setRoute = createEvent<RouterResponse>()

// try route agains current jail cage
const trialRoute = createEvent<RouterResponse>()

// current loaded route
export const $route = createStore<RouterResponse | null>(null)

// resolve route from location, using universal router
const resolveRouteFx: Effect<Location, RouterResponse | null | undefined> =
  attach({
    source: {
      options: options.$optionsFlags,
      features: features.$featuredFlags,
      session: session.$session, // TODO: session is not available on page reload :(
    },
    effect: async ({ options, features, session }, location: Location) =>
      universalRouter.resolve({
        pathname: location.pathname,
        query: new URLSearchParams(location.search),
        options,
        features,
        session,
      }),
  })

// update browser title from route
export const titleFx: Effect<RouterResponse, string> = createEffect<
  RouterResponse,
  string
>((route) => {
  const titles = isArray(route.title) ? route.title : [route.title]
  const documentTitle = titles
    .map((title) => (isFunction(title) ? title() : title || 'Tria'))
    .join(' » ') // related with titles for no content page. If you should change format or separator here than do the same for no content block in shared/ui

  document.title = documentTitle
  return documentTitle
})

// awaits for blocking calls before render
const waitFx: Effect<RouterResponse & Staged, void> = createEffect<
  RouterResponse & Staged,
  void
>((route) => {
  if (route.wait) {
    // `wait` should be an Effect, to be able to await it
    return route.wait({ ...route.props, stage: route.stage })
  }
})

// init non-blocking calls in parallel with render
const initFx: Effect<RouterResponse & Staged, void> = createEffect<
  RouterResponse & Staged,
  void
>((route) => {
  if (route.init) {
    // `init` could be any effector's unit, usually an Event
    launch({
      target: route.init,
      params: { ...route.props, stage: route.stage },
    })
  }
})

// trigger left route actions
const leftFx: Effect<RouterResponse, void> = attach({
  source: $route,
  effect(fromRoute, toRoute: RouterResponse) {
    if (
      fromRoute &&
      toRoute &&
      fromRoute.__resolved !== toRoute.__resolved &&
      fromRoute.left
    ) {
      // `left` could be any effector's unit, usually an Event
      // can pass from and to routes here, if needed
      launch({
        target: fromRoute.left,
        params: { ...fromRoute.props },
      })
    }
  },
})

// effect to render application, should be implemented _outside_ of model
export const renderFx: Effect<
  { location: Location; route: RouterResponse },
  void
> = createEffect()

//
// react on location change
//

// check /error state on init
sample({
  clock: init,
  source: router.$location,
  filter: (
    location: Location | null
  ): location is Location & { state: { params: Record<string, string> } } =>
    location != null &&
    location.state != null &&
    typeof location.state === 'object' &&
    'params' in location.state &&
    location.state.params != null &&
    typeof location.state.params === 'object' &&
    location.pathname.startsWith('/error'),
  fn: ({ state }) =>
    state.params.pathname + state.params.search + state.params.hash,
  target: router.navigateReplace,
})

// resolve route on each location change
sample({
  clock: router.$location,
  filter: exists,
  target: resolveRouteFx,
})

// when route is resolved - check, if we still on the same location, as we were before,
// and route exists at all
sample({
  clock: resolveRouteFx.done,
  source: router.$key,
  filter: (key, { params, result }) => params.key === key && exists(result),
  fn: (__, { result }) => result!,
  target: setRoute,
})

// some error happen when resolving route -> redirect to error page
sample({
  clock: resolveRouteFx.fail,
  filter: ({ params }) => nay(params.pathname.startsWith('/error')), // prevent infinite cycle
  fn: (state) => ({ pathname: '/error', state }),
  target: router.navigatePush,
})

// log resolve route error
sample({
  clock: resolveRouteFx.fail,
  fn: (fail) => ['🔁 router.resolve:', fail],
  target: logger.errorFx,
})

// start transaction on resolve route start
sample({
  clock: resolveRouteFx,
  fn: noop,
  target: router.transitionStart,
})

// finish transaction on resolve route finish
sample({
  clock: resolveRouteFx.finally,
  fn: noop,
  target: router.transitionEnd,
})

//
// check route validity
//

// handles redirects first
// in case new route has `redirect` field -> replace current location
sample({
  clock: setRoute,
  filter: (route) => exists(route.redirect),
  fn: (route) => route.redirect!,
  target: router.navigateReplace,
})

// in case new route has no `redirect` field,
// try route agains current jail cage
sample({
  clock: setRoute,
  filter: (route) => nay(exists(route.redirect)),
  target: trialRoute,
})

// if route cannot access current cage -> put it to trial
sample({
  clock: trialRoute,
  source: warden.$cage,
  filter: (cage, route) => {
    // console.log('🚀', cage, route, `accessible:${accessible(cage, route.keys)}`)
    return nay(accessible(cage, route.keys))
  },
  fn: (_, route) => route,
  target: warden.trial,
})

// if route can access current cage -> set it as new route (and clear trial)
sample({
  clock: trialRoute,
  source: warden.$cage,
  filter: (cage, route) => accessible(cage, route.keys),
  fn: (_, route) => route,
  target: [warden.clear, leftFx],
})

// and then -> actually set new route
sample({
  clock: leftFx.finally,
  fn: ({ params }) => params,
  target: [$route, warden.$route],
})

//
// update and render actual rendered route
//

// update browser title on route change
sample({
  clock: $route,
  filter: exists,
  target: titleFx,
})

// on any i18n language change -> update title
sample({
  clock: i18n.$t,
  source: titleFx,
  target: titleFx,
})

// fill document title store update any title update (after i18n language change or after route change)
sample({
  clock: titleFx.doneData,
  target: domModel.$documentTitle,
})

// finally render requested and resolved route
sample({
  clock: $route,
  filter: exists,
  target: prerender,
})

//
// render process
//

// awaits for blocking initialization, if any
sample({
  clock: prerender,
  fn: stage.navigate,
  target: [router.transitionStart, waitFx],
})

// render and launch non-blocking initialization, if any
sample({
  clock: waitFx.finally,
  source: prerender,
  filter: (_, { params }) => params.stage === 'navigate', // to avoid rerender on `refresh` stage
  fn: stage.navigate,
  target: [router.transitionEnd, initFx, render],
})

// call actual render effect with current location
// TODO: add filter on changed location? what if while we' ve waited `waitFx`, user has go elsewhere
sample({
  clock: render,
  source: router.$location,
  filter: (location, route) => exists(location) && exists(route),
  fn: (location, route) => ({ location: location!, route }),
  target: renderFx,
})

//
// Refresh current route
//

// in case refresh requested -> get last result of `prerender` event,
// which should be currently rendered route,
// and call `wait` and `init` for that route
sample({
  clock: router.refresh,
  source: prerender,
  fn: stage.refresh,
  target: [waitFx, initFx],
})

//
// handle scroll position
//

// save scroll position of previous location
sample({
  clock: router.$previous,
  filter: (location): location is Location => exists(location?.key),
  fn: (location: Location) => location.key,
  target: scroll.pushFx,
})

// clear scroll position in case action is Push (= go to new page)
sample({
  clock: router.$action,
  source: router.$key,
  filter: (key, action): key is string => exists(key) && action === Action.Push,
  target: scroll.clearFx,
})

// restore scroll position (or just scroll up)
sample({
  clock: renderFx.finally,
  source: router.$key,
  filter: exists,
  target: scroll.applyFx,
})
