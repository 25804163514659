import { createEffect, createStore } from 'effector'
import type { ApiEffect } from '../index.h'
import type {
  ICreditCard,
  ICreditCardSettings,
  ICreditCardsConfig,
} from '../interfaces/creditCards'

export const getSavedCreditCardsFx: ApiEffect<any, ICreditCardsConfig> =
  createEffect()

export const $savedCreditCards = createStore<Array<ICreditCard> | null>(
  null
).on(
  getSavedCreditCardsFx.doneData,
  (_, savedCreditCards) => savedCreditCards.creditCards
)

export const $creditCardsSettings =
  createStore<Array<ICreditCardSettings> | null>(null).on(
    getSavedCreditCardsFx.doneData,
    (_, savedCreditCards) => savedCreditCards.creditCardSettings ?? null
  )
