import { v4 as uuidv4 } from 'uuid'
import { toISOStringWithTimezone } from '../../utils/time'
import type { IRequest, IRequestWatchingContent } from './index.h'

/**
 * Escape value to be safe for SQL request
 * https://stackoverflow.com/questions/7744912/making-a-javascript-string-sql-friendly
 */
const escape = (v: string) =>
  String(v).replace(/[\0\x08\x09\x1a\n\r"'\\\%]/g, (char) => {
    // prettier-ignore
    switch (char) {
      case '\0': return '\\0'
      case '\x08': return '\\b'
      case '\x09': return '\\t'
      case '\x1a': return '\\z'
      case '\n': return '\\n'
      case '\r': return '\\r'
      case '"':
      case "'":
      case '\\':
      case '%': return '\\' + char // prepends a backslash to backslash, percent, and double/single quotes
      default: return char
    }
  })

/**
 * Escape value for SQL request, and wrap it in quotes
 */
const value = (v: string | undefined) =>
  "'" + (v !== undefined ? escape(v) : '') + "'"

/**
 * Get timestamp like `2022-09-19 12:38:15`
 */
const timestamp = () =>
  toISOStringWithTimezone(new Date())
    .replace(/T/, ' ')
    .replace(/(\.\d{3})?Z$/, '')
    .replace(/([+-]\d\d:\d\d)$/, '')

/**
 * Format ClickHouse SQL request
 * https://docs.google.com/spreadsheets/d/1kGyfISEZEO2m5IjdKKIFHYjVQVjyWBVMTrp4w7RjoRw/edit#gid=350646525
 */
export const request = ({
  // common fields
  event_id = uuidv4(),
  event_timestamp = timestamp(),
  config_accountCode = 'setplex',
  app_releaseVersion,
  app_name,
  app_id,
  device_OsVersion,
  device_model,
  device_name,
  device_type,
  device_id,
  device_mac_address,
  device_serial_number,
  network_ip,
  network_isp,
  network_type,
  network_location,
  content_provider_id,
  content_user_subscriber_id,
  content_user_account_number,
  content_user_profile_id,
  content_user_profile_name,

  // event fields
  event_type,
  event_parameter1,
  event_parameter2,
  event_parameter3,
  event_parameter4,
  event_parameter5,
  event_parameter6,
  event_parameter7,
  event_parameter8,
  event_parameter9,
}: IRequest) =>
  // prettier-ignore
  `INSERT INTO stat.info_v1_7 Values (${[
    event_id,
    event_timestamp,
    config_accountCode,
    app_releaseVersion,
    app_name,
    app_id,
    device_OsVersion,
    device_model,
    device_name,
    device_type,
    device_id,
    device_mac_address,
    device_serial_number,
    network_ip,
    network_isp,
    network_type,
    network_location,
    content_provider_id,
    content_user_subscriber_id,
    content_user_account_number,
    content_user_profile_id,
    content_user_profile_name,
    event_type,
    event_parameter1,
    event_parameter2,
    event_parameter3,
    event_parameter4,
    event_parameter5,
    event_parameter6,
    event_parameter7,
    event_parameter8,
    event_parameter9
  ].map(value).join(',')})`

export const requestWatchingContent = ({
  // common fields
  event_id = uuidv4(),
  event_timestamp = timestamp(),
  config_accountCode = 'setplex',
  app_releaseVersion,
  app_name,
  app_id,
  device_OsVersion,
  device_model,
  device_name,
  device_type,
  device_id,
  device_mac_address,
  device_serial_number,
  network_ip,
  network_isp,
  network_type,
  network_location,
  content_provider_id,
  content_user_subscriber_id,
  content_user_account_number,
  content_user_profile_id,
  content_user_profile_name,

  content_id,
  content_url,
  content_title,
  content_programm,
  content_type,
  content_open_from,
  content_is_success_show,
  content_error_code,
}: IRequestWatchingContent) =>
  // prettier-ignore
  `INSERT INTO stat.player_open_v1_6 Values (${[
    event_id,
    event_timestamp,
    config_accountCode,
    app_releaseVersion,
    app_name,
    app_id,
    device_OsVersion,
    device_model,
    device_name,
    device_type,
    device_id,
    device_mac_address,
    device_serial_number,
    network_ip,
    network_isp,
    network_type,
    network_location,
    content_provider_id,
    content_user_subscriber_id,
    content_user_account_number,
    content_user_profile_id,
    content_user_profile_name,
    content_id,
    content_url,
    content_title,
    content_programm,
    content_type,
    content_open_from,
    content_is_success_show,
    content_error_code,
  ].map(value).join(',')})`

/**
 * Post analytics request to ClickHouse
 */
export const postAnalytics = async (domain: string, body: string) => {
  const params: URLSearchParams = new URLSearchParams()

  // https://docs.google.com/document/d/1B4RLt2U32Rav9-nzGcy2z-BtSUp753XBl-AZPXJTK-4/edit?usp=sharing
  params.set('user', 'devices')
  params.set('password', 'Mn94PCq35ieg0uYOGIpFoSoGrCoRQDYLkJneMdcUcz')
  params.set('max_result_rows', '1000')
  params.set('max_result_bytes', '10000000')
  params.set('result_overflow_mode', 'break')

  domain += domain.endsWith('/') ? '' : '/'
  return await fetch(`${domain}?${params}`, {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      ContentType: 'text/plain',
    },
    body,
  })
}
