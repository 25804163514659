import { features } from '!/flags'
import { createStore, sample, type Store } from 'effector'
import { getCountryCallingCode, type CountryCode } from 'libphonenumber-js'

// indicates country
export const $detectedCountry: Store<{ country: string }> = features.get(
  'countryLocation',
  {
    country: 'US',
  }
) // `US` by default

export const $phoneNumberCountyCode = createStore<string>('')

sample({
  clock: $detectedCountry,
  fn: (location) => getCountryCallingCode(`${location.country}` as CountryCode),
  target: $phoneNumberCountyCode,
})
