import { getPath } from '../helpers'
import { type Map } from './raw'

/*
 * Map helpers
 */

/**
 * Get part of object/array by path
 */
export const path =
  (pathToSearch: string[]): Map =>
  (object) =>
    getPath(object, pathToSearch)

/**
 * Wrap value to array, unless it is not array already
 */
export const array = <T>(value?: T | T[] | null): T[] | null => {
  if (value == null) return null
  return Array.isArray(value) ? value : [value]
}

/**
 * Get `content` field from store, because
 * almost all stores in @setplex/tria-api has `.content` field
 * as ad hoc solution for SSR update issue
 *
 * TODO: remove .content from all stores?
 * UPDATE: removed default content in this task - FP-1339
 */
export const content: Map = (data) => data
