import {
  $ongoingTransition,
  transitionEnd,
  transitionStart,
} from '!/router/model'
import {
  attach,
  createEffect,
  createEvent,
  restore,
  sample,
  type Effect,
  type Store,
} from 'effector'
import { createGate, type Gate } from 'effector-react'
import { type LoadingBarRef } from 'react-top-loading-bar'

export const setLoaderRef = createEvent<LoadingBarRef>()

const $loader: Store<LoadingBarRef | null> = restore(setLoaderRef, null)

const startFx: Effect<void, void> = attach({
  source: $loader,
  effect(loader: LoadingBarRef | null) {
    if (loader) loader.continuousStart()
  },
})

const completeFx: Effect<void, void> = attach({
  source: $loader,
  effect(loader: LoadingBarRef | null) {
    if (loader) loader.complete()
  },
})

// hack to avoid loading forever
// @see https://github.com/klendi/react-top-loading-bar/issues/52
const timeoutHackFx: Effect<void, void> = createEffect<void, void>(
  () => new Promise((resolve) => setTimeout(resolve, 0))
)

sample({
  clock: transitionStart,
  target: startFx,
})

sample({
  clock: transitionEnd,
  target: timeoutHackFx,
})

sample({
  clock: timeoutHackFx.done,
  filter: $ongoingTransition.map((has) => !has),
  target: completeFx,
})

//
// kinda hack to start continuous loading if there is ongoing transition at the moment of mount
//

export const LoaderGate: Gate = createGate()

sample({
  clock: LoaderGate.open,
  filter: $ongoingTransition,
  target: startFx,
})
