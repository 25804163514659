import { createContext, type Context, type Provider } from 'react'
import { type SkinViews } from '../../index.h'

// skin context
export const SkinContext: Context<SkinViews | Promise<SkinViews> | null> =
  createContext<SkinViews | Promise<SkinViews> | null>(null)
SkinContext.displayName = 'SkinContext'

// skin provider
export const SkinProvider: Provider<SkinViews | Promise<SkinViews> | null> =
  SkinContext.Provider
