import { aye } from '@/helpers'
import type { IProfile } from '@setplex/tria-api'

export const nameMaxLengthFormatter = (
  profileName: string,
  maxLength: number
) => {
  if (profileName.length <= maxLength) return profileName
  return profileName.substring(0, maxLength)
}

export const hasProfileName = (
  profiles: Array<IProfile>,
  username: string
): boolean => {
  const hasName = profiles.find(
    ({ name }) => name?.toLowerCase() === username.toLowerCase()
  )
  return aye(hasName)
}
