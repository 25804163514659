import { createEffect } from 'effector'
import type { ApiEffect } from '../index.h'
import type {
  ICartConfirmPurchaseInfo,
  ICartFullInfo,
} from '../interfaces/cart'
import type { ICartItemInfo, ICartProperties } from '../interfaces/paymentInfo'

export const postItemToCartFx: ApiEffect<ICartItemInfo, ICartFullInfo> =
  createEffect()

export const setPaymentDataCartFx: ApiEffect<ICartProperties, ICartFullInfo> =
  createEffect()

export const checkoutSelectedContentItemFx: ApiEffect<void, ICartFullInfo> =
  createEffect()

export const confirmCartPurchaseFx: ApiEffect<void, ICartConfirmPurchaseInfo> =
  createEffect()
