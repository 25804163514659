import {
  PriceSettingsFormattedType,
  PriceSettingsType as PriceSettingsTypeEnum,
  type FormattedPriceSettings,
  type PriceSettingsAvailabilityPeriodTimeUnit,
  type PriceSettingsPaymentMethod,
  type PriceSettingsType,
} from '@setplex/tria-api'
import { type ContentPriceSettingsDto } from '@setplex/wbs-api-types'

interface IPurchaseText
  extends Pick<
    FormattedPriceSettings,
    'type' | 'availabilityPeriodTimeUnit' | 'availabilityPeriodLength'
  > {}

const getPurchaseTextByType = ({
  type,
  availabilityPeriodLength,
  availabilityPeriodTimeUnit,
}: IPurchaseText): string => {
  switch (type) {
    case PriceSettingsTypeEnum.Purchase:
      return 'Content is always available.\nThe number of views is not limited.'
    case PriceSettingsTypeEnum.Rent: {
      // TODO: handle plurals cases
      const timeUnit = availabilityPeriodTimeUnit
        ? `${availabilityPeriodTimeUnit.toLowerCase()}s`
        : ''
      return `Content available for ${availabilityPeriodLength} ${timeUnit}.\nThe time limit starts from the moment of payment.`
    }
    default:
      return ''
  }
}

export const formatPriceSettings = (
  priceSettings?: Array<ContentPriceSettingsDto>
): FormattedPriceSettings[] | undefined => {
  if (!priceSettings) return

  return priceSettings.map(
    ({
      type,
      paymentMethod,
      currency,
      price,
      availabilityPeriodLength,
      availabilityPeriodTimeUnit,
      ...rest
    }) => {
      const formattedType =
        type === PriceSettingsTypeEnum.Purchase
          ? PriceSettingsFormattedType.Buy
          : PriceSettingsFormattedType.Rent
      const text = getPurchaseTextByType({
        type: type as PriceSettingsType,
        availabilityPeriodLength,
        availabilityPeriodTimeUnit:
          availabilityPeriodTimeUnit as PriceSettingsAvailabilityPeriodTimeUnit,
      })

      return {
        formattedType,
        text,
        priceString: `${currency} ${price}`,
        type: type as PriceSettingsType,
        paymentMethod: paymentMethod as PriceSettingsPaymentMethod,
        availabilityPeriodLength,
        availabilityPeriodTimeUnit:
          availabilityPeriodTimeUnit as PriceSettingsAvailabilityPeriodTimeUnit,
        currency,
        price,
        ...rest,
      }
    }
  )
}
