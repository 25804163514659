import { type IProfile } from '@setplex/tria-api'

export const MAX_NUMBER_OF_PROFILES = 5
export const PROFILE_NAME_MIN_LENGTH = 3
export const PROFILE_NAME_MAX_LENGTH = 16

/**
 * Profile type for optimistic updates
 */
export type OptimisticProfile = IProfile & {
  _deleted?: boolean
  _original?: IProfile
}
