import { sample } from 'effector'
import * as http from './http'
import { $meterProvider } from './init'

export * from './init'

sample({
  clock: $meterProvider,
  filter: Boolean,
  target: http.init,
})
