import { createEffect, createStore } from 'effector'
import type {
  TvChannel,
  TvChannelUpdateObj,
  TvShow,
  TvShowCategory,
  TvShowUpdateObj,
  Vod,
  VodCategory,
  VodUpdateObj,
} from '../index'
import type { ApiEffect } from '../index.h'
import type { MediaTypes, ResourceTypes } from '../interfaces/generic'
import { MediaTypes as MediaTypesEnum } from '../interfaces/generic'
import { updateTvChannelFx } from './tv'
import { updateTvShowFx } from './tvshows'
import {
  updateSingleFailHandler,
  updateSingleHandler,
  updateSingleSuccessHandler,
  updateVodFx,
} from './vods'

export type BannerContentItem =
  | Vod
  | TvShow
  | TvChannel
  | VodCategory
  | TvShowCategory

export type BannerContentItemPrice = Vod | TvShow | TvChannel

// TvRadioCategory // unused atm but should be there

export interface Banner {
  bannerBundleId?: number
  sortOrder?: number
  resourceType?: ResourceTypes
  contentType?: MediaTypes
  mobileBannerUrl?: string
  tabletBannerUrl?: string
  tvBannerUrl?: string
  content?: BannerContentItem
  /**
   * Contents link to the resource.
   * Will be presented only in cases:
   * 1. resourceType:EXTERNAL
   */
  link?: string
}

export interface BannerWithPrice extends Banner {
  content?: BannerContentItemPrice
}

export interface BannerCarousel {
  bannerCarouselId?: number
  bannerBundles?: Array<Banner>
  autoScrollEnabled?: boolean
  autoScrollIn?: number
}

export enum CarouselTypes {
  VOD = 'VOD',
  TV_SHOW = 'TV_SHOW',
  TV_CHANNEL = 'TV_CHANNEL',
}

export interface CarouselItemTyped<T> {
  contentItemId: number
  carouselMediaId: number
  order: number
  contentType: CarouselTypes
  vod?: Vod | T
  tvShow?: TvShow | T
  channel?: TvChannel | T
}

export interface CarouselItem {
  contentItemId: number
  carouselMediaId: number
  order: number
  contentType: CarouselTypes
  vod?: Vod
  tvShow?: TvShow
  channel?: TvChannel
}

export interface FeaturedCarouselTyped<T> {
  id: number
  type: CarouselTypes
  content: Array<CarouselItemTyped<T>>
  deleted: boolean
  enabledOnModulePage: boolean
}

export interface FeaturedCarousel {
  id: number
  type: CarouselTypes
  content: Array<CarouselItem>
  deleted: boolean
  enabledOnModulePage: boolean
}

export interface BannerUpdateObjOption1 {
  id: number
  itemType: MediaTypes
  favorite: boolean
  watched?: boolean
}

export interface BannerUpdateObjOption2 {
  id: number
  itemType: MediaTypes
  favorite?: boolean
  watched: boolean
}

export type BannerUpdateObj = BannerUpdateObjOption1 | BannerUpdateObjOption2

// GET /v3/carousels/banners
export const getBannersFx: ApiEffect<void, BannerCarousel[]> = createEffect()

// GET /v3/carousels/featured
export const getFeaturedCarouselFx: ApiEffect<void, FeaturedCarousel[]> =
  createEffect()

// *
// * HANDLERS
// *

const bannersItemHandler = (
  banners: BannerCarousel[],
  params: VodUpdateObj | TvShowUpdateObj | TvChannelUpdateObj,
  func: Function
) =>
  banners.map((bundle: BannerCarousel) => ({
    ...bundle,
    bannerBundles: bundle?.bannerBundles?.map((banner) => {
      if (banner.content?.id === params.id)
        return {
          ...banner,
          content: func(banner?.content, params),
        }
      return banner
    }),
  }))

const featuredItemHandler = (
  carousels: FeaturedCarousel[],
  params: VodUpdateObj,
  func: Function
) =>
  carousels.map((carousel: FeaturedCarousel) => ({
    ...carousel,
    content: carousel?.content?.map((item) => {
      if (item.vod?.id === params.id)
        return { ...item, vod: func(item.vod, params) }
      if (item.tvShow?.id === params.id)
        return { ...item, tvShow: func(item.tvShow, params) }
      if (item.channel?.id === params.id)
        return { ...item, channel: func(item.channel, params) }
      return item
    }),
  }))

/*
 * Stores
 */

/******************** $banners ******************/
export const $banners = createStore<BannerCarousel[]>([])
  .on(getBannersFx.doneData, (_, banners) => banners || [])
  .on([updateVodFx, updateTvShowFx, updateTvChannelFx], (state, params) =>
    bannersItemHandler(state, params, updateSingleHandler)
  )
  .on(
    [updateVodFx.fail, updateTvShowFx.fail, updateTvChannelFx.fail],
    // api.vods.updateSingleHandler is type agnostic actually, so could be used for many items
    (state, { params }) =>
      bannersItemHandler(state, params, updateSingleFailHandler)
  )
  .on(
    [updateVodFx.done, updateTvShowFx.done, updateTvChannelFx.done],
    (state, { params }) =>
      bannersItemHandler(state, params, updateSingleSuccessHandler)
  )
/******************** $banners ******************/

export const $bannersTypeVod = createStore<BannerCarousel[]>([]).on(
  $banners,
  (_, banners) => {
    const mediaTypes = [
      MediaTypesEnum.VOD,
      MediaTypesEnum.VOD_CATEGORY,
      MediaTypesEnum.VOD_SUBCATEGORY,
    ]

    return banners.map((bundle: BannerCarousel) => ({
      ...bundle,
      bannerBundles: bundle.bannerBundles?.filter(({ contentType }) => {
        if (!contentType) return false
        return mediaTypes.includes(contentType)
      }),
    }))
  }
)

export const $bannersTypeTvShow = createStore<BannerCarousel[]>([]).on(
  $banners,
  (_, banners) => {
    const bannerBundles = banners.some(
      ({ bannerBundles }) => bannerBundles?.length
    )
    if (!bannerBundles) return []

    const mediaTypes = [
      MediaTypesEnum.TV_SHOW,
      MediaTypesEnum.TV_SHOW_CATEGORY,
      MediaTypesEnum.TV_SHOW_SUBCATEGORY,
    ]

    return banners.map((bundle) => ({
      ...bundle,
      bannerBundles: bundle.bannerBundles?.filter(({ contentType }) => {
        if (!contentType) return false
        return mediaTypes.includes(contentType)
      }),
    }))
  }
)

export const $bannersTypeTv = createStore<BannerCarousel[]>([]).on(
  $banners,
  (_, banners) => {
    return banners.map((bundle: BannerCarousel) => ({
      ...bundle,
      bannerBundles: bundle.bannerBundles?.filter(
        ({ contentType }) =>
          contentType === MediaTypesEnum.TV_CHANNEL ||
          contentType === MediaTypesEnum.TV_CATEGORY
      ),
    }))
  }
)

export const $featuredCarousel = createStore<FeaturedCarousel[]>([])
  .on(getFeaturedCarouselFx.doneData, (_, carousel) => carousel || [])
  .on([updateVodFx, updateTvShowFx, updateTvChannelFx], (state, params) =>
    featuredItemHandler(state, params, updateSingleHandler)
  )
  .on(
    [updateVodFx.fail, updateTvShowFx.fail, updateTvChannelFx.fail],
    // api.vods.updateSingleHandler is type agnostic actually, so could be used for many items
    (state, { params }) =>
      featuredItemHandler(state, params, updateSingleFailHandler)
  )
  .on(
    [updateVodFx.done, updateTvShowFx.done, updateTvChannelFx.done],
    (state, { params }) =>
      featuredItemHandler(state, params, updateSingleSuccessHandler)
  )

export const $featuredCarouselTypeVod = createStore<FeaturedCarousel[]>([]).on(
  $featuredCarousel,
  (_, carousels) =>
    carousels.filter(
      (carousel: FeaturedCarousel) => carousel.type === CarouselTypes.VOD
    )
)

export const $featuredCarouselTypeTvShow = createStore<FeaturedCarousel[]>(
  []
).on($featuredCarousel, (_, carousels) =>
  carousels.filter(
    (carousel: FeaturedCarousel) => carousel.type === CarouselTypes.TV_SHOW
  )
)

// TODO create type that would include only "tv: TvChannel"
export const $featuredCarouselTypeTv = createStore<FeaturedCarousel[]>([]).on(
  $featuredCarousel,
  (_, carousels) =>
    carousels.filter(
      (carousel: FeaturedCarousel) => carousel.type === CarouselTypes.TV_CHANNEL
    )
)
