import { is, type Store } from 'effector'

const map: Map<string, Store<unknown>> = new Map<string, Store<unknown>>()

export const type = Symbol()

export function check(x: unknown): x is Store<unknown> {
  return is.store(x)
}

export function set(store: Store<unknown>, name?: string): Store<unknown> {
  name = name ?? store.shortName
  map.set(name, store)
  return store
}

export function get<T = unknown>(name: string): Store<T> | null {
  return (map.get(name) as Store<T>) ?? null
}
