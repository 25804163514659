import { F } from '@mobily/ts-belt'
import { createEvent, sample, split } from 'effector'
import { profilesModel } from '~/entities/profiles'
import { profilesFeaturesModel } from '~/features/profiles'
import { keys } from '~/shared/jail'
import { type Cage, type Transit } from '../index.h'

/*
 * ### Hallway->Profiles intermediate cage ###
 */

const init = createEvent<Transit>('init')

// out events
const singleActiveProfile = createEvent('singleActiveProfile')
const selectedActiveProfile = createEvent('selectedActiveProfile')
const shouldCreateProfile = createEvent('shouldCreateProfile')
const shouldChooseProfile = createEvent('shouldChooseProfile')

export const hallway2profiles = {
  name: 'hallway2profiles',
  key: keys.lost,
  init,
  in: [],
  out: {
    singleActiveProfile,
    shouldCreateProfile,
    selectedActiveProfile,
    shouldChooseProfile,
  },
} satisfies Cage

/*
 * cage logic
 */

const enum OUT {
  SINGLE_ACTIVE_PROFILE,
  SELECTED_ACTIVE_PROFILE,
  SHOULD_CREATE_PROFILE,
  SHOULD_CHOOSE_PROFILE,
  __AUTO_ACTIVATE_PROFILE__, // intermediate, not out event
}

const out = createEvent<OUT>()

split({
  source: out,
  match: F.identity,
  cases: {
    [OUT.SINGLE_ACTIVE_PROFILE]: singleActiveProfile,
    [OUT.SELECTED_ACTIVE_PROFILE]: selectedActiveProfile,
    [OUT.SHOULD_CREATE_PROFILE]: shouldCreateProfile,
    [OUT.SHOULD_CHOOSE_PROFILE]: shouldChooseProfile,
  },
})

sample({
  clock: init,
  target: profilesModel.getProfilesFx,
})

sample({
  clock: profilesModel.getProfilesFx.done,
  source: {
    profiles: profilesModel.$optimisticProfiles,
    profile: profilesModel.$activeProfile,
    selectedProfileId: profilesFeaturesModel.$selectedProfileId,
  },
  fn: ({ profiles, profile, selectedProfileId }) => {
    if (profiles.length === 0) {
      return OUT.SHOULD_CREATE_PROFILE
    }

    if (profiles.length === 1) {
      if (profile != null && profiles[0].id === profile.id) {
        return OUT.SINGLE_ACTIVE_PROFILE
      }

      return OUT.__AUTO_ACTIVATE_PROFILE__
    }

    if (selectedProfileId && profile?.id === selectedProfileId) {
      return OUT.SELECTED_ACTIVE_PROFILE
    }

    return OUT.SHOULD_CHOOSE_PROFILE
  },
  target: out,
})

sample({
  clock: out,
  source: profilesModel.$optimisticProfiles,
  filter: (_, reason) => reason === OUT.__AUTO_ACTIVATE_PROFILE__,
  fn: (profiles) => profiles[0].id!,
  target: profilesModel.updateProfileActiveStateFx,
})

sample({
  clock: profilesModel.updateProfileActiveStateFx.done,
  fn: () => OUT.SINGLE_ACTIVE_PROFILE,
  target: out,
})
