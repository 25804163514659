import type { API } from '@setplex/tria-api'
import { type HttpClient } from '../../http'
import { type ApiGlobalSearchResponse } from '../../interfaces/global-search'
import { formatLiveEvent } from '../live-events/live-events.format'
import { formatChannel } from '../tv/tv.format'
import { formatShow } from '../tvshows/tvshows.format'
import { formatVod } from '../vods/vods.format'

export function use(
  http: HttpClient,
  globalSearch: API['globalSearch'],
  _api: API
): void {
  //GET /v3/search
  globalSearch.getSearchFx.use(
    async ({ q, count, contentType, contentTypes, cursor }) => {
      const params = new URLSearchParams()
      if (q != null) params.set('q', String(q))
      if (count) params.set('count', String(count))
      if (contentType) params.set('contentType', String(contentType))
      if (contentTypes) params.set('contentTypes', String(contentTypes))
      if (cursor) params.set('cursor', String(cursor))

      const json = await http.get<ApiGlobalSearchResponse>(
        `/v3/search?${params}`
      )

      if (!json || !json.payload) {
        throw new Error('Empty answer in getSearchFx')
      }

      const { liveEvents, tvChannels, tvShows, vods, radioChannels } =
        json.payload

      const formattedEvents = (liveEvents?.content || []).map(formatLiveEvent)
      const formattedTvChannels = (tvChannels?.content || []).map(formatChannel)
      const formattedTvShows = (tvShows?.content || []).map(formatShow)
      const formattedVods = (vods?.content || []).map(formatVod)

      return {
        liveEvents: {
          ...liveEvents,
          content: formattedEvents,
        },
        tvChannels: {
          ...tvChannels,
          content: formattedTvChannels,
        },
        tvShows: {
          ...tvShows,
          content: formattedTvShows,
        },
        vods: {
          ...vods,
          content: formattedVods,
        },
        radioChannels: {
          ...radioChannels,
          content: [],
        },
      }
    }
  )
}
