/*
 * Different useful checkers
 */

/**
 * Check if smth is a Promise instance, looks ugly but ¯\_(ツ)_/¯
 *
 * Other solutions, like
 *  - `value instanceof Promise`
 *  - `Object.prototype.toString.call(value) === '[object Promise]'`
 *  - `Object(x).constructor === Promise`
 * are not working across realms and with non-native promises
 *
 * One semi-good way is to use `Promise.resolve(value)`,
 * but it will return a Promise out of non-promises.
 * It might be ok in async environment, but if you want to be sure that
 * functions is synchronous when `value` is not a Promise - it will not do ._.
 *
 * Check answers here for more details:
 * https://stackoverflow.com/questions/27746304/how-do-i-tell-if-an-object-is-a-promise
 */
export const isPromise = <T = unknown>(value: unknown): value is Promise<T> =>
  value != null &&
  (typeof value === 'object' || typeof value === 'function') &&
  'then' in value &&
  typeof value.then === 'function'

/**
 * Checks if smth is a function
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (value: unknown): value is Function =>
  typeof value === 'function'

/**
 * Check if smth is an array
 */
export const isArray = <T = unknown>(value: T | T[]): value is T[] =>
  value != null && Array.isArray(value)

/**
 * Check if smth is a plain object
 */
export const isPlainObject = (
  value: unknown
): value is Record<string, unknown> =>
  value != null &&
  typeof value === 'object' &&
  !Array.isArray(value) &&
  Object.prototype.toString.call(value) === '[object Object]'
