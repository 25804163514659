import { model as router } from '!/router'
import { createEvent, restore, sample } from 'effector'
import { persist } from 'effector-storage/session'
import { getFromComeHref } from './helpers'
import { FromComeLocationEnum } from './index.h'

export const setFromComeLocation = createEvent<FromComeLocationEnum>()

export const setFromComeHref = createEvent<string | null>()
export const $fromComeHref = restore(setFromComeHref, null)

persist({
  store: $fromComeHref,
  key: 'tria__from-come-href',
})

sample({
  clock: setFromComeLocation,
  source: {
    previousLocation: router.$previous,
    currentLocation: router.$location,
  },
  filter: ({ previousLocation }, currentEventType) =>
    previousLocation != null || currentEventType === FromComeLocationEnum.LEFT,
  fn: ({ previousLocation }, currentEventType) =>
    getFromComeHref({
      previousLocation,
      currentEventType,
    }),
  target: setFromComeHref,
})
