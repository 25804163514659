import { logger } from '@/logger'
import { is } from 'effector'
import { useStoreMap } from 'effector-react'
import { inherit, Pack, Raw } from '../scope'

// debug flag
const debug =
  process.env.NODE_ENV !== 'production' &&
  typeof document !== 'undefined' &&
  globalThis.debugUseRaw

/**
 * Hook to get value from Raw
 */
export function useRaw(raw: Raw | null): unknown {
  if (raw === null) {
    return null
  }

  // prettier-ignore
  if (debug) {
    const title = raw.name.split('~>').map((name) => name.trim().split(':')).map(([t, ...b]) => '%c' + t + '%c' + b.join(':'))
    logger.groupCollapsed('👩‍🍳 ' + title.join(' %c~> '), ...Array(title.length).fill(['background:lightblue;border-radius:3px;padding:0 2px;color:black;font-weight:normal','color:inherit;font-weight:normal','color:pink']).flat().slice(0, -1))
    logger.log('🥚', raw)
  }

  const unscope = unraw(raw.scope) as
    | { [name: string]: unknown }
    | null
    | undefined

  if (debug) {
    if (unscope && Object.keys(unscope).length > 0) logger.log('🧂', unscope)
  }

  let result: unknown = is.store(raw.src)
    ? // this is ok, because skin is static and never changes on the fly
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useStoreMap(raw.src, (x) => raw.map(x) ?? null)
    : raw.map(raw.src, unscope) ?? null

  // recursively unraw value
  // this allows to use arrows and nested objects for properties in skin definition
  if (
    !is.store(raw.src) && // not allow for stores, because store value is dynamic
    !(raw instanceof Pack) // do not unraw packed value
  ) {
    result = unraw(result)
  }

  if (debug) {
    logger.log('🍳', result)
    logger.groupEnd()
  }

  return result
}

function unraw(obj: unknown): unknown {
  if (obj == null || typeof obj !== 'object') {
    return obj
  }

  // recursively unraw array
  if (Array.isArray(obj)) {
    return obj.map((item) => (item instanceof Raw ? useRaw(item) : item))
  }

  // recursively unraw simple object
  if (Object.prototype.toString.call(obj) === '[object Object]') {
    const result: { [name: string]: unknown } = inherit()
    for (const k in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, k)) {
        const v: unknown = obj[k as keyof typeof obj]
        // this is ok, because skin is static and never changes on the fly
        // eslint-disable-next-line react-hooks/rules-of-hooks
        result[k] = v instanceof Raw ? useRaw(v) : v
      }
    }
    return result
  }

  return obj
}
