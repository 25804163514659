import { noop } from '@/helpers'
import { debug as _debug } from 'patronum/debug'

type DebugFn = typeof _debug

declare global {
  // disable `no-var` rule because this is not a variable declarations,
  // but a type definitions, and it works only with `var`

  /* eslint-disable no-var */
  var debug: DebugFn
  var debugUseEvents: boolean
  var debugUseData: boolean
  var debugUseRaw: boolean
  var debugExact: boolean
  /* eslint-enable no-var */
}

if (process.env.NODE_ENV === 'production') {
  window.debug = noop as DebugFn
} else {
  globalThis.debug = _debug
  globalThis.debugUseEvents = false
  globalThis.debugUseData = false
  globalThis.debugUseRaw = false
  globalThis.debugExact = false
}

// add mimic in development mode
if (process.env.NODE_ENV !== 'production') {
  if (/^1|yes|true$/i.test(import.meta.env.VITE_MIMIC)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import('mimic')
  }
}
