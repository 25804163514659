import { memo, type MemoExoticComponent } from 'react'
import { type SkinView } from '../../index.h'
import { key, useRender, useScope, useSources, type Scope } from '../core'
import { hasLink, Link } from './Link'
import { hasMap, hasOver, Many } from './Many'
import { hasMatchCases, Match } from './Match'
import { One } from './One'

export type Recursive = typeof Recursive

interface Props {
  skin: SkinView
  parent?: Scope
  [key: string]: unknown // any additional props, will be propogated to ALL children
}

// exports memoized component
export const Recursive: MemoExoticComponent<typeof _Recursive> =
  memo(_Recursive)

// _Recursive.whyDidYouRender = true
_Recursive.displayName = 'Recursive'
function _Recursive({ skin, parent, ...props }: Props): JSX.Element | null {
  const sources: Scope = useSources(skin)
  const scope: Scope = useScope(skin, sources, parent)

  const render: boolean = useRender(skin, scope)
  if (!render) {
    return null
  }

  // skin has `map` or `over` field -> iterate over list values
  if (hasMap(skin) || hasOver(skin)) {
    return (
      <Many
        {...props}
        key={key(skin)}
        skin={skin}
        parent={scope}
        Recursive={Recursive}
      />
    )
  }

  // skin has `link` field -> render component, wrapped in link
  if (hasLink(skin)) {
    return (
      <Link
        {...props}
        key={key(skin)}
        skin={skin}
        parent={scope}
        Recursive={Recursive}
      />
    )
  }

  // skin has `match`/`cases` fields -> choose one of the cases
  if (hasMatchCases(skin)) {
    return (
      <Match
        {...props}
        key={key(skin)}
        skin={skin}
        parent={scope}
        Recursive={Recursive}
      />
    )
  }

  // default case -> render exact component
  return (
    <One
      {...props}
      key={key(skin)}
      skin={skin}
      parent={scope}
      Recursive={Recursive}
    />
  )
}
