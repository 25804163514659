import { T } from '@/helpers'
import { A, D, pipe } from '@mobily/ts-belt'
import { createEvent, createStore, sample, type Store } from 'effector'
import { getAll } from 'firebase/remote-config'
import { and, delay, not } from 'patronum'
import { firebaseSharedModel as firebase } from '~/shared/firebase'
import {
  asType,
  byKey,
  defaults,
  type Remote,
  type RemoteKeys,
} from './defaults'
import { MAX_REMOTE_CONFIG_AWAIT_TIME } from './index.h'

const setReady = createEvent()
export const $ready = createStore<boolean>(false).on(setReady, T)

// firebase remote config store
export const $config = createStore<Remote>(defaults)

// firebase remote config single key getter
export const get = <K extends RemoteKeys>(
  name: K,
  def: NonNullable<Remote[K]> = defaults[name]
): Store<NonNullable<Remote[K]>> => $config.map((remote) => remote[name] ?? def)

// set firebase remote config defaults
sample({
  clock: firebase.$ready,
  filter: Boolean,
  fn: () => defaults,
  target: firebase.remote.setDefaults,
})

// fetch and activate on firebase readiness
// and re-fetch and re-activate remote config on any user property change
sample({
  clock: [firebase.$ready, firebase.analytics.setUserPropertiesFx.done],
  filter: and(firebase.$ready, not(firebase.remote.fetchAndActivateFx.pending)),
  target: firebase.remote.fetchAndActivateFx,
})

// convert firebase remote config to local config
sample({
  clock: firebase.remote.fetchAndActivateFx.done,
  source: firebase.remote.$firebaseConfig,
  filter: Boolean,
  fn: (config) =>
    pipe(
      config,
      getAll,
      D.filterWithKey(byKey),
      D.toPairs,
      A.map(([key, value]) => [key, asType(key, value!)] as const),
      D.fromPairs
    ) as Remote,
  target: $config,
})

// set ready after remote config is loaded or failed to load
sample({
  clock: firebase.remote.fetchAndActivateFx.finally,
  target: setReady,
})

// set ready after some timeout, if remote config is not loaded
delay({
  source: firebase.remote.fetchAndActivateFx,
  timeout: MAX_REMOTE_CONFIG_AWAIT_TIME,
  target: setReady,
})

//
// register stores and events
//

// register($, '$config/remote')
