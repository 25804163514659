import { model as config, remote } from '@/config'
import { api, type AccountAccessToken } from '@setplex/tria-api'
import { attach, createEffect, createEvent, sample } from 'effector'
import { openWindow } from '~/core/router'
import { model as session } from '~/entities/session'
import { getSubscriberAccountSettingsUrl } from './helpers'

export const getPortalAccountAccessTokenFx = attach({
  effect: api.subscriber.getAccountAccessTokenFx,
})

export const $remoteSubscriberAccountSettingsURL = config.get(
  remote.tria_subscriberAccountSettingsUrl
)

export const navigateToAccountSettings = createEvent()
const getAccountSettingsUrl = createEvent<AccountAccessToken | null>()
const redirectToAccountSettingsFx = createEffect((url: string | null) => {
  if (url) {
    const handler = openWindow(url, 'external_portal')
    if (handler) return handler
  }
  throw new Error('Failed to open window')
})

sample({
  clock: navigateToAccountSettings,
  target: getPortalAccountAccessTokenFx,
})

sample({
  clock: getPortalAccountAccessTokenFx.doneData,
  target: getAccountSettingsUrl,
})
sample({
  clock: getPortalAccountAccessTokenFx.failData,
  fn: () => null,
  target: getAccountSettingsUrl,
})

sample({
  clock: getAccountSettingsUrl,
  source: {
    baseUrl: $remoteSubscriberAccountSettingsURL,
    session: session.$session,
  },
  fn: ({ baseUrl, session }, token) =>
    getSubscriberAccountSettingsUrl({ baseUrl, session, token }),
  target: redirectToAccountSettingsFx,
})
