import { F, flow } from '@mobily/ts-belt'
import { extend, inherit, type Scope } from '../scope'

/**
 * Source modifier
 */
export type Map = (
  arg: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  unscope?: { [name: string]: unknown } | null
) => unknown

/**
 * Raw required data with optional mapper
 */
export class Raw {
  // it is impractical to change the number of arguments in the constructor
  // eslint-disable-next-line max-params
  constructor(
    // any string for debug purposes
    public name: string,

    // any boxed source
    // could be effector Store, or any object, or string, or anything
    public src: unknown,

    // function to get value from source
    // could map path, or take element by index, or modify source in any way
    // result of the map function will be given to component
    public map: Map,

    // optional scope, which will be unrawed first, and then given to map function
    // useful for expressions, which needs unrawed data before evaluation
    public scope?: Scope
  ) {}
}

/**
 * Raw required packed data, with optional mapper
 * Basically the same as Raw but hook will not recursively unpack it
 */
export class Pack extends Raw {}

/*
 * Factories
 */

const factory =
  (Cls: typeof Raw) =>
  // it is impractical to change the number of arguments in the constructor
  // eslint-disable-next-line max-params
  (name: string, src: Raw | unknown, map?: Map, scope?: Scope): Raw =>
    src instanceof Raw
      ? new Cls(
          name && src.name ? src.name + ' ~> ' + name : name || src.name,
          src.src,
          map && src.map !== F.identity ? flow(src.map, map) : map || src.map,
          scope && src.scope
            ? extend(scope, inherit(src.scope))
            : scope || src.scope
        )
      : new Cls(name, src, map || F.identity, scope)

export const raw = factory(Raw)
export const pack = factory(Pack)
