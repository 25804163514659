import { F } from '@/helpers'
import { deleteToken, getToken } from 'firebase/messaging'
import type { Workbox } from 'workbox-window'
import type { Messaging } from './index.h'
import { MessageTypes, NotificationPermission } from './index.h'

const firebaseConfig = window.ERA_CONFIG?.firebaseConfig || {}

export const requestPermission = (): Promise<boolean> => {
  return Notification.requestPermission()
    .then((permission) => permission === NotificationPermission.GRANTED)
    .catch(F)
}

export const requestToken = ({
  messaging,
  serviceWorkerRegistration,
}: {
  messaging: Messaging
  serviceWorkerRegistration: ServiceWorkerRegistration
}): Promise<string> => {
  const vapidKey = (firebaseConfig.vapidKey || '') as string

  if (!vapidKey)
    throw new Error(
      'vapidKey is missing. Generate vapidKey in firebase and add to app.'
    )

  return getToken(messaging, {
    vapidKey,
    serviceWorkerRegistration,
  })
}
export const unregisterPushNotification = ({
  messaging,
}: {
  messaging: Messaging
}): Promise<unknown> => {
  return deleteToken(messaging)
}

export const setLogoUrlToPushNotifications = ({
  workbox,
  iconUrl,
}: {
  workbox: Workbox
  iconUrl: string
}): Promise<unknown> => {
  //TODO: consider adding logo url to indexDB
  return workbox.messageSW({
    type: MessageTypes.SET_APP_ICON,
    iconUrl,
  })
}
