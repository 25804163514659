import { exists, invertObj } from '@/helpers'
import { logger } from '@/logger'
import { produce } from 'immer'
import { memo, useMemo, type MemoExoticComponent } from 'react'
import { type SkinView } from '../../index.h'
import { express, key, useRaw, type Scope } from '../core'
import { type Recursive } from './Recursive'

export type MatchCasesSkin = SkinView &
  Required<Pick<SkinView, 'match' | 'cases'>>

interface Props {
  skin: MatchCasesSkin
  parent: Scope
  Recursive: Recursive
  [key: string]: unknown // any additional props, will be propogated to all children
}

// exports memoized component
export const Match: MemoExoticComponent<typeof _Match> = memo(_Match)

// _Match.whyDidYouRender = true
_Match.displayName = 'Match'
function _Match({
  skin,
  parent,
  Recursive,
  ...props
}: Props): JSX.Element | null {
  const match: unknown = useRaw(express(skin.match, parent))
  const matched: SkinView | undefined = skin.cases[parseMatch(skin, match)]
  const mergedSkin: SkinView | undefined = useMemo(
    () => (matched ? merge(matched, skin) : undefined),
    [skin, matched]
  )

  return mergedSkin ? (
    <Recursive
      {...props}
      key={key(mergedSkin)}
      skin={mergedSkin}
      parent={parent}
    />
  ) : null
}

export function hasMatchCases(skin: SkinView): skin is MatchCasesSkin {
  return exists(skin.match) && exists(skin.cases)
}

/**
 * Merge two or more skins (actually just merge data for now)
 */
function merge(...skins: SkinView[]): SkinView {
  return skins.reduce(
    produce((draft, skin) => {
      if (draft.data || skin.data) {
        draft.data = Object.assign({}, draft.data, skin.data)
      }
    })
  )
}

/**
 * Find matching key for `match` field, from `cases` or `viewRules`
 */
function parseMatch(skin: MatchCasesSkin, resolved: unknown): string {
  if (typeof resolved === 'undefined') {
    // should warn or this is expected behavior?
    logger.warn(
      `${skin.match} has been resolved to undefined, will use 'undefined' as matching value`
    )
  }

  const viewRulesReversed:
    | Record<string | number, string | number>
    | undefined = skin.viewRules ? invertObj(skin.viewRules) : undefined

  const against: string[] = Object.keys(viewRulesReversed ?? skin.cases)
  const match: string | undefined =
    typeof resolved === 'number' ||
    (typeof resolved === 'string' &&
      resolved !== '' &&
      !isNaN(Number(resolved)))
      ? matchNumber(Number(resolved), against)
      : matchString(String(resolved ?? 'undefined'), against)

  if (typeof match === 'undefined') {
    // should warn or this is expected behavior?
    logger.warn(
      `${skin.match} (= ${resolved}) is not found in cases/viewRules, will use 'undefined' as case value`
    )
  }

  return String(
    viewRulesReversed ? viewRulesReversed[match ?? 'undefined'] : match
  )
}

const splitRe = /\s*,\s*/

/**
 * Match string value against list of cases, cases could be separated by comma,
 * like 'VOD', or 'TV_CHANNEL, TV_SHOW'
 */
function matchString(match: string, against: string[]): string | undefined {
  for (const probe of against) {
    for (const pick of probe.split(splitRe)) {
      if (pick === match) {
        return probe
      }
    }
  }
}

const RANGE = '...' // range dots
const DOTS = '..' // between dots
const DASH = '-' // between dash
const GT = '>' // greater than
const GE = '>=' // greater than or equal
const LT = '<' // less than
const LE = '<=' // less than or equal
const EQ = '=' // equal to
const EVERY = '/' // every

/**
 * Match number value against list of cases, cases could be
 * - exact values, like '1', '2', or '3,5,10'
 * - ranges (brackets are optional for backwards compatibility), like '[3...5]', or '[...3, 5...10, 12...]'
 * - ranges, like '3-5', or '<=3, 5-10, >=12'
 * - multiplicatives, like '/3' -> every 3d value
 */
function matchNumber(match: number, against: string[]): string | undefined {
  for (const probe of against) {
    let x: number
    for (let pick of probe.split(splitRe)) {
      // remove optional brackets
      if (pick.startsWith('[')) pick = pick.slice(1).trim()
      if (pick.endsWith(']')) pick = pick.slice(0, -1).trim()

      // ...3
      if (pick.startsWith(RANGE)) {
        x = Number(pick.slice(RANGE.length))
        if (match <= x) return probe
        else continue
      }

      // <=3
      if (pick.startsWith(LE)) {
        x = Number(pick.slice(LE.length))
        if (match <= x) return probe
        else continue
      }

      // <3
      if (pick.startsWith(LT)) {
        x = Number(pick.slice(LT.length))
        if (match < x) return probe
        else continue
      }

      // 12...
      if (pick.endsWith(RANGE)) {
        x = Number(pick.slice(0, -RANGE.length))
        if (match >= x) return probe
        else continue
      }

      // >=12
      if (pick.startsWith(GE)) {
        x = Number(pick.slice(GE.length))
        if (match >= x) return probe
        else continue
      }

      // >12
      if (pick.startsWith(GT)) {
        x = Number(pick.slice(GT.length))
        if (match > x) return probe
        else continue
      }

      // 5...10
      if (pick.includes(RANGE)) {
        const [x, y] = pick.split(RANGE, 2)
        if (match >= Number(x) && match <= Number(y)) return probe
        else continue
      }

      // 5..10
      if (
        pick.includes(DOTS) &&
        !pick.startsWith(DOTS) &&
        !pick.endsWith(DOTS)
      ) {
        const [x, y] = pick.split(DOTS, 2)
        if (match >= Number(x) && match <= Number(y)) return probe
        else continue
      }

      // 5-10
      if (
        pick.includes(DASH) &&
        !pick.startsWith(DASH) &&
        !pick.endsWith(DASH)
      ) {
        const [x, y] = pick.split(DASH, 2)
        if (match >= Number(x) && match <= Number(y)) return probe
        else continue
      }

      // /3
      if (pick.startsWith(EVERY)) {
        x = Number(pick.slice(EVERY.length))
        if (match % x === 0) return probe
        else continue
      }

      // =1
      if (pick.startsWith(EQ)) {
        x = Number(pick.slice(EQ.length))
        if (match === x) return probe
        else continue
      }

      // 1
      if (match === Number(pick)) return probe
    }
  }
}
