import type { AnalyticsInfo, API } from '@setplex/tria-api'
import { type HttpClient } from '../../http'
import type { AdapterDefaults } from '../../index.h'
import { logger } from '../../utils/logger'
import { postAnalytics, request, requestWatchingContent } from './request'

const formatReleaseVersion = (appVersion: string): string => {
  return appVersion.split('#')[0]
}

const common = (info: AnalyticsInfo) => ({
  app_releaseVersion: formatReleaseVersion(info.appVersion),
  app_name: info.appName,
  app_id: info.appId,
  device_OsVersion: info.browserVersion,
  device_model: info.browserName,
  device_name: info.osName,
  device_type: 'web',
  device_id: info.deviceId,
  network_ip: info.networkIp,
  network_isp: info.networkIsp,
  network_location: info.location,
  content_provider_id: info.contentProviderId,
  content_user_subscriber_id: info.contentUserSubscriberId,
  content_user_account_number: info.contentUserAccountNumber,
  content_user_profile_id: info.contentUserProfileId,
  content_user_profile_name: info.contentUserProfileName,
})

export function use(
  _http: HttpClient,
  analytics: API['analytics'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  // `session_start` event
  analytics.sendAnalyticsSessionStartFx.use(async ({ domain, data }) => {
    const requestData = {
      ...common(data),
      event_type: data.eventType,
    }
    logger.info('sendAnalyticsSessionStart:', requestData)
    await postAnalytics(domain, request(requestData))
  })

  // `session_end` event
  analytics.sendAnalyticsSessionStopFx.use(async ({ domain, data }) => {
    const requestData = {
      ...common(data),
      event_type: data.eventType,
    }
    logger.info('sendAnalyticsSessionStop:', requestData)
    await postAnalytics(domain, request(requestData))
  })

  // `favorite_add` event
  analytics.sendAnalyticsFavoriteAddFx.use(async ({ domain, data }) => {
    const requestData = {
      ...common(data),
      event_type: data.eventType,
      event_parameter1: data.contentId,
      event_parameter2: data.contentType,
    }
    logger.info('sendAnalyticsFavoriteAdd:', requestData)
    await postAnalytics(domain, request(requestData))
  })

  // `favorite_remove` event
  analytics.sendAnalyticsFavoriteRemoveFx.use(async ({ domain, data }) => {
    const requestData = {
      ...common(data),
      event_type: data.eventType,
      event_parameter1: data.contentId,
      event_parameter2: data.contentType,
    }
    logger.info('sendAnalyticsFavoriteRemove:', requestData)
    await postAnalytics(domain, request(requestData))
  })

  // `play_start` event
  analytics.sendAnalyticsStartWatchingFx.use(async ({ domain, data }) => {
    const requestData = {
      ...common(data),
      event_type: data.eventType,
      event_parameter1: data.contentId,
      event_parameter2: data.contentType,
      event_parameter3: data.contentUrl,
      event_parameter4: data.audioLanguage,
      event_parameter5: data.textLanguage,
      event_parameter6: data.startedTime,
      event_parameter7: data.duration,
    }
    logger.info('sendAnalyticsStartWatching:', requestData)
    await postAnalytics(domain, request(requestData))
  })

  // `play_stop` event
  analytics.sendAnalyticsStopPauseWatchingFx.use(async ({ domain, data }) => {
    const requestData = {
      ...common(data),
      event_type: data.eventType,
      event_parameter1: data.contentId,
      event_parameter2: data.contentType,
      event_parameter3: data.contentUrl,
      event_parameter4: data.audioLanguage,
      event_parameter5: data.textLanguage,
      event_parameter6: data.startedTime,
      event_parameter7: data.duration,
    }
    logger.info('sendAnalyticsStopPauseWatching:', requestData)
    await postAnalytics(domain, request(requestData))
  })

  analytics.sendAnalyticsWatchingContentFx.use(async ({ domain, data }) => {
    const requestData = {
      ...common(data),
      content_id: data.contentId,
      content_url: data.contentUrl,
      content_title: data.contentTitle,
      content_programm: data.contentProgramm,
      content_type: data.contentType,
      content_open_from: data.contentOpenFrom,
      content_is_success_show: data.contentIsSuccessShow,
      content_error_code: data.contentErrorCode,
    }
    logger.info('sendAnalyticsWatchingContent:', requestData)
    await postAnalytics(domain, requestWatchingContent(requestData))
  })
}
