import type {
  PlaylistSeason,
  PurchaseInfo,
  PurchaseInfoContentPurchaseType,
  TvShow,
  TvShowEpisode,
  TvShowSeason,
  TvShowUpdated,
} from '@setplex/tria-api'
import {
  MediaTypes as MediaTypesEnum,
  PurchaseInfoContentPurchaseType as PurchaseInfoContentPurchaseTypeEnum,
} from '@setplex/tria-api'
import {
  type EpisodeDto,
  type SeasonDto,
  type TvShowDto,
} from '@setplex/wbs-api-types'
import type { IFormatSeasonPlaylistParams } from '../../interfaces/tvshows'
import {
  getBackgroundImage,
  getHorizontalImage,
  getVerticalImage,
} from '../../utils/images'
import { formatDuration, humanizeLengthWithoutSeconds } from '../../utils/media'
import {
  getMinPriceObject,
  getTvShowContentMinPriceObject,
} from '../../utils/minPriceObj'
import { formatPriceSettings } from '../../utils/payment'

// TODO fix sizes later as they in test mode before show
export const formatShow = ({
  landscapeImageUrl,
  portraitImageUrl,
  backgroundImageUrl,
  isTrailerExists,
  free,
  priceSettings,
  purchaseInfo,
  ...rest
}: TvShowDto): TvShow => {
  const minPriceObj = getMinPriceObject(priceSettings)

  return {
    id: rest.id!,
    landscapeImageUrl: getHorizontalImage(landscapeImageUrl),
    portraitImageUrl: getBackgroundImage(portraitImageUrl),
    backgroundImageUrl: getBackgroundImage(backgroundImageUrl),
    trailerPresent: Boolean(isTrailerExists),
    mediaType: MediaTypesEnum.TV_SHOW,
    free: free === undefined ? true : free, // Set field free is true for Nora 1.46 version, where there is no field free
    priceSettings: formatPriceSettings(priceSettings),
    minCurrency: minPriceObj?.currency,
    minPrice: minPriceObj?.price,
    purchaseInfo: purchaseInfo as PurchaseInfo | undefined,
    rented:
      purchaseInfo?.contentPurchaseType ===
      PurchaseInfoContentPurchaseTypeEnum.Rented,
    ...rest,
  }
}

export const formatSeason = ({
  landscapeImageUrl,
  portraitImageUrl,
  backgroundImageUrl,
  free,
  seasonPriceSettings,
  tvShowPriceSettings,
  purchaseInfo,
  year,
  ...rest
}: SeasonDto): TvShowSeason => {
  const minPriceObj = getTvShowContentMinPriceObject({
    seasonPriceSettings,
    tvShowPriceSettings,
  })

  return {
    id: rest.id!,
    landscapeImageUrl: getHorizontalImage(landscapeImageUrl),
    portraitImageUrl: getVerticalImage(portraitImageUrl),
    backgroundImageUrl: getBackgroundImage(backgroundImageUrl),
    free: free === undefined ? true : free, // Set field free is true for Nora 1.46 version, where there is no field free
    seasonPriceSettings: formatPriceSettings(seasonPriceSettings),
    tvShowPriceSettings: formatPriceSettings(tvShowPriceSettings),
    minCurrency: minPriceObj?.currency,
    minPrice: minPriceObj?.price,
    purchaseInfo: (purchaseInfo as PurchaseInfo) || null,
    rented:
      purchaseInfo?.contentPurchaseType ===
      PurchaseInfoContentPurchaseTypeEnum.Rented,
    year: year !== undefined ? String(year) : undefined,
    ...rest,
  }
}

function formatTvShowPurchaseType(
  type: 'BOUGHT' | 'RENTED' | 'RENTED_UNLIMITED' | undefined
): PurchaseInfoContentPurchaseType {
  if (type === 'BOUGHT') return PurchaseInfoContentPurchaseTypeEnum.Bought
  if (type === 'RENTED_UNLIMITED')
    return PurchaseInfoContentPurchaseTypeEnum.RentedUnlimited

  return PurchaseInfoContentPurchaseTypeEnum.Rented
}

export const formatEpisode = ({
  landscapeImageUrl,
  portraitImageUrl,
  backgroundImageUrl,
  length,
  free,
  episodePriceSettings,
  seasonPriceSettings,
  tvShowPriceSettings,
  purchaseInfo,
  ...rest
}: EpisodeDto): TvShowEpisode => {
  const minPriceObj = getTvShowContentMinPriceObject({
    episodePriceSettings,
    seasonPriceSettings,
    tvShowPriceSettings,
  })

  const showPurchaseInfo = purchaseInfo
    ? {
        ...purchaseInfo,
        contentPurchaseType: formatTvShowPurchaseType(
          purchaseInfo?.contentPurchaseType
        ),
      }
    : undefined

  return {
    landscapeImageUrl: getHorizontalImage(landscapeImageUrl),
    portraitImageUrl: getVerticalImage(portraitImageUrl),
    backgroundImageUrl: getBackgroundImage(backgroundImageUrl),
    length: humanizeLengthWithoutSeconds({ length }),
    free: free === undefined ? true : free, // Set field free is true for Nora 1.46 version, where there is no field free
    episodePriceSettings: formatPriceSettings(episodePriceSettings),
    seasonPriceSettings: formatPriceSettings(seasonPriceSettings),
    tvShowPriceSettings: formatPriceSettings(tvShowPriceSettings),
    minCurrency: minPriceObj?.currency,
    minPrice: minPriceObj?.price,
    duration: formatDuration({ length }),
    purchaseInfo: showPurchaseInfo,
    ...rest,
  }
}

export const formatTvShowUpdatedOnly = ({
  id,
  updated,
}: TvShowDto): TvShowUpdated => {
  return {
    id,
    updated,
  }
}

export const formatSeasonPlaylist = ({
  season,
  episodes,
  episodesAmount,
}: IFormatSeasonPlaylistParams): PlaylistSeason => {
  return {
    ...season,
    episodesAmount: episodesAmount || 0,
    episodes: episodes || [],
  }
}
