import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
  type Unit,
  type UnitTargetable,
} from 'effector'

// this is multipurpose effector operator, it by design can accept any effector units
/* eslint-disable @typescript-eslint/no-explicit-any */

export function holdup({
  target,
  cancel,
}: {
  target: UnitTargetable<void>
  cancel?: Unit<any> | Unit<any>[]
}) {
  const clock = createEvent<number>()

  const saveCancel = createEvent<[NodeJS.Timeout, () => void]>()
  const $cancel = createStore<[NodeJS.Timeout, () => void] | []>([]) //
    .on(saveCancel, (_, payload) => payload)

  const cancelFx = attach({
    source: $cancel,
    effect([timeoutId, rejectPromise]) {
      if (timeoutId) clearTimeout(timeoutId)
      if (rejectPromise) rejectPromise()
    },
  })

  const timeoutFx = createEffect(
    (timeout: number) =>
      new Promise((resolve, reject) =>
        saveCancel([setTimeout(resolve, timeout), reject])
      )
  )

  $cancel.reset(timeoutFx.done, cancelFx.done)

  if (cancel) {
    sample({
      clock: cancel as Unit<any>,
      target: cancelFx,
    })
  }

  sample({
    clock,
    target: [cancelFx, timeoutFx],
  })

  sample({
    clock: timeoutFx.done,
    target,
  })

  return clock
}
